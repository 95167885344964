import { Form, Input, InputNumber } from 'antd'
import { isEqual, omit } from 'lodash'
import React, { useEffect } from 'react'

import { formatNumberFractionDigits } from '~/utils/helper'
import { validateInput } from '~/utils/stringUtils'

interface EditableCellProps {
  record: any
  dataIndex: string
  editing?: boolean
  formItemName?: string
  suffix?: string
  title?: any
  inputType?: 'numberSuffix' | 'number' | 'text'
  index?: number
  children?: React.ReactNode
  onBlur?: any
  setFieldValue?: any
  VirtualizedCell?: any
}

const EditableCell = ({
  record,
  dataIndex,
  editing,
  formItemName,
  suffix,
  inputType,
  children,
  onBlur,
  setFieldValue,
  VirtualizedCell = null,
  ...restProps
}: EditableCellProps) => {
  const initialValue = record?.[dataIndex]

  const actionBlur = (event: React.SyntheticEvent<Element>) => {
    let inputValue = event.target.value
    if (inputValue === '') {
      inputValue = null
    }
    if (isEqual(inputValue?.toString(), initialValue?.toString())) return

    if (onBlur) onBlur()
  }

  const renderInputNode = () => {
    if (inputType === 'numberSuffix') {
      return <Input type="number" className="rounded w-11/12 text-right" suffix={suffix} onBlur={actionBlur} />
    } else if (inputType === 'number') {
      return (
        <InputNumber
          className="rounded w-11/12 text-right"
          controls={false}
          formatter={(value) => {
            validateInput(value, true)
            return formatNumberFractionDigits(value, 'ja-JP')
          }}
          parser={(value) => {
            validateInput(value)
            return value?.replace(/\$\s?|(,*)/g, '') || ''
          }}
          onBlur={actionBlur}
        />
      )
    }

    return <Input className="rounded w-11/12 " onBlur={actionBlur} />
  }

  useEffect(() => {
    if (record && editing) {
      setFieldValue(formItemName, record[dataIndex])
    }
  }, [record, editing])

  const renderChildNode = () => {
    return editing ? (
      <Form.Item name={formItemName} style={{ margin: 0 }} initialValue={initialValue}>
        {renderInputNode()}
      </Form.Item>
    ) : (
      children
    )
  }

  if (VirtualizedCell) {
    return <VirtualizedCell {...restProps}>{renderChildNode()}</VirtualizedCell>
  }

  return <td {...omit(restProps, ['title'])}>{renderChildNode()}</td>
}

export default EditableCell
