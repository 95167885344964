import { Button as AntdButton, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'

import { getCompanyOffices, getOfficeApi, getProjectSections, getProjectStaffs, postProjectEstimate } from '~/api/project'
import DatePickerIcon from '~/assets/icons/DatePickerIcon'
import { CompanyOffice, CreateProjectEstimateData, ProjectSection, ProjectStaff } from '~/interfaces/project'
import { pushNotification } from '~/utils/helper'

import HeaderModalAdd from './headerModal'

interface EstimateProps {
  projectName?: string
  propertyNumber: string
  onCancel: () => void
  onSuccess: () => void
}

const ModalEstimate = ({ onCancel, projectName, propertyNumber, onSuccess }: EstimateProps) => {
  const { t } = useTranslation()
  const buttonSubmitForm = useRef<any>(null)

  const { id: projectId } = useParams()

  const [companyOffices, setCompanyOffices] = useState<CompanyOffice[]>([])
  const [offices, setOffices] = useState([])
  const [projectSections, setProjectSections] = useState<ProjectSection[]>([])
  const [projectStaffs, setProjectStaffs] = useState<ProjectStaff[]>([])
  const [creating, setCreating] = useState<boolean>()

  useEffect(() => {
    form.setFieldValue('estimate_name', projectName)

    getCompanyOffices(propertyNumber)
      .then((result) => {
        const data = result.data.data
        setCompanyOffices(data)
      })
      .catch((e) => {
        pushNotification(e.message, 'error')
      })

    getOfficeApi({ params: { sort: 'asc' } })
      .then((result) => {
        const offices = result.data.data.data
        setOffices(offices)
        if (offices[0] && offices[0].is_selected_default) {
          form.setFieldValue('office_id', offices[0].id)
          getProjectSectionByOfficeId(offices[0].id, true)
        } else {
          form.setFieldValue('office_id', undefined)
        }
      })
      .catch((e) => {
        pushNotification(e.message, 'error')
      })
  }, [])

  const getProjectSectionByOfficeId = async (officeId: string, selectStaffDefault?: boolean) => {
    form.setFieldValue('project_sections', undefined)
    form.setFieldValue('staff_number', undefined)
    setProjectSections([])
    setProjectStaffs([])
    try {
      const result = await getProjectSections(officeId, 'asc', 'number')
      const projectSections = result.data.data.data
      setProjectSections(projectSections)
      if (projectSections[0] && projectSections[0].default) {
        form.setFieldValue('project_sections', projectSections[0].id)
        getProjectStaffsBySectionNumber(projectSections[0].number, selectStaffDefault)
      }
    } catch (e) {
      pushNotification((e as any).message, 'error')
    }
  }

  const getProjectStaffsBySectionNumber = async (sectionNumber: string, selectDefault?: boolean) => {
    form.setFieldValue('staff_number', undefined)
    setProjectStaffs([])
    try {
      const result = await getProjectStaffs(sectionNumber, 'asc', 'number')
      const staffs = result.data.data.data
      setProjectStaffs(staffs)
      if (staffs[0] && staffs[0].default && selectDefault) {
        form.setFieldValue('staff_number', staffs[0].number)
      }
    } catch (e) {
      pushNotification((e as any).message, 'error')
    }
  }

  const onSelectSection = (id: string) => {
    const section = projectSections.find((section: ProjectSection) => section.id === id) as ProjectSection
    if (section) {
      getProjectStaffsBySectionNumber(section.number)
    }
  }

  const onSelectOffice = (id: string) => {
    const office = offices.find((office) => office.id === id)
    if (office) {
      getProjectSectionByOfficeId(office.id)
    }
  }

  const [form] = Form.useForm()

  const handleFinish = async (values: any) => {
    const data: CreateProjectEstimateData = {
      submission_deadline: values.submission_deadline?.format('YYYY-MM-DD'),
      estimate_name: values.estimate_name,
      project_id: projectId as string,
      customer_code: values.company_code,
      staff_number: values.staff_number,
    }
    setCreating(true)
    try {
      await postProjectEstimate(data)
      setCreating(false)
      onSuccess()
    } catch (e) {
      pushNotification((e as any).message, 'error')
      setCreating(false)
    }
  }

  return (
    <Modal
      className="modal-estimate"
      open
      title={<HeaderModalAdd />}
      okText="OK"
      okButtonProps={{ className: 'h-9 w-32', size: 'small' }}
      onOk={() => {
        buttonSubmitForm.current.click()
      }}
      cancelText={t('modal.cancel_text')}
      cancelButtonProps={{ type: 'link', size: 'small' }}
      onCancel={onCancel}
      width="60rem"
      mask={true}
      centered
      closable={false}
      confirmLoading={creating}
    >
      <>
        <Form form={form} onFinish={handleFinish} className="relative">
          <Row>
            <Col span={3}>
              <div className="text-base font-bold mb-4">見積ID</div>
              <Form.Item name="estimate_number">
                <Button className="h-10" text="ID取得" isCurrentNotImplemented />
              </Form.Item>
            </Col>
            <Col span={21}>
              <div className="text-base font-bold mb-4">見積名</div>
              <Form.Item name="estimate_name">
                <Input className="rounded" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="w-full ">
            <Col span={24}>
              <div className="text-base font-bold mb-4">提出先</div>
              <Input.Group className="flex justify-between space-x-2">
                <Form.Item name="company_code" className="w-11/12 ">
                  <Select className="rounded" size="large">
                    {companyOffices.map((companyOffice: CompanyOffice) => (
                      <Select.Option key={companyOffice.company_code}>{`${companyOffice.company_name}${
                        companyOffice.company_office_name ? ' ' + companyOffice.company_office_name : ''
                      }`}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-base font-bold mb-4">見積提出期限</div>
              <Form.Item name="submission_deadline">
                <DatePicker className="rounded" size="large" placeholder="yyyy/mm/dd" suffixIcon={<DatePickerIcon />} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="text-base font-bold mb-4">担当</Row>
          <Row className="w-full ">
            <Col span={12}>
              <div className="text-sm mb-4">担当支部</div>
              <Input.Group className="flex justify-between space-x-2">
                <Form.Item name="office_id" className="w-11/12">
                  <Select onChange={onSelectOffice}>
                    {offices.map((office) => (
                      <Select.Option key={office.id}>{office.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Col>
          </Row>
          <Row className="w-full space-x-6 ">
            <Col span={12}>
              <div className="text-sm mb-4">見積担当課</div>
              <Input.Group className="flex justify-between space-x-2">
                <Form.Item name="project_sections" className="w-11/12 ">
                  <Select onChange={onSelectSection}>
                    {projectSections.map((projectSection: ProjectSection) => (
                      <Select.Option key={projectSection.id}>{projectSection.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Col>
            <Col span={11}>
              <div className="text-sm mb-4">見積担当者</div>
              <Input.Group className="flex justify-between space-x-2">
                <Form.Item name="staff_number" className="w-11/12">
                  <Select>
                    {projectStaffs.map((staff: ProjectSection) => (
                      <Select.Option key={staff.number}>{staff.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Col>
          </Row>
          <AntdButton ref={buttonSubmitForm} htmlType="submit" className="hidden" />
        </Form>
      </>
    </Modal>
  )
}

export default ModalEstimate
