import Spacer from '@components/atoms/Spacer'
import { Tag } from 'antd'
import React from 'react'

import Button from '~/components/atoms/Button'
import ChartLineCommon from '~/components/organisms/MValue/chartLineCommon'
import FooterMValue from '~/components/organisms/MValue/footerMValueDetail'
import HeaderMValue from '~/components/organisms/MValue/headerMValueDetail'

import ArrowLeft from '~/assets/icons/ArrowLeftIcon'

interface MValueDetailProps {
  handleOnClickGraph: (detail: boolean) => any
}

const DATA_CHART_DEMO = {
  dataSourceLeft: [
    [2, 1388888],
    [6, 980006.6],
    [10, 780006.4],
    [15, 580006.0],
    [30, 300006.5],
    [45, 174006.7],
    [60, 130006.7],
    [70, 100006.7],
    [80, 71299],
  ],
  dataSourceRight: [
    [2, 1388888],
    [6, 980006.6],
    [10, 780006.4],
    [15, 580006.0],
    [30, 300006.5],
    [45, 174006.7],
    [60, 130006.7],
    [70, 100006.7],
    [80, 71299],
  ],
  dimensions: ['Category', 'Value2'],
}

const OPTION_DUMMY = [{ label: '発注時金額', value: 'order_amount' }]
const MValueDetail = ({ handleOnClickGraph }: MValueDetailProps) => {
  return (
    <div className="flex flex-col w-98 h-full">
      <div className="flex w-full font-bold mt-2 items-center">
        <Button
          type="text"
          offFocus
          className="button-navigate"
          onClick={() => {
            handleOnClickGraph(false)
          }}
          prefixIcon={<ArrowLeft />}
        />
        <span className="font-bold">M値一覧へ</span>
      </div>
      <div className="flex w-full items-center mt-2">
        <div className="flex flex-col w-49d5">
          <HeaderMValue optionsMValue={OPTION_DUMMY} />
          <div className="flex flex-col w-full rounded bg-white mt-1">
            <div className="flex w-full ml-4 mt-4 items-center">
              <div className="mr-2 text-2xl font-bold">M値単体</div>
              <Tag color="#2A7856" className="rounded-lg w-20 text-center">
                ダクト
              </Tag>
              <Tag color="#2A7856" className="rounded-lg w-20 text-center">
                亜鉛...
              </Tag>
              <Tag color="#2A7856" className="rounded-lg w-20 text-center">
                名古屋
              </Tag>
            </div>
            <div className="flex w-full justify-center">
              <ChartLineCommon dataChart={DATA_CHART_DEMO.dataSourceLeft} dimensionsChart={DATA_CHART_DEMO.dimensions} />
            </div>
          </div>
          <FooterMValue />
        </div>
        <Spacer />
        <div className="flex flex-col w-49d5">
          <div className="flex w-full">
            <HeaderMValue optionsMValue={OPTION_DUMMY} />
          </div>
          <div className="flex flex-col w-full rounded bg-white mt-1">
            <div className="flex w-full ml-4 mt-4 items-center">
              <div className="mr-2 text-2xl font-bold">M値単体</div>
              <Tag color="#2A7856" className="rounded-lg w-20 text-center">
                ダクト
              </Tag>
              <Tag color="#2A7856" className="rounded-lg w-20 text-center">
                亜鉛...
              </Tag>
              <Tag color="#2A7856" className="rounded-lg w-20 text-center">
                名古屋
              </Tag>
            </div>
            <div className="flex w-full justify-center">
              <ChartLineCommon dataChart={DATA_CHART_DEMO.dataSourceRight} />
            </div>
          </div>
          <FooterMValue />
        </div>
      </div>
      <div className="flex w-full mt-5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
    </div>
  )
}

export default MValueDetail
