import { Checkbox, InputNumber, Modal, Tabs, TabsProps } from 'antd'
import get from 'lodash/get'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import SegmentedButton from '~/components/atoms/SegmentedButton'
import TreeSelectCustom from '~/components/atoms/TreeSelect'
import ButtonForAirCondition from '~/components/molecules/AirConditionButton/GroupButton'
import TableDeleteMachine from '~/components/molecules/TableDeleteMachine'
import BodyModalEdit from '~/components/organisms/ModalChartDevice/BodyModalEdit'
import TitleModal from '~/components/organisms/ModalChartDevice/TitleModalEdit'

import { apiAutoGroupNumber, apiDeleteMachine, apiInquiries, apiReInquiries, apiUpdateGroupNumber } from '~/api/airCondition'
import { apiMachineDetailForChart, apiUpdateEcost } from '~/api/chart'
import ArrowDownIcon from '~/assets/icons/ArrowDownIcon'
import ArrowUpIcon from '~/assets/icons/ArrowUpIcon'
import AutoGroupIcon from '~/assets/icons/AutoGroupIcon'
import CraftGroup from '~/assets/icons/CraftGroupIcon'

import { ADD_MACHINE_TYPE, BUTTON_TYPE, EQUIPMENT_COST_TABS, MACHINE_KEY, POSITION_SEGMENTED_BUTTON } from '~/consts/common'
import AddMachine from '~/features/addMachine/step1'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { machines, setInfoMachine } from '~/redux/reducers/machine.slice'
import type { NotificationType } from '~/utils/helper'
import { handleError, pushNotification } from '~/utils/helper'
import { getLocalStorage, setLocalStorage } from '~/utils/storage'

import Individual from './individual'

import '~/assets/styles/feature/airCondition.scss'
import '~/assets/styles/feature/chart.scss'

interface airConditionProps {
  keyScreen?: any
}

const airCondition = ({ keyScreen }: airConditionProps) => {
  const { t } = useTranslation()

  const [show, setShow] = useState(false)
  const [tab, setTab] = useState<any>(EQUIPMENT_COST_TABS.INDIVIDUAL)
  const [isStep, setIsStep] = useState<boolean>(false)
  const [checkReloadIndividual, setCheckReloadIndividual] = useState<boolean>(false)
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false)
  const [isLoadingReInquiry, setIsLoadingReInquiry] = useState<boolean>(false)
  const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [mainElement, setMainElement] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [isGroup, setIsGroup] = useState<number>(0)
  const [groupNumber, setGroupNumber] = useState<any>([])
  const [isSelectRow, setIsSelectRow] = useState<boolean>(false)
  const [isDeleteRow, setDeleteRow] = useState<boolean>(false)
  const [isEditRows, setEditRows] = useState<boolean>(false)
  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false)
  const buttonSubmitForm = useRef<any>(null)
  const buttonCancel = useRef<any>(null)
  const [recordId, setRecordId] = useState<string>('')
  const [keyDelete, setKeyDelete] = useState<string[]>([])
  const [isOpenReInquiryPopup, setIsOpenReInquiryPopup] = useState<boolean>(false)
  const [isSendNotifyReInquiry, setIsSendNotifyReInquiry] = useState<boolean>(false)

  const params = useParams()
  const dispatch = useAppDispatch()
  const { infoMachine, dataTable: machineList } = useAppSelector(machines)

  //TODO: Demo to Delete
  const [demoChart, setDemoChart] = useState<boolean>(false)

  // Area Action
  const showStep = () => setIsStep(!isStep)
  const toggleShow = () => setShow((p) => !p)
  const onClickGroup = () => setVisible(true)
  const getMachine = () => {
    setCheckReloadIndividual(true)
  }
  const handleChangeTab = (active) => {
    setSelectedRowKeys([])
    setMainElement(false)
    setEditRows(false)
    switch (Number(active)) {
      case 1:
        setTab(EQUIPMENT_COST_TABS.INDIVIDUAL)
        setLocalStorage('activeTabLevel-3', `${EQUIPMENT_COST_TABS.INDIVIDUAL.id}`)
        break
      case 2:
        setTab(EQUIPMENT_COST_TABS.ELEMENT)
        setLocalStorage('activeTabLevel-3', `${EQUIPMENT_COST_TABS.ELEMENT.id}`)
        break
      case 3:
        setTab(EQUIPMENT_COST_TABS.GROUP)
        setLocalStorage('activeTabLevel-3', `${EQUIPMENT_COST_TABS.GROUP.id}`)
        break
      default:
        break
    }
  }
  const handleUpdateStatus = async () => {
    await onUpdateStatusIndividual()
  }

  const handleReInquiry = async () => {
    await onReInquiry()
  }

  const handleCloseReInquiryPopup = () => {
    setIsOpenReInquiryPopup(false)
    setIsSendNotifyReInquiry(false)
  }

  const handleAutomaticGroup = () => {
    switch (tab) {
      case EQUIPMENT_COST_TABS.INDIVIDUAL:
        onAutomaticGroup()
        break
      case EQUIPMENT_COST_TABS.GROUP:
        onAutomaticGroup()
        break
      case EQUIPMENT_COST_TABS.ELEMENT:
        onAutomaticGroup()
        break
      default:
        break
    }
  }
  const handleClickGroup = () => {
    switch (tab) {
      case EQUIPMENT_COST_TABS.INDIVIDUAL:
        onClickGroup()
        break
      case EQUIPMENT_COST_TABS.GROUP:
        break
      case EQUIPMENT_COST_TABS.ELEMENT:
        break
      default:
        break
    }
  }
  const handleCancelDelete = () => {
    setDeleteRow(false)
  }
  const changeInputNumber = (value: any) => {
    setIsGroup(value)
  }
  const treeSelectParam = {
    left: (
      <div className="mr-1.5 w-[22rem] h-[38px] mt-[10px] custom-select-tree bg-red">
        <TreeSelectCustom keyScreen={keyScreen} />
      </div>
    ),
  }
  const handleCancelGroup = () => {
    setVisible(false)
  }
  const onSubmit = () => {
    buttonSubmitForm.current.click()
  }
  const onCancel = () => {
    buttonCancel.current.click()
    setEditRows(!isEditRows)
    setSelectedRowKeys([])
  }
  const checkKeyAdd = useMemo(() => {
    switch (keyScreen) {
      case MACHINE_KEY.laborCost:
        return ADD_MACHINE_TYPE.AIR_CONDITION_TYPE
      case MACHINE_KEY.outSourceCost:
        return ADD_MACHINE_TYPE.OUTSOURCE_COST_TYPE
      case MACHINE_KEY.expenses:
        return ADD_MACHINE_TYPE.EXPENSES_TYPE
      default:
        return ADD_MACHINE_TYPE.INDIVIDUAL_TYPE
    }
  }, [keyScreen])

  // Area Config Tab
  const listTab = [
    {
      id: 1,
      title: t('air_condition.individual_display'),
      component: (
        <Individual
          show={show}
          toggleShow={toggleShow}
          reload={checkReloadIndividual}
          setSelectedRowKeys={setSelectedRowKeys}
          setMainElement={setMainElement}
          setIsGroup={setIsGroup}
          setGroupNumber={setGroupNumber}
          selectedRowKeys={selectedRowKeys}
          setIsSelectRow={setIsSelectRow}
          setCheckReloadIndividual={setCheckReloadIndividual}
          isEditRows={isEditRows}
          setEditRows={setEditRows}
          buttonSubmitForm={buttonSubmitForm}
          buttonCancel={buttonCancel}
          setDemoChart={setDemoChart}
          setRecordId={setRecordId}
          keyScreen={keyScreen}
          keyTab={'machine'}
        />
      ),
    },
    {
      id: 2,
      title: t('air_condition.element_by_element'),
      component: (
        <Individual
          show={show}
          toggleShow={toggleShow}
          reload={checkReloadIndividual}
          setSelectedRowKeys={setSelectedRowKeys}
          setMainElement={setMainElement}
          setIsGroup={setIsGroup}
          setGroupNumber={setGroupNumber}
          selectedRowKeys={selectedRowKeys}
          setIsSelectRow={setIsSelectRow}
          setCheckReloadIndividual={setCheckReloadIndividual}
          isEditRows={isEditRows}
          setEditRows={setEditRows}
          buttonSubmitForm={buttonSubmitForm}
          buttonCancel={buttonCancel}
          setDemoChart={setDemoChart}
          setRecordId={setRecordId}
          keyScreen={keyScreen}
          keyTab={'machineInElement'}
        />
      ),
    },
    {
      id: 3,
      title: t('air_condition.group_display'),
      component: (
        <Individual
          show={show}
          toggleShow={toggleShow}
          reload={checkReloadIndividual}
          setSelectedRowKeys={setSelectedRowKeys}
          setMainElement={setMainElement}
          setIsGroup={setIsGroup}
          setGroupNumber={setGroupNumber}
          selectedRowKeys={selectedRowKeys}
          setIsSelectRow={setIsSelectRow}
          setCheckReloadIndividual={setCheckReloadIndividual}
          isEditRows={isEditRows}
          setEditRows={setEditRows}
          buttonSubmitForm={buttonSubmitForm}
          buttonCancel={buttonCancel}
          setDemoChart={setDemoChart}
          setRecordId={setRecordId}
          keyScreen={keyScreen}
          keyTab={'machineInGroup'}
        />
      ),
    },
  ]
  const items: any = listTab.map((item, index) => ({
    label: (
      <SegmentedButton
        position={
          index === 0 ? POSITION_SEGMENTED_BUTTON.left : index === listTab.length - 1 ? POSITION_SEGMENTED_BUTTON.right : POSITION_SEGMENTED_BUTTON.center
        }
        text={item.title}
        isSelected={tab.id === item.id}
      />
    ),
    key: item.id,
    children: item.component,
  }))

  //  Area Customer UI
  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
    return (
      <div className="tabBar-render">
        <DefaultTabBar className="shadow-none" {...props} />
        <div className="tabBar-gr-button">
          <ButtonForAirCondition
            isSelectRow={isSelectRow}
            isLoadingGroup={isLoadingGroup}
            selectedRowKeys={selectedRowKeys}
            isLoadingStatus={isLoadingStatus}
            isLoadingReInquiry={isLoadingReInquiry}
            setShowAddModal={showStep}
            isDeleteRow={isDeleteRow}
            isEditRows={isEditRows}
            handleUpdateStatus={handleUpdateStatus}
            handleAutomaticGroup={handleAutomaticGroup}
            handleClickGroup={handleClickGroup}
            handleDeleteRow={() => setDeleteRow(!isDeleteRow)}
            handleEditRows={() => setEditRows(!isEditRows)}
            onSuccessUploadCSV={handleSuccessUploadCSV}
            onReInquiry={() => setIsOpenReInquiryPopup(true)}
          />
        </div>
      </div>
    )
  }
  const ButtonForGroup = () =>
    useMemo(() => {
      const renderClass = `${selectedRowKeys.length === 1 || !mainElement ? 'text-[#A3A3A3] cursor-not-allowed' : 'text-[#009BE0]'} text-sm font-medium`
      return (
        <div className="flex w-fit space-x-2">
          <span className={renderClass} onClick={() => (selectedRowKeys.length === 1 || !mainElement ? null : onClickGroup())}>
            <span className="flex">
              <span className="mr-1">
                <CraftGroup isDisable={selectedRowKeys.length === 1 || !mainElement} />
              </span>
              グループ化
            </span>
          </span>
          <span className="text-[#009BE0] text-sm font-medium" onClick={onAutomaticGroup}>
            <span className="flex">
              <span className="mr-1">
                <AutoGroupIcon />
              </span>
              自動グループ化
            </span>
          </span>
        </div>
      )
    }, [mainElement, selectedRowKeys])

  const objectRegistrationNumbers = (objectNumber: number | string, data: any, hierarchy: string[] = []): string => {
    const stack = [...data]

    while (stack.length) {
      const obj = stack.pop()

      if (obj.object_registrations_number === objectNumber) {
        const childNum = obj.object_registrations_number
        return [...hierarchy, childNum]
          .filter(Boolean)
          .map((num) => `「${num}」`)
          .join(' ')
      }

      if (obj.children && obj.children.length) {
        const childHierarchy = [...hierarchy, obj.object_registrations_number]
        for (const child of obj.children) {
          const result = objectRegistrationNumbers(objectNumber, [child], childHierarchy)
          if (result) {
            return result
          }
        }
      }
    }

    return ''
  }

  const syncInquiriesMessageDisplay = (responseData?: any) => {
    const { status = null, data } = responseData || {}
    const type = [2, 4].includes(status as number) ? 'error' : 'success'
    let message: any = ''

    switch (status) {
      case 1:
        message = '引合連携が成功しました'
        break
      case 2:
      case 4:
        message = '引合連携が失敗しました'
        break
      case 3: {
        const machineTabType = {
          [EQUIPMENT_COST_TABS.INDIVIDUAL.id]: '個別',
          [EQUIPMENT_COST_TABS.ELEMENT.id]: '要素',
          [EQUIPMENT_COST_TABS.GROUP.id]: 'グループ',
        }[tab.id]
        const successMsg = 'の引合連携が成功しました'
        const errorMsg = 'の引合連携が失敗しました'
        message = (
          <>
            {data
              .filter((error: any) => groupNumber.map((item: any) => item.id).includes(error.id))
              .map((error: any, index: number) => {
                const numbers = objectRegistrationNumbers(
                  get(
                    groupNumber.find((item: any) => item.id === error.id),
                    'object_registrations_number'
                  ),
                  machineList
                )
                return (
                  <React.Fragment key={index}>
                    <span>{`• ${machineTabType}ID ${numbers} ${error.status ? successMsg : errorMsg}`}</span>
                    {index < data.length - 1 && <br />}
                  </React.Fragment>
                )
              })}
          </>
        )
        break
      }

      default:
        break
    }

    return { type, message }
  }

  // Area Call API
  // 1. API for chart modal
  const getInfoApi = async () => {
    try {
      setIsLoadingChart(true)
      const { data } = await apiMachineDetailForChart(recordId)
      dispatch(setInfoMachine(data.data))
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingChart(false)
    }
  }
  // 2. API Update machine
  const handleUpdateMachine = async () => {
    const convertValueY = Number(infoMachine.e_cost.replaceAll(',', ''))
    try {
      setIsLoadingChart(true)
      await apiUpdateEcost({
        object_registration_id: recordId,
        e_cost: (Math.round(convertValueY * 100) / 100).toFixed(0),
      })
      setCheckReloadIndividual(true)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingChart(false)
      setDemoChart(false)
    }
  }
  // 3. API Update GroupNumber
  const handleOkModal = async () => {
    try {
      await apiUpdateGroupNumber({
        object_registration_id: groupNumber.map((item) => item.id),
        group_number: isGroup,
      })
      getMachine()
      setVisible(false)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }
  // 4. API delete
  const handleOkDelete = async () => {
    const dataRequestDelete = keyDelete.filter((item) => !item.includes('-parent')).map((item) => ({ id: item }))
    try {
      await apiDeleteMachine({
        idEstimate: params.idEstimate,
        filter: keyScreen,
        data: dataRequestDelete,
        screen: tab.name,
      })
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      getMachine()
      setDeleteRow(false)
      setSelectedRowKeys([])
    }
  }
  // 5. API AutomaticGroup
  const onAutomaticGroup = async () => {
    setIsLoadingGroup(true)
    setCheckReloadIndividual(true)
    try {
      await apiAutoGroupNumber({
        idEstimate: params.idEstimate,
        filter: keyScreen,
      })
      getMachine()
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingGroup(false)
    }
  }

  // 7. API UpdateStatusIndividual
  const onUpdateStatusIndividual = async () => {
    setIsLoadingStatus(true)
    const data = groupNumber.map((item: any) => item.id)
    try {
      const response = await apiInquiries({
        object_registration_id: data,
        idEstimate: params.idEstimate,
      })
      const { type, message } = syncInquiriesMessageDisplay(response?.data.data)
      pushNotification(message, type as NotificationType, 5, get(response, 'data.data.status') === 3 ? 'notification-inquiries' : '')
      setTimeout(() => {
        setCheckReloadIndividual(true)
      }, 1000)
      setIsLoadingStatus(false)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingStatus(false)
    }
  }

  // 8. API reInquiry
  const onReInquiry = async () => {
    setIsLoadingReInquiry(true)
    try {
      await apiReInquiries({
        idEstimate: params.idEstimate,
        isSendNotify: isSendNotifyReInquiry,
      })
      setTimeout(() => {
        setCheckReloadIndividual(true)
      }, 1000)
      setIsLoadingReInquiry(false)
      handleCloseReInquiryPopup()
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingReInquiry(false)
    }
  }

  const handleSuccessUploadCSV = () => {
    setTimeout(() => {
      setCheckReloadIndividual(true)
    }, 1000)
  }

  useEffect(() => {
    const storedActiveTab = getLocalStorage('activeTabLevel-3', '1')
    if (storedActiveTab) {
      switch (Number(storedActiveTab)) {
        case 1:
          setTab(EQUIPMENT_COST_TABS.INDIVIDUAL)
          break
        case 2:
          setTab(EQUIPMENT_COST_TABS.ELEMENT)
          break
        case 3:
          setTab(EQUIPMENT_COST_TABS.GROUP)
          break
        default:
          break
      }
    }
  }, [])
  // Area useEffect
  useEffect(() => {
    if (recordId) {
      getInfoApi()
    }
  }, [recordId])

  useEffect(() => {
    if (selectedRowKeys.length < 1) {
      setIsSelectRow(false)
    } else {
      setEditRows(false)
      setDeleteRow(false)
    }
  }, [selectedRowKeys])

  return (
    <div className="h-full">
      <div className="main-layout h-full ml-6">
        <div className="flex tab-main-layout mb-16">
          <div className="pt-6 pr-4 w-full overflow-hidden">
            <div className="w-full relative device-edit">
              <Tabs
                tabBarExtraContent={treeSelectParam}
                renderTabBar={renderTabBar}
                activeKey={tab.id}
                onChange={handleChangeTab}
                type="editable-card"
                className="none"
                items={items}
                addIcon={<ButtonForGroup />}
                destroyInactiveTabPane={true}
              />
            </div>
          </div>
        </div>
        <div className="w-full absolute bottom-0 right-0 h-16 bg-white flex justify-end px-6 py-3 z-10">
          <Button type="text" disabled={!isEditRows} onClick={onCancel} text="キャンセル" />
          <Button className="w-[120px]" disabled={!isEditRows} onClick={onSubmit} text="確定" />
        </div>
      </div>
      {isStep && <AddMachine keyAdd={checkKeyAdd} isStep={isStep} showStep={showStep} reloadListMachine={getMachine} />}
      <Modal
        className="modal-group"
        title={t('modal.spec_group_tag')}
        open={visible}
        onOk={handleOkModal}
        onCancel={handleCancelGroup}
        okText={t('modal.ok_text')}
        cancelText={t('modal.cancel_text')}
        centered={true}
        mask={true}
        destroyOnClose={true}
        cancelButtonProps={{ type: 'link' }}
      >
        <p>{t('modal.follow_tag')}</p>
        <div className="mt-2 text-center">
          <InputNumber
            className="w-4/6 mt-auto rounded"
            size="large"
            min={0}
            defaultValue={isGroup}
            onChange={changeInputNumber}
            controls={{ upIcon: <ArrowUpIcon />, downIcon: <ArrowDownIcon /> }}
          />
        </div>
      </Modal>
      <Modal
        className="modal-delete w-[594px]"
        title="機器削除"
        open={isDeleteRow}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        okText="OK"
        cancelText={t('modal.cancel_text')}
        centered={true}
        mask={true}
        destroyOnClose={true}
      >
        <TableDeleteMachine selectedRowKeys={groupNumber} setKeyDelete={setKeyDelete} />
      </Modal>
      <Modal
        className="modal-chart w-full"
        title={<TitleModal />}
        open={demoChart}
        onOk={handleUpdateMachine}
        onCancel={() => {
          setDemoChart(!demoChart)
        }}
        centered={true}
        mask={true}
        destroyOnClose={true}
        footer={
          <div className="flex w-full justify-end">
            <Button
              type={BUTTON_TYPE.text}
              text={t('modal.cancel_text')}
              onClick={() => {
                setDemoChart(!demoChart)
              }}
              block
              className="w-[120px]"
            />
            <Button text={'確定'} onClick={handleUpdateMachine} block className="w-[120px]" />
          </div>
        }
      >
        <BodyModalEdit objectID={recordId} setIsLoadingChart={setIsLoadingChart} isLoadingChart={isLoadingChart} />
      </Modal>
      <Modal
        className="w-[594px]"
        title={t('air_condition.inquiry_rsync')}
        open={isOpenReInquiryPopup}
        onOk={handleReInquiry}
        onCancel={handleCloseReInquiryPopup}
        okText="再連携"
        cancelText={t('modal.cancel_text')}
        centered={true}
        mask={true}
        destroyOnClose={true}
      >
        <div className="p-4">
          <span>再連携しますか？</span>
          <Checkbox className="mt-4 w-full" onChange={(event) => setIsSendNotifyReInquiry(event.target.checked)}>
            再連携に合わせ再見積もり依頼を自動で通知
          </Checkbox>
        </div>
      </Modal>
    </div>
  )
}

export default airCondition
