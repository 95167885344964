import SortIcon from '@components/atoms/SortIconTable'
import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'

import { apiGetLabor, apiGetLaborTotal } from '~/api/airCondition-7'
import PencilIcon from '~/assets/icons/PencilIcon'
import { showTotalPaginationTable, pushNotification, handleError, formatNumber } from '~/utils/helper'

interface DeviceEditProps {
  handleEdit: any
  reloadData: boolean
  setShowAddModal: () => void
  setCheckReload: any
}

interface LaborTotal {
  total_unit_price: number
  total_a0_cost: number
  total_offer_amount: number
  total_e_cost: number
}

const initialState = {
  data: [],
  per_page: 10,
  prev_page_url: null,
  to: 0,
  total: 0,
  current_page: 1,
}

const initLabor = {
  total_unit_price: 0,
  total_a0_cost: 0,
  total_offer_amount: 0,
  total_e_cost: 0,
}
const { Text } = Typography

const LaborCost = ({ handleEdit, setShowAddModal, reloadData, setCheckReload }: DeviceEditProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [listLabor, setListLabor] = useState<any>(initialState)
  const [laborTotal, setLaborTotal] = useState<LaborTotal>(initLabor)
  const [dataList, setDataList] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { data, total, current_page, from, to, per_page } = listLabor
  const params = useParams()
  const [queryParams, setQueryParams] = useState<{
    limit: number
    page: number
    order_by: string | null
    sort: string | null
  }>({
    limit: 10,
    page: 1,
    order_by: null,
    sort: null,
  })

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: true,
  }

  const columns: ColumnsType<any> = [
    // {
    //   title: ({ sortColumns }) => <SortIcon title="ID" sortColumns={sortColumns} keyName="labor_id" />,
    //   dataIndex: 'labor_id',
    //   key: 'labor_id',
    //   width: 100,
    //   defaultSortOrder: 'ascend ',
    //   sorter: true,
    // },
    {
      title: ({ sortColumns }) => <SortIcon title="要素" sortColumns={sortColumns} keyName="element" />,
      dataIndex: 'element',
      key: 'element',
      width: 120,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="工事区分" sortColumns={sortColumns} keyName="construction_section" />,
      dataIndex: 'construction_section',
      key: 'construction_section',
      defaultSortOrder: 'ascend',
      sorter: true,
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="工事種別" sortColumns={sortColumns} keyName="name" />,
      dataIndex: 'name',
      key: 'name',
      width: 180,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="数量" sortColumns={sortColumns} keyName="quantity" />,
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      defaultSortOrder: 'ascend',
      sorter: true,
      render: (text: any, record: any, index: any) => {
        return <div>{index === data.length ? <span>合計費</span> : <div className="flex justify-between  items-center">{record.quantity}</div>}</div>
      },
    },
    {
      //TODO: Confirm change text 工事単価 -> 定価単価
      title: ({ sortColumns }) => <SortIcon title="定価単価" sortColumns={sortColumns} keyName="unit_price" />,
      dataIndex: 'unit_price',
      key: 'unit_price',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      //TODO: Confirm response column 定価
      title: ({ sortColumns }) => <SortIcon title="定価" sortColumns={sortColumns} keyName="unit_price" />,
      dataIndex: 'unit_price',
      key: 'unit_price',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="A0原価" sortColumns={sortColumns} keyName="a0_cost" />,
      dataIndex: 'a0_cost',
      key: 'a0_cost',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="粗利率" sortColumns={sortColumns} keyName="a0_gross_profit_rate" />,
      dataIndex: 'a0_gross_profit_rate',
      key: 'a0_gross_profit_rate',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="提出金額" sortColumns={sortColumns} keyName="offer_amount" />,
      dataIndex: 'offer_amount',
      key: 'offer_amount',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="E原価" sortColumns={sortColumns} keyName="e_cost" />,
      dataIndex: 'e_cost',
      key: 'e_cost',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
      width: 90,
      align: 'center',
      render: (_, record) => <Button type="text" offFocus className="text-black" onClick={() => handleEdit(record.labor_id)} prefixIcon={<PencilIcon />} />,
    },
  ]

  const getListLabor = async () => {
    try {
      setIsLoading(true)
      const res = await apiGetLabor({
        idEstimate: params.idEstimate,
        params: queryParams,
      })
      setListLabor(res.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
      setCheckReload(false)
    }
  }

  const getListLaborTotal = async () => {
    try {
      setIsLoading(true)
      const { data } = await apiGetLaborTotal({
        idEstimate: params.idEstimate,
      })
      const customTotal: any = {
        total: '合計費',
        unit_price: formatNumber(data.data.total_unit_price || 0),
        a0_cost: formatNumber(data.data.total_a0_cost || 0),
        a0_gross_profit_rate: null,
        offer_amount: formatNumber(data.data.total_offer_amount || 0),
        e_cost: formatNumber(data.data.total_e_cost || 0),
      }
      setLaborTotal(customTotal)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleAction = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParams }
    if (sorter.column)
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'asc',
          page: pagination.current,
        }
      else {
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'desc',
          page: pagination.current,
        }
      }
    queryParamsClone = {
      ...queryParamsClone,
      page: queryParams.page === pagination.current ? 1 : pagination.current,
    }
    setQueryParams(queryParamsClone)
  }
  const renderCell = Object.keys(laborTotal).map((item) => (
    <Table.Summary.Cell key={item} index={0} className={laborTotal[item] === null ? 'font-bold text-end bg-transparent' : ''}>
      <Text className="mr-8">{laborTotal[item]}</Text>
    </Table.Summary.Cell>
  ))
  useEffect(() => {
    getListLabor()
  }, [queryParams, reloadData])

  useEffect(() => {
    getListLaborTotal()
  }, [params])

  useEffect(() => {
    if (data?.length !== 0 && data !== undefined) {
      const newState = [...data]
      setDataList(newState)
    }
  }, [data])

  return (
    <div className="ariCondition-7">
      <div className="flex justify-between mb-6 ">
        <span className="text-xl font-bold text-center">労務費一覧</span>
        <Button onClick={setShowAddModal} text="項目追加" />
      </div>
      <Table
        className="w-full hasSummary"
        rowSelection={rowSelection}
        loading={isLoading}
        columns={columns}
        dataSource={dataList}
        showSorterTooltip={false}
        rowKey={(record) => record.labor_id}
        onChange={handleAction}
        summary={() => {
          return (
            <>
              {dataList.length !== 0 && (
                <Table.Summary.Row>
                  <>
                    <Table.Summary.Cell index={0} colSpan={5} className={'bg-[#f5f5f5] border-b-0'} />
                    {renderCell}
                  </>
                </Table.Summary.Row>
              )}
            </>
          )
        }}
        pagination={{
          total: total + Math.ceil(total / per_page),
          current: current_page,
          showTotal: () => showTotalPaginationTable(from, to, total),
          position: ['bottomRight' as const],
          pageSize: per_page + 1,
        }}
      />
    </div>
  )
}

export default LaborCost
