import React from 'react'

const SettingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="13.333" viewBox="0 0 13.333 13.333">
      <g id="setting" transform="translate(-1.333 -1.333)">
        <path
          id="settings_FILL0_wght400_GRAD0_opsz48"
          d="M5.133,13.333l-.333-2.1a4.461,4.461,0,0,1-.667-.317,4.591,4.591,0,0,1-.617-.417l-1.967.9L0,8.667,1.8,7.35a1.982,1.982,0,0,1-.042-.342q-.008-.192-.008-.342t.008-.342A1.982,1.982,0,0,1,1.8,5.983L0,4.667,1.55,1.933l1.967.9a4.581,4.581,0,0,1,.617-.417,3.448,3.448,0,0,1,.667-.3L5.133,0H8.2l.333,2.1a5.4,5.4,0,0,1,.675.308,2.869,2.869,0,0,1,.608.425l1.967-.9,1.55,2.733-1.8,1.283a2.344,2.344,0,0,1,.042.358q.008.192.008.358t-.008.35a2.317,2.317,0,0,1-.042.35l1.8,1.3L11.783,11.4l-1.967-.9a5.537,5.537,0,0,1-.608.425,2.9,2.9,0,0,1-.675.308l-.333,2.1Zm1.533-4.5A2.162,2.162,0,0,0,8.833,6.667,2.162,2.162,0,0,0,6.667,4.5,2.162,2.162,0,0,0,4.5,6.667,2.162,2.162,0,0,0,6.667,8.833Zm0-1a1.167,1.167,0,0,1-.825-1.992,1.167,1.167,0,0,1,1.65,1.65A1.124,1.124,0,0,1,6.667,7.833Zm-.733,4.5H7.4l.233-1.867a3.931,3.931,0,0,0,1.042-.417,4.272,4.272,0,0,0,.892-.683l1.767.767L12,8.933l-1.567-1.15q.067-.283.108-.558a3.732,3.732,0,0,0,.042-.558,4.641,4.641,0,0,0-.033-.558,3.442,3.442,0,0,0-.117-.558L12,4.4l-.667-1.2-1.767.767A3.715,3.715,0,0,0,8.7,3.242a2.811,2.811,0,0,0-1.067-.375L7.4,1H5.933L5.7,2.867a3.531,3.531,0,0,0-1.058.4,3.676,3.676,0,0,0-.875.7L2,3.2,1.333,4.4,2.9,5.55q-.067.283-.108.558a3.761,3.761,0,0,0,0,1.117q.042.275.108.558L1.333,8.933,2,10.133l1.767-.767a4.272,4.272,0,0,0,.892.683,3.931,3.931,0,0,0,1.042.417Z"
          transform="translate(1.333 1.333)"
          fill="#171717"
          opacity="0.87"
        />
      </g>
    </svg>
  )
}

export default SettingIcon
