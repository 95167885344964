import { Form, Input, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '~/components/atoms/Button'

import { signIn } from '~/api/authenApi'
import { ROUTER_PATH } from '~/consts/router'
import { EXP_IN, IS_LOGGED_IN, TOKEN_TTL } from '~/consts/storage'
import { pushNotification } from '~/utils/helper'
import { setLocalStorage } from '~/utils/storage'

const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [disabled, setDisabled] = useState<boolean>(false)

  const onLogin = async (values) => {
    if (import.meta.env.MODE !== 'staging') return
    setDisabled(true)
    try {
      const res = await signIn({
        userName: values.email,
        password: values.password,
      })
      //TODO: profile
      console.log('🚀 ~ file: login.tsx:24 ~ onLogin ~ res:', res)
      setLocalStorage(IS_LOGGED_IN, true)
      setLocalStorage(EXP_IN, Date.now() + TOKEN_TTL)
      navigate(ROUTER_PATH.PROJECT.LIST)
    } catch (error: any) {
      pushNotification('ユーザー名またはパスワードが正しくありません', error)
    } finally {
      setDisabled(false)
    }
  }

  const onReset = () => {
    navigate(ROUTER_PATH.AUTH.RESET)
  }

  return (
    <div className="justify-center items-center align-center flex h-full">
      <div>
        <Form onFinish={onLogin} name="basic" autoComplete="off" style={{ width: '600px' }} className="bg-white flex m-auto">
          <div className="m-auto w-3/4 py-9">
            <Typography className="text-center mb-10 font-bold text-xl">ログイン</Typography>
            <div>
              <Typography>{t('login.email_address')}</Typography>
              <Form.Item name="email">
                <Input />
              </Form.Item>
            </div>
            <div>
              <Typography>{t('login.password')}</Typography>
              <Form.Item className="w-full" name="password">
                <Input.Password />
              </Form.Item>
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button text={t('login.login_button')} htmlType="submit" disabled={disabled} />
            </Form.Item>
            <Button type="text" text={t('login.reset_password')} block onClick={onReset} />
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Login
