import { omit } from 'lodash'
import React, { useState } from 'react'
import { Resizable } from 'react-resizable'

import type { ResizeCallbackData } from 'react-resizable'

interface ResizableTitleProps {
  width: number
  onResize: (size: { width: number; height: number }) => void
  onDrag?: any
}

const ResizableTitle = ({ width, onResize, onDrag, ...restProps }: ResizableTitleProps) => {
  const [isDrag, setIsDrag] = useState<boolean>(false)

  const handleResizeStart = () => {
    setIsDrag(true)
    onDrag(true)
  }

  const handleResizeStop = () => {
    setIsDrag(false)
    onDrag(false)
  }

  const handleResize = async (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
    onResize && onResize(size)
  }

  const renderHeader = () => <th {...omit(restProps, ['minWidth'])} />

  return width ? (
    <Resizable
      className={isDrag ? 'dragging' : ''}
      width={width}
      height={0}
      minConstraints={[10, 20]}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e: React.SyntheticEvent<Element>) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={handleResize}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      {renderHeader()}
    </Resizable>
  ) : (
    renderHeader()
  )
}
export default React.memo(ResizableTitle)
