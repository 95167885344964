import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  isLoading: false,
  message: {
    type: '',
    content: '',
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SET_MESSAGE: (state, action: PayloadAction<any>) => {
      state.message = action.payload
    },
    RESET_MESSAGE: (state) => {
      state.message = initialState.message
    },
    SET_LOADING: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

// Actions
export const { SET_MESSAGE, RESET_MESSAGE, SET_LOADING } = appSlice.actions

// Selector
export const message = (state: any) => state.app

// Reducer
export default appSlice.reducer
