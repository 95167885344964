import React from 'react'

const Graph2Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.707" height="18" viewBox="0 0 18.707 18">
      <g id="Group_8858" data-name="Group 8858" transform="translate(-3 -3)">
        <path id="Path_50487" data-name="Path 50487" d="M3,3V20a1,1,0,0,0,1,1H21V19H5V3Z" fill="#1963ca" />
        <path
          id="Path_50488"
          data-name="Path 50488"
          d="M15.293,14.707a1,1,0,0,0,1.414,0l5-5L20.293,8.293,16,12.586l-2.293-2.293a1,1,0,0,0-1.414,0l-5,5,1.414,1.414L13,12.414Z"
          fill="#1963ca"
        />
      </g>
    </svg>
  )
}

export default Graph2Icon
