import React from 'react'

export interface TrashIconProps {
  hover?: boolean
  isDisable?: boolean
}

const TrashIcon = ({ hover, isDisable }: TrashIconProps) => {
  const iconColor = hover ? '#38BDF8' : isDisable ? '#A3A3A3' : '#009BE0'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.46659 15.3332H6.53325C5.30659 15.3332 4.69325 15.3332 4.11992 15.0398C3.61325 14.7798 3.21325 14.3798 2.95325 13.8732C2.65992 13.2998 2.65992 12.6865 2.65992 11.4598V4.6665H1.99992C1.63325 4.6665 1.33325 4.3665 1.33325 3.99984C1.33325 3.63317 1.63325 3.33317 1.99992 3.33317H4.66659C4.66659 2.59317 4.67992 2.1665 4.88659 1.75984C5.07992 1.37984 5.37992 1.07984 5.75992 0.886504C6.18659 0.666504 6.64659 0.666504 7.46659 0.666504H8.53325C9.35992 0.666504 9.81325 0.666504 10.2399 0.886504C10.6199 1.07984 10.9199 1.37984 11.1133 1.75984C11.3199 2.1665 11.3333 2.59317 11.3333 3.33317H13.9999C14.3666 3.33317 14.6666 3.63317 14.6666 3.99984C14.6666 4.3665 14.3666 4.6665 13.9999 4.6665H13.3333V11.4665C13.3333 12.6932 13.3333 13.3065 13.0399 13.8798C12.7799 14.3865 12.3799 14.7865 11.8733 15.0465C11.2999 15.3398 10.6866 15.3398 9.45992 15.3398L9.46659 15.3332ZM3.99992 4.6665V11.4665C3.99992 12.4465 3.99992 12.9865 4.14659 13.2732C4.27325 13.5265 4.47992 13.7265 4.72659 13.8532C5.01325 13.9998 5.55325 13.9998 6.53325 13.9998H9.46659C10.4466 13.9998 10.9866 13.9998 11.2733 13.8532C11.5266 13.7265 11.7266 13.5198 11.8533 13.2732C11.9999 12.9865 11.9999 12.4465 11.9999 11.4665V4.6665H3.99992ZM5.99992 3.33317H9.99992C9.99992 2.8265 9.99992 2.49317 9.92659 2.35984C9.85992 2.23317 9.75992 2.13317 9.63325 2.0665C9.49325 1.99317 9.10659 1.99317 8.53325 1.99317H7.46659C6.88659 1.99317 6.50659 1.99317 6.35992 2.0665C6.23325 2.13317 6.13325 2.23317 6.06659 2.35984C5.99992 2.49317 5.99325 2.8265 5.99325 3.3265L5.99992 3.33317ZM9.33325 11.6665C8.96659 11.6665 8.66659 11.3665 8.66659 10.9998V7.6665C8.66659 7.29984 8.96659 6.99984 9.33325 6.99984C9.69992 6.99984 9.99992 7.29984 9.99992 7.6665V10.9998C9.99992 11.3665 9.69992 11.6665 9.33325 11.6665ZM6.66659 11.6665C6.29992 11.6665 5.99992 11.3665 5.99992 10.9998V7.6665C5.99992 7.29984 6.29992 6.99984 6.66659 6.99984C7.03325 6.99984 7.33325 7.29984 7.33325 7.6665V10.9998C7.33325 11.3665 7.03325 11.6665 6.66659 11.6665Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default TrashIcon
