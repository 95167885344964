import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number/index'
import React from 'react'

import ArrowDownIcon from '~/assets/icons/ArrowDownIcon'
import ArrowUpIcon from '~/assets/icons/ArrowUpIcon'

interface InputNumberSuffixProps extends InputNumberProps {
  suffixText: string
  classes?: string
}

const InputNumberSuffix = ({ suffixText, placeholder, step, stringMode, value, classes, min, max, onChange }: InputNumberSuffixProps) => {
  return (
    <div className="w-full h-10 ">
      <InputNumber
        className={`${classes} w-full rounded `}
        size="large"
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        step={step}
        stringMode={stringMode}
        controls={{ upIcon: <ArrowUpIcon />, downIcon: <ArrowDownIcon /> }}
        onChange={onChange}
      />
      {suffixText && <div className="absolute text-end top-2.5 max-w-12 right-[24px] w-fit bg-white text-[#737373]">{suffixText}</div>}
    </div>
  )
}

export default InputNumberSuffix
