import axios from 'axios'

import client from '~/api/axiosClient'
import { CompanyOffice, CreateProjectEstimateData, ProjectSection, ProjectStaff } from '~/interfaces/project'

// Project list
export const apiGetProject = (request: any) =>
  client.get('/projects', {
    params: request.params,
  })

// Project Estimate List
export const apiGetEstimateList = (request: any) =>
  client.get(`/projectEstimates/${request.id}`, {
    params: request.params,
  })

// Project Estimate
export const apiGetEstimate = (request: any) => client.get(`/projectEstimates/${request.idProject}/${request.idEstimate}`)

// Project Detail
export const apiGetProjectDetail = (request: any) => client.get(`/projects/${request.idProject}/${request.idEstimate}`)

export const apiCreateMemo = (request: any) =>
  client.post(`/projects/${request.idProject}/memo`, {
    content: request.content,
  })

export const apiGetMemo = (request: any) => client.get(`/projects/${request.idProject}/memo`)

// Create Project
export const apiCreatProject = (data: any) => client.post(`/projects`, data)

// Building Mode
export const apiBuildingMode = () => client.get(`/projectBuildingModes`)

// Building Type
export const apiBuildingType = () => client.get(`/projectBuildingTypes`)

// Detail Machine
export const apiDetailMachine = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machine/${request.objectRegistration}`, { params: request.params })

// Project element
export const apiProjectElement = () => client.get('/projects/element')

// Add device
export const apiAddDevice = (request: any) => client.get(`/projects/element/${request.mainCode}/${request.subCode}`, { params: request.params })

// Add machine submit
export const apiAddMachine = (request: any) => client.post(`/projects/${request.estimateId}/machine`, { body: request.body })

export const getGeographyApi = (search_text: string, access_token: string) =>
  axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search_text}.json`, {
    params: { limit: '1', access_token: access_token, language: 'ja' },
  })

export const getOfficeApi = (request?: any) =>
  client.get(`/offices`, {
    params: request?.params,
  })

export const getCompanyOffices = (propertyNumber: string) => {
  return client.get<{ data: CompanyOffice[] }>(`/companyOffices?f={"property_number":${propertyNumber}}`)
}

export const getProjectSections = (officeId: string, sort?: string, orderBy?: string) => {
  return client.get<{
    data: { data: ProjectSection[] }
  }>(`/projectSections?f={"office_id":"${officeId}"}`, { params: { sort, order_by: orderBy } })
}

export const getProjectStaffs = (sectionNumber?: string | number, sort?: string, orderBy?: string) => {
  return client.get<{ data: { data: ProjectStaff[] } }>(`/projectStaffs/${sectionNumber}`, {
    params: {
      sort,
      order_by: orderBy,
    },
  })
}

export const postProjectEstimate = (data: CreateProjectEstimateData) => {
  return client.post<{ data: { data: ProjectStaff[] } }>(`/projectEstimates`, data)
}

export const deleteProjectEstimate = (request: any) => {
  return client.delete(`/projectEstimates/${request.projectId}`, {
    data: { data: request.estimateIds },
  })
}

export const uploadImportApi = (request: any) => {
  return client.post(`/import/deviceECosts/${request.estimateId}`, request.formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const validateDrawingNumber = (request: any) => {
  return client.get(`projects/${request.estimateId}/checkUnique/drawingNumber`, {
    params: request?.params,
  })
}
