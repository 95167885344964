import React from 'react'

interface ArrowUpOrDownProps {
  width?: string
  height?: string
  type?: string
  disabled: boolean
}

const ArrowUpOrDownIcon = (props: ArrowUpOrDownProps) => {
  const width = props.width ?? '24'
  const height = props.height ?? '24'
  const type = props.type ?? 'up'
  const disabled = props.disabled ?? false

  const arrowUp = (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1562 2.29289C12.5467 1.90237 13.1799 1.90237 13.5704 2.29289L20.5704 9.29289C20.8564 9.57889 20.942 10.009 20.7872 10.3827C20.6324 10.7564 20.2678 11 19.8633 11H16.8633L16.8633 20.2231C16.8634 20.3422 16.8634 20.4845 16.8532 20.6098C16.8413 20.755 16.8109 20.963 16.6998 21.181C16.556 21.4632 16.3265 21.6927 16.0443 21.8365C15.8264 21.9476 15.6184 21.978 15.4731 21.9899C15.3478 22.0001 15.2056 22.0001 15.0865 22C15.0786 22 15.0709 22 15.0633 22H10.6633C10.6557 22 10.648 22 10.6402 22C10.521 22.0001 10.3788 22.0001 10.2535 21.9899C10.1083 21.978 9.90027 21.9476 9.68232 21.8365C9.40008 21.6927 9.17061 21.4632 9.0268 21.181C8.91575 20.963 8.88531 20.755 8.87344 20.6098C8.8632 20.4845 8.86326 20.3423 8.8633 20.2231L8.86331 11H5.86331C5.45885 11 5.09421 10.7564 4.93943 10.3827C4.78465 10.009 4.87021 9.57889 5.1562 9.29289L12.1562 2.29289ZM8.27752 9H9.86331C10.4156 9 10.8633 9.44772 10.8633 10V20H14.8633V10C14.8633 9.44772 15.311 9 15.8633 9H17.4491L12.8633 4.41421L8.27752 9Z"
        fill={disabled ? '#A3A3A3' : '#171717'}
      />
    </svg>
  )

  const arrowDown = (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6402 2.00001C10.648 2.00001 10.6557 2.00001 10.6633 2.00001H15.0633C15.0709 2.00001 15.0786 2.00001 15.0864 2.00001C15.2056 1.99996 15.3478 1.99991 15.4731 2.01015C15.6184 2.02201 15.8264 2.05245 16.0443 2.1635C16.3265 2.30731 16.556 2.53678 16.6998 2.81903C16.8109 3.03697 16.8413 3.24496 16.8532 3.39018C16.8634 3.5155 16.8634 3.65777 16.8633 3.77688C16.8633 3.7847 16.8633 3.79241 16.8633 3.80001V13H19.8633C20.2678 13 20.6324 13.2437 20.7872 13.6173C20.942 13.991 20.8564 14.4211 20.5704 14.7071L13.5704 21.7071C13.1799 22.0976 12.5467 22.0976 12.1562 21.7071L5.1562 14.7071C4.87021 14.4211 4.78465 13.991 4.93943 13.6173C5.09421 13.2437 5.45885 13 5.86331 13H8.86331V3.80001C8.86331 3.79241 8.86331 3.78469 8.8633 3.77687C8.86326 3.65776 8.8632 3.5155 8.87344 3.39018C8.88531 3.24496 8.91575 3.03697 9.0268 2.81903C9.17061 2.53678 9.40008 2.30731 9.68232 2.1635C9.90027 2.05245 10.1083 2.02201 10.2535 2.01015C10.3788 1.99991 10.5211 1.99996 10.6402 2.00001ZM10.8633 4.00001V14C10.8633 14.5523 10.4156 15 9.86331 15H8.27752L12.8633 19.5858L17.4491 15H15.8633C15.311 15 14.8633 14.5523 14.8633 14V4.00001H10.8633Z"
        fill={disabled ? '#A3A3A3' : '#171717'}
      />
    </svg>
  )

  if (type === 'down') return arrowDown

  return arrowUp
}

export default ArrowUpOrDownIcon
