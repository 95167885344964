import { Checkbox, Col, Popover, Row, Tabs } from 'antd'
import { cloneDeep } from 'lodash'
import React, { useMemo, useState } from 'react'

import Button from '~/components/atoms/Button'

import ArrowLeft from '~/assets/icons/ArrowLeftIcon'
import CheckIcon from '~/assets/icons/CheckIcon'
import TabIcon from '~/assets/icons/TabIcon'
import ThirdTab from '~/features/airCondition-7/thirdTab'
import { generateUniqueKey } from '~/utils/helper'

import TableA0Cost from './tableA0Cost'

import '~/assets/styles/feature/airCondition-7.scss'

interface AirConditionProps {
  setIsEdit: any
  laborCostId: string | number
  setShowAddModal: () => void
}

const AirCondition = ({ setIsEdit, laborCostId, setShowAddModal }: AirConditionProps) => {
  const filterColumns = [
    {
      key: 'filter-1',
      items: [
        {
          id: 9,
          label: '定価単価',
          key: 'list_price',
          isCheck: true,
          sorter: true,
        },
        {
          id: 10,
          label: '定価',
          key: 'list_price_total',
          isCheck: true,
          sorter: true,
        },
        {
          id: 11,
          label: 'A0掛率',
          key: 'a0_mul_rate',
          isCheck: false,
          sorter: true,
        },
        {
          id: 12,
          label: 'A0単価',
          key: 'a0_price',
          isCheck: false,
          sorter: true,
        },
        {
          id: 13,
          label: 'A0原価',
          key: 'a0_price_total',
          isCheck: true,
          sorter: true,
        },
        {
          id: 14,
          label: '粗利率',
          key: 'a0_profit_rate',
          isCheck: false,
          sorter: true,
        },
        {
          id: 15,
          label: '提出金額',
          key: 'offer_amount',
          isCheck: true,
          sorter: true,
        },
      ],
    },
    {
      key: 'filter-2',
      items: [
        // {
        //   id: 15,
        //   label: 'E単価(掛率)',
        //   key: 'e_cost_10',
        //   isCheck: false,
        // },
        {
          id: 16,
          label: 'E原価(掛率)',
          key: 'e_cost_10_total',
          isCheck: false,
        },
        {
          id: 17,
          label: '粗利率',
          key: 'e_cost_10_profit_rate',
          isCheck: false,
          sorter: true,
        },
      ],
    },
    {
      key: 'filter-3',
      items: [
        // {
        //   id: 18,
        //   label: 'Ｅ単価（設定値）',
        //   key: 'e_cost_20',
        //   isCheck: false,
        //   sorter: true,
        // },
        {
          id: 19,
          label: 'E原価(設定値)',
          key: 'e_cost_20_total',
          isCheck: false,
          sorter: true,
        },
        {
          id: 20,
          label: '粗利率',
          key: 'e_cost_20_profit_rate',
          isCheck: false,
          sorter: true,
        },
      ],
    },
    {
      key: 'filter-4',
      items: [
        // {
        //   id: 21,
        //   label: 'E単価(実績)',
        //   key: 'e_cost_30',
        //   isCheck: false,
        //   sorter: true,
        // },
        {
          id: 22,
          label: 'E原価(実績)',
          key: 'e_cost_30_total',
          isCheck: false,
          sorter: true,
        },
        {
          id: 23,
          label: '粗利率',
          key: 'e_cost_30_profit_rate',
          isCheck: false,
          sorter: true,
        },
      ],
    },
    {
      key: 'filter-5',
      items: [
        // {
        //   id: 24,
        //   label: 'E単価(確定)',
        //   key: 'e_cost',
        //   isCheck: false,
        //   sorter: true,
        // },
        {
          id: 25,
          label: 'E原価(確定)',
          key: 'e_cost_total',
          isCheck: true,
          sorter: true,
        },
        {
          id: 26,
          label: '粗利率',
          key: 'e_cost_profit_rate',
          isCheck: true,
          sorter: true,
        },
        {
          id: 27,
          label: 'E原価タイプ',
          key: 'rule_type',
          isCheck: true,
          sorter: true,
        },
      ],
    },
  ]

  const [tab, setTab] = useState<string>('1')

  const [filterTabA, setFilterTabA] = useState<any[]>(cloneDeep(filterColumns))
  const [filterTabE, setFilterTabE] = useState<any[]>(cloneDeep(filterColumns))

  const [aCostList, setACostList] = useState<any[]>(filterColumns.flatMap((item) => item.items).filter((item) => item.isCheck))
  const [forwardACostList, setForwardACostList] = useState<any[]>(aCostList)

  const [eCostList, setECostList] = useState<any>(filterColumns.flatMap((item) => item.items).filter((item) => item.isCheck))
  const [forwardECostList, setForwardECostList] = useState<any[]>(eCostList)

  const { TabPane } = Tabs

  const listTab = [
    {
      id: 1,
      title: <TabIcon />,
      component: null,
    },
    {
      id: 2,
      title: 'A0原価単価',
      component: <TableA0Cost aCostList={aCostList} laborCostId={laborCostId} />,
    },
    {
      id: 3,
      title: 'E原価単価',
      component: <ThirdTab eCostList={eCostList} />,
    },
  ]

  const handleFilter = (e, filterItem) => {
    if (tab === '1') {
      const cloneACostList = [...forwardACostList]
      const cloneFilterA = [...filterTabA]

      cloneFilterA.forEach((item) => {
        item.items.forEach((item2) => {
          if (item2.id === filterItem.id) {
            item2.isCheck = e.target.checked
          }
        })
      })
      setFilterTabA(cloneFilterA)

      if (e.target.checked) {
        cloneACostList.push({ ...filterItem, isCheck: true })
        setForwardACostList(cloneACostList)
      } else {
        setForwardACostList(cloneACostList.filter((item) => item.id !== filterItem.id))
      }
    }
    if (tab === '2') {
      const cloneECostList = [...forwardECostList]
      const cloneFilterE = [...filterTabE]

      cloneFilterE.forEach((item) => {
        item.items.forEach((item2) => {
          if (item2.id === filterItem.id) {
            item2.isCheck = e.target.checked
          }
        })
      })
      setFilterTabE(cloneFilterE)

      if (e.target.checked) {
        cloneECostList.push({ ...filterItem, isCheck: true })
        setForwardECostList(cloneECostList)
      } else {
        setForwardECostList(cloneECostList.filter((item) => item.id !== filterItem.id))
      }
    }
  }

  const filterListA = useMemo(() => {
    if (tab === '1') {
      return (
        <Row gutter={3} className="p-1">
          {filterTabA.map((item) => (
            <Col span={4} key={generateUniqueKey('tabA', item.key)} className="filter-labor">
              {item.items?.map((item2, idx2) => (
                <Checkbox
                  className="mx-0 my-[5px]"
                  key={generateUniqueKey(`tabA-${item2.isCheck}`, idx2)}
                  checked={item2.isCheck}
                  onChange={(e) => handleFilter(e, item2)}
                >
                  {item2.label}
                </Checkbox>
              ))}
            </Col>
          ))}
        </Row>
      )
    }
  }, [tab, filterTabA])

  const filterListE = useMemo(() => {
    if (tab === '2') {
      return (
        <Row gutter={3} className="p-1">
          {filterTabE.map((item) => (
            <Col span={4} key={generateUniqueKey('tabE', item.key)}>
              {item.items?.map((item2, idx2) => (
                <Checkbox
                  className="mx-0 my-[5px]"
                  key={generateUniqueKey(`tabE-${item2.isCheck}`, idx2)}
                  checked={item2.isCheck}
                  onChange={(e) => handleFilter(e, item2)}
                >
                  {item2.label}
                </Checkbox>
              ))}
            </Col>
          ))}
        </Row>
      )
    }
  }, [tab, filterTabE])

  const handleCloseFilter = (visible: boolean) => {
    if (!visible) {
      if (tab === '1') {
        setACostList(forwardACostList)
      }
      if (tab === '2') {
        setECostList(forwardECostList)
      }
    }
  }

  return (
    <div className="w-full">
      <div className="px-7 overflow-hidden test2">
        <div className="flex w-full items-center mt-3 mb-3">
          <Button
            type="text"
            offFocus
            className="button-navigate"
            onClick={() => {
              setIsEdit(false)
            }}
            prefixIcon={<ArrowLeft />}
          />
          <span className="font-bold">要素詳細画面</span>
        </div>
        <div className="w-full relative">
          <Tabs
            activeKey={tab}
            onChange={(active) => {
              if (active === '0') return
              setTab(active)
            }}
            type="card"
          >
            {listTab.map((item, index) => {
              const key = `${index}`
              return (
                <TabPane
                  key={key}
                  className="relative"
                  tab={
                    key === '0' ? (
                      <Popover
                        key={tab}
                        placement="bottom"
                        content={tab === '1' ? filterListA : filterListE}
                        trigger="click"
                        onOpenChange={handleCloseFilter}
                        overlayClassName="w-3/6 mt-1 min-h-[300px]"
                      >
                        <span className="flex items-center w-full">
                          {tab === key ? <CheckIcon /> : null}
                          <span className="ml-1">{item.title}</span>
                        </span>
                      </Popover>
                    ) : (
                      <span className="flex items-center w-full">
                        {tab === key ? <CheckIcon /> : null}
                        <span className="ml-1">{item.title}</span>
                      </span>
                    )
                  }
                >
                  <div className="mt-4">{item.component}</div>
                </TabPane>
              )
            })}
          </Tabs>

          <div className="absolute right-3 top-0">
            <Button onClick={setShowAddModal} text="項目追加" />
          </div>
        </div>
      </div>
      {/* <AddMachine keyAdd={ADD_MACHINE_TYPE.AIR_CONDITION_TYPE} isStep={isShowAdd} showStep={showStep} reloadListMachine={() => console.log('ssss')} /> */}
    </div>
  )
}

export default AirCondition
