import { Row } from 'antd'
import React from 'react'

import InformationProject from './informationProject'

const HeaderModalAdd = () => {
  return (
    <>
      <Row className="mb-9">
        <span className="text-[22px] leading-8 font-bold">新規見積</span>
      </Row>
      <Row className=" py-3 border-b">
        <InformationProject isModal />
      </Row>
      <Row className="py-3 justify-end text-[#171717]">
        <div className="mr-9">
          <span className="text-xs mr-3">施工</span>
          <span className="text-sm font-bold">2022/9/1</span>
        </div>
        <div>
          <span className="text-xs mr-3">竣工</span>
          <span className="text-sm font-bold">2022/12/30</span>
        </div>
      </Row>
    </>
  )
}

export default HeaderModalAdd
