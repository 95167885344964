import React from 'react'
import { Trans } from 'react-i18next'

import DropdownIcon from '~/assets/icons/DropdownIcon'
import UpDownIcon from '~/assets/icons/UpDownIcon'
import UpIcon from '~/assets/icons/UpIcon'

interface IconProps {
  title?: string
  sortColumns?: any
  keyName?: string
}

const SortCustom = ({ title, sortColumns, keyName }: IconProps) => {
  return (
    <div className="flex justify-between items-center">
      <Trans parent="span" i18nKey={title} />
      {sortColumns?.find(({ column }) => column.key === keyName) ? (
        sortColumns?.find(({ column }) => column.key === keyName).order === 'ascend' ? (
          <UpIcon />
        ) : sortColumns?.find(({ column }) => column.key === keyName).order === 'descend' ? (
          <DropdownIcon />
        ) : (
          <UpDownIcon />
        )
      ) : (
        <UpDownIcon />
      )}
    </div>
  )
}

export default SortCustom
