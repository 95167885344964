import React from 'react'

const ImportIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3 15.1666H5.69999C4.47333 15.1666 3.85999 15.1666 3.28666 14.8733C2.77999 14.6133 2.37999 14.2133 2.11999 13.7066C1.82666 13.1333 1.82666 12.5199 1.82666 11.2933V10.4933C1.82666 10.1266 2.12666 9.82659 2.49333 9.82659C2.85999 9.82659 3.15999 10.1266 3.15999 10.4933V11.2933C3.15999 12.2733 3.15999 12.8133 3.30666 13.0999C3.43333 13.3533 3.63999 13.5533 3.88666 13.6799C4.17333 13.8266 4.71333 13.8266 5.69333 13.8266H11.2933C12.2733 13.8266 12.8133 13.8266 13.1 13.6799C13.3533 13.5533 13.5533 13.3466 13.68 13.0999C13.8267 12.8133 13.8267 12.2733 13.8267 11.2933V10.4933C13.8267 10.1266 14.1267 9.82659 14.4933 9.82659C14.86 9.82659 15.16 10.1266 15.16 10.4933V11.2933C15.16 12.5199 15.16 13.1333 14.8667 13.7066C14.6067 14.2133 14.2067 14.6133 13.7 14.8733C13.1267 15.1666 12.5133 15.1666 11.2867 15.1666H11.3ZM8.49999 11.1666C8.41333 11.1666 8.32666 11.1466 8.24666 11.1133C8.16666 11.0799 8.09333 11.0333 8.02666 10.9666L4.69333 7.63325C4.43333 7.37325 4.43333 6.95325 4.69333 6.69325C4.95333 6.43325 5.37333 6.43325 5.63333 6.69325L7.82666 8.88659V2.49992C7.82666 2.13325 8.12666 1.83325 8.49333 1.83325C8.85999 1.83325 9.15999 2.13325 9.15999 2.49992V8.89325L11.3533 6.69992C11.6133 6.43992 12.0333 6.43992 12.2933 6.69992C12.5533 6.95992 12.5533 7.37992 12.2933 7.63992L8.95999 10.9733C8.89333 11.0399 8.81999 11.0866 8.73999 11.1199C8.65999 11.1533 8.57999 11.1666 8.48666 11.1733L8.49999 11.1666Z"
        fill="#009BE0"
      />
    </svg>
  )
}

export default ImportIcon
