import { Row } from 'antd'
import { EChartsOption, ECharts, SetOptionOpts, init } from 'echarts'
import * as echarts from 'echarts'
import * as ecStat from 'echarts-stat'
import React, { useState, useRef, useEffect, useMemo } from 'react'

import Button from '~/components/atoms/Button'

import { DUMMY_CURVED_CHART } from '~/consts/common'

export interface ReactEChartsProps {
  option?: EChartsOption
  settings?: SetOptionOpts
  loading?: boolean
  theme?: 'light' | 'dark'
  contractPrice: any
  setValueY: any
  ability: any
  queryParamsChart: any
  xAxisUnit: string
}

echarts.registerTransform(ecStat['transform'].regression)

const ModuleValueChart = ({ option, theme, contractPrice, queryParamsChart, setValueY, ability, xAxisUnit }: ReactEChartsProps) => {
  const chartRef = useRef<HTMLDivElement>(null)

  const [myChart, setMyChart] = useState<ECharts | undefined>()
  const [min, setMin] = useState<number>(0)
  const [isLine, setIsLine] = useState<boolean>(true)
  const [redLine, setRedLine] = useState<any>(null)

  const sourcePrice: any = useMemo(() => {
    if (contractPrice?.length) {
      return contractPrice?.filter((item) => item.contract_price > 0).map((child) => child.contract_price)
    }
  }, [contractPrice])

  const modulePrice: any = useMemo(() => {
    if (contractPrice?.length) {
      return contractPrice?.filter((item) => item.module_price > 0).map((child) => child.module_price)
    }
  }, [contractPrice])

  const dataSource: any[] = useMemo(() => {
    if (contractPrice?.length && contractPrice !== undefined) {
      const arrData = contractPrice?.map((itemLine: any) => [
        itemLine.ability,
        itemLine.module_price || null,
        itemLine.office_name,
        itemLine.property_name,
        itemLine.quantity,
        itemLine.list_price || null,
        itemLine.rate,
        itemLine.contract_price || null,
        itemLine.contract_date,
        itemLine.color,
        Math.min(...sourcePrice),
        Math.max(...sourcePrice),
      ])
      arrData.length > 1 && arrData.unshift([0, Math.max(...modulePrice), ...DUMMY_CURVED_CHART.reverse()])
      return arrData
    }
    return DUMMY_CURVED_CHART
  }, [contractPrice, queryParamsChart, sourcePrice])

  const defaultLines = useMemo(
    () => [
      {
        name: 'legend1',
        type: 'line',
        datasetIndex: 1,
        symbolSize: 0.1,
        smooth: true,
        symbol: 'none',
        label: { show: false, fontSize: 16 },
        labelLayout: { dx: -20 },
      },
      {
        name: 'scatter',
        type: 'scatter',
        symbolSize: (data) => {
          return (data[7] / data[11]) * 50
        },
        itemStyle: {
          color: (params: any) => params.data[9] || 'transparent',
          borderColor: (params: any) => params.data[9] || 'transparent',
          borderWidth: (params: any) => (params.data[9] ? 1 : 0),
        },
      },
      // Line 3
      {
        name: 'line3',
        type: 'line',
        datasetIndex: 2,
        symbolSize: 0.1,
        zlevel: 0,
        markLine: {
          symbol: ['none', 'none'],
          zlevel: 0,
          label: {
            show: false,
            formatter: (params: any) => {
              return setValueY(params.value * ability)
            },
          },
          animation: false,
          clickable: true,
          data: [
            {
              type: 'average',
              name: 'changeLine',
              xAxis: ability,
              lineStyle: {
                type: 'dot',
                color: 'green',
                width: 2,
              },
            },
          ],
        },
      },
      //red line
      {
        name: 'redLine',
        type: 'line',
        datasetIndex: 2,
        symbolSize: 0.1,
        zlevel: 0,
        markLine: {
          symbol: ['none', 'none'],
          zlevel: 0,
          label: {
            show: false,
          },
          animation: false,
          clickable: true,
          data: [
            {
              type: 'average',
              name: 'changeLine',
              yAxis: redLine || null,
              lineStyle: {
                type: 'dot',
                color: 'red',
                width: 3,
              },
            },
          ],
        },
      },
    ],
    [min, contractPrice, sourcePrice, redLine]
  )
  const handleSetRedLine = () => {
    setRedLine(0.7)
  }
  useEffect(() => {
    if (sourcePrice !== undefined) {
      setMin(Math.min(...sourcePrice))
    }
  }, [sourcePrice])

  option = useMemo(
    () => ({
      dataset: [
        {
          source: dataSource,
        },
        {
          transform: {
            type: 'ecStat:regression',
            config: { method: 'exponential', order: 3 },
          },
        },
      ],
      grid: [{ width: '85%', containLabel: true, show: true, left: '2%', bottom: '5%' }],
      title: {
        text: 'M値-合計能力',
        left: 'left',
      },
      tooltip: {
        showContent: isLine,
        triggerOn: isLine ? 'mousemove' : 'click',
        axisPointer: {
          type: isLine ? 'none' : 'cross',
          label: {
            formatter: (params: any) => {
              if (params.axisDimension === 'y') {
                return setValueY(params.value * ability)
              }
            },
          },
        },
        formatter: (param) => {
          return [
            `支店: ${param.data[2]} <br/>`,
            `物件名:  ${param.data[3]} <br/>`,
            `数量:  ${param.data[4]} <br/>`,
            `定価:  ${param.data[5]} <br/>`,
            `掛け率: ${param.data[6]} <br/>`,
            `契約金額:  ${param.data[7]} <br/>`,
            `契約日:  ${param.data[8]} <br/>`,
          ].join('')
        },
      },
      xAxis: {
        axisLabel: {
          width: 60,
          height: 20,
          color: '#737373',
          lineHeight: 20,
          align: 'center',
          margin: 11,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
          },
        },
        axisTick: {
          show: false,
          length: 5,
        },
        boundaryGap: false,
        name: xAxisUnit,
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#A3A3A3',
          },
        },
      },
      yAxis: {
        axisLabel: {
          formatter: '{value} 円',
          color: '#737373',
          lineHeight: 20,
          margin: 12,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
          },
        },
        axisTick: {
          show: false,
        },
        triggerEvent: true,
      },
    }),
    [dataSource, isLine]
  )

  useEffect(() => {
    if (chartRef.current !== null) {
      setMyChart(init(chartRef.current, theme))
    }
    return () => {
      myChart?.dispose()
    }
  }, [theme, chartRef])

  useEffect(() => {
    if (chartRef.current !== null) {
      // setSizeChart(Math.max(myChart?.getWidth(), myChart?.getHeight()))
      myChart?.setOption({ ...option, series: defaultLines }, true, true)

      myChart?.on('mousemove', (params: any) => {
        if (params.name === 'changeLine') {
          setIsLine(false)
        }
      })
      myChart?.on('mouseout', (params) => {
        if (params.componentType === 'series') {
          setIsLine(false)
        }
      })
      myChart?.on('mouseover', (params) => {
        if (params.componentType === 'series') {
          setIsLine(true)
        }
      })
      myChart?.on('click', (params) => {
        if (params.componentType === 'series') {
          setIsLine(false)
        }
      })
      myChart?.getZr().on('click', () => {
        setIsLine(false)
      })
    }
  }, [option, defaultLines, chartRef.current, myChart])

  return (
    <>
      <Row>
        <div ref={chartRef} className="curvedChartModule" />
      </Row>
      <Row className="flex justify-center">
        <Button
          // onClick={handleSetRedLine}
          text="モジュール値推定"
          isCurrentNotImplemented
        />
      </Row>
    </>
  )
}

export default React.memo(ModuleValueChart)
