import { Input, Select } from 'antd'
import React from 'react'

import Spacer from '~/components/atoms/Spacer'

interface HeaderMValue {
  optionsMValue: any[]
}
const HeaderMValue = ({ optionsMValue }: HeaderMValue) => {
  const handleChangeSelect = () => {
    console.log('handleChangeSelect')
  }
  const handleChangeInput = () => {
    console.log('handleChangeInput')
  }
  return (
    <div className="flex w-full">
      <Select defaultValue="竣工時金額" className="w-3/12" onChange={handleChangeSelect} options={optionsMValue} />
      <Spacer />
      <div className="flex flex-row w-3/12 items-center">
        <span className="w-2/5">推奨M値</span>
        <Input defaultValue="9999" className="w-3/5" onChange={handleChangeInput} />
      </div>
    </div>
  )
}

export default HeaderMValue
