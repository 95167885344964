import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import InputNumberSuffix from '~/components/atoms/InputNumberSuffix'
import MapBoxGL from '~/components/molecules/MapBoxGL'

import { apiBuildingMode, apiBuildingType, apiCreatProject, getGeographyApi } from '~/api/project'
import { DUMMY_FEATURE_MAP, MAPBOXGL_TOKEN } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import { ProjectFeatureMap } from '~/interfaces/project'
import { handleError, pushNotification } from '~/utils/helper'

import '~/assets/styles/feature/CreateProject.scss'

const { TextArea } = Input
const { Item } = Form

const CreateProject = () => {
  const [modeList, setModeList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [buildingStatus, setBuildingStatus] = useState('b71f596a-b527-8f71-8ae2-48e1151d26c7')
  const [buildingType, setBuildingType] = useState('30021280-3521-8830-d86f-5d9b49abc84e')
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [geoFeatures, setGeoFeatures] = useState<ProjectFeatureMap>(DUMMY_FEATURE_MAP)

  const onChangeBuildingStatus = (e: RadioChangeEvent) => {
    setBuildingStatus(e.target.value)
  }

  const onChangeBuildingType = (e: RadioChangeEvent) => {
    setBuildingType(e.target.value)
  }

  const onFinish = async (values: any) => {
    const submitData = {
      ...values,
      property_number: `${values['property_number']}`,
      sales_section_number: '100',
      sales_staff_number: '100',
      estimate_section_number: '200',
      estimate_staff_number: '200',
      cost_management_section_number: '300',
      cost_management_staff_number: '300',
    }
    try {
      await apiCreatProject(submitData)
      navigate(ROUTER_PATH.PROJECT.LIST)
    } catch (error: any) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  const onCancel = () => {
    navigate(ROUTER_PATH.PROJECT.LIST)
  }

  const getModeList = async () => {
    try {
      const res = await apiBuildingMode()
      setModeList(res.data.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  const getTypeList = async () => {
    try {
      const res = await apiBuildingType()
      setTypeList(res.data.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }
  const handleGetGeoGL = async () => {
    const addressSearch = form.getFieldValue('property_address')
    if (addressSearch) {
      try {
        const resGeo = await getGeographyApi(addressSearch, MAPBOXGL_TOKEN)
        setGeoFeatures(resGeo.data.features[0])
      } catch (error) {
        const message = handleError(error)
        pushNotification(message, 'error')
      }
    }
  }

  useEffect(() => {
    getModeList()
  }, [])

  useEffect(() => {
    getTypeList()
  }, [])
  return (
    <div className="bg-white mx-[-28px]">
      <div className="flex w-full h-full">
        <div className="block w-2/4 mx-auto">
          <div className="mt-16 mb-6 items-center text-2xl font-bold">新規プロジェクト</div>
          <div className="flex w-full bg-white justify-center rounded-md py-20 px-28">
            <div className="flex flex-col">
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                  building_mode_id: buildingStatus,
                  building_type_id: buildingType,
                }}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <div className="w-full mb-12">
                  <div className="mb-4 text-lg font-bold">工事名</div>
                  <Item name="project_name" rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}>
                    <Input className="h-10 rounded-md" />
                  </Item>
                </div>
                <div className="w-full mb-12">
                  <div className="mb-4 text-lg font-bold">物件番号</div>
                  <Item className="h-10" name="property_number" rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}>
                    <InputNumber className="w-full rounded-md" size="large" min={0} />
                  </Item>
                </div>
                <div className="w-full mb-12">
                  <div className="mb-4 text-lg font-bold">プロジェクトID</div>
                  <Item name="core_system_project_id" rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}>
                    <Input className="w-full h-10 rounded-md" />
                  </Item>
                </div>
                <div className="w-full mb-12">
                  <div className="w-full mb-4 text-lg font-bold">新設/既設</div>
                  <Item name="building_mode_id" className="flex w-full mt-1">
                    <Radio.Group onChange={onChangeBuildingStatus}>
                      <Row>
                        {modeList.map((item: any, index: number) => (
                          <Col span={12} key={index} className="mt-1">
                            <Radio key={item.id} value={item.id}>
                              {item.name}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Item>
                </div>
                <div className="w-full mb-12">
                  <div className="w-full mb-4 text-lg font-bold">建物種別</div>
                  <Item name="building_type_id" className="flex w-full mt-1">
                    <Radio.Group onChange={onChangeBuildingType}>
                      <Row>
                        {typeList.map((item: any, index: number) => (
                          <Col span={6} key={index} className="mt-1">
                            <Radio key={item.id} value={item.id}>
                              {item.name}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Item>
                </div>
                <div className="w-5/6 mb-12">
                  <div className="mb-4 text-lg font-bold">建物規模</div>
                  <Row className="w-full" gutter={[16, 24]}>
                    <Col span={8}>
                      <div>延床面積</div>
                      <div className="flex text-xs items-center">
                        <Item className="w-full m-0" name="total_floor_space">
                          <InputNumberSuffix suffixText="m²" min={0} />
                        </Item>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div>階数(上)</div>
                      <div>
                        <Item className="w-full m-0" name="floor_up_number">
                          <InputNumberSuffix suffixText="階" min={0} />
                        </Item>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div>階数(下)</div>
                      <div className="flex items-center">
                        <Item className="w-full m-0" name="floor_number_down">
                          <InputNumberSuffix suffixText="階" min={0} />
                        </Item>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="w-full mb-12">
                  <div className="mb-4 text-lg font-bold">場所</div>
                  <Input.Group className="flex space-x-2 mb-3" compact>
                    <Item
                      className="w-11/12 border-0 mb-0"
                      name="property_address"
                      rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                    >
                      <Input className="h-10 rounded-md" />
                    </Item>
                    <Button type="outlined" onClick={handleGetGeoGL} className="rounded-md h-10" text="送信" />
                  </Input.Group>
                  <MapBoxGL geoFeatures={geoFeatures} />
                </div>
                <div className="w-full mb-12">
                  <div className="w-full mb-4 text-lg font-bold">BIM360連携</div>
                  <Button type="outlined" className="h-10 rounded-md" text="集計表の自動取得" />
                </div>
                <div className="w-full mb-12">
                  <div className="mb-4 text-lg font-bold">担当</div>
                  {/* sales_section_number */}
                  <Row className="w-full" gutter={[16, 24]}>
                    <Col span={12}>
                      <div>営業担当課番号</div>
                      <Input.Group className="flex space-x-2" compact>
                        <Item
                          className="w-3/4 border-0"
                          name="sales_section_number"
                          rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                        >
                          <Input className="h-10 rounded-md" />
                        </Item>
                        <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                      </Input.Group>
                    </Col>
                    <Col span={12} className="pr-0">
                      <div>営業担当課名称</div>
                      <Input.Group className="flex space-x-2" compact>
                        <Item
                          className="w-3/4 border-0"
                          name="sales_section_name"
                          rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                        >
                          <Input className="h-10 rounded-md" />
                        </Item>
                        <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                      </Input.Group>
                    </Col>
                  </Row>
                  {/* sales_staff_number */}
                  <Row className="w-full" gutter={16}>
                    <Col span={12}>
                      <div>営業担当社員番号</div>
                      <Input.Group className="flex space-x-2" compact>
                        <Item
                          className="w-3/4 border-0"
                          name="sales_sfaff_number"
                          rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                        >
                          <Input className="h-10 rounded-md" />
                        </Item>
                        <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                      </Input.Group>
                    </Col>
                    <Col span={12} className="pr-0">
                      <div>営業担当社員名</div>
                      <Input.Group className="flex space-x-2" compact>
                        <Item className="w-3/4 border-0" name="sales_staff_name" rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}>
                          <Input className="h-10 rounded-md" />
                        </Item>
                        <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                      </Input.Group>
                    </Col>
                  </Row>
                  {/* TODO: confirm UI */}
                  {/* estimate_section_number */}
                  {/* <Row className="w-full" gutter={16}>
                  <Col span={12}>
                    <div>原価管理担当課</div>
                    <Input.Group className="flex space-x-2" compact>
                      <Item
                        className="w-3/4 border-0 m-0"
                        name="cost_management_section_name"
                        rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                      >
                        <Input className="h-10 rounded-md" />
                      </Item>
                      <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                    </Input.Group>
                  </Col>
                  <Col span={12} className="pr-0">
                    <div>原価管理担当者</div>
                    <Input.Group className="flex space-x-2" compact>
                      <Item
                        className="w-3/4 border-0 m-0"
                        name="cost_management_staff_name"
                        rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                      >
                        <Input className="h-10 rounded-md" />
                      </Item>
                      <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                    </Input.Group>
                  </Col>
                </Row> */}
                </div>
                {/* customer_name */}
                <div className="w-full mb-12">
                  <div className="w-full mb-4 text-lg font-bold">顧客情報</div>
                  <Row className="w-full mx-0" gutter={16}>
                    <div>会社名</div>
                    <Input.Group className="flex space-x-2" compact>
                      <Item className="w-[600px] border-0" name="customer_name" rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}>
                        <Input className="h-10 rounded-md" />
                      </Item>
                      <Button type="outlined" className="rounded-md h-10" text="情報取得" />
                    </Input.Group>
                  </Row>
                  {/* office_name */}
                  <Row className="w-full" gutter={16}>
                    <Col span={12}>
                      <div>見積担当課</div>
                      <Input.Group className="flex space-x-2" compact>
                        <Item className="w-3/4 border-0" name="office_name" rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}>
                          <Input className="h-10 rounded-md" />
                        </Item>
                        <Button type="outlined" className="w-1/4 rounded-md h-10" text="情報取得" />
                      </Input.Group>
                    </Col>
                    {/* customer_staff_name */}
                    <Col span={12} className="pr-0">
                      <div>担当者</div>
                      <Input.Group compact>
                        <Item
                          className="w-full border-0"
                          name="customer_staff_name"
                          rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                        >
                          <Input className="h-10 rounded-md" />
                        </Item>
                      </Input.Group>
                    </Col>
                  </Row>
                  {/* Staff name */}
                  <Row className="w-full" gutter={16}>
                    <Col span={12}>
                      <div>Email</div>
                      <Input.Group className="flex space-x-2" compact>
                        <Item
                          className="w-full border-0 m-0"
                          name="customer_staff_email"
                          rules={[
                            { required: true, message: 'このフィールドに値を入力してください' },
                            {
                              type: 'email',
                              message: '有効なメールアドレスを入力してください',
                            },
                          ]}
                        >
                          <Input className="h-10 rounded-md" />
                        </Item>
                      </Input.Group>
                    </Col>
                    <Col span={12} className="pr-0">
                      <div>電話番号</div>
                      <Input.Group compact>
                        <Item
                          className="w-full border-0 m-0"
                          name="customer_staff_phone_no"
                          rules={[{ required: true, message: 'このフィールドに値を入力してください' }]}
                        >
                          <Input className="h-10 rounded-md" />
                        </Item>
                      </Input.Group>
                    </Col>
                  </Row>
                </div>
                <div className="w-full mb-12">
                  <Row className="w-full mx-0" gutter={16}>
                    <div className="w-full mb-4 text-lg font-bold">メモ</div>
                    <Input.Group>
                      <Item name="remarks" className="m-0">
                        <TextArea className="rounded-md" rows={6} placeholder="メモを記述" maxLength={8} />
                      </Item>
                    </Input.Group>
                  </Row>
                </div>
                {/* Button submit */}
                <Item className="m-0">
                  <div className="flex justify-center w-full text-blue-600 resizeButton32">
                    <Button className="mr-3" type="text" text="キャンセル" onClick={onCancel} />
                    <Button className="w-28" text="OK" htmlType="submit" />
                  </div>
                </Item>
              </Form>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}

export default CreateProject
