import { ColumnsType } from 'antd/lib/table'
import { pick } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import TableResize from '~/components/molecules/TableResize'

import { apiGetProjectDetail } from '~/api/project'
import { ROUTER_PATH } from '~/consts/router'
import { useAppDispatch } from '~/redux/hooks'
import { SET_LOADING } from '~/redux/reducers/app.slice'
import { convertObject, handleError, pushNotification } from '~/utils/helper'

const quotationOverview = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()

  const [quotationOverview, setQuotationOverview] = useState<any[]>([])

  const renderTitle = (title) => {
    switch (title) {
      case 'total_air_conditioning_amount':
        return t('project_detail.air_condition')
      case 'total_architecture_amount':
        return t('project_detail.architecture')
      case 'total_electricity_amount':
        return t('project_detail.electricity')
      case 'total_expense_amount':
        return t('project_detail.expense')
      case 'total_hygiene_amount':
        return t('project_detail.hygiene')
      case 'total_utility_amount':
        return t('project_detail.utility')
      default:
        return t('project_detail.all')
    }
  }

  const columnWidthProps = {
    width: 15,
    className: 'min-w[15px]',
  }

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'title',
      key: 'title',
      ...columnWidthProps,
      onCell: () => {
        return {
          style: { background: '#f5f5f5' },
        }
      },
      render(text) {
        return renderTitle(text)
      },
      align: 'center',
      ellipsis: true,
    },
    {
      title: () => <p className="w-full truncate">工事金額（税抜）</p>,
      dataIndex: 'contract_amount',
      key: 'contract_amount',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.contract_amount === null ? '-' : record.contract_amount ?? record.ac_contract_amount
      },
    },
    {
      title: () => <p className="w-full truncate">A0原価</p>,
      dataIndex: 'a0_cost',
      key: 'a0_cost',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.a0_cost === null ? '-' : record.a0_cost ?? record.ac_a0_cost
      },
    },
    {
      title: () => <p className="w-full truncate">粗利率（A0原価）</p>,
      dataIndex: 'a0_gross_profit_rate',
      key: 'a0_gross_profit_rate',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.a0_gross_profit_rate === null ? '-' : record.a0_gross_profit_rate ?? record.ac_a0_gross_profit_rate
      },
    },
    {
      title: () => <p className="w-full truncate">E原価</p>,
      dataIndex: 'e_cost',
      key: 'e_cost',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.e_cost === null ? '-' : record.e_cost ?? record.ac_e_cost
      },
    },
    {
      title: () => <p className="w-full truncate">粗利率（E原価）</p>,
      dataIndex: 'e_gross_profit_rate',
      key: 'e_gross_profit_rate',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.e_gross_profit_rate === null ? '-' : record.e_gross_profit_rate ?? record.ac_e_gross_profit_rate
      },
    },
    {
      title: () => <p className="w-full truncate">延面積当り金額</p>,
      dataIndex: 'cost_per_space',
      key: 'cost_per_space',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.cost_per_space === null ? '-' : record.cost_per_space ?? record.ac_cost_per_space
      },
    },
    {
      title: () => <p className="w-full truncate">Kw当り金額</p>,
      dataIndex: 'cost_per_capacity',
      key: 'cost_per_capacity',
      ...columnWidthProps,
      align: 'center',
      render(_, record) {
        return record.cost_per_capacity === null ? '-' : record.cost_per_capacity ?? record.ac_cost_per_capacity
      },
    },
  ]

  const getDetail = async () => {
    try {
      dispatch(SET_LOADING(true))
      const res = await apiGetProjectDetail({
        idProject: params.idProject,
        idEstimate: params.idEstimate,
      })
      const listObject = pick(res.data.data, [
        'total_amount',
        'total_air_conditioning_amount',
        'total_hygiene_amount',
        'total_electricity_amount',
        'total_utility_amount',
        'total_architecture_amount',
        'total_expense_amount',
      ])
      setQuotationOverview(convertObject(listObject))
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  return (
    <div className="px-12 py-16">
      <div className=" text-[22px] font-bold">見積概要</div>
      <div className="py-6 w-full table-all">
        <TableResize
          columnDefine={columns}
          data={quotationOverview}
          handleClickRow={(record) =>
            navigate(
              generatePath(ROUTER_PATH.PROJECT.DETAIL, {
                idProject: params.idProject,
                idEstimate: record.id ?? params.idEstimate,
              })
            )
          }
          usingScroll={false}
          noHoverOnRow={true}
        />
      </div>
    </div>
  )
}

export default quotationOverview
