import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'

import { showTotalPaginationTable } from '~/utils/helper'

import ResizableTitle from './ResizableTitle'

import type { ColumnsType } from 'antd/es/table'
import type { ResizeCallbackData } from 'react-resizable'

interface TableResizeProps {
  columnDefine: ColumnsType<any>
  data: any[]
  loading: boolean
  total?: number
  from?: number
  to?: number
  per_page?: number
  current_page?: number
  defaultExpandAllRows?: boolean
  rowSelection?: any
  handleAction?: any
  handleClickRow?: any
  position?: string
  usingScroll?: boolean
  customMaxWidth?: number
  customMaxHeight?: string
  noHoverOnRow?: boolean
}

const TableResize = ({
  columnDefine,
  data,
  loading,
  position,
  defaultExpandAllRows,
  handleAction,
  handleClickRow = null,
  rowSelection,
  total,
  per_page,
  to,
  from,
  current_page,
  usingScroll = true,
  customMaxWidth = 150,
  customMaxHeight = '56vh',
  noHoverOnRow = false,
}: TableResizeProps) => {
  const [columns, setColumns] = useState<ColumnsType<any>>(columnDefine || [])
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const handleResize = useCallback(
    (index: number) => (_: React.SyntheticEvent<Element>, size: ResizeCallbackData) => {
      if (size) {
        const newColumns = [...columns]
        const getMinWidth = columns[index].className?.match(/(\d+)/)
        const valueMin = getMinWidth ? getMinWidth[0] : null
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
          title: size.width <= 12 ? '' : columnDefine[index].title,
          render: size.width < +valueMin ? null : columnDefine[index].render,
        }
        setColumns(newColumns)
      }
    },
    [columns, columnDefine]
  )

  const mergedColumns: any = useMemo(
    () =>
      columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: ColumnsType<any>[number]) => {
          const getMinWidth = column.className?.match(/(\d+)/)
          const valueMin: any = getMinWidth ? getMinWidth[0] : null
          return {
            width: column.width || +valueMin,
            minWidth: +valueMin,
            onResize: handleResize(index),
            onDrag: setIsDragging,
          }
        },
      })),
    [columns, handleResize]
  )

  const definePagination = useMemo(() => {
    if (per_page) {
      return {
        position: [position ?? 'topRight'],
        total: total ? total + Math.ceil((total / per_page) as number) : 0,
        current: current_page || 1,
        showTotal: () => <span className="flex flex-wrap items-center">{showTotalPaginationTable(from || 1, to || 1, total || 0)}</span>,
        pageSize: per_page && per_page + 1,
      }
    }
    return false
  }, [current_page, from, per_page, position, showTotalPaginationTable, to, total])

  const handleOnRow = (record) => {
    if (!handleClickRow) return
    return {
      onClick: () => handleClickRow(record),
    }
  }

  const scrollProps = usingScroll
    ? {
        scroll: defaultExpandAllRows
          ? {
              x: `${customMaxWidth}%`,
              y: customMaxHeight,
            }
          : { x: `${customMaxWidth - 10}%` },
      }
    : {}

  return (
    <Table
      {...scrollProps}
      tableLayout="fixed"
      columns={mergedColumns}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      className={`${noHoverOnRow ? 'table-resize-no-hover-row' : ''} ${isDragging ? 'dragging-table' : ''}`}
      dataSource={data}
      rowSelection={rowSelection}
      showSorterTooltip={false}
      loading={loading}
      onChange={handleAction}
      pagination={definePagination}
      rowKey={(record) => record.key || Math.random()}
      rowClassName="cursor-pointer"
      onRow={handleOnRow}
      expandable={{
        defaultExpandAllRows,
        expandIcon: ({ expanded, onExpand, record }) => {
          if (record.children) {
            const checkRenderSpan =
              record.parentEnd &&
              record.parentEnd.split('-').map((item, index) => {
                if (index > 1 && !item.includes('end')) {
                  return <span key={index} className={`child-${index}`} />
                }
              })

            return (
              <div className="flex">
                <span className={`level-${record.index && record.index.split('-').length}`}>
                  {checkRenderSpan}
                  {record.index && record.index.length > 1 && <span className={`${record.end ? 'LMark' : 'xMark'}`} />}
                </span>
                {!defaultExpandAllRows && (
                  <span className="mr-2 flex items-center">
                    {record.children && record.children.length > 0 ? (
                      expanded ? (
                        <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                      ) : (
                        <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                      )
                    ) : (
                      <span className="block w-2">&nbsp;</span>
                    )}
                  </span>
                )}
              </div>
            )
          }
        },
      }}
    />
  )
}

export default TableResize
