import React from 'react'
interface CusPlusIconProps {
  isDisable?: boolean
}
const CusPlusIcon = ({ isDisable }: CusPlusIconProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.16646 1.9987C9.16646 1.63051 8.86798 1.33203 8.49979 1.33203C8.1316 1.33203 7.83313 1.63051 7.83313 1.9987V7.33264H2.49919C2.131 7.33264 1.83252 7.63112 1.83252 7.99931C1.83252 8.3675 2.131 8.66597 2.49919 8.66597H7.83313V13.9999C7.83313 14.3681 8.1316 14.6666 8.49979 14.6666C8.86798 14.6666 9.16646 14.3681 9.16646 13.9999V8.66597H14.5004C14.8686 8.66597 15.1671 8.3675 15.1671 7.99931C15.1671 7.63112 14.8686 7.33264 14.5004 7.33264H9.16646V1.9987Z"
        fill={isDisable ? '#A3A3A3' : '#009BE0'}
      />
    </svg>
  )
}

export default CusPlusIcon
