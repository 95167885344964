import React from 'react'

import { POSITION_SEGMENTED_BUTTON } from '~/consts/common'

type SegmentedButtonProps = {
  disabled?: boolean
  isSelected?: boolean
  containerStyle?: string
  buttonStyle?: string
  outlineStyle?: string
  textStyle?: string
  text?: string | React.ReactNode
  position?: string
  handleClick?: () => void
}

const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  position = POSITION_SEGMENTED_BUTTON.center,
  isSelected,
  containerStyle = '',
  buttonStyle = '',
  outlineStyle = '',
  textStyle = '',
  text,
  disabled,
  handleClick,
  ...rest
}) => {
  const baseClasses = 'flex items-center w-full hover:text-white'
  const disabledClasses = 'bg-[#E5E5E5] text-[#A3A3A3] pointer-events-none cursor-not-allowed'

  const positionClasses = {
    [POSITION_SEGMENTED_BUTTON.left]: disabled ? disabledClasses : '',
    [POSITION_SEGMENTED_BUTTON.center]: disabled ? disabledClasses : '',
    [POSITION_SEGMENTED_BUTTON.right]: disabled ? disabledClasses : '',
  }

  const outlineButtonClasses = {
    [POSITION_SEGMENTED_BUTTON.left]: 'border-r-0 rounded-lg rounded-r-none w-[calc(100%+4.5px)] left-[-4px]',
    [POSITION_SEGMENTED_BUTTON.center]: 'border-r-0 border-l-0 w-[calc(100%+2px)] left-[-1px]',
    [POSITION_SEGMENTED_BUTTON.right]: 'border-l-0 rounded-lg rounded-l-none w-[calc(100%+4.5px)] right-[-4px]',
  }

  return (
    <div className={`segmentedButton p-2 relative bg-white hover:bg-transparent ${containerStyle} `} onClick={handleClick}>
      {!disabled && <div className={`outlineButton ${outlineButtonClasses[position]} ${outlineStyle}`} />}
      <div className={`${baseClasses} ${positionClasses[position]} ${buttonStyle}`} {...rest}>
        <span className={`${isSelected && 'text-white'} ml-1 ${textStyle}`}>{text}</span>
      </div>
    </div>
  )
}

export default SegmentedButton
