import React from 'react'

const UpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 5" width="10" height="10">
      <path d="m 7 5 l -3 -3 l -3 3 l -0.923 -1 l 3.923 -4 l 4 4 z" fill="#1a44a9" />
    </svg>
  )
}

export default UpIcon
