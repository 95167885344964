import React, { useState } from 'react'

import Header from '~/components/molecules/Header'
// import PageLoading from '~/components/molecules/PageLoading'
import Tab from '~/components/molecules/Tab'

// import { useAppSelector } from '~/redux/hooks'

const MainLayout = () => {
  // const { isLoading } = useAppSelector((state) => state.app)
  const [headerTitle, setHeaderTitle] = useState<string>('')

  return (
    <div className="main-layout relative w-screen h-screen">
      <Header title={headerTitle} />
      <Tab setHeaderTitle={setHeaderTitle} />
      {/* <PageLoading show={isLoading} /> */}
    </div>
  )
}

export default MainLayout
