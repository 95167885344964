import client from '~/api/axiosClient'

// Get machine
export const apiGetLabor = (request: any) =>
  client.get(`/projects/${request.idEstimate}/labor`, {
    params: request.params,
  })

export const apiGetLaborTotal = (request: any) => client.get(`/projects/${request.idEstimate}/laborTotal`)

export const apiGetLaborDetail = ({ idEstimate, idLabor, params }: any) =>
  client.get(`/projects/${idEstimate}/labor/${idLabor}`, {
    params,
  })
export const apiGetECostType = ({ registrationId, params }: any) =>
  client.get(`/projects/deviceRule/eCosts/${registrationId}`, {
    params,
  })

export const apiGetValueOfCategory = (params: any) =>
  client.get(`/projects/deviceRule/ruleCategories`, {
    params,
  })

export const apiPutDataECostType = ({ registrationId, data }: any) => client.put(`/projects/deviceRule/eCosts/${registrationId}`, data)
