import React from 'react'

interface EditIconProps {
  width?: string
  height?: string
}

const EditIcon2 = (props: EditIconProps) => {
  const width = props.width ?? '24'
  const height = props.height ?? '24'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <path
        d="M2.00097 22.9999C1.74097 22.9999 1.48097 22.8999 1.29097 22.7099C1.08097 22.4999 0.970966 22.1899 1.00097 21.8899L1.39097 18.3899C1.44097 17.9299 1.47097 17.6499 1.57097 17.3499C1.65097 17.0899 1.78097 16.8399 1.93097 16.5999C2.10097 16.3399 2.30097 16.1399 2.63097 15.8099L13.171 5.26993C13.171 5.26993 13.181 5.25993 13.191 5.24993C13.201 5.23993 13.201 5.23993 13.211 5.22993L16.301 2.13993C17.831 0.609932 20.321 0.609932 21.851 2.13993C23.381 3.66993 23.381 6.15993 21.851 7.68993L18.751 10.7899L8.18097 21.3699C7.85097 21.6999 7.65097 21.8999 7.39097 22.0699C7.16097 22.2199 6.91097 22.3399 6.65097 22.4299C6.35097 22.5299 6.07097 22.5599 5.61097 22.6099L2.11097 22.9999C2.11097 22.9999 2.04097 22.9999 2.00097 22.9999ZM13.901 7.36993L4.05097 17.2299C3.81097 17.4699 3.67097 17.6099 3.61097 17.6999C3.56097 17.7799 3.51097 17.8699 3.49097 17.9599C3.46097 18.0599 3.44097 18.2399 3.40097 18.6099L3.15097 20.8699L5.41097 20.6199C5.78097 20.5799 5.95097 20.5599 6.06097 20.5299C6.15097 20.4999 6.24097 20.4599 6.32097 20.4099C6.41097 20.3499 6.54097 20.2199 6.80097 19.9699L16.621 10.0999L13.901 7.37993V7.36993ZM15.311 5.95993L18.031 8.67993L20.431 6.27993C21.181 5.52993 21.181 4.30993 20.431 3.55993C19.681 2.80993 18.461 2.80993 17.701 3.55993L15.301 5.95993H15.311Z"
        fill="black"
      />
    </svg>
  )
}

export default EditIcon2
