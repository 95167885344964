import React from 'react'

const DropdownIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10">
      <path id="_Color" data-name=" ↳Color" d="M6.932,0l-3,2.994L.923,0,0,.922,3.927,4.844,7.855.922Z" fill="#1a44a9" />
    </svg>
  )
}

export default DropdownIcon
