import React from 'react'

const MoveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path fill="#171717" fillRule="evenodd" d="M2 4h12v1.333H2V4zm0 3.333h12v1.334H2V7.333zm12 3.334H2V12h12v-1.333z" clipRule="evenodd"></path>
    </svg>
  )
}

export default MoveIcon
