import React from 'react'

interface CraftGroupProps {
  isDisable: boolean
}
const CraftGroup = ({ isDisable }: CraftGroupProps) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86723 5.09961C6.86723 4.17913 7.61342 3.43294 8.5339 3.43294C9.45437 3.43294 10.2006 4.17913 10.2006 5.09961C10.2006 6.02008 9.45437 6.76628 8.5339 6.76628C7.61342 6.76628 6.86723 6.02008 6.86723 5.09961ZM8.5339 2.09961C6.87704 2.09961 5.53389 3.44276 5.53389 5.09961C5.53389 6.0262 5.95397 6.85468 6.61407 7.40498L5.79114 8.92241C5.49037 8.82115 5.16828 8.76628 4.83337 8.76628C3.17652 8.76628 1.83337 10.1094 1.83337 11.7663C1.83337 13.4231 3.17652 14.7663 4.83337 14.7663C6.2611 14.7663 7.45588 13.7689 7.75904 12.4329H9.24105C9.5442 13.7689 10.739 14.7663 12.1667 14.7663C13.8236 14.7663 15.1667 13.4231 15.1667 11.7663C15.1667 10.1094 13.8236 8.76628 12.1667 8.76628C11.9028 8.76628 11.6468 8.80035 11.403 8.86435L10.5904 7.28381C11.1713 6.73671 11.5339 5.96051 11.5339 5.09961C11.5339 3.44276 10.1907 2.09961 8.5339 2.09961ZM9.24105 11.0996C9.3866 10.4581 9.73772 9.89473 10.2215 9.48232L9.43916 7.96061C9.15352 8.05091 8.8494 8.09961 8.5339 8.09961C8.28172 8.09961 8.03681 8.0685 7.8028 8.0099L6.92955 9.6201C7.33529 10.0165 7.62908 10.5269 7.75904 11.0996H9.24105ZM3.16671 11.7663C3.16671 10.8458 3.9129 10.0996 4.83337 10.0996C5.75385 10.0996 6.50004 10.8458 6.50004 11.7663C6.50004 12.6868 5.75385 13.4329 4.83337 13.4329C3.9129 13.4329 3.16671 12.6868 3.16671 11.7663ZM12.1667 10.0996C11.2462 10.0996 10.5 10.8458 10.5 11.7663C10.5 12.6868 11.2462 13.4329 12.1667 13.4329C13.0872 13.4329 13.8334 12.6868 13.8334 11.7663C13.8334 10.8458 13.0872 10.0996 12.1667 10.0996Z"
        fill={isDisable ? '#A3A3A3' : '#009BE0'}
      />
    </svg>
  )
}

export default CraftGroup
