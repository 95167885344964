import { Tabs, Tooltip, Typography } from 'antd'
import { get, isNil } from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import SideBar from '~/components/molecules/Sidebar'
import SidebarMenu from '~/components/molecules/SidebarMenu'

import { apiGetEstimate } from '~/api/project'
import ArrowLeft from '~/assets/icons/ArrowLeftIcon'
import EstimateIcon from '~/assets/icons/MenuIcon/EstimateIcon'
import FolderConstructionIcon from '~/assets/icons/MenuIcon/FolderConstructionIcon'
import HistoryIcon from '~/assets/icons/MenuIcon/HistoryIcon'
import ProjectInfoIcon from '~/assets/icons/MenuIcon/ProjectInfoIcon'
import TableIcon from '~/assets/icons/MenuIcon/TableIcon'
import { ESTIMATE_STATUS, MACHINE_KEY } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import AirCondition from '~/features/airCondition'
import AirCondition7 from '~/features/airCondition-7'
import Construction from '~/features/construction'
import EstimateDocument from '~/features/estimateDocument'
import History from '~/features/histories'
import BreakDownOverview from '~/features/overView'
import CleanOverview from '~/features/overView/clean'
import QuotationOverview from '~/features/overView/quotation'
import UtilityOverview from '~/features/overView/utility'
import ProjectDetail from '~/features/project/detail'
import { useAppDispatch } from '~/redux/hooks'
import { reset as resetMachineState } from '~/redux/reducers/machine.slice'
import { DateFormat, formatDatetime } from '~/utils/datetimeUtils'
import { handleError, pushNotification } from '~/utils/helper'
import { getLocalStorage, setLocalStorage } from '~/utils/storage'

const { Title } = Typography

const demo = {
  isEdit: false,
  count: 0,
}

const defaultNoDataText = '---'

export const handleDemo = (state, action) => {
  switch (action.type) {
    case 'increment':
      state.count += action.payload
      break
  }
}

export const Context = React.createContext<any>(demo)

interface TabProps {
  setHeaderTitle?: (title: string) => void
}

const Tab = ({ setHeaderTitle }: TabProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()

  const [projectEstimateData, setProjectEstimateData] = useState(null)
  const [activeMenuItem, setActiveMenuItem] = useState('1')
  const [activeTab, setActiveTab] = useState('1')

  const handleNavigate = () => {
    navigate(
      generatePath(ROUTER_PATH.PROJECT.LIST_ESTIMATE, {
        id: `${params.idProject}`,
      })
    )
  }

  const getDetail = async () => {
    try {
      const res = await apiGetEstimate({
        idProject: params.idProject,
        idEstimate: params.idEstimate,
      })
      if (res?.data) {
        const data = {
          estimate_name: get(res.data, 'data.estimate_name') ?? '',
          customer_name: get(res.data, 'data.customer_name') ?? '',
          submission_deadline: get(res.data, 'data.submission_deadline') ?? '',
          submission_date: get(res.data, 'data.submission_date') ?? '',
          estimate_status: get(res.data, 'data.estimate_status') ?? '',
        }
        setProjectEstimateData(data)
      }
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getDetail()
    }

    fetchData()
  }, [params])

  useEffect(() => {
    if (!projectEstimateData) return
    setLocalStorage('projectEstimateData', projectEstimateData)
    if (setHeaderTitle) setHeaderTitle(projectEstimateData?.project_name ?? '')
  }, [projectEstimateData])

  useEffect(() => {
    return () => {
      dispatch(resetMachineState())
    }
  }, [])

  const generateMenu = (overviewComponent) => [
    {
      id: '1',
      menuName: '概要',
      component: overviewComponent,
    },
    {
      id: '2',
      menuName: '全て項目一覧',
      component: <AirCondition keyScreen={MACHINE_KEY.all} />,
      destroyInactiveTabPane: true,
      forceRender: true,
    },
    {
      id: '3',
      menuName: '機器費一覧',
      component: <AirCondition />,
      destroyInactiveTabPane: true,
      forceRender: true,
      secondLevel: true,
    },
    {
      id: '4',
      menuName: '労務費一覧',
      component: <AirCondition keyScreen={MACHINE_KEY.laborCost} />,
      destroyInactiveTabPane: true,
      forceRender: true,
      secondLevel: true,
    },
    {
      id: '5',
      menuName: '外注費一覧',
      component: <AirCondition keyScreen={MACHINE_KEY.outSourceCost} />,
      destroyInactiveTabPane: true,
      forceRender: true,
      secondLevel: true,
    },
    {
      id: '6',
      menuName: '経費一覧',
      component: <AirCondition keyScreen={MACHINE_KEY.expenses} />,
      destroyInactiveTabPane: true,
      forceRender: true,
      secondLevel: true,
    },
    {
      id: '7',
      menuName: '履歴',
      component: '',
    },
  ]

  const generateMenu2 = (overviewComponent) => [
    {
      id: '1',
      menuName: '概要',
      component: overviewComponent,
    },
    {
      id: '2',
      menuName: '全て項目一覧',
      component: <AirCondition keyScreen={MACHINE_KEY.all} />,
      destroyInactiveTabPane: true,
      forceRender: true,
    },
    {
      id: '3',
      menuName: '機器費一覧',
      component: <AirCondition />,
      destroyInactiveTabPane: true,
      forceRender: true,
      secondLevel: true,
    },
    {
      id: '4',
      menuName: '労務費一覧',
      component: <AirCondition7 />,
      destroyInactiveTabPane: true,
      forceRender: true,
      secondLevel: true,
    },
    {
      id: '5',
      menuName: '外注費一覧',
      component: '',
      secondLevel: true,
    },
    {
      id: '6',
      menuName: '経費一覧',
      component: '',
      secondLevel: true,
    },
    {
      id: '7',
      menuName: '履歴',
      component: '',
    },
  ]

  const staticMenuFirst = generateMenu(<QuotationOverview />)
  const staticMenuSecond = generateMenu(<BreakDownOverview />)
  const staticMenuThird = generateMenu2(<UtilityOverview />)
  const staticMenuFour = generateMenu2(<CleanOverview />)

  const NoImplementedTab = ({ children }: { children: string }) => (
    <Tooltip placement="top" title={t('common.current_not_implemented')}>
      {children}
    </Tooltip>
  )

  const listTab = [
    {
      key: '1',
      label: t('tab.all'),
      children: <SideBar menu={staticMenuFirst} />,
      disabled: false,
    },
    {
      key: '2',
      label: t('tab.air_conditioning'),
      children: <SideBar menu={staticMenuSecond} />,
      disabled: false,
    },
    {
      key: '3',
      label: <NoImplementedTab>{t('tab.utility')}</NoImplementedTab>,
      children: <SideBar menu={staticMenuThird} />,
      disabled: true,
    },
    {
      key: '4',
      label: <NoImplementedTab>{t('tab.hygiene')}</NoImplementedTab>,
      children: <SideBar menu={staticMenuFour} />,
      disabled: true,
    },
    {
      key: '5',
      label: <NoImplementedTab>{t('tab.electricity')}</NoImplementedTab>,
      children: <SideBar menu={staticMenuSecond} />,
      disabled: true,
    },
    {
      key: '6',
      label: <NoImplementedTab>{t('tab.architecture')}</NoImplementedTab>,
      children: <SideBar menu={staticMenuSecond} />,
      disabled: true,
    },
  ]

  const menuItems = [
    { id: '1', icon: <ProjectInfoIcon />, text: t('tab.project_info_icon') },
    { id: '2', icon: <FolderConstructionIcon />, text: t('tab.folder_construction_icon') },
    { id: '3', icon: <TableIcon />, text: t('tab.table_icon') },
    { id: '4', icon: <EstimateIcon />, text: t('tab.estimate_icon') },
    { id: '5', icon: <HistoryIcon />, text: t('tab.history_icon') },
  ]

  const handleChangeTab = (tab) => {
    setActiveTab(tab)
    setLocalStorage('activeTab', tab)
  }

  const handleChangeSidebarMenu = (menuItemId: string) => {
    setActiveMenuItem(menuItemId)
    setLocalStorage('menuItem', menuItemId)
  }

  const resetStoredMenuTab = () => {
    setLocalStorage('menuItem', '1')
    setLocalStorage('activeTab', '1')
    setLocalStorage('activeTabChild', '1')
  }

  const renderContent = () => {
    switch (activeMenuItem) {
      case '1':
        return <ProjectDetail />
      case '2':
        return <Construction />
      case '3':
        return <Tabs activeKey={activeTab} items={listTab} onChange={handleChangeTab} />
      case '4':
        return <EstimateDocument />
      case '5':
        return <History />
      default:
        return null
    }
  }

  useLayoutEffect(() => {
    const storedMenuItem = getLocalStorage('menuItem', '1')
    if (storedMenuItem) {
      setActiveMenuItem(storedMenuItem)
    }

    const storedActiveTab = getLocalStorage('activeTab', '1')
    if (storedActiveTab) {
      setActiveTab(storedActiveTab)
    }
    return () => {
      resetStoredMenuTab()
    }
  }, [])
  return (
    <Context.Provider value={demo}>
      <div className="h-[calc(100%-56px)] w-full flex flex-col">
        <div className="flex justify-between py-4 px-10 bg-white border-b border-[#D4D4D4]">
          <div className="flex items-center">
            <Button type="text" offFocus onClick={handleNavigate} className="button-navigate" prefixIcon={<ArrowLeft />} />
            <Title level={3} className="title-tab ml-4">
              {get(projectEstimateData, 'estimate_name') || defaultNoDataText}
            </Title>
          </div>
          <div className="flex justify-between items-center space-x-24 mt-2">
            <div className="flex space-x-10">
              <div className="space-x-4">
                <span>提出先</span>
                <span className="text-lg text-[#171717] font-bold">{get(projectEstimateData, 'customer_name') || defaultNoDataText}</span>
              </div>
              <div className="space-x-4">
                <span>見積提出期日</span>
                <span className="text-lg text-[#171717] font-bold">
                  {formatDatetime(get(projectEstimateData, 'submission_deadline'), DateFormat.DATE_SLASH, false, defaultNoDataText)}
                </span>
              </div>
              <div className="space-x-4">
                <span>見積提出日</span>
                <span className="text-lg text-[#171717] font-bold">
                  {formatDatetime(get(projectEstimateData, 'submission_date'), DateFormat.DATE_SLASH, false, defaultNoDataText)}
                </span>
              </div>
            </div>
            <div className={`text-lg text-[#171717] font-bold ${isNil(get(projectEstimateData, 'estimate_status')) ? 'invisible' : ''}`}>
              <span>{get(ESTIMATE_STATUS, get(projectEstimateData, 'estimate_status'))}</span>
            </div>
          </div>
        </div>
        <div className="flex h-[calc(100%-69px)]">
          <div className="w-[5.25rem] bg-white border-r border-[#D4D4D4]">
            <SidebarMenu items={menuItems} selectedItemId={activeMenuItem} onChange={handleChangeSidebarMenu} />
          </div>
          <div className="h-full w-full overflow-auto">{renderContent()}</div>
        </div>
      </div>
    </Context.Provider>
  )
}
export default Tab
