import React from 'react'

interface PlusIconProps {
  isDisable?: boolean
}

const PlusIcon = ({ isDisable }: PlusIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="10.5" viewBox="0 0 10.5 10.5">
      <g id="Add" transform="translate(-0.75 -0.75)">
        <path
          id="Union"
          d="M6,.75a.75.75,0,0,0-1.5,0V4.5H.75A.75.75,0,0,0,.75,6H4.5V9.75a.75.75,0,0,0,1.5,0V6H9.75a.75.75,0,0,0,0-1.5H6Z"
          transform="translate(0.75 0.75)"
          fill={isDisable ? '#A3A3A3' : '#0EA5E9'}
        />
      </g>
    </svg>
  )
}

export default PlusIcon
