import { Col, Row, Spin } from 'antd'
import dayjs from 'dayjs'
import { cloneDeep, debounce, get } from 'lodash'
import React, { useState, useEffect } from 'react'

import Button from '~/components/atoms/Button'

import { apiContractPricesWithDetails } from '~/api/chart'
import { BUTTON_TYPE, YEAR } from '~/consts/common'
import { PreOrderInDeviceFilters } from '~/interfaces/device'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { machines, setInfoMachine } from '~/redux/reducers/machine.slice'
import { DateFormat, formatDatetime } from '~/utils/datetimeUtils'
import { formatNumberFractionDigits, handleError, pushNotification } from '~/utils/helper'

import ModuleValueChart from './ChartModal/ModuleValueChart'
import PastCostChart from './ChartModal/PastCostChart'
import FilterInForMachine from './FilterInformationMachine'
import FilterSelectDealOffices from './FilterSelectDealOffices'
import FilterSelectLists from './FilterSelectLists'
import InformationMachine from './InformationMachine'

interface BodyModalEditProps {
  objectID: string
  setIsLoadingChart: any
  isLoadingChart: boolean
}

const BodyModalEdit = ({ objectID, isLoadingChart, setIsLoadingChart }: BodyModalEditProps) => {
  const { infoMachine } = useAppSelector(machines)

  const dispatch = useAppDispatch()

  const [contractPriceLineChart, setContractPriceLineChart] = useState<any[]>([])
  const [optionFilters, setOptionFilters] = useState<PreOrderInDeviceFilters>({})
  const [capacityUnit, setCapacityUnit] = useState<string>('')
  const [ability, setAbility] = useState<number>(0)
  const [contractPrice, setContractPrice] = useState<any>([])
  const [queryParamsChart, setQueryParamsChart] = useState<{
    start_date: string | null
    end_date: string | null
    object_registration_id: string | null
  }>({
    start_date: formatDatetime(dayjs().subtract(5, YEAR), DateFormat.DATE),
    end_date: formatDatetime(dayjs(), DateFormat.DATE),
    object_registration_id: objectID,
  })

  const hasFiltersSelectDealOffices = get(optionFilters, 'select_deal_offices.length')
  const hasFiltersSelectLists = get(optionFilters, 'select_lists.length')

  const getContractPriceDetail = async () => {
    try {
      setIsLoadingChart(true)
      const res = await apiContractPricesWithDetails({
        params: queryParamsChart,
      })
      const responseData = get(res, 'data.data')
      if (responseData) {
        const { objects, x_axis_unit, filters, ability } = responseData
        setContractPriceLineChart([responseData])
        setContractPrice(objects)
        setCapacityUnit(x_axis_unit)
        setOptionFilters(filters)
        setAbility(ability)
      } else {
        setContractPriceLineChart([])
        setContractPrice([])
      }
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingChart(false)
    }
  }

  const handleQueryFilter = (filters: any, offColor = false) => {
    const clonedOptionsFilters = cloneDeep(optionFilters)
    if (offColor) {
      Object.entries(clonedOptionsFilters).forEach(([key, value]) => {
        clonedOptionsFilters[key] = (value as any[])?.map((f: any) => {
          return f.is_color ? { ...f, is_color: false } : f // only one "is_color" can be true at a time
        })
      })
    }

    setQueryParamsChart({ ...queryParamsChart, filters: { ...clonedOptionsFilters, ...filters } })
  }

  const handleQueryChartDebounce = debounce((filters, offColor = false) => {
    handleQueryFilter(filters, offColor)
  }, 500)

  const handleChangeDate = (newQueryData: any) => {
    setQueryParamsChart({ ...queryParamsChart, ...newQueryData })
  }

  const setValueInput = (data: any) => {
    const eCostFormat = formatNumberFractionDigits(data)
    dispatch(setInfoMachine({ ...infoMachine, e_cost: eCostFormat }))
  }

  useEffect(() => {
    if (objectID) {
      getContractPriceDetail()
    }
  }, [objectID, queryParamsChart])

  useEffect(() => {
    if (contractPrice) {
      dispatch(setInfoMachine({ ...infoMachine, objects: contractPrice }))
    }
  }, [contractPrice])

  return (
    <Spin spinning={isLoadingChart}>
      <Row className="flex justify-end">
        <Button type={BUTTON_TYPE.text} text="社内工事量をみる ＞" isCurrentNotImplemented />
      </Row>
      <Row className="overflow-x-auto">
        <Row className="min-w-[1792px] overflow-x-auto">
          <Col span={5} className="border-r pr-4">
            <InformationMachine />
          </Col>
          <Col span={6} className="border-r px-4">
            <PastCostChart objectRegistration={objectID} queryParamsChart={queryParamsChart} contractPrice={contractPriceLineChart} />
          </Col>
          <Col span={6} className="pl-4">
            <ModuleValueChart
              queryParamsChart={queryParamsChart}
              contractPrice={contractPrice}
              xAxisUnit={capacityUnit}
              ability={ability}
              setValueY={setValueInput}
            />
          </Col>
          <Col span={3} className="pl-4">
            <FilterInForMachine optionFilter={optionFilters} onChangeDateOption={handleChangeDate} setQueryFilters={handleQueryChartDebounce} />
            {hasFiltersSelectDealOffices && <FilterSelectDealOffices optionFilter={optionFilters} setQueryFilters={handleQueryChartDebounce} />}
          </Col>
          <Col span={4} className="pl-2">
            {hasFiltersSelectLists ? (
              <FilterSelectLists optionFilter={optionFilters} setQueryFilters={handleQueryChartDebounce} />
            ) : (
              <div className="h-full flex flex-col items-center border-2 border-gray-300 rounded-md px-3 py-12 gap-6 flex-shrink-0 text-[#737373] text-sm font-bold">
                No data
              </div>
            )}
          </Col>
        </Row>
      </Row>
    </Spin>
  )
}

export default BodyModalEdit
