import React from 'react'
import { Outlet } from 'react-router-dom'

const AuthLayout = () => {
  return (
    <div>
      <div>
        <div className="auth-layout">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
