import { Row } from 'antd'
import { ECharts, EChartsOption, SetOptionOpts, init } from 'echarts'
import React, { useRef, useState, useEffect, useMemo } from 'react'

import { formatDecimal } from '~/utils/helper'

export interface ReactEChartsProps {
  option?: EChartsOption
  settings?: SetOptionOpts
  loading?: boolean
  theme?: 'light' | 'dark'
  dataSource: any[]
  setHoverItem?: any
  dataSelect?: any
}
const DoughnutChart = ({ option, theme, dataSource, setHoverItem, dataSelect }: ReactEChartsProps) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [myChart, setMyChart] = useState<ECharts>()
  const listColor = useMemo(() => dataSource.map((item) => item['color'].slice(4, -1)), [dataSource])

  option = useMemo(
    () => ({
      color: listColor,
      backgroundColor: 'transparent',
      series: [
        {
          name: 'doughnut',
          type: 'pie',
          width: 'auto',
          height: 'auto',
          radius: [80, 210],
          stillShowZeroSum: false,
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 3,
          },
          label: {
            position: 'inner',
            show: true,
            color: '#171717',
            fontSize: 20,
            fontWeight: 'bold',
            formatter: (params: any) => {
              return params?.percent > 0 ? `${formatDecimal(params?.percent)}%` : ''
            },
          },
          data: dataSource,
          emphasis: {
            scaleSize: 15,
            focus: 'self',
            blurScope: 'series',
            label: {
              fontSize: 32,
            },
          },
        },
      ],
    }),
    [dataSource]
  )

  useEffect(() => {
    if (chartRef.current !== null) {
      setMyChart(init(chartRef.current, theme))
    }
    return () => {
      myChart?.dispose()
    }
  }, [theme, chartRef])
  useEffect(() => {
    if (chartRef.current !== null) {
      myChart?.setOption(
        { ...option },
        {
          notMerge: true,
          lazyUpdate: true,
          silent: true,
        }
      )
      myChart?.on('mousemove', (params) => {
        setHoverItem(params.dataIndex)
      })
      myChart?.on('mouseout', () => {
        setHoverItem(null)
      })
    }
  }, [option, chartRef.current, myChart])

  useEffect(() => {
    const displayList = dataSource.map((item) => item.name)
    myChart?.dispatchAction({
      type: 'downplay',
      seriesIndex: 0,
      dataIndex: displayList,
    })
    if (dataSelect) {
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: dataSelect.key,
      })
    }
  }, [dataSelect, myChart])

  return (
    <div className="w-11/12 m-auto ">
      <Row>
        <div className="doughnutChart relative bg-white ">
          <div ref={chartRef} className=" doughnutChartSub" />
        </div>
      </Row>
    </div>
  )
}

export default DoughnutChart
