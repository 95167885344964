import Table from 'antd/es/table'
import { cloneDeep } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '~/redux/hooks'
import { machines } from '~/redux/reducers/machine.slice'
import { getKeyInArr, removeStructData } from '~/utils/helper'

interface TableDeleteMachine {
  selectedRowKeys: any[]
  setKeyDelete: any
}

const TableDeleteMachine = ({ selectedRowKeys, setKeyDelete }: TableDeleteMachine) => {
  const { t } = useTranslation()
  const [dataList, setDataList] = useState<any[]>([])
  const { dataTable } = useAppSelector(machines)

  const columns: any = [
    {
      title: t('air_condition.device_id'),
      dataIndex: 'object_registrations_number',
      key: 'object_registrations_number',
      align: 'center',
      width: 25,
      defaultSortOrder: 'ascend',
      fixed: 'left',
    },
    {
      title: t('air_condition.name_element'),
      dataIndex: 'element_name',
      key: 'element_name',
      width: 70,
      defaultSortOrder: 'ascend',
      sorter: true,
      ellipsis: true,
    },
  ]
  const checkDuplicates = (data) => {
    const uniqueItems = {}
    const filteredList = data.filter((item) => {
      const itemId = item.id
      if (!uniqueItems[itemId]) {
        uniqueItems[itemId] = true
        return true
      }
      return false
    })
    return filteredList
  }
  const getDataTable = () => {
    const removeChildren = cloneDeep(selectedRowKeys).map((item: any) => {
      const checkParent = dataTable.filter((machine: any) => machine.id.includes(item.id))
      return checkParent.length > 0 ? checkParent : item
    })
    const removeRecord = removeStructData([...removeChildren].flat()).sort((a, b) => {
      if (`${a.object_registrations_number}` > `${b.object_registrations_number}`) {
        return 1
      }
      if (`${a.object_registrations_number}` < `${b.object_registrations_number}`) {
        return -1
      } else {
        return 0
      }
    })
    const listDataCheck = checkDuplicates(removeRecord)
    setDataList(listDataCheck)
    setKeyDelete(getKeyInArr(listDataCheck, 'id'))
  }
  useEffect(() => {
    if (selectedRowKeys.length) {
      getDataTable()
    }
    return () => {
      setDataList([])
    }
  }, [selectedRowKeys])

  return (
    <div>
      <p className="mb-6">以下の機器を削除します。よろしいですか？</p>
      <Table
        scroll={{ y: 330 }}
        tableLayout="fixed"
        columns={columns}
        dataSource={dataList}
        pagination={false}
        rowKey={(record: any) => record.id}
        showSorterTooltip={false}
        expandable={{ expandIcon: () => <></> }}
      />
    </div>
  )
}

export default TableDeleteMachine
