import { Dropdown, Select, Space, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import Flex from '~/components/atoms/Flex'
import Spacer from '~/components/atoms/Spacer'

import { getOfficeApi } from '~/api/project'
import ArrowDownBoldIcon from '~/assets/icons/ArrowDownBoldIcon'
import ArrowRight from '~/assets/icons/ArrowRightIcon'
import HomeIcon from '~/assets/icons/HomeIcon'
import Logo from '~/assets/icons/Logo'
import SettingIcon from '~/assets/icons/Setting'
import { HEADER_OFFICE_OPTION_ALL } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import { SELECTED_OFFICE } from '~/consts/storage'
import { useAppDispatch } from '~/redux/hooks'
import { resetTableProjectParams, setFilterProjectList } from '~/redux/reducers/project.slice'
import { handleError, pushNotification } from '~/utils/helper'
import { getLocalStorage, setLocalStorage } from '~/utils/storage'
import { logout } from '~/utils/user'

import type { MenuProps } from 'antd/es/menu'

interface HeaderProps {
  title?: string
}

const Header = ({ title }: HeaderProps) => {
  const [dropData, setDropData] = useState<any[]>([])
  const [valueSelected, setValueSelected] = useState<string>(getLocalStorage(SELECTED_OFFICE, ''))
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleNavigate = () => {
    if (window.location.pathname === ROUTER_PATH.PROJECT.LIST) return
    navigate(ROUTER_PATH.PROJECT.LIST)
  }

  const onLogout = () => {
    logout()
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" onClick={onLogout}>
          ログアウト
        </a>
      ),
    },
  ]

  const onChange = (value: string) => {
    setValueSelected(value)
    setTimeout(() => {
      handleNavigate()
      dispatch(resetTableProjectParams())
    }, 1000)
  }

  const getOffice = async () => {
    try {
      const { data } = await getOfficeApi({ params: { limit: -1 } })
      const dataList = data.data.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }))
      const dataCustom = [HEADER_OFFICE_OPTION_ALL, ...dataList]
      setDropData(dataCustom)
      if (!valueSelected || isEmpty(valueSelected)) setValueSelected(dataCustom?.find((item) => item?.is_selected_default)?.value || dataCustom?.[0])
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  useEffect(() => {
    getOffice()
  }, [])

  useEffect(() => {
    if (!valueSelected) return
    setLocalStorage(SELECTED_OFFICE, valueSelected)
    dispatch(setFilterProjectList(valueSelected))
  }, [valueSelected])

  return (
    <Flex>
      <Button type="text" offFocus onClick={handleNavigate} className="button-navigate h-[44px]" prefixIcon={<Logo />} />
      <Typography className="font-bold">高砂熱学 原価管理</Typography>
      {location.pathname !== '/' && location.pathname.indexOf('/list-estimate/') !== 0 ? (
        <div className="flex items-center">
          <Button type="text" offFocus onClick={handleNavigate} className="button-navigate pr-0" prefixIcon={<HomeIcon />} />
          <Button type="text" offFocus className="px-0" prefixIcon={<ArrowRight />} />
          <Typography className="font-bold">{location.pathname === '/create-project' ? '新規プロジェクト' : title}</Typography>
        </div>
      ) : null}
      <Spacer />
      <Select
        className="rounded-md w-64"
        placeholder="事業所名"
        optionFilterProp="children"
        onChange={onChange}
        options={dropData}
        value={dropData.length ? valueSelected : undefined}
        suffixIcon={<ArrowDownBoldIcon />}
      />
      <Button type="text" offFocus className="button-navigate" prefixIcon={<SettingIcon isNotImplemented />} isCurrentNotImplemented />
      <Dropdown menu={{ items }}>
        <a>
          <Space>
            <img src="https://bit.ly/dan-abramov" alt="avatar" className="rounded-full w-10" />
          </Space>
        </a>
      </Dropdown>
    </Flex>
  )
}

export default Header
