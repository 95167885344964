import { Tabs } from 'antd'
import React, { useLayoutEffect, useState } from 'react'

import { getLocalStorage, setLocalStorage } from '~/utils/storage'

interface SidebarProps {
  menu: any[]
}

const SideBar = ({ menu }: SidebarProps) => {
  const renderTabItem = menu.map((item) => ({
    label: <span className={item?.secondLevel ? 'ml-4' : ''}>{item.menuName}</span>,
    key: item.id,
    children: item.component,
  }))
  const [activeTabChild, setActiveTabChild] = useState<string>('1')

  const handleChangeTab = (tab) => {
    setActiveTabChild(tab)
    setLocalStorage('activeTabChild', tab)
    setLocalStorage('activeTabLevel-3', '1')
  }

  useLayoutEffect(() => {
    const storedActiveTabChild = getLocalStorage('activeTabChild', '1')
    if (menu && storedActiveTabChild) {
      setActiveTabChild(storedActiveTabChild)
    }
  }, [menu])

  return (
    <div className="side-bar">
      <Tabs tabPosition="left" activeKey={activeTabChild} items={renderTabItem} onChange={handleChangeTab} />
    </div>
  )
}

export default SideBar
