import { Descriptions, InputNumber, Row } from 'antd'
import React from 'react'

import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { machines, setInfoMachine } from '~/redux/reducers/machine.slice'
import { formatNumber, formatNumberFractionDigits } from '~/utils/helper'
import { className, validateInput } from '~/utils/stringUtils'

const InformationMachine = () => {
  const { infoMachine } = useAppSelector(machines)
  const dispatch = useAppDispatch()

  const setValueInput = (data) => {
    const eCostFormat = formatNumberFractionDigits(data)
    dispatch(setInfoMachine({ ...infoMachine, e_cost: eCostFormat }))
  }

  return (
    <div className="text-[#171717]">
      <>
        <div className="flex mb-4 pb-2 justify-between items-center space-x-4 border-b text-base">
          <span className="font-bold">E原価</span>
          <InputNumber
            className={className(
              'w-1/2 flex rounded border border-[#A3A3A3] [text-align-last:right]',
              'justify-end hover:bg-[#f0f9ff]',
              'focus-within:border-2 focus-within:border-[#009BE0]'
            )}
            formatter={(value) => {
              validateInput(value, true)
              return formatNumberFractionDigits(value, 'ja-JP')
            }}
            onChange={setValueInput}
            value={infoMachine.e_cost || 0}
            controls={false}
          />
        </div>
        <div className="flex mb-4 pb-2 justify-between space-x-4 border-b text-base font-normal">
          <span>A0原価</span>
          <span>{formatNumber(infoMachine.a0_cost) || 0}</span>
        </div>
        <div className="flex mb-4 pb-2 justify-between space-x-4 border-b text-base font-normal">
          <span>E原価 (掛率)</span>
          <span>{formatNumber(infoMachine.e_cost_10) || 0}</span>
        </div>
        <div className="flex mb-4 pb-2 justify-between space-x-4 border-b text-base font-normal">
          <span>E原価 (設定値)</span>
          <span className=" text-base ">{formatNumber(infoMachine.e_cost_20) || 0}</span>
        </div>
        <div className="flex mb-4 pb-2 justify-between space-x-4 border-b text-base font-normal">
          <span>E原価 (実績)</span>
          <span>{formatNumber(infoMachine.e_cost_30) || 0}</span>
        </div>
        <div className="flex mb-4 pb-2 justify-between space-x-4 border-b text-base font-normal">
          <span>合計能力</span>
          <span>{`${formatNumber(infoMachine.capacity_value) || 0} ${infoMachine.capacity_unit || ''}`}</span>
        </div>
      </>
      <>
        <Row className="flex items-center justify-center h-9 border bg-[#F5F5F5] border-[#D4D4D4] text-sm font-normal text-[#171717]">仕様（設計図書）</Row>
        <Row className="max-h-80 overflow-y-auto text-[#171717]">
          <Descriptions bordered className="w-full" column={1}>
            <Descriptions.Item
              label={<span className="text-[#171717] text-sm font-normal">型番</span>}
              labelStyle={{
                backgroundColor: '#F5F5F5',
                width: '25%',
                border: 'none',
                padding: '8px',
              }}
            >
              <span className="text-[#171717] text-sm font-normal">{infoMachine.machine_spec?.code || ''}</span>
            </Descriptions.Item>
            {infoMachine.machine_spec?.specs.map((item, index) => (
              <Descriptions.Item
                key={index}
                label={<span className="text-[#171717] text-sm font-normal">{item.label}</span>}
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  width: '25%',
                  border: 'none',
                  padding: '8px',
                }}
              >
                <span className="text-[#171717] text-sm font-normal">{item.content}</span>
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Row>
      </>
    </div>
  )
}

export default InformationMachine
