import { Table } from 'antd'
import React, { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import SortIcon from '~/components/atoms/CustomSort'

import { apiGetLaborDetail } from '~/api/airCondition-7'
import EditIconLight from '~/assets/icons/EditLightIcon'
import SettingIcon from '~/assets/icons/Setting'
import { handleError, pushNotification, ruleECostType } from '~/utils/helper'

import AirCondition from './secondTab'

interface AirConditionProps {
  aCostList: any[]
  laborCostId: string | number
}

const initialState = {
  data: [],
  per_page: 10,
  prev_page_url: null,
  to: 0,
  total: 0,
  current_page: 1,
}
const TableA0Cost = ({ aCostList, laborCostId }: AirConditionProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [laborDetailList, setLaborDetailList] = useState<any>([])
  const [laborDetailTotal, setLaborDetailTotal] = useState<any>({})
  const [laborDetail, setLaborDetail] = useState<any>(initialState)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openDraw, setOpenDraw] = useState<boolean>(false)
  const [recordData, setRecordData] = useState<any>(null)
  const [queryParams, setQueryParams] = useState<{
    limit: number
    page: number
    order_by: string | null
    sort: string | null
  }>({
    limit: 10,
    page: 1,
    order_by: null,
    sort: null,
  })
  const { data } = laborDetail
  const params = useParams()

  const listKey = [
    'list_price_total',
    'a0_mul_rate',
    'a0_price_total',
    'offer_amount',
    'e_cost_10_total',
    'e_cost_20_total',
    'e_cost_30_total',
    'a0_profit_rate',
    'e_cost_total',
    'e_cost_10_profit_rate',
    'e_cost_20_profit_rate',
    'e_cost_30_profit_rate',
    'e_cost_profit_rate',
  ]
  const columnsInitial: any = [
    {
      title: ({ sortColumns }) => <SortIcon title="ID" sortColumns={sortColumns} keyName="labor_number" />,
      dataIndex: 'labor_number',
      key: 'labor_number',
      width: 100,
      id: 1,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="要素名称" sortColumns={sortColumns} keyName="element_name" />,
      dataIndex: 'element_name',
      key: 'element_name',
      width: 150,
      id: 2,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="名称" sortColumns={sortColumns} keyName="labor_name" />,
      dataIndex: 'labor_name',
      key: 'labor_name',
      width: 150,
      id: 3,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="種別" sortColumns={sortColumns} keyName="category" />,
      dataIndex: 'category',
      key: 'category',
      width: 180,
      id: 4,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="タイプ" sortColumns={sortColumns} keyName="type" />,
      dataIndex: 'type',
      key: 'type',
      width: 100,
      id: 5,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="規格" sortColumns={sortColumns} keyName="spec" />,
      dataIndex: 'spec',
      key: 'spec',
      width: 100,
      id: 6,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="数量" sortColumns={sortColumns} keyName="quantity" />,
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      id: 7,
      sorter: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="協力会社" sortColumns={sortColumns} keyName="company_name" />,
      dataIndex: 'company_name',
      key: 'company_name',
      width: 100,
      id: 8,
      sorter: true,
    },
  ]
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const showDrawer = (record: any) => {
    setOpenDraw(true)
    setRecordData({ object_registration_id: record.object_registration_id, rule_type: record.rule_type })
  }
  const columnsFilter = useMemo(() => {
    const filteredColumns = aCostList.map((item) => {
      if (item.key === 'rule_type') {
        return {
          id: item.id,
          title: ({ sortColumns }) => <SortIcon title={item.label} sortColumns={sortColumns} keyName={item.key} />,
          key: item.id,
          dataIndex: 'id',
          width: 120,
          isHide: !item.isCheck,
          sorter: item.sorter,
          render(text, record, index) {
            if (index !== laborDetailList.length - 1) {
              return (
                <div className="flex justify-between  items-center" onClick={() => showDrawer(record)}>
                  <span className={!record.rule_type ? 'text-slate-300' : 'text-black'}>{ruleECostType(record.rule_type) ?? '未選択'}</span>
                  <SettingIcon />
                </div>
              )
            }
          },
        }
      }
      if (item.key === 'list_price') {
        return {
          id: item.id,
          title: ({ sortColumns }) => <SortIcon title={item.label} sortColumns={sortColumns} keyName={item.key} />,
          key: item.id,
          dataIndex: 'id',
          width: 120,
          isHide: !item.isCheck,
          sorter: item.sorter,
          render(text, record, index) {
            if (index !== laborDetailList.length - 1) {
              return record.company_name
            }
            return <span className="font-bold">合計費</span>
          },
          onCell: (record, index) => ({
            style: {
              background: index !== data.length ? '#fff' : '',
            },
          }),
        }
      }
      if (listKey.includes(item.key)) {
        return {
          id: item.id,
          title: ({ sortColumns }) => <SortIcon title={item.label} sortColumns={sortColumns} keyName={item.key} />,
          key: item.id,
          dataIndex: 'id',
          width: 120,
          isHide: !item.isCheck,
          sorter: item.sorter,
          render(text, record, index) {
            if (index !== laborDetailList.length - 1) {
              return <span>{record[item.key]}</span>
            }
            return <span className="font-bold">{record[item.key]}</span>
          },
          onCell: (record, index) => ({
            style: {
              background: index !== data.length ? '#fff' : 'transparent',
            },
          }),
        }
      }

      return {
        id: item.id,
        title: ({ sortColumns }) => <SortIcon title={item.label} sortColumns={sortColumns} keyName={item.key} />,
        key: item.key,
        dataIndex: item.key,
        width: 90,
        isHide: !item.isCheck,
        sorter: item.sorter,
      }
    })
    return [
      ...columnsInitial,
      ...filteredColumns,
      {
        id: [...columnsInitial, ...filteredColumns].length * 2,
        dataIndex: 'edit',
        key: 'edit',
        title: '編集',
        render: () => <Button type="text" offFocus prefixIcon={<EditIconLight />} />,
        width: 90,
      },
    ]
      .filter((item) => !item.isHide)
      .sort((a, b) => a.id - b.id)
  }, [aCostList, laborDetailList.length])

  const handleAction = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParams }
    if (sorter.column)
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'asc',
          page: pagination.current,
        }
      else {
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'desc',
          page: pagination.current,
        }
      }
    queryParamsClone = {
      ...queryParamsClone,
      page: queryParams.page === pagination.current ? 1 : pagination.current,
    }
    setQueryParams(queryParamsClone)
  }
  const handleOpenCloseDraw = (status: boolean) => {
    setOpenDraw(status)
  }
  const getListLaborDetail = async () => {
    try {
      setIsLoading(true)
      const res = await apiGetLaborDetail({
        idEstimate: params.idEstimate,
        idLabor: laborCostId,
        params: queryParams,
      })
      setLaborDetail(res.data.data[0])
      setLaborDetailTotal(res.data.data[1]?.total)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getListLaborDetail()
  }, [queryParams])

  useEffect(() => {
    if (data?.length !== 0 && laborDetailTotal !== undefined) {
      const newState = [
        ...data,
        {
          object_registration_id: 'total',
          labor_number: null,
          element_name: null,
          labor_name: null,
          category: null,
          type: null,
          spec: null,
          quantity: null,
          company_name: null,
          list_price: null,
          list_price_total: laborDetailTotal?.sum_list_price_total,
          a0_mul_rate: laborDetailTotal?.sum_a0_mul_rate,
          a0_price: null,
          a0_price_total: laborDetailTotal?.sum_a0_price_total,
          a0_profit_rate: laborDetailTotal?.sum_a0_profit_rate,
          offer_amount: laborDetailTotal?.sum_offer_amount,
          e_cost_10_total: laborDetailTotal?.sum_e_cost_10_total,
          e_cost_10_profit_rate: laborDetailTotal?.sum_e_cost_10_profit_rate,
          e_cost_20_total: laborDetailTotal?.sum_e_cost_20_total,
          e_cost_20_profit_rate: laborDetailTotal?.sum_e_cost_20_profit_rate,
          e_cost_30_total: laborDetailTotal?.sum_e_cost_30_total,
          e_cost_30_profit_rate: laborDetailTotal?.sum_e_cost_30_profit_rate,
          e_cost_total: laborDetailTotal?.sum_e_cost_total,
          e_cost_profit_rate: laborDetailTotal?.sum_e_cost_profit_rate,
          rule_type: 10,
        },
      ]
      setLaborDetailList(newState)
    }
  }, [data, laborDetailTotal])

  return (
    <div className={openDraw ? 'w-3/4 h-full relative' : 'w-full'}>
      <Table
        scroll={{ x: 1000 }}
        tableLayout="fixed"
        rowSelection={rowSelection}
        columns={columnsFilter}
        dataSource={laborDetailList}
        showSorterTooltip={false}
        pagination={false}
        bordered={true}
        loading={isLoading}
        onChange={handleAction}
        className="render-total-condition"
        rowKey={(record) => record.id}
      />
      <AirCondition openDraw={openDraw} recordData={recordData} setOpenDraw={handleOpenCloseDraw} reloadDataList={getListLaborDetail} />
    </div>
  )
}

export default TableA0Cost
