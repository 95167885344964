import { Col, Descriptions, Form, Input, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import MapBoxGL from '~/components/molecules/MapBoxGL'

import { apiCreateMemo, apiGetMemo, apiGetProjectDetail, getGeographyApi } from '~/api/project'
import { BUTTON_TYPE, DUMMY_FEATURE_MAP, MAPBOXGL_TOKEN } from '~/consts/common'
import { ProjectFeatureMap } from '~/interfaces/project'
import { useAppDispatch } from '~/redux/hooks'
import { SET_LOADING } from '~/redux/reducers/app.slice'
import { DateFormat, formatDatetime } from '~/utils/datetimeUtils'
import { formatDecimal, formatNumber, handleError, pushNotification } from '~/utils/helper'

import '~/assets/styles/feature/projectDetail.scss'

const initialState = {
  data: {},
}

const detailProject = () => {
  const { t } = useTranslation()
  const { TextArea } = Input
  const { Item } = Descriptions
  const params = useParams()
  const dispatch = useAppDispatch()

  const [form] = Form.useForm<{ content: string }>()
  const contentMemo = Form.useWatch('content', form)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [detail, setDetail] = useState<any>(initialState)
  const [memo, setMemo] = useState<any[]>([])
  const [geoFeatures, setGeoFeatures] = useState<ProjectFeatureMap>(DUMMY_FEATURE_MAP)

  const { data } = detail
  const buildingDescription = [
    {
      label: t('project_detail.aspect'),
      value: data.building_mode_name ?? '',
    },
    {
      label: t('project_detail.create_date'),
      value: formatDatetime(data.create_date, DateFormat.DATE_SLASH) ?? '',
    },
  ]
  const listSale = [
    {
      label: t('project_detail.sale_selection'),
      value:
        data.sales_section?.map((item, index) => (
          <span key={index}>
            {item.section_name} {item.section_number}
          </span>
        )) ?? '',
    },
    {
      label: t('project_detail.sales_represent'),
      value:
        data.sales_staff?.map((item, index) => (
          <span key={index}>
            {item.staff_name} {item.staff_number}
          </span>
        )) ?? '',
    },
    {
      label: t('project_detail.section_charge_estimate'),
      value:
        data.estimate_section?.map((item, index) => (
          <span key={index}>
            {item.section_name} {item.section_number}
          </span>
        )) ?? '',
    },
    {
      label: t('project_detail.estimator'),
      value:
        data.estimate_staff?.map((item, index) => (
          <span key={index}>
            {item.staff_name} {item.staff_number}
          </span>
        )) ?? '',
    },
    {
      label: t('project_detail.department_charge_cost'),
      value:
        data.cost_management_section?.map((item, index) => (
          <span key={index}>
            {item.section_name} {item.section_number}
          </span>
        )) ?? '',
    },
    {
      label: t('project_detail.cost_controller'),
      value:
        data.cost_management_staff?.map((item, index) => (
          <span key={index}>
            {item.staff_name} {item.staff_number}
          </span>
        )) ?? '',
    },
  ]
  const listCost = [
    {
      label: t('project_detail.answer_amount'),
      value: `${data.offer_amount ?? 0}円`,
    },
    {
      label: t('project_detail.contract_amount'),
      value: `${data.construction_amount ?? 0}円`,
    },
    {
      label: t('project_detail.a_cost'),
      value: `${data.a0_cost ?? 0}円`,
    },
    {
      label: t('project_detail.e_cost'),
      value: `${data.e_cost ?? 0}円`,
    },
    {
      label: t('project_detail.gross_margin_acost'),
      value: data.a0_gross_profit_rate ? formatDecimal(data.a0_gross_profit_rate) : '-',
    },
    {
      label: t('project_detail.gross_margin'),
      value: data.e_gross_profit_rate ? `${formatDecimal(data.e_gross_profit_rate) ?? 0}％` : '-',
    },
    {
      label: t('project_detail.quote_submit_date'),
      value: data.estimate_submission_date ? formatDatetime(data.estimate_submission_date, DateFormat.DATE_SLASH) : '-',
    },
    {
      label: t('project_detail.quote_submit_destination'),
      value:
        data.submission_to?.map((item, index) => (
          <span key={index}>
            {item.customer_name} {item.branch_name}
          </span>
        )) ?? '',
    },
  ]

  const listThird = [
    {
      label: t('project_detail.total_floor_area'),
      value: data.total_floor_space ? formatNumber(data.total_floor_space) : '-',
    },
    {
      label: t('project_detail.number_of_floor'),
      value:
        (
          <span>
            <span className="mr-2">{data?.floor_number?.floor_number_up}</span>
            {data?.floor_number?.floor_number_down}
          </span>
        ) ?? '',
    },
    {
      label: t('project_detail.refrigerator_capacity'),
      value: data.refrigerator_capacity ?? '',
    },
    {
      label: t('project_detail.heat_source_capacity'),
      value: data.heat_source_capacity ?? '',
    },
    {
      label: t('project_detail.air_conditioning'),
      value: data.air_conditioning_piping_weight ?? '',
    },
    {
      label: t('project_detail.refrigerant'),
      value: data.refrigerant_meters ?? '',
    },
    {
      label: t('project_detail.sanitary_plumbing_weight'),
      value: data.hygiene_piping_weight ?? '',
    },
    {
      label: t('project_detail.central_monitoring'),
      value: data.central_monitoring_points ?? '',
    },
    {
      label: t('project_detail.total_power_load'),
      value: data.power_load ?? '',
      span: 2,
    },
  ]
  const projectDescriptions = [
    {
      label: t('project_detail.construction_name'),
      value: data.project_name ?? '',
      span: 2,
    },
    {
      label: t('project_detail.order_probability'),
      value: data.order_accuracy ?? '-',
    },
    {
      label: t('project_detail.order_date'),
      value: data.order_expected_date ? formatDatetime(data.order_expected_date, DateFormat.DATE_SLASH) : '-',
    },
    {
      label: t('project_detail.start_of_construction'),
      value: data.construction_period_start_date ? formatDatetime(data.construction_period_start_date, DateFormat.DATE_SLASH) : '-',
    },
    {
      label: t('project_detail.completion_date'),
      value: data.construction_period_end_date ? formatDatetime(data.construction_period_end_date, DateFormat.DATE_SLASH) : '-',
    },
  ]
  const buildingDescriptions = useMemo(() => {
    const dataInformation = data.customer_information?.map((item, index) => (
      <div key={index} className="flex justify-between">
        <div className="flex justify-between">
          <div>{item.customer_name ?? ''}</div>
          <div>{item.branch_name ?? ''}</div>
          <div>{item.customer_staff_name ?? ''}</div>
        </div>
        <div className="flex justify-between">
          <div>{item.email ?? ''}</div>
          <div>{item.phone_no ?? ''}</div>
        </div>
      </div>
    ))
    return [
      { label: t('project_detail.building_type'), value: data.building_type_name ?? '' },
      { label: t('project_detail.property_no'), value: data.property_number ?? '' },
      {
        label: t('project_detail.customer_info'),
        value: dataInformation ?? '',
      },
    ]
  }, [data])

  const refrigeratorDescriptions = [
    { label: t('延面積当り価格'), value: data.cost_per_space ?? '' },
    { label: t('project_detail.heat_source_capacity'), value: data.piping_per_space ?? '' },
    { label: t('延面積当りダクト面積'), value: data.cost_per_capacity ?? '' },
    { label: t('延べ面積当りパイプ重量'), value: data.duct_per_space ?? '' },
  ]

  const onFinish = async () => {
    if (contentMemo !== undefined) {
      setIsLoading(true)
      try {
        await apiCreateMemo({
          idProject: params.idProject,
          content: contentMemo,
        })
        getMemo()
        form.resetFields()
        setIsLoading(false)
      } catch (error) {
        const message = handleError(error)
        pushNotification(message, 'error')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const getDetail = async () => {
    try {
      dispatch(SET_LOADING(true))
      const res = await apiGetProjectDetail({
        idProject: params.idProject,
        idEstimate: params.idEstimate,
      })
      setDetail(res.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      dispatch(SET_LOADING(false))
    }
  }
  const getGeojson = async () => {
    try {
      const resGeo = await getGeographyApi(data.address, MAPBOXGL_TOKEN)
      setGeoFeatures(resGeo.data.features[0])
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  const getMemo = async () => {
    try {
      const res = await apiGetMemo({
        idProject: params.idProject,
      })
      setMemo(res.data.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  useEffect(() => {
    getDetail()
  }, [])

  useEffect(() => {
    if (data.address) {
      getGeojson()
    }
  }, [detail])

  useEffect(() => {
    getMemo()
  }, [])

  return (
    <div className="project-detail flex flex-col px-16 overflow-visible">
      <div className="flex justify-between py-5">
        <span className="text-[22px] font-bold">概要</span>
        <div>
          <Button
            isCurrentNotImplemented
            // type={BUTTON_TYPE.outlined}
            text={t('project_detail.make_quote')}
          />
          <Button
            isCurrentNotImplemented
            // type={BUTTON_TYPE.outlined}
            className="mx-2"
            text={t('project_detail.quote_state_create')}
          />
          <Button
            isCurrentNotImplemented
            // type={BUTTON_TYPE.outlined}
            text={t('project_detail.edit')}
          />
        </div>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <Descriptions className="mb-6 w-full" bordered column={2}>
            {buildingDescription.map((item, index) => (
              <Item
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                contentStyle={{ border: 'none' }}
                className="w-1/4"
                label={item.label}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
          <Descriptions className="mb-6 w-full" bordered column={2}>
            {listSale.map((item, index) => (
              <Item
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                contentStyle={{ border: 'none' }}
                className="w-1/4"
                label={item.label}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
          <Descriptions className="mb-6 w-full" bordered column={2}>
            {listCost.map((item, index) => (
              <Item
                labelStyle={{ backgroundColor: '#F5F5F5', border: 'none', fontSize: '12px' }}
                contentStyle={{ textAlign: 'right', border: 'none' }}
                className="w-1/4"
                label={item.label}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
          <Descriptions className="mb-6 w-full" bordered>
            <Item
              labelStyle={{
                backgroundColor: '#F5F5F5',
                width: '25%',
                border: 'none',
                fontSize: '12px',
                fontWeight: 400,
                color: '#171717',
                fontStyle: 'normal',
              }}
              contentStyle={{
                border: 'none',
                fontSize: '12px',
                fontWeight: 400,
                color: '#171717',
                fontStyle: 'normal',
              }}
              label={t('project_detail.main_work')}
            >
              {data.main_constructions}
            </Item>
          </Descriptions>
          <Descriptions className="mb-6 w-full" bordered column={2}>
            {projectDescriptions.map((item, index) => (
              <Item
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                contentStyle={{
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                className="w-1/4"
                label={item.label}
                span={item.span}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
          <Descriptions className="mb-6 w-full listThird" bordered column={2}>
            {listThird.map((item, index) => (
              <Item
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                contentStyle={{
                  textAlign: 'right',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                className="w-1/4"
                label={item.label}
                span={item?.span}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
          <Descriptions className="mb-6 w-full" bordered column={2}>
            {buildingDescriptions.map((item, index) => (
              <Item
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                contentStyle={{
                  textAlign: 'right',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                className="w-1/4"
                label={item.label}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
          <Descriptions className="mb-6 w-full" bordered column={2}>
            {refrigeratorDescriptions.map((item, index) => (
              <Item
                labelStyle={{
                  backgroundColor: '#F5F5F5',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                contentStyle={{
                  textAlign: 'right',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#171717',
                  fontStyle: 'normal',
                }}
                className="w-1/4"
                label={item.label}
                key={index}
              >
                {item.value}
              </Item>
            ))}
          </Descriptions>
        </Col>
        <Col span={12}>
          <Row className="border-y">
            <Col className="p-4 bg-[#F5F5F5] text-xs text-[#171717]" span={5}>
              <span className="font-medium">{t('project_detail.place')}</span>
            </Col>
            <Col className="pt-4 pl-4 pb-2" span={19}>
              <span className="font-medium">{data.address ?? ''}</span>
              <div className="mt-2">
                <MapBoxGL geoFeatures={geoFeatures} />
              </div>
            </Col>
          </Row>
          <Row className="mt-4 border-y">
            <Col className="p-4 bg-[#F5F5F5] text-xs text-[#171717]" span={5}>
              <span className="font-medium">{t('project_detail.memo')}</span>
            </Col>
            <Col className="pl-3 py-3" span={19}>
              <Form form={form} onFinish={onFinish}>
                <Form.Item name="content">
                  <TextArea className="rounded-md" rows={6} maxLength={200} placeholder="メモを記入" />
                </Form.Item>
                <Form.Item className="flex justify-end m-0">
                  <Button
                    htmlType="submit"
                    type={form.getFieldValue('content') ? 'filled' : undefined}
                    disabled={!form.getFieldValue('content')}
                    loading={isLoading}
                    text="保存"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row className="mt-4 border-y">
            {memo.length > 0 && (
              <>
                <Col className="p-4 bg-[#F5F5F5]  text-xs text-[#171717]" span={5}>
                  <span>メモ履歴</span>
                </Col>
                <Col span={19}>
                  <div className="max-h-96 overflow-y-auto space-y-2">
                    {memo.map((item, index) => (
                      <div key={index} className="p-4 border-y space-y-1">
                        <p className="text-sm font-bold">{item.user_name}</p>
                        <p className="text-sm">{item.content}</p>
                        <p className="text-slate-300">{formatDatetime(item.created_at, DateFormat.DATE_SLASH)}</p>
                      </div>
                    ))}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default detailProject
