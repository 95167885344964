import Modal from '@components/molecules/Modal'
import { Divider, Drawer, Select, Spin } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import Button from '~/components/atoms/Button'

import { apiGetECostType, apiGetValueOfCategory, apiPutDataECostType } from '~/api/airCondition-7'
import DropDownBoldIcon from '~/assets/icons/ArrowDownBoldIcon'
import { OPTION_ECOST_TYPE, RULE_CATEGORY, SELECT_COST_TYPE, BUTTON_TYPE } from '~/consts/common'
import TypeInputValue from '~/features/airCondition-7/typeInputValue'
import { handleError, pushNotification } from '~/utils/helper'

interface AirConditionDrawProps {
  openDraw: boolean
  recordData: any
  setOpenDraw: (status: boolean) => void
  reloadDataList?: () => void
}

const AirCondition = ({ openDraw, recordData, setOpenDraw, reloadDataList }: AirConditionDrawProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [textCancelDrawer, setTextCancelDrawer] = useState('キャンセル')
  const [listComparative, setListComparative] = useState<any[]>([])
  const [eCostData, setECostData] = useState([])
  const [isDrawerWillClose, setIsDrawerWillClose] = useState(false)
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [propertyModal, setPropertyModal] = useState({
    beforeOpen: false,
    isOpen: false,
    type: '',
  })
  const [changeCategory, setChangeCategory] = useState(false)
  const [dataSubmit, setDataSubmit] = useState<any>()
  const [selectValue, setSelectValue] = useState<string | null>(SELECT_COST_TYPE.M_VALUE)
  const [objectRegistrationId, setObjectRegistrationId] = useState<string | null>('')

  const buttonSubmitFormHangingRateRef = useRef<any>(null)
  const buttonSubmitFormInputValueRef = useRef<any>(null)
  const buttonSubmitFormActualValueRef = useRef<any>(null)
  const buttonCancelForm = useRef<any>(null)

  const { Option } = Select

  const onCancelModal = () => {
    setIsDrawerWillClose(false)
    setPropertyModal({ ...propertyModal, isOpen: false })
  }
  const closeModal = () => {
    if (isDataUpdated && !propertyModal.beforeOpen) {
      setIsDataUpdated(false)
    }
    if (isDrawerWillClose) {
      setOpenDraw(false)
      setIsDrawerWillClose(false)
      setChangeCategory(false)
      setIsDataUpdated(false)
    }
    setPropertyModal({ type: '', beforeOpen: false, isOpen: false })
  }
  const onOkModal = async () => {
    setPropertyModal({ ...propertyModal, beforeOpen: false, isOpen: false })
    switch (propertyModal.type) {
      case 'submit':
        if (!propertyModal.beforeOpen && !changeCategory) {
          setOpenDraw(false)
        } else {
          await putDataECostType()
          if (isDataUpdated || (isDataUpdated && changeCategory)) {
            buttonCancelForm.current.click()
            return
          }
          if (changeCategory) {
            setOpenDraw(false)
            setChangeCategory(false)
            return
          }
        }
        break
      case 'cancel':
        buttonCancelForm.current.click()
        closeModal()
        break
      case 'change_category':
        setChangeCategory(true)
        setIsDataUpdated(false)
        closeModal()
        break
      case 'unchanged_confirmation':
        closeModal()
        break
      default:
        break
    }
  }

  const cancelEditData = () => {
    if (propertyModal.beforeOpen) {
      if (isDataUpdated) {
        setPropertyModal({ ...propertyModal, type: 'cancel', isOpen: true })
      } else {
        buttonCancelForm.current.click()
      }
      setIsDataUpdated(false)
    } else {
      setIsDrawerWillClose(!propertyModal.beforeOpen)
      setPropertyModal({ ...propertyModal, type: 'cancel', isOpen: true })
    }
  }

  const onCloseDrawer = () => {
    if (propertyModal.beforeOpen) {
      if (isDataUpdated) {
        cancelEditData()
      } else {
        buttonCancelForm.current.click()
      }
    } else {
      if (isDataUpdated || changeCategory) {
        setPropertyModal({ ...propertyModal, type: 'unchanged_confirmation', isOpen: true })
        setIsDrawerWillClose(true)
        return
      }
      setOpenDraw(false)
      setChangeCategory(false)
      setIsDataUpdated(false)
    }
  }

  const getDataShowModal = async (value: string = recordData.rule_type) => {
    setObjectRegistrationId(recordData.object_registration_id)
    await Promise.all([getListECostType(value, recordData.object_registration_id), getValueOfCategory(recordData.object_registration_id)])
    setSelectValue(value?.toString() || null)
  }

  const handleChangeSelect = async (value: string) => {
    setChangeCategory(true)
    setSelectValue(value)
    if (propertyModal.beforeOpen) {
      if (isDataUpdated) {
        setPropertyModal({ ...propertyModal, type: 'change_category', isOpen: true })
      } else {
        await getListECostType(value)
      }
      return
    }
    setPropertyModal({ ...propertyModal, beforeOpen: false })
    await getListECostType(value)
  }

  const submitFormData = () => {
    if (propertyModal.beforeOpen) {
      switch (selectValue) {
        case SELECT_COST_TYPE.HANGING_RATE:
          buttonSubmitFormHangingRateRef.current.click()
          break
        case SELECT_COST_TYPE.M_VALUE:
          buttonSubmitFormInputValueRef.current.click()
          break
        case SELECT_COST_TYPE.ACTUAL_VALUE:
          buttonSubmitFormActualValueRef.current.click()
          break
        default:
          break
      }
      return
    }
    setPropertyModal({ ...propertyModal, type: 'submit', isOpen: true })
  }

  const getContentModal = useMemo(() => {
    switch (propertyModal.type) {
      case 'cancel':
      case 'change_category':
        if (propertyModal.beforeOpen) {
          return (
            <h3 className="mx-1">
              現在入力中の<span className="font-bold text-base"> 編集ルールを破棄 </span>します
            </h3>
          )
        }
        return <h3 className="mx-1">E原価タイプは変更しません。</h3>
      case 'submit':
        if (propertyModal.beforeOpen) {
          return (
            <h3 className="mx-1">
              現在入力中の<span className="font-bold text-base"> 編集ルールを反映 </span>します
            </h3>
          )
        }
        return (
          <h3 className="mx-1">
            E原価タイプを
            <span className="font-bold text-base">
              {selectValue === SELECT_COST_TYPE.HANGING_RATE ? ' 掛率 ' : selectValue === SELECT_COST_TYPE.M_VALUE ? ' M(入力値) ' : ' M(実績値) '}
            </span>
            に変更します。
          </h3>
        )
      case 'unchanged_confirmation':
        if (propertyModal.beforeOpen) {
          return (
            <h3 className="mx-1">
              現在入力中の<span className="font-bold text-base"> 編集ルールを反映 </span>します
            </h3>
          )
        }
        return <h3 className="mx-1">E原価タイプは変更しません。</h3>
      default:
        return null
    }
  }, [selectValue, propertyModal.type, propertyModal.beforeOpen])

  const getRuleName = () => {
    if (selectValue === SELECT_COST_TYPE.HANGING_RATE) {
      return '掛率ルール'
    }
    if (selectValue === SELECT_COST_TYPE.M_VALUE) {
      return 'M(入力値)ルール'
    }
    if (selectValue === SELECT_COST_TYPE.ACTUAL_VALUE) {
      return 'M(実績値)ルール'
    }
    return ''
  }

  const getButtonSubmitFormRef = () => {
    if (selectValue === SELECT_COST_TYPE.HANGING_RATE) {
      return buttonSubmitFormHangingRateRef
    }
    if (selectValue === SELECT_COST_TYPE.M_VALUE) {
      return buttonSubmitFormInputValueRef
    }
    if (selectValue === SELECT_COST_TYPE.ACTUAL_VALUE) {
      return buttonSubmitFormActualValueRef
    }
    return null
  }

  const renderUI = useMemo(
    () => (
      <div>
        {!selectValue && (
          <div className="mt-[50px]">
            <div className="title flex justify-between font-bold">
              <span className="font-bold">ルール</span>
              <span>モジュール単価</span>
            </div>
            <Divider />
          </div>
        )}
        {[SELECT_COST_TYPE.HANGING_RATE, SELECT_COST_TYPE.M_VALUE, SELECT_COST_TYPE.ACTUAL_VALUE].includes(selectValue) && (
          <TypeInputValue
            ruleName={getRuleName()}
            isActualValue={selectValue === SELECT_COST_TYPE.ACTUAL_VALUE}
            eCostData={eCostData}
            buttonSubmitFormRef={getButtonSubmitFormRef()}
            setTextCancelDrawer={setTextCancelDrawer}
            setPropertyModal={setPropertyModal}
            propertyModal={propertyModal}
            setDataSubmit={setDataSubmit}
            buttonCancelForm={buttonCancelForm}
            setIsDataUpdated={setIsDataUpdated}
            listComparative={listComparative}
            objectRegistrationId={objectRegistrationId}
          />
        )}
      </div>
    ),
    [selectValue, eCostData, propertyModal, listComparative]
  )
  const getListECostType = async (ecost_type: string | number, objectRegistrationIdArgs = null) => {
    if (ecost_type) {
      try {
        setIsLoading(true)
        const res = await apiGetECostType({
          registrationId: objectRegistrationIdArgs || objectRegistrationId,
          params: { ecost_type },
        })
        res.data.data.forEach((item) => item.rules.sort((a, b) => (a.category > b.category ? 1 : -1)))
        setECostData(res.data.data)
      } catch (error) {
        const message = handleError(error)
        pushNotification(message, 'error')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const getValueOfCategory = async (objectRegistrationIdValue: string | number) => {
    try {
      setIsLoading(true)
      const res = await apiGetValueOfCategory({
        rule_category: RULE_CATEGORY.CATEGORY_40.value,
        object_registration_id: objectRegistrationIdValue,
      })
      setListComparative(res.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const renderRuleValue = (ruleName: string) => {
    const rule = listComparative.find((item) => item.name === ruleName)
    return (rule?.detail_item_registration_id ?? ruleName)?.toString()
  }

  const putDataECostType = async () => {
    const dataPut = dataSubmit || eCostData
    const dataRequest = dataPut
      ? dataPut.map((item) => ({
          ...item,
          rules: item.rules.map((rule) => {
            const newRule = {
              ...rule,
              rule_value: rule.category === RULE_CATEGORY.CATEGORY_40.value ? renderRuleValue(rule.name) : null,
              ecost_type: +(selectValue ?? 0),
            }
            delete newRule.name
            return newRule
          }),
        }))
      : []
    if (dataRequest.length > 0) {
      try {
        setIsLoading(true)
        await apiPutDataECostType({ registrationId: objectRegistrationId, data: { data: dataRequest } })
        reloadDataList?.()
        setChangeCategory(false)
        setIsDataUpdated(false)
      } catch (error) {
        const message = handleError(error)
        pushNotification(message, 'error')
      } finally {
        setIsLoading(false)
        setDataSubmit()
        getDataShowModal(selectValue)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (recordData) {
        await getDataShowModal()
      }
    }
    fetchData()
  }, [recordData])

  return (
    <div>
      <Drawer
        title=""
        placement="right"
        onClose={onCloseDrawer}
        open={openDraw}
        maskClassName="bg-[transparent]"
        maskClosable={true}
        destroyOnClose={true}
        className="absolute top-[146px] right-0 bottom-0 second-tab"
        footer={
          <div className="text-right">
            <Button type={BUTTON_TYPE.text} className="w-[120px]" text={textCancelDrawer} onClick={cancelEditData} />
            <Button className="w-[120px]" text="確定" onClick={submitFormData} disabled={!selectValue} />
          </div>
        }
        width={660}
      >
        <h1 className="font-bold text-[22px] leading-8 mb-4 mt-[-24px]">E原価タイプ</h1>
        <Spin spinning={isLoading}>
          <Select
            placeholder="選んでください"
            suffixIcon={<DropDownBoldIcon />}
            onChange={handleChangeSelect}
            value={selectValue ?? undefined}
            className="w-3/5 h-10 select-mode-type"
          >
            {OPTION_ECOST_TYPE.map((item) => (
              <Option key={item.id} value={item.value.toString()}>
                {item.label}
              </Option>
            ))}
          </Select>
          {renderUI}
        </Spin>
      </Drawer>
      <Modal
        okText="はい"
        cancelText="キャンセル"
        handleClose={onCancelModal}
        handleOk={onOkModal}
        isOpen={propertyModal.isOpen}
        width="400px"
        closeIcon={null}
      >
        <div className="px-5">
          <h1 className="font-bold text-lg">E原価タイプ</h1>
          <div className="my-3">
            {getContentModal}
            <span className="pb-5 mt-3">よろしいですか？</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AirCondition
