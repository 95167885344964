import { Checkbox, Form, Switch } from 'antd'
import React from 'react'

import { PreInDeviceFiltersSelectDealOffices, PreOrderInDeviceFilters } from '~/interfaces/device'

interface FilterDealOfficesProps {
  optionFilter?: PreOrderInDeviceFilters
  setQueryFilters: (filters: any, offColor: boolean) => void
}

const FILTER_KEY = 'select_deal_offices'

const FilterSelectDealOffices = ({ optionFilter, setQueryFilters }: FilterDealOfficesProps) => {
  const [form] = Form.useForm()

  const filtersState = {
    [FILTER_KEY]: optionFilter?.[FILTER_KEY],
  }

  React.useEffect(() => {
    form.setFieldsValue(filtersState)
  }, [filtersState])

  const handleSetQueryFilters = (selectDealOffices: PreInDeviceFiltersSelectDealOffices[], offColor = false) => {
    if (!selectDealOffices.length) return
    setQueryFilters({ [FILTER_KEY]: selectDealOffices }, offColor)
  }

  const handleActionChange = (changedValues: PreOrderInDeviceFilters, allValues: PreOrderInDeviceFilters) => {
    if (!allValues[FILTER_KEY]) return
    handleSetQueryFilters([...allValues[FILTER_KEY]])
  }

  const handleSwitchChange = (contentId: number) => (checked: boolean) => {
    if (!form.getFieldValue(FILTER_KEY)) return
    const updatedSelectDealOffices = form.getFieldValue(FILTER_KEY)?.map((selectDealOffices: PreInDeviceFiltersSelectDealOffices) => {
      return {
        ...selectDealOffices,
        // only current interacted list will be updated by checked value, others will be false
        is_color: selectDealOffices?.id === contentId ? checked : false,
      }
    })
    handleSetQueryFilters(updatedSelectDealOffices, true)
  }

  const renderFilterHeader = (contentId: number, headerTitle: string, isActive = false) => {
    return (
      <div className="flex justify-between items-center px-0 py-2 border-b border-[#D4D4D4]">
        <span className="text-base text-[#23221E] font-bold">{headerTitle}</span>
        <div className="flex items-center">
          <span className="text-sm text-[#171717] font-normal pr-2">カラー</span>
          <Switch checked={isActive} onChange={handleSwitchChange(contentId)} />
        </div>
      </div>
    )
  }

  const renderFilterItemContent = (renderedItemContent: React.ReactNode | string, isChecked = false, isAlignedCheckbox = false) => {
    return (
      <div className="pt-3">
        <Checkbox className={`w-full ${isAlignedCheckbox ? 'ant-checkbox-wrapper-no-top' : ''}`} checked={isChecked}>
          {renderedItemContent}
        </Checkbox>
      </div>
    )
  }

  return (
    <div>
      <Form className="h-full" form={form} autoComplete="off" initialValues={filtersState} onValuesChange={handleActionChange}>
        <div className="h-full max-h-[15rem] overflow-y-auto flex flex-col items-start gap-6 px-3 py-2 border border-[#D4D4D4] rounded-sm">
          <Form.List name={FILTER_KEY}>
            {(fields) => (
              <div className="w-full">
                {fields.map((field) => {
                  const contentId = form.getFieldValue([FILTER_KEY, field.name, 'id'])
                  const name = form.getFieldValue([FILTER_KEY, field.name, 'name'])
                  const isColor = form.getFieldValue([FILTER_KEY, field.name, 'is_color'])

                  return (
                    <div key={field.key}>
                      {renderFilterHeader(contentId, name, isColor)}
                      <Form.List name={[field.name, 'contents']}>
                        {(fieldsContents) => (
                          <div>
                            {fieldsContents.map((fieldContent: any) => {
                              const fieldContentName = form.getFieldValue([FILTER_KEY, field.name, 'contents', fieldContent.name, 'name'])
                              const color = form.getFieldValue([FILTER_KEY, field.name, 'contents', fieldContent.name, 'color'])
                              const is_checked = form.getFieldValue([FILTER_KEY, field.name, 'contents', fieldContent.name, 'is_checked'])
                              const renderedItemContent = (
                                <div className="flex items-center">
                                  {isColor && <div className="min-w-[1.25rem] w-5 h-5 rounded-full" style={{ backgroundColor: color }} />}
                                  <span className="text-sm text-[#1A202C] font-normal pl-1.5">{fieldContentName}</span>
                                </div>
                              )

                              return (
                                <Form.Item key={fieldContent.key} className="mb-0" name={[fieldContent.name, 'is_checked']} valuePropName="checked">
                                  {renderFilterItemContent(renderedItemContent, is_checked, isColor)}
                                </Form.Item>
                              )
                            })}
                          </div>
                        )}
                      </Form.List>
                    </div>
                  )
                })}
              </div>
            )}
          </Form.List>
        </div>
      </Form>
    </div>
  )
}

export default FilterSelectDealOffices
