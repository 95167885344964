import { Button as AntdButton, Col, Divider, Form, Row } from 'antd'
import { get, groupBy, isEqual } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import Button from '~/components/atoms/Button'

import MoveIcon from '~/assets/icons/MoveIcon'
import PlusIcon from '~/assets/icons/PlusNoBorderIcon'
import SettingIcon from '~/assets/icons/SettingIcon2'
import { BUTTON_TYPE, OPTION_RULE_TYPE, RULE_CATEGORY } from '~/consts/common'
import TypeInputValueForm from '~/features/airCondition-7/typeInputValueForm'
import { ruleCategory, ruleComparative } from '~/utils/helper'

const typeInputValue = ({
  ruleName,
  isActualValue = false,
  eCostData,
  buttonSubmitFormRef,
  setTextCancelDrawer,
  setPropertyModal,
  setDataSubmit,
  buttonCancelForm,
  setIsDataUpdated,
  propertyModal,
  listComparative,
  objectRegistrationId,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [dataType, setDataType] = useState<any[]>([])

  const [form] = Form.useForm()

  const dataForm = Form.useWatch('groupForm', form)

  const initDataSecondary = {
    groupFormSecondary: [
      {
        name: undefined,
        value: '',
        comparative: null,
        eCost: 0,
        category: null,
        unit: '',
        eCostId: null,
        deviceRuleId: null,
        eCostName: '',
      },
    ],
  }

  const initData = {
    groupFormFirst: [
      {
        ...initDataSecondary,
      },
    ],
  }
  const eCostDataCustom = useMemo(
    () =>
      eCostData.map((eCostItem) => ({
        rules: eCostItem.rules.map((rule) => {
          delete rule['device_id']
          rule['name'] = [RULE_CATEGORY.CATEGORY_20.value, RULE_CATEGORY.CATEGORY_40.value, RULE_CATEGORY.CATEGORY_50.value].includes(rule['category'])
            ? rule['name']
            : ''
          return rule
        }),
        e_cost: eCostItem.e_cost,
      })),
    [eCostData]
  )

  const fetchDataForm = (eCostDataArgs) => {
    setDataType(eCostDataArgs)
    let dataTransform: any = {
      groupForm: [],
    }
    if (eCostData.length > 0) {
      const eCostDataClone: any[] = [...eCostDataArgs]
      const data = groupBy(eCostDataClone, (item) => item.e_cost.e_cost_value)

      if (form) {
        const transformData = (rule, e_cost) => {
          return {
            name: rule.name ?? '',
            value: rule.value,
            comparative: rule.comparative,
            eCost: e_cost.e_cost_value,
            category: rule.category,
            unit: rule.unit,
            eCostId: e_cost.e_cost_id,
            eCostName: e_cost.e_cost_name,
            deviceRuleId: rule.device_rule_id,
          }
        }

        const groupForm = Object.values(data).flatMap((item) => {
          const formGroupByCategory = item.flatMap((category) => ({
            rules: Object.values(groupBy(category.rules, (rule) => rule.category)),
            ...category.e_cost,
          }))
          return {
            groupFormFirst: formGroupByCategory.flatMap((eCost) => {
              const { rules: eCostRules, ...eCostRest } = eCost
              return eCostRules.map((ruleItem) => ({ groupFormSecondary: ruleItem.map((itemChild) => transformData(itemChild, { ...eCostRest })) }))
            }),
          }
        })

        dataTransform = { groupForm }
      }
    }

    form.setFieldsValue(dataTransform)
  }

  const handleEdit = (value) => {
    setIsEditing(value)
    setPropertyModal((prev) => ({ ...prev, beforeOpen: value, isOpen: false }))
    if (propertyModal.type === 'cancel') {
      fetchDataForm(eCostData)
    }
  }

  const getFormattedFormData = (data) => {
    return data.reduce((acc: any[], item: any) => {
      if (!get(item, 'groupFormFirst.length')) return acc

      const rules: any[] = []
      for (const first of get(item, 'groupFormFirst')) {
        if (!get(first, 'groupFormSecondary.length')) continue

        for (const second of get(first, 'groupFormSecondary')) {
          if (!second.category) continue

          const baseItem = {
            category: second.category,
            name: second.name ?? '',
            unit: second.unit,
          }

          rules.push({
            ...baseItem,
            device_rule_id: second.deviceRuleId,
            value: second.value,
            comparative: second.comparative ?? '',
          })
        }
      }

      if (!rules.length) return acc

      const currentItem = item.groupFormFirst[0]?.groupFormSecondary[0]
      const e_cost = {
        e_cost_id: currentItem?.eCostId,
        e_cost_value: currentItem?.eCost,
        e_cost_name: currentItem?.eCostName,
      }

      acc.push({ rules, e_cost })
      return acc
    }, [])
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const newData = getFormattedFormData(values.groupForm)
        setDataType(newData)
        setDataSubmit(newData)
        if (!isEqual(eCostDataCustom, newData)) {
          setPropertyModal((prev) => ({ ...prev, type: 'submit', isOpen: true }))
        } else {
          setIsEditing(!isEditing)
        }
      })
      .catch((errorInfo) => {
        throw errorInfo
      })
  }

  const handleCheckChangeForm = () => {
    const newData = getFormattedFormData(dataForm)
    setIsDataUpdated(!isEqual(eCostDataCustom, newData))
  }

  useEffect(() => {
    if (dataForm) {
      handleCheckChangeForm()
    }
  }, [dataForm])

  const renderRuleName = (idRegistration) => {
    const rule = listComparative.find((item) => item.detail_item_registration_id === idRegistration)
    return rule?.name ?? idRegistration
  }

  const renderSetRulesForm = (fields: any, removeParent: any, add: any) => {
    return (
      <>
        {fields.map(({ key: keyParent, name: nameParent }, indexParent) => (
          <Form.List name={[nameParent, 'groupFormFirst']} key={keyParent}>
            {(fields, { add, remove }) => (
              <Row className="group flex bg-[#FAFAFA] hover:bg-[#F0F9FF] py-2 mt-2 rounded-lg">
                <Col className="h-inherit!important w-[52px] flex justify-center items-center">
                  <MoveIcon />
                </Col>
                <Col className="w-[calc(100%-52px)] group-hover:bg-[#F0F9FF]">
                  {fields.map(({ key, name }, index) => (
                    <TypeInputValueForm
                      fieldsLength={fields.length}
                      removeParent={removeParent}
                      remove={remove}
                      indexParent={indexParent}
                      key={key}
                      nameParent={name}
                      nameGrandpa={nameParent}
                      index={index}
                      dataForm={dataForm}
                      form={form}
                      objectRegistrationId={objectRegistrationId}
                      isActualValue={isActualValue}
                    />
                  ))}
                  <Row>
                    <Col span={16}>
                      <Form.Item className="mt-2 mb-0">
                        <Button
                          type={BUTTON_TYPE.outlined}
                          onClick={() => add(initDataSecondary)}
                          block
                          className="text-center w-full h-10"
                          disabled={fields.length === OPTION_RULE_TYPE.length}
                          prefixIcon={<PlusIcon isDisable={fields.length === OPTION_RULE_TYPE.length} />}
                          text="項目の追加"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}></Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Form.List>
        ))}
        <Button
          type={BUTTON_TYPE.outlined}
          onClick={() => add(initData)}
          block
          className="mt-2 w-full text-center h-10"
          prefixIcon={<PlusIcon />}
          text="ルールの追加"
        />
      </>
    )
  }

  useEffect(() => {
    fetchDataForm(eCostData)
    return () => {
      setIsEditing(false)
      setDataType([])
      fetchDataForm([])
    }
  }, [eCostData])

  useEffect(() => {
    if (isEditing) {
      setTextCancelDrawer('値を元に戻す')
      return
    }
    setTextCancelDrawer('キャンセル')
  }, [isEditing])

  return (
    <div>
      <div className="mt-2">
        <div className={`title flex justify-between items-center h-12 ${isEditing ? 'px-[58px]' : 'px-2'}`}>
          <span className="font-bold">{ruleName}</span>
          <span className="font-bold">{isActualValue ? 'モジュール単位' : 'モジュール単価'}</span>
        </div>
        <Divider />
      </div>
      <div className="flex flex-col">
        {!isEditing ? (
          <>
            {dataType.map((item, index) => (
              <div key={index}>
                <Row className="px-4">
                  <Col span={20} className="my-3">
                    {item.rules.map((itemRule, indexRule) => (
                      <Row key={indexRule} className={indexRule > 0 ? 'mt-2' : ''}>
                        <Col span={5}>{ruleCategory(itemRule.category)}</Col>
                        <Col span={7}>{itemRule.category === RULE_CATEGORY.CATEGORY_40.value ? renderRuleName(itemRule.name) : ''}</Col>
                        <Col span={7}>
                          <span>
                            {[RULE_CATEGORY.CATEGORY_20.value, RULE_CATEGORY.CATEGORY_50.value].includes(itemRule.category)
                              ? itemRule.name
                              : `${itemRule.value ?? ''} ${itemRule.unit ?? ''}`}
                          </span>
                        </Col>
                        <Col span={5}>
                          <span>{ruleComparative(itemRule.comparative?.toLowerCase())}</span>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col span={4} className="flex justify-end items-center">
                    <div>{item.e_cost.e_cost_value}</div>
                  </Col>
                </Row>
                <Divider className="mb-2 bg-[#A3A3A3]" />
              </div>
            ))}
            <Button
              type={BUTTON_TYPE.text}
              onClick={() => handleEdit(true)}
              block
              className="px-0 mt-2 text-right w-fit self-end mr-4"
              prefixIcon={<SettingIcon />}
              text="ルールの編集"
            />
          </>
        ) : (
          <Form form={form}>
            <Form.List name="groupForm">{(fields, { add, remove: removeParent }) => renderSetRulesForm(fields, removeParent, add)}</Form.List>
          </Form>
        )}
        <AntdButton ref={buttonSubmitFormRef} onClick={onFinish} className="hidden" />
        <AntdButton ref={buttonCancelForm} onClick={() => handleEdit(false)} className="hidden" />
      </div>
    </div>
  )
}

export default typeInputValue
