import client from '~/api/axiosClient'

export const apiListRelevantObject = (request: any) => client.get(`/relevantObjects/${request.object_registration_id}`)

export const apiUpdateListRelevantObject = (data: any) => client.put(`/relevantObjects`, data)

export const apiGetStatistic = (request: any) => client.get(`/statistic/${request.object_registration_id}`)

export const apiContractPrices = (request: any) =>
  client.get(`/contractPrices/${request.object_id}`, {
    params: request.params,
  })
export const apiPredictECost = (request: any) => {
  client.get(`/predictECost`, {
    params: request,
  })
}
export const apiUpdateEcost = (request: any) =>
  client.put(`/eCost/${request.object_registration_id}`, {
    e_cost: request.e_cost,
  })

// Curved chart
export const apiContractPricesWithDetails = (request: any) =>
  client.post(`/contractPricesWithDetails`, {
    ...request.params,
  })

export const apiMachineDetailForChart = (machine_id: string) => client.get(`/machineDetailForChart/${machine_id}`)
