import { UploadOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import React from 'react'

import Button from '../atoms/Button'

import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

interface UploadButtonProps {
  request?: any
  formDataKey?: string
  acceptType?: string
  multiple?: boolean
  showUploadList?: boolean
  children?: React.ReactNode
}

const UploadButton = (props: UploadButtonProps) => {
  const { request, formDataKey = 'file', acceptType = '*/*', multiple = false, showUploadList = false, children, ...rest } = props

  const { url: requestUrl, params: requestParams, onSuccess: successHandler, onError: errorHandler } = request

  const [fileListState, setFileListState] = React.useState<UploadFile[]>([])

  const handleUpload = async (options: any) => {
    const { onSuccess, onError } = options
    try {
      const formData = new FormData()
      fileListState.forEach((file: RcFile) => {
        formData.append(formDataKey, file)
      })
      const response = await requestUrl({ ...requestParams, formData })
      if (onSuccess) {
        onSuccess(response?.data)
        setFileListState([])
      }
      if (successHandler) successHandler(response?.data)
    } catch (error) {
      if (onError) onError(error)
      if (errorHandler) errorHandler(error)
    }
  }

  const uploadProps: UploadProps = {
    name: 'uploadFile',
    accept: acceptType,
    beforeUpload: (file, fileList) => {
      setFileListState(fileList)
      return file?.uid === fileList[0]?.uid
    },
    customRequest: handleUpload,
    multiple,
    showUploadList,
    ...rest,
  }

  return <Upload {...uploadProps}>{children ? children : <Button type="outlined" prefixIcon={<UploadOutlined />} text="Click to Upload" />}</Upload>
}

export default UploadButton
