import { Checkbox, Modal, Row, Spin, Tabs } from 'antd'
import * as echarts from 'echarts'
import * as ecStat from 'echarts-stat'
import React, { useState, useRef, useEffect, useMemo } from 'react'

import Button from '~/components/atoms/Button'

import { apiGetStatistic, apiListRelevantObject, apiUpdateListRelevantObject } from '~/api/chart'
import SettingIcon from '~/assets/icons/Setting'
import { DUMMY_LINE_CHART } from '~/consts/common'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { machines, setInfoMachine } from '~/redux/reducers/machine.slice'
import { formatDecimal, generateUniqueKey, getRandomColor, handleError, pushNotification } from '~/utils/helper'

import type { EChartsOption, ECharts, SetOptionOpts } from 'echarts'

export interface ReactEChartsProps {
  option?: EChartsOption
  settings?: SetOptionOpts
  theme?: 'light' | 'dark'
  objectId: string
  objectRegistration: string
  queryParamsChart: any
  contractPrice: any
}

echarts.registerTransform(ecStat['transform'].regression)

const PastCostChart = ({ option, theme, objectRegistration, contractPrice, queryParamsChart }: ReactEChartsProps) => {
  const chartRef = useRef(null)
  const dispatch = useAppDispatch()
  const { infoMachine } = useAppSelector(machines)

  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [listControl, setListControl] = useState<any[]>([])
  const [max, setMax] = useState<number>(0)
  const [maxStatic, setMaxStatic] = useState<number>(0)
  const [viewFlag, setViewFlag] = useState<any[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)
  const [listRelevant, setListRelevant] = useState<any[]>([])

  // Default chart
  const dataTransform = useMemo(
    () =>
      contractPrice?.map((_, index) => ({
        fromDatasetIndex: 2 * index,
        transform: {
          type: 'ecStat:regression',
          config: { method: 'polynomial', order: 3 },
        },
        key: generateUniqueKey('transform', index),
      })),
    [contractPrice]
  )

  const dataSource = useMemo(
    () =>
      contractPrice?.map((itemLine: any, itemLineIdx: number) => {
        return {
          source: itemLine?.objects
            ? itemLine.objects?.map((line) => [
                Date.parse(line.contract_date),
                line.module_price,
                line.office_name,
                line.property_name,
                line.quantity,
                line.list_price,
                line.rate,
                line.contract_price,
                line.contract_date,
                line.color,
              ])
            : DUMMY_LINE_CHART[0].source,
          key: generateUniqueKey('source', itemLineIdx),
        }
      }),
    [contractPrice, queryParamsChart]
  )

  const sourceStatic = useMemo(
    () =>
      listControl?.flatMap((itemLine) => {
        return itemLine?.objects.filter((item) => item.value > 0).flatMap((child) => child.value)
      }),
    [listControl]
  )

  // Get max price and static
  const sourcePrice = useMemo(
    () =>
      contractPrice?.flatMap((itemLine) => {
        return itemLine.objects ? itemLine.objects.filter((item) => item.contract_price > 0).map((child) => child.contract_price) : [0]
      }),
    [contractPrice, queryParamsChart]
  )

  const defaultScatter = useMemo(
    () =>
      contractPrice?.map((item, index) => ({
        name: `scatter + ${index}`,
        type: 'scatter',
        datasetIndex: 2 * index,
        itemStyle: {
          color: (params: any) => params.data[9] || 'transparent',
          borderColor: (params: any) => params.data[9] || 'transparent',
          borderWidth: (params: any) => (params.data[9] ? 1 : 0),
        },
        yAxisIndex: 0,
        key: generateUniqueKey('scatter', index),
      })),
    [contractPrice]
  )

  const defaultChangeLine = useMemo(
    () =>
      contractPrice?.map((item, index) => ({
        name: `line + ${index}`,
        type: 'line',
        smooth: true,
        datasetIndex: 2 * index + 1,
        symbolSize: 0.1,
        symbol: 'circle',
        lineStyle: {
          color: item.color,
        },
        key: generateUniqueKey('line', index),
      })),
    [contractPrice]
  )

  const controlDataDefault = useMemo(
    () =>
      listControl.map((itemLine) => ({
        id: itemLine.name,
        data: itemLine?.objects.map((line) => {
          return [Date.parse(line.date), line.value]
        }),
        type: 'line',
        showSymbol: false,
        show: true,
        default: true,
        lineStyle: {
          color: 'transparent',
          type: itemLine.type,
        },
        yAxisIndex: 1,
      })),
    [listControl]
  )

  const controlData = useMemo(
    () =>
      listControl.map((itemLine) => ({
        id: itemLine.name,
        data: itemLine?.objects.map((line) => {
          return [Date.parse(line.date), line.value, line.cost]
        }),
        type: 'line',
        showSymbol: true,
        tooltip: {
          show: true,
          formatter: (params) => {
            return params.data[2]
          },
        },
        symbolSize: 0.5,
        default: true,
        lineStyle: {
          color: itemLine.color,
          type: itemLine.type,
        },
        yAxisIndex: 1,
        checked: itemLine.checked,
      })),
    [listControl]
  )

  const defaultLines = [...defaultScatter, ...defaultChangeLine, ...controlDataDefault]

  const defaultLinesControl = useMemo(
    () => [...defaultScatter, ...defaultChangeLine, ...controlData.filter((line) => line.checked)],
    [controlData, contractPrice]
  )

  const dataSet = [...dataSource, ...dataTransform]

  const onCheckAllChange = () => {
    setCheckAll(!checkAll)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const initRelevanceFirst = useMemo(
    () =>
      listRelevant.flatMap((listItem) =>
        listItem?.objects.map((item) => ({
          id: item.id,
          view_flag: item.view_flag,
        }))
      ),
    [listRelevant]
  )

  const handleOk = async () => {
    setIsLoadingModal(true)
    try {
      await apiUpdateListRelevantObject({
        data: viewFlag,
      })
      getListRelevantObject()
      getStatics()
      setViewFlag([])
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingModal(false)
    }
  }

  // Modal change selectBox
  const handleChange = (e: any, item: any) => {
    const targetData = listControl.map((itemData) => {
      if (itemData.name === item.name) {
        return { ...itemData, checked: e.target.checked }
      }
      return itemData
    })
    setListControl(targetData)
  }

  const handleClose = () => {
    setViewFlag([])
    setIsModalVisible(false)
    setViewFlag([])
  }

  const getListRelevantObject = async () => {
    try {
      const res = await apiListRelevantObject({
        object_registration_id: objectRegistration,
      })
      setListRelevant(res.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  const handleChangeRelevanceFirst = (e: any, record: any) => {
    const targetIndex = initRelevanceFirst.findIndex((item) => item.id === record.id)
    let listUpdate = [...viewFlag]

    if (e.target.checked !== initRelevanceFirst[targetIndex].view_flag) {
      listUpdate.push({
        id: initRelevanceFirst[targetIndex].id,
        view_flag: e.target.checked,
      })
    } else {
      listUpdate = listUpdate.filter((item) => item.id !== record.id)
    }
    setViewFlag(listUpdate)
  }

  option = useMemo(
    () => ({
      dataset: contractPrice.length > 0 ? dataSet : DUMMY_LINE_CHART,
      title: {
        text: 'M値推移',
        left: 'left',
      },
      grid: [{ left: '1%', bottom: '5%', show: true, width: '95%', containLabel: true }],
      tooltip: {
        trigger: 'item',
        formatter: (param) => {
          return [
            `支店: ${param.data[2]} <br/>`,
            `物件名:  ${param.data[3]} <br/>`,
            `数量:  ${param.data[4]} <br/>`,
            `定価:  ${param.data[5]} <br/>`,
            `掛け率: ${param.data[6]} <br/>`,
            `契約金額:  ${param.data[7]} <br/>`,
            `契約日:  ${param.data[8]} <br/>`,
          ].join('')
        },
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        splitLine: {
          show: true,
        },
        axisLabel: {
          color: '#737373',
          showMinLabel: true,
          showMaxLabel: false,
          formatter: (_, index) => (index % 2 === 0 ? `{yyyy}` : null),
          hideOverlap: true,
          margin: 11,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            width: 2,
            color: '#A3A3A3',
          },
        },
      },
      yAxis: [
        {
          type: 'value',

          axisLabel: {
            formatter: '{value} 円',
            margin: 12,
            color: '#737373',
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'black',
              width: 1,
            },
          },
          splitLine: {
            show: true,
          },
          position: 'left',
        },
        {
          // name: '石油',
          type: 'value',
          nameLocation: 'end',
          axisLabel: {
            formatter: '{value}',
            color: '#737373',
            margin: 12,
          },
          position: 'right',
          splitLine: { show: true },
          max: () => {
            return formatDecimal(Math.round(maxStatic + 400))
          },
        },
      ],
    }),
    [contractPrice, max, maxStatic]
  )

  const getStatics = async () => {
    try {
      const res = await apiGetStatistic({
        object_registration_id: objectRegistration,
      })
      setListControl(
        res.data.data.map((item, index) => ({
          ...item,
          checked: true,
          color: getRandomColor(),
          name: item.name,
          type: index % 2 === 0 ? 'dashed' : null,
          key: generateUniqueKey('listControl', item.name),
        }))
      )
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  useEffect(() => {
    if (isModalVisible) {
      getListRelevantObject()
    }
  }, [isModalVisible])

  useEffect(() => {
    getStatics()
  }, [objectRegistration])

  useEffect(() => {
    let chart: ECharts | undefined
    if (chartRef.current !== null) {
      chart = echarts.init(chartRef.current, theme)
    }
    return () => {
      chart?.dispose()
    }
  }, [theme])

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current)
      chart?.setOption({ ...option, series: checkAll ? defaultLines : defaultLinesControl }, true, true)
      chart?.resize()
    }
  }, [checkAll, defaultLines])

  useEffect(() => {
    if (sourcePrice !== undefined || listControl !== undefined) {
      setMax(Math.max(...sourcePrice))
      setMaxStatic(Math.max(...sourceStatic))
    }
  }, [sourcePrice, listControl])

  useEffect(() => {
    if (viewFlag.length > 0) {
      setTimeout(handleOk, 200)
    }
  }, [viewFlag])

  useEffect(() => {
    const mapStatistics = listControl.reduce((result, statistic) => {
      if (statistic.checked) {
        result.push(statistic.name)
      }
      return result
    }, [])
    dispatch(
      setInfoMachine({
        ...infoMachine,
        statistics: mapStatistics,
      })
    )
  }, [listControl])

  const componentContent = (objectContent: any) =>
    objectContent[0]?.objects.map((item: any) => (
      <Row key={item.id} className="flex mb-4">
        <Checkbox defaultChecked={item.view_flag} onChange={(e) => handleChangeRelevanceFirst(e, item)}>
          {item.name}
        </Checkbox>
      </Row>
    ))

  return (
    <>
      <Row>
        <div className="linePastCostChart" ref={chartRef} />
      </Row>
      <Row>
        <Row className="flex items-center">
          <span className="text-[#23221E] font-bold text-base mr-8">市場価格</span>
          <Button type="text" onClick={showModal} text="グラフ切替" prefixIcon={<SettingIcon customColor="#009BE0" />} />
        </Row>
        <Row className="w-full text-sm text-[#171717] font-normal">
          <div className="w-full mb-3">
            {listControl.length > 0 && (
              <Checkbox className="ml-1" onChange={onCheckAllChange} checked={!checkAll}>
                全て表示
              </Checkbox>
            )}
          </div>
          <div className="grid gap-x-4 gap-y-3 grid-cols-2 w-full">
            {listControl.map((item, index) => (
              <div key={item.key} className=" p-1">
                <Checkbox className="flex items-start" defaultChecked={item.checked} disabled={checkAll} onChange={(e) => handleChange(e, item)}>
                  <hr className={`w-8 h-0.5 mr-2 ${index % 2 === 0 ? 'border-dashed' : null} `} style={{ backgroundColor: item.color }} />
                  {item.name}
                </Checkbox>
              </div>
            ))}
          </div>
        </Row>
      </Row>
      <Modal
        className="modal-chart-line mt-[6%] ml-[56%] w-[25.15rem]"
        title={<span className="text-base font-bold px-3">関連グラフ表示</span>}
        onCancel={handleClose}
        open={isModalVisible}
        okButtonProps={{ disabled: viewFlag.length === 0 }}
        footer={null}
        mask={false}
      >
        <>
          <Spin spinning={isLoadingModal}>
            <div className="text-xs p-3 ">グラフに表示したい項目を10個まで選択できます。初期値として対象の機器に関連した項目を選択しています。</div>
            <div>
              <Tabs
                className="w-full"
                defaultActiveKey="1"
                items={[
                  {
                    label: '関連度・高',
                    key: '1',
                    children: componentContent(listRelevant.filter((relevant) => relevant.relevanceRatio === 1)),
                  },
                  {
                    label: '関連度・中',
                    key: '2',
                    children: componentContent(listRelevant.filter((relevant) => relevant.relevanceRatio === 5)),
                  },
                  {
                    label: '関連度・小',
                    key: '3',
                    children: componentContent(listRelevant.filter((relevant) => relevant.relevanceRatio === 10)),
                  },
                ]}
              />
            </div>
          </Spin>
        </>
      </Modal>
    </>
  )
}

export default React.memo(PastCostChart)
