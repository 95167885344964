import React, { useState, useEffect, useRef, useCallback } from 'react'

import { IFixedTableValue } from '~/interfaces/scrollbar'

type ScrollbarProps = {
  contentRef?: any
  defaultContentWidth?: number
  defaultFixedWidth?: number
  defaultThumbWidth?: number
  fixedTableValue?: IFixedTableValue | null
}

const Scrollbar: React.FC<ScrollbarProps> = ({ contentRef, fixedTableValue, defaultContentWidth, defaultFixedWidth, defaultThumbWidth }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const trackRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)

  const [thumbWidth, setThumbWidth] = useState(defaultThumbWidth)
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(null)
  const [initialScrollLeft, setInitialScrollLeft] = useState<number>(0)
  const [isDragging, setIsDragging] = useState(false)
  const containerWidth = containerRef?.current?.clientWidth || 0

  useEffect(() => {
    if (defaultFixedWidth && defaultContentWidth) setThumbWidth(Math.pow(containerWidth - defaultFixedWidth, 2) / defaultContentWidth)
  }, [containerWidth, fixedTableValue])

  const handleTrackClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target.className !== 'fake-scrollbar__track' || isDragging) return

    const { current: trackCurrent } = trackRef
    const { current: contentCurrent } = contentRef
    const { current: thumbCurrent } = thumbRef

    if (trackCurrent && contentCurrent && thumbCurrent) {
      const { clientX } = e
      const target = e.target as HTMLDivElement
      const rect = target.getBoundingClientRect()
      const trackLeft = rect.left
      const thumbOffset = -(thumbCurrent.offsetWidth / 2)
      const clickRatio = (clientX - trackLeft + thumbOffset) / trackCurrent.clientWidth
      const scrollAmount = Math.floor(clickRatio * contentCurrent.scrollWidth)

      contentCurrent.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  const handleThumbMouseup = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (isDragging) {
        setIsDragging(false)
        setScrollStartPosition(null)
      }
    },
    [isDragging]
  )

  const handleThumbPosition = useCallback((e: any) => {
    if (!trackRef.current || !thumbRef.current || !containerRef.current) return

    const { scrollLeft: contentScrollLeft, scrollWidth: contentScrollWidth } = e.target
    const { clientWidth: trackClientWidth } = trackRef.current
    const { clientWidth: containerClientWidth } = containerRef.current
    const { offsetWidth: thumbOffsetWidth } = thumbRef.current

    let newLeft = (contentScrollLeft / (contentScrollWidth - containerClientWidth)) * (trackClientWidth - thumbOffsetWidth)
    newLeft = Math.min(newLeft, trackClientWidth - thumbOffsetWidth)

    thumbRef.current.style.left = `${newLeft}px`
  }, [])

  const handleThumbMousemove = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      if (!trackRef.current || !thumbRef.current || !containerRef.current || !contentRef.current || !isDragging || !scrollStartPosition) return

      const { scrollWidth: contentScrollWidth, offsetWidth: contentOffsetWidth } = contentRef.current
      const { clientWidth: trackClientWidth } = trackRef.current
      const { clientWidth: containerClientWidth } = containerRef.current
      const { offsetWidth: thumbOffsetWidth } = thumbRef.current

      const ratio = (trackClientWidth - thumbOffsetWidth) / (contentScrollWidth - containerClientWidth)

      const deltaX = (e.clientX - scrollStartPosition) / ratio
      const newScrollLeft = Math.min(initialScrollLeft + deltaX, contentScrollWidth - contentOffsetWidth)

      contentRef.current.scrollLeft = newScrollLeft
    },
    [isDragging, scrollStartPosition, contentRef.current]
  )

  const handleThumbMousedown = (e) => {
    if (contentRef.current) setInitialScrollLeft(contentRef.current.scrollLeft)
    setScrollStartPosition(e.clientX)
    setIsDragging(true)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleResizeWindow = () => {
    if (!trackRef.current || !thumbRef.current || !containerRef.current || !contentRef.current || !isDragging || !scrollStartPosition) return
    const { clientWidth: trackClientWidth } = trackRef.current
    const { clientWidth: thumbClientWidth } = thumbRef.current

    const { scrollWidth: contentScrollWidth } = contentRef.current
    const value = Math.max(Math.pow(trackClientWidth, 2) / contentScrollWidth, thumbClientWidth)

    setThumbWidth(value)
    thumbRef.current.style.width = `${value}px`
    contentRef.current.scrollTo({
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove)
    document.addEventListener('mouseup', handleThumbMouseup)
    document.addEventListener('mouseleave', handleThumbMouseup)
    window.addEventListener('resize', handleResizeWindow)

    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove)
      document.removeEventListener('mouseup', handleThumbMouseup)
      document.removeEventListener('mouseleave', handleThumbMouseup)
      window.addEventListener('resize', handleResizeWindow)
    }
  }, [handleThumbMousemove, handleThumbMouseup])

  useEffect(() => {
    if (contentRef.current) {
      const ref = contentRef.current

      ref.addEventListener('scroll', handleThumbPosition)
      return () => {
        ref.removeEventListener('scroll', handleThumbPosition)
      }
    }
  }, [contentRef.current])

  let fixedThumbWidth = 0

  if (fixedTableValue) {
    const { contentWidth, containerWidth, fixedColumWidth } = fixedTableValue
    fixedThumbWidth = Math.pow(containerWidth - fixedColumWidth, 2) / contentWidth
  }

  return (
    <div className="fake-scrollbar__container" ref={containerRef}>
      <div className="fake-scrollbar__track" ref={trackRef} onClick={handleTrackClick}>
        <div
          className="fake-scrollbar__thumb"
          ref={thumbRef}
          onMouseDown={handleThumbMousedown}
          style={{
            width: `${fixedThumbWidth ? fixedThumbWidth : thumbWidth}px`,
          }}
        />
      </div>
    </div>
  )
}

export default Scrollbar
