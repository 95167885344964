import { Button as AntdButton, Col, Form, Row, Table, Tooltip, Typography } from 'antd'
import { TableRowSelection } from 'antd/lib/table/interface'
import { cloneDeep, fromPairs, isNumber, keys, uniq } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import SortIcon from '~/components/atoms/SortIconTable'
import TableMachine from '~/components/molecules/TableMachine'

import { apiGetMachine, apiGetMachineElement, apiGetTotalMachine, apiMachineGroup, apiUpdateMachine } from '~/api/airCondition'
import AnnotationIcon from '~/assets/icons/AnnotationIcon'
import ChartIcon from '~/assets/icons/ChartIcon'
import SettingIcon from '~/assets/icons/SettingIcon'
import { QueryParamMachine } from '~/interfaces/device'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { machines, setDataListMachine, setMetaDataTable } from '~/redux/reducers/machine.slice'
import {
  addIndex,
  checkChildrenEnd,
  checkMerge,
  findParent,
  findParentAll,
  formatDecimal,
  formatNumber,
  groupedObj,
  handleError,
  keepLatestItemsByKey,
  markLastEnd,
  moveOrAddElementToEnd,
  pushNotification,
  reFormatDataSubmit,
  revertDataSubmit,
  revertID,
  ruleECostType,
  sortedTreeData,
  updateChild,
} from '~/utils/helper'

import AirCondition from '../airCondition-7/secondTab'

import '~/assets/styles/feature/airCondition.scss'

interface DeviceEditProps {
  keyScreen?: string
  keyTab?: string
  show: any
  toggleShow: any
  reload: boolean
  isEditRows: boolean
  setEditRows: any
  setIsGroup: any
  setSelectedRowKeys: any
  setMainElement: any
  setGroupNumber: any
  selectedRowKeys: any[]
  setIsSelectRow: any
  setCheckReloadIndividual: any
  buttonSubmitForm: any
  buttonCancel: any
  setDemoChart: any
  setRecordId: any
}

const { Text } = Typography

const initialState = {
  data: [],
  per_page: 10,
  prev_page_url: null,
  to: 0,
  total: 0,
  current_page: 1,
}

const renderTooltipTextCell = (value: string, width = 'w-full', textPosition = 'text-center') => {
  return (
    <Tooltip placement="bottom" title={value}>
      <p className={`truncate ${width} ${textPosition}`}>{value}</p>
    </Tooltip>
  )
}

const renderTooltipValueUnitCell = (value: number | string, unit?: string, tooltipText?: string) => {
  if (!value || !unit) return null
  const tooltip = tooltipText || `${value} ${unit}`
  return (
    <Tooltip placement="bottom" title={tooltip}>
      <Row className="w-full truncate space-x-2">
        <Col span={unit ? 12 : 24} className="text-right">
          <span>{value}</span>
        </Col>
        {unit && (
          <Col span={10} className="text-left">
            <span className="text-[#737373]">{unit}</span>
          </Col>
        )}
      </Row>
    </Tooltip>
  )
}

const widthColumnProps = (width: number, className = '') => ({
  width,
  className: `min-w-[${width}px] ${className}`,
})

const checkStatus = (status: string | number, isParent = false) => {
  let text = status
  if (isNumber(status)) {
    switch (status) {
      case 0:
        text = isParent ? '未引合あり' : '未引合'
        break
      case 2:
        text = isParent ? '引合中あり' : '引合中'
        break
      case 3:
        text = isParent ? '引合済' : '引合済'
        break
      default:
        text = 'しない'
        break
    }
  }

  return <p className="truncate">{text}</p>
}

const checkColor = (status: number | string) => {
  let color = ''
  switch (status) {
    case 0:
      color = '#FEE2E2'
      break
    case 2:
      color = '#E0F2FE'
      break
    case 3:
    default:
      // no need to set color #FFFFFF, use default row color (for hover case)
      break
  }

  return color
}

const statusIcon = () => {
  return (
    <Tooltip
      overlayClassName="min-w-[275px]"
      overlayInnerStyle={{ padding: 0 }}
      placement="bottom"
      title={
        <div className="text-xs font-bold leading-5 px-3 py-1.5">
          <p>修正後、引合システムと連携されていません。</p>
          <p>[引合再連携]ボタンで再連携してください</p>
        </div>
      }
    >
      <div className="ml-2">
        <AnnotationIcon isError />
      </div>
    </Tooltip>
  )
}

const revertChildren = (currentItem, matchingItem) => {
  const newData =
    matchingItem.children.length > 0
      ? matchingItem.children.flatMap((item) => {
          const dataUpdate = currentItem.flatMap((itemCurrent) => {
            if (itemCurrent.id.includes(item.id)) {
              return {
                ...itemCurrent,
                a0_cost: item.a0_cost,
                a0_gross_profit_rate: item.a0_gross_profit_rate,
                e_gross_profit_rate: item.e_gross_profit_rate,
                e_cost: item.e_cost,
                name: item.name,
                offer_unit_cost: item.offer_unit_cost,
                offer_amount: item.offer_amount,
                order_accuracy: item.order_accuracy,
                quantity: item.quantity,
                capacity_value: item.capacity_value,
                module_value: item.module_value || '',
                list_price: item.list_price,
                used_a0_mul_rate: item.a0_mul_rate,
                a0_no_rate: item.a0_NO_rate,
                e_no_rate: item.e_NO_rate,
                children: itemCurrent.children && itemCurrent.children.length > 0 ? revertChildren(itemCurrent.children, item) : [],
              }
            }
            return null
          })
          return dataUpdate.filter((item) => item)
        })
      : []
  return newData
}

const checkChildrenTab = (recordsEdit, itemData) => {
  const mapOnlyXX = itemData.children.map((itemS) => (itemS.id.includes('-parent') ? itemS.id.split('-parent')[0] : itemS.id))
  const mapOnly = findParent(recordsEdit, mapOnlyXX)
  const itemMerged = mapOnly
    ? {
        ...itemData,
        a0_cost: mapOnly.a0_cost,
        a0_gross_profit_rate: mapOnly.a0_gross_profit_rate,
        e_gross_profit_rate: mapOnly.e_gross_profit_rate,
        e_cost: mapOnly.e_cost,
        name: mapOnly.name,
        offer_unit_cost: mapOnly.offer_unit_cost,
        offer_amount: mapOnly.offer_amount,
        order_accuracy: mapOnly.order_accuracy,
        quantity: mapOnly.quantity,
        capacity_value: mapOnly.capacity_value,
        module_value: mapOnly.module_value || '',
        list_price: mapOnly.list_price,
        used_a0_mul_rate: mapOnly.a0_mul_rate,
        a0_no_rate: mapOnly.a0_NO_rate,
        e_no_rate: mapOnly.e_NO_rate,
        children: itemData.children && itemData.children.length > 0 ? revertChildren(itemData.children, mapOnly) : [],
      }
    : itemData

  return itemMerged
}

const mergeItemData = (itemData, matchingItem) => {
  return {
    ...itemData,
    a0_cost: matchingItem.a0_cost,
    a0_gross_profit_rate: matchingItem.a0_gross_profit_rate,
    e_gross_profit_rate: matchingItem.e_gross_profit_rate,
    e_cost: matchingItem.e_cost,
    name: matchingItem.name,
    offer_unit_cost: matchingItem.offer_unit_cost,
    offer_amount: matchingItem.offer_amount,
    order_accuracy: matchingItem.order_accuracy,
    quantity: matchingItem.quantity,
    capacity_value: matchingItem.capacity_value,
    module_value: matchingItem.module_value || '',
    a0_no_rate: matchingItem.a0_NO_rate,
    e_no_rate: matchingItem.e_NO_rate,
    list_price: matchingItem.list_price,
    used_a0_mul_rate: matchingItem.a0_mul_rate,
    children: itemData.children && itemData.children.length > 0 ? revertChildren(itemData.children, matchingItem) : [],
  }
}

const Individual = ({
  keyTab,
  keyScreen,
  isEditRows,
  reload,
  selectedRowKeys,
  setSelectedRowKeys,
  setMainElement,
  setIsGroup,
  setGroupNumber,
  setCheckReloadIndividual,
  buttonSubmitForm,
  buttonCancel,
  setDemoChart,
  setRecordId,
  setEditRows,
}: DeviceEditProps) => {
  const { t } = useTranslation()

  const params = useParams()

  const [form] = Form.useForm()

  const { dataTable, selectedConstruction, loaded: machineStateLoaded } = useAppSelector(machines)
  const dispatch = useAppDispatch()

  const [machine, setMachine] = useState<any>(initialState)
  const [dataList, setDataList] = useState<any[]>([])
  const [totalMachine, setTotalMachine] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openDraw, setOpenDraw] = useState<boolean>(false)
  const [recordData, setRecordData] = useState<any>(null)
  const [editingKey, setEditingKey] = useState<string[]>([])
  const [queryParams, setQueryParams] = useState<QueryParamMachine>({
    limit: 10,
    page: 1,
    order_by: null,
    sort: null,
    f: { construction_id: [] },
  })
  const [listDataEdit, setListDataEdit] = useState<any>({})
  const [dataEditSubmit, setDataEditSubmit] = useState<any>([])

  const { data, total, current_page, from, to, per_page } = machine

  const rowSelection: TableRowSelection<any> = useMemo(
    () => ({
      columnTitle: <div className="selection-column-box" />,
      columnWidth: 58,
      selectedRowKeys,
      checkStrictly: false,
      onChange: (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
      },
      onSelect: (record: any, selected, selectedRows) => {
        setGroupNumber(selectedRows.filter((item) => !item.children.length))
        setIsGroup(record.group_id)
        if (selectedRows.length > 1) {
          const mainElementValue = selectedRows[0].main_element
          setMainElement(selectedRows.every((item) => item.main_element === mainElementValue))
        } else setMainElement(false)
      },
      hideSelectAll: true,
      getCheckboxProps: () => {
        return {
          disabled: isEditRows ? true : false,
        }
      },
    }),
    [isEditRows, selectedRowKeys]
  )
  const handleOpenCloseDraw = (status: boolean) => {
    setOpenDraw(status)
    setRecordData(null)
  }

  const showDrawer = async (record: any) => {
    setOpenDraw(true)
    setRecordData({ object_registration_id: record.id, rule_type: record.rule_type })
  }

  const quantityUnitCol = (index: number) => {
    const key = `quantity-${index}`
    return {
      title: ({ sortColumns }) => <SortIcon title={t('air_condition.quantity')} sortColumns={sortColumns} keyName={key} />,
      dataIndex: 'quantity',
      key,
      defaultSortOrder: 'ascend',
      editable: true,
      sorter: true,
      ...widthColumnProps(150),
      render: (_: string, record: any) => {
        const value = formatNumber(record.quantity)
        const unit = record.unit
        return renderTooltipValueUnitCell(value, unit)
      },
    }
  }

  const columns: any = [
    {
      fixed: 'left',
      className: 'hidden-group-header',
      children: [
        {
          title: ({ sortColumns }) => (
            <SortIcon
              title={`${keyTab === 'machine' ? '個別' : keyTab === 'machineInElement' ? '要素' : 'グループ'}ID`}
              sortColumns={sortColumns}
              keyName="object_registrations_number"
            />
          ),
          dataIndex: 'object_registrations_number',
          key: 'object_registrations_number',
          align: 'center',
          sorter: true,
          defaultSortOrder: 'ascend',
          fixed: 'left',
          ...widthColumnProps(150),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.name_element')} sortColumns={sortColumns} keyName="element_name" />,
          dataIndex: 'element_name',
          key: 'element_name',
          defaultSortOrder: 'ascend',
          sorter: true,
          fixed: 'left',
          ...widthColumnProps(200),
          render: (text: string) => renderTooltipTextCell(text, 'w-full', 'text-left'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.name')} sortColumns={sortColumns} keyName="name" />,
          dataIndex: 'name',
          key: 'name',
          defaultSortOrder: 'ascend',
          sorter: true,
          ellipsis: true,
          fixed: 'left',
          ...widthColumnProps(160, 'border-r'),
        },
      ],
    },
    {
      className: 'hidden-group-header',
      children: [
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.main_element')} sortColumns={sortColumns} keyName="main_element" />,
          dataIndex: 'main_element',
          key: 'main_element',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(text),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.group_number')} sortColumns={sortColumns} keyName="group_id" />,
          dataIndex: 'group_id',
          key: 'group_id',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(120),
          render: (text: string) => renderTooltipTextCell(text),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.inquiry')} sortColumns={sortColumns} keyName="status" />,
          dataIndex: 'status',
          key: 'status',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(140),
          render: (text: any, record: any) => {
            const isParent = record.children.length
            const isReInquiryStatusIcon = !!record?.re_inquiry
            return (
              <div className="flex justify-center items-center">
                <>
                  {checkStatus(record?.status_name || text, isParent)}
                  {isReInquiryStatusIcon && statusIcon()}
                </>
              </div>
            )
          },
          onCell: (record: any) => ({
            style: { background: checkColor(record.status) },
          }),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.drawing_number')} sortColumns={sortColumns} keyName="drawing_number" />,
          dataIndex: 'drawing_number',
          key: 'drawing_number',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(text, 'w-64', 'text-left'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.ability')} sortColumns={sortColumns} keyName="capacity_value" />,
          dataIndex: 'capacity_value',
          key: 'capacity_value',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (_: string, record: any) => {
            const value = formatNumber(record.capacity_value)
            const unit = record.capacity_unit
            return renderTooltipValueUnitCell(value, unit)
          },
        },
      ],
    },
    // ** Group 1: 定価 **
    {
      title: '定価',
      className: 'group-header',
      children: [
        quantityUnitCol(1),
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.unit_cost')} sortColumns={sortColumns} keyName="list_price" />,
          dataIndex: 'list_price',
          key: 'list_price',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(120),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.order_accuracy')} sortColumns={sortColumns} keyName="order_accuracy" />,
          dataIndex: 'order_accuracy',
          key: 'order_accuracy',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-25', 'text-right'),
        },
      ],
    },
    // ** End of Group 1: 定価 **
    {
      className: 'hidden-group-header',
      children: [
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.used_a0_mul_rate')} sortColumns={sortColumns} keyName="used_a0_mul_rate" />,
          dataIndex: 'used_a0_mul_rate',
          key: 'used_a0_mul_rate',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(100),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
      ],
    },
    // ** Group 2: 見積原価 **
    {
      title: '見積原価',
      className: 'group-header',
      children: [
        quantityUnitCol(2),
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.unit_cost')} sortColumns={sortColumns} keyName="a0_unit_cost" />,
          dataIndex: 'a0_unit_cost',
          key: 'a0_unit_cost',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.cost_amount')} sortColumns={sortColumns} keyName="a0_cost" />,
          dataIndex: 'a0_cost',
          key: 'a0_cost',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.gross_profit_margin')} sortColumns={sortColumns} keyName="a0_gross_profit_rate" />,
          dataIndex: 'a0_gross_profit_rate',
          key: 'a0_gross_profit_rate',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(120),
          render: (text: any) => (
            <Tooltip placement="bottom" title={formatNumber(text)}>
              <p className="w-full truncate text-right">{formatDecimal(text)}</p>
            </Tooltip>
          ),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.module_value_unit')} sortColumns={sortColumns} keyName="a0_module_value" />,
          dataIndex: 'a0_module_value',
          key: 'a0_module_value',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (_: string, record: any) => {
            const value = formatNumber(record.a0_module_value)
            const unit = record.module_unit
            return renderTooltipValueUnitCell(value, unit ? `/${unit}` : '', unit ? `${value}/${unit}` : '')
          },
        },
      ],
    },
    // ** End of Group 2: 見積原価 **
    {
      className: 'hidden-group-header',
      children: [
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.a0_no_rate')} sortColumns={sortColumns} keyName="a0_no_rate" />,
          dataIndex: 'a0_no_rate',
          key: 'a0_no_rate',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(120),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
      ],
    },
    // ** Group 3: 提出 **
    {
      title: '提出',
      className: 'group-header',
      children: [
        quantityUnitCol(3),
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.unit_cost')} sortColumns={sortColumns} keyName="offer_unit_cost" />,
          dataIndex: 'offer_unit_cost',
          key: 'offer_unit_cost',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.cost_amount')} sortColumns={sortColumns} keyName="offer_amount" />,
          dataIndex: 'offer_amount',
          key: 'offer_amount',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right'),
        },
      ],
    },
    // ** End of Group 3: 提出 **
    // ** Group 4: 実行原価 **
    {
      title: '実行原価',
      className: 'group-header',
      children: [
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.e_cost')} sortColumns={sortColumns} keyName="e_cost" />,
          dataIndex: 'e_cost',
          key: 'e_cost',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(180),
          render(text: any, record: any) {
            return (
              <Row className="space-x-3">
                <Col span={14} className="flex items-center">
                  {renderTooltipTextCell(formatNumber(text), 'w-full', 'text-right')}
                </Col>
                <Col span={7}>
                  {record.children.length === 0 && (
                    <div
                      className="hover:opacity-50"
                      onClick={() => {
                        setRecordId(record.id)
                        setDemoChart(true)
                      }}
                    >
                      <ChartIcon />
                    </div>
                  )}
                </Col>
              </Row>
            )
          },
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.gross_profit_margin')} sortColumns={sortColumns} keyName="e_gross_profit_rate" />,
          dataIndex: 'e_gross_profit_rate',
          key: 'e_gross_profit_rate',
          defaultSortOrder: 'ascend',
          editable: true,
          sorter: true,
          ...widthColumnProps(120),
          render: (text: any) => (
            <Tooltip placement="bottom" title={formatNumber(text)}>
              <p className="w-full truncate text-right">{formatDecimal(text)}</p>
            </Tooltip>
          ),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.e_cost_type')} sortColumns={sortColumns} keyName="rule_type" />,
          dataIndex: 'rule_type',
          key: 'rule_type',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(140),
          render(_, record: any) {
            return (
              <>
                {record.children.length > 0 ? (
                  <p className="w-full text-center">---</p>
                ) : (
                  <div className="flex justify-around items-center" onClick={() => showDrawer(record)}>
                    <span className={!record.rule_type ? 'text-slate-300' : 'text-black'}>{ruleECostType(record.rule_type) ?? '未選択'}</span>
                    <SettingIcon />
                  </div>
                )}
              </>
            )
          },
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.module_value_unit')} sortColumns={sortColumns} keyName="module_value" />,
          dataIndex: 'module_value',
          key: 'module_value',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(180),
          render: (_: string, record: any) => {
            const value = formatNumber(record.module_value)
            const unit = record.module_unit
            return renderTooltipValueUnitCell(value, `/${unit}`, `${value}/${unit}`)
          },
        },
      ],
    },
    // ** End of Group 4: 実行原価 **
    {
      className: 'hidden-group-header',
      children: [
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.manufacturer')} sortColumns={sortColumns} keyName="manufacturer" />,
          dataIndex: 'manufacturer',
          key: 'manufacturer',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(180),
          render: (text: string) => renderTooltipTextCell(text, 'w-58', 'text-left'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.agency_name')} sortColumns={sortColumns} keyName="agency_name" />,
          dataIndex: 'agency_name',
          key: 'agency_name',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(text, 'w-58', 'text-left'),
        },
        {
          title: ({ sortColumns }) => <SortIcon title={t('air_condition.model_number')} sortColumns={sortColumns} keyName="model_number" />,
          dataIndex: 'model_number',
          key: 'model_number',
          defaultSortOrder: 'ascend',
          sorter: true,
          ...widthColumnProps(150),
          render: (text: string) => renderTooltipTextCell(text, 'w-55', 'text-left'),
        },
      ],
    },
  ]

  const queryParamsGet: { idEstimate: any; params: any } = useMemo(() => {
    const formattedSelectedConstruction = uniq(
      selectedConstruction.map((item: any) => {
        // fn: addKeyValueSelectTree - conventional item format: `${item.construction_id}_${item.item_number}_${item.estimate_construction_id}`
        const [construction_id] = item.split('_')
        return construction_id || item
      })
    )

    return {
      idEstimate: params.idEstimate,
      params: {
        ...queryParams,
        f: {
          construction_id: formattedSelectedConstruction || [],
          main_code: keyScreen,
        },
      },
    }
  }, [selectedConstruction, queryParams, keyScreen])

  const getMachine = async () => {
    try {
      setIsLoading(true)
      let res: any
      switch (keyTab) {
        case 'machineInElement':
          res = await apiGetMachineElement(queryParamsGet)
          break
        case 'machineInGroup':
          res = await apiMachineGroup(queryParamsGet)
          break
        default:
          res = await apiGetMachine(queryParamsGet)
          break
      }
      const revertData = checkChildrenEnd(markLastEnd(addIndex(sortedTreeData(revertID(cloneDeep(res.data.data.data) || [])))))
      dispatch(setDataListMachine(revertData))
      dispatch(setMetaDataTable(res.data.meta))
      setMachine({ ...res.data.data, data: revertData })
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
      setCheckReloadIndividual(false)
    }
  }

  const getTotalMachine = async () => {
    const { f } = queryParamsGet.params
    try {
      setIsLoading(true)
      const { data } = await apiGetTotalMachine({
        idEstimate: params.idEstimate,
        filter: { f },
      })

      const customTotal = {
        order_accuracy: {
          index: 11,
          value: formatNumber(data.data.total_order_accuracy || 0),
        },
        used_a0_mul_rate: {
          index: 12,
          value: formatNumber(data.data.a0_mul_rate || 0),
        },
        quantity_unit_2: { index: 13, value: '' },
        a0_unit_cost: { index: 14, value: '' },
        a0_cost: {
          index: 15,
          value: formatNumber(data.data.total_a0_cost || 0),
        },
        a0_gross_profit_rate: {
          index: 16,
          value: formatDecimal(data.data.a0_gross_profit_rate || 0),
        },
        a0_module_value_unit: { index: 17, value: '' },
        a0_no_rate: {
          index: 18,
          value: formatNumber(data.data.a0_NO_rate || 0),
        },
        quantity_unit_3: { index: 19, value: '' },
        offer_unit_cost: { index: 20, value: '' },
        offer_amount: {
          index: 21,
          value: formatNumber(data.data.total_offer_amount || 0),
        },
        e_cost: {
          index: 22,
          value: formatNumber(data.data.total_e_cost || 0),
        },
        e_gross_profit_rate: {
          index: 23,
          value: formatDecimal(data.data.e_gross_profit_rate || 0),
        },
      }
      setTotalMachine(customTotal)
      setIsLoading(false)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  const handleAction = async (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParams }
    if (sorter.column)
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'asc',
          page: pagination.current,
        }
      else {
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'desc',
          page: pagination.current,
        }
      }
    queryParamsClone = {
      ...queryParamsClone,
      page: queryParams.page === pagination.current ? 1 : pagination.current,
    }
    await setQueryParams(queryParamsClone)
  }

  const checkRecordEdit = (objectKeyEdit) => {
    const [key]: any = Object.entries(objectKeyEdit)
    if (Object.keys(listDataEdit).includes(key[0])) {
      const dataClone = cloneDeep(listDataEdit)
      moveOrAddElementToEnd(dataClone[key[0]], key[1][0])
      return dataClone
    }
    return { ...listDataEdit, ...objectKeyEdit }
  }

  const checkChildrenSub = (dataReplaced) => {
    if (!dataReplaced.id) {
      const mapOnlyXX = dataReplaced.children.map((itemS) => itemS.id)
      const checkDataSubmit = dataEditSubmit.find(
        (itemSubmit) => itemSubmit.children[0].id.includes(dataReplaced.children[0].id) || mapOnlyXX.includes(itemSubmit.children[0].id)
      )
      return checkDataSubmit
    }
  }

  const checkDataSubmit = (dataReplaced) => {
    const isMachineTab = keyTab === 'machine'
    const hasMatchingId = dataEditSubmit.some((item) => item.id === dataReplaced.id)
    const hasMatchingChildId = checkChildrenSub(dataReplaced)

    if ((isMachineTab && (!dataEditSubmit.length || !hasMatchingId)) || (!isMachineTab && (!dataEditSubmit.length || !hasMatchingChildId))) {
      return [...dataEditSubmit, dataReplaced]
    } else {
      const updatedDataSubmit = dataEditSubmit.map((item) => {
        if (item.id === dataReplaced.id) {
          return dataReplaced
        }
        return item
      })

      return updatedDataSubmit
    }
  }

  const updateDataSubmit = (record: any, value, dataIndex) => {
    const itemSubmit = record.map((itemChild) => {
      if (itemChild.children.length > 0) {
        return {
          id: itemChild.id.split('-parent')[0] || itemChild.id,
          children: updateDataSubmit(itemChild.children, value, dataIndex),
        }
      }
      return {
        ...itemChild,
        ...value,
        id: itemChild.id,
        edit_key: [dataIndex],
        children: itemChild.children > 0 ? updateDataSubmit(itemChild.children, value, dataIndex) : [],
      }
    })
    return itemSubmit
  }

  const checkEditParent = (record: any, values, dataIndex) => {
    const listDataSubmit: any = cloneDeep(dataEditSubmit)
    const listDemo = values.map((value) => updateDataSubmit(record.children, value, dataIndex))
    const dataRevertSubmit = {
      id: values[0].id.split('-parent')[0].length < 3 ? null : values[0].id.replace('-parent', ''),
      children: keepLatestItemsByKey(listDemo.flat()),
    }
    if (values[0].id.split('-parent')[0].length > 3 && keyTab !== 'machine') {
      const check = findParentAll(dataList, dataRevertSubmit.id)
      const upData = checkMerge(check, { id: null, children: [dataRevertSubmit] })
      return [upData]
    }
    const mapOnlyXX = dataRevertSubmit.children.map((itemS) => (itemS.id.includes('-parent') ? itemS.id.split('-parent')[0] : itemS.id))
    if (dataEditSubmit.length > 0) {
      const checkParentRemain = findParent(listDataSubmit, mapOnlyXX)
      if (checkParentRemain) {
        const dataParentMerged = {
          id: dataRevertSubmit.id,
          children: updateChild(dataRevertSubmit, checkParentRemain).children,
        }
        return [dataParentMerged]
      } else {
        listDataSubmit.push(dataRevertSubmit)
      }
    } else {
      listDataSubmit.push(dataRevertSubmit)
    }
    return listDataSubmit
  }

  const getChangedQuantityValue = (obj: Record<string, unknown>): number | null => {
    const keys = ['quantity-1', 'quantity-2', 'quantity-3']
    let res = obj[keys[0]]
    for (const key of keys) {
      if (typeof obj[key] === 'string') {
        res = obj[key]
      }
    }

    return res ? Number(res) : null
  }

  const handleBlurInput = async (record: any, dataIndex: string[]) => {
    const row = await form.validateFields()
    const values: any = Object.values(groupedObj(row))

    values.forEach((obj: any) => {
      obj.quantity = getChangedQuantityValue(obj)
    })

    if (record.id.includes('-parent')) {
      const dataCheck = checkEditParent(record, values, dataIndex)
      await onCallApiEdit(dataCheck)
    } else {
      const listRecordEdit = values.map((value) => {
        if (value.id === record.id) {
          return {
            ...cloneDeep(record),
            ...value,
          }
        }
      })
      const recordEditing = listRecordEdit.filter((itemEdit) => itemEdit)
      const itemEdit = { ...recordEditing[0] }
      const defaultData = cloneDeep(dataList)
      const defaultRecord = itemEdit.index
        ? itemEdit.index?.split('-').length > 1
          ? defaultData.find((item) => itemEdit.index.split('-')[0] === item.index)
          : itemEdit
        : itemEdit
      const newObject = { [itemEdit.id]: [dataIndex] }
      const dataEditing = checkRecordEdit(newObject)
      const mergerRecord = { ...itemEdit, edit_key: dataEditing[itemEdit.id] }
      const dataNewSubmit = revertDataSubmit(keyTab as string, defaultRecord, mergerRecord)
      const newDataSubmit = checkDataSubmit(dataNewSubmit)
      await onCallApiEdit(newDataSubmit)
      setListDataEdit(dataEditing)
    }
  }

  const handleFinish = async (e) => {
    if (dataEditSubmit.length === 0) {
      e.preventDefault()
    } else {
      await onCallApiEdit(dataEditSubmit, 'finish')
    }
  }

  const handleCancelSubmit = () => {
    setIsLoading(true)
    setDataList(dataTable)
    setIsLoading(false)
  }

  const handleEditCell = () => {
    form.resetFields()
    form.setFieldsValue(dataList)
    setEditingKey(selectedRowKeys.length ? selectedRowKeys : [])
  }

  const replaceDataCache = (recordsEdit: any, dataSubmit: any) => {
    const newData = dataSubmit.map((itemData) => {
      const matchingItem = recordsEdit.find((item) => itemData.id && (itemData.id.includes(item.id) || itemData.id === item.id))
      if (matchingItem) {
        return mergeItemData(itemData, matchingItem)
      } else {
        const mergedItem = checkChildrenTab(recordsEdit, itemData)
        return mergedItem
      }
    })
    setDataEditSubmit(newData)
  }

  const updateDataBlur = (recordsEdit: any) => {
    const cloneDatalist = cloneDeep(dataList)
    const newData = cloneDatalist.map((itemData) => {
      if (keyTab === 'machine') {
        const matchingItem = recordsEdit.find((item) => itemData.id && (itemData.id.includes(item.id) || itemData.id === item.id))
        return matchingItem
          ? {
              ...itemData,
              a0_cost: matchingItem.a0_cost,
              a0_gross_profit_rate: matchingItem.a0_gross_profit_rate,
              e_gross_profit_rate: matchingItem.e_gross_profit_rate,
              e_cost: matchingItem.e_cost,
              name: matchingItem.name,
              offer_unit_cost: matchingItem.offer_unit_cost,
              offer_amount: matchingItem.offer_amount,
              order_accuracy: matchingItem.order_accuracy,
              quantity: matchingItem.quantity,
              capacity_value: matchingItem.capacity_value,
              module_value: matchingItem.module_value || '',
              list_price: matchingItem.list_price,
              used_a0_mul_rate: matchingItem.a0_mul_rate,
              a0_no_rate: matchingItem.a0_NO_rate,
              e_no_rate: matchingItem.e_NO_rate,
              children: itemData.children && itemData.children.length > 0 ? revertChildren(itemData.children, matchingItem) : [],
            }
          : itemData
      } else {
        return checkChildrenTab(recordsEdit, itemData)
      }
    })
    return newData
  }

  const onCallApiEdit = async (dataSubmit: any, key: string | null = null) => {
    const formatData = reFormatDataSubmit(dataSubmit, key)
    try {
      setIsLoading(true)
      const { data } = await apiUpdateMachine({
        idEstimate: params.idEstimate,
        data: formatData,
        screen: keyTab,
        filter: keyScreen,
      })
      const recordEdit = data.data
      const newData = updateDataBlur(recordEdit)
      setDataList(sortedTreeData(newData, 'index'))
      if (!key) {
        replaceDataCache(recordEdit, dataSubmit)
      } else {
        getMachine()
        setEditingKey([])
        setSelectedRowKeys([])
        setDataEditSubmit([])
        setListDataEdit([])
        setEditRows(false)
      }
    } catch (error: any) {
      if (error?.response) {
        const message = handleError(error)
        pushNotification(message, 'error')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetFieldValueForm = () => {
    form.setFieldValue
  }

  const setUIValueChange = (listKeyForm: any[], value) => {
    const mapValues = listKeyForm.map((keyInp) => [keyInp, value])
    form.setFieldsValue(fromPairs(mapValues))
  }

  const handleChangeForm = (changedValues, allValues) => {
    const changedKey = keys(changedValues)[0]
    const changedValue = changedValues[changedKey]
    if (changedKey.includes('-parent')) {
      const splitKeyChange = changedKey.split('&')
      const keyChange = keys(allValues).filter((key) => {
        const checkKey = key.split('&')[2].includes('-')
        const getCorrectKey = checkKey ? key.split('&')[2] : key.split('&')[2]
        return key.includes(splitKeyChange[0]) && getCorrectKey.includes(changedKey.split('&')[2])
      })
      setUIValueChange(keyChange, changedValue)
    }
  }

  const renderCell = Object.entries(totalMachine).map(([itemKey, itemValue]) => {
    const { index = 11, value = '' } = itemValue as { index: number; value: number | string }
    if (itemKey === 'e_cost') {
      return (
        <Table.Summary.Cell key={itemKey} index={index}>
          <Row>
            <Col span={15} className="text-right">
              {value}
            </Col>
          </Row>
        </Table.Summary.Cell>
      )
    }
    return (
      <Table.Summary.Cell key={itemKey} index={index} className="text-right w-full truncate">
        <Text>{value}</Text>
      </Table.Summary.Cell>
    )
  })

  useEffect(() => {
    const fetchData = async () => {
      await getMachine()
      await getTotalMachine()
    }
    if (machineStateLoaded) {
      fetchData()
    }
  }, [queryParams, queryParamsGet, reload, keyTab])

  useEffect(() => {
    if (data !== undefined) {
      setDataList([...data])
      form.setFieldsValue(dataList)
    } else {
      setDataList([])
    }
  }, [data])

  useEffect(() => {
    if (isEditRows && selectedRowKeys.length > 0) {
      handleEditCell()
    } else {
      handleCancelSubmit()
      setEditingKey([])
    }
  }, [isEditRows, dataList])

  return (
    <div className="tree-table-device">
      <Form form={form} onFinish={handleFinish} onValuesChange={handleChangeForm}>
        <AntdButton htmlType="submit" ref={buttonSubmitForm} className="hidden" />
        <div className={`${dataList.length ? 'pt-12' : 'pt-24'}`}>
          <TableMachine
            id={`table-device-key-screen-${keyScreen ?? '0'}`}
            columnsDefine={columns}
            data={dataList}
            total={total}
            from={from}
            to={to}
            current_page={current_page}
            per_page={per_page}
            loading={isLoading}
            editingKey={editingKey}
            rowSelection={rowSelection}
            // usingVirtualized
            handleBlurInput={handleBlurInput}
            handleAction={handleAction}
            renderCell={renderCell}
            setFieldValue={handleSetFieldValueForm}
            activeRecordId={recordData?.object_registration_id}
          />
        </div>
      </Form>
      <AntdButton ref={buttonCancel} className="hidden" onClick={handleCancelSubmit} />
      <AirCondition openDraw={openDraw} recordData={recordData} setOpenDraw={handleOpenCloseDraw} />
    </div>
  )
}

export default Individual
