import { Button as AntdButton, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BUTTON_TYPE } from '~/consts/common'
import { className as classNameEl } from '~/utils/stringUtils'

type ButtonProps = {
  type?: string | undefined
  disabled?: boolean
  offFocus?: boolean
  className?: string
  text?: string | React.ReactNode
  prefixIcon?: React.ReactNode
  suffixIcon?: React.ReactNode
  isCurrentNotImplemented?: boolean
}

const Button: React.FC<ButtonProps> = ({
  type = BUTTON_TYPE.filled,
  disabled = false,
  offFocus = false,
  className = '',
  text,
  prefixIcon,
  suffixIcon,
  isCurrentNotImplemented = false,
  ...rest
}) => {
  const { t } = useTranslation()
  const baseClasses = {
    [BUTTON_TYPE.filled]: 'bg-[#009BE0] text-white',
    [BUTTON_TYPE.danger]: 'bg-[#EF4444] text-white',
    [BUTTON_TYPE.outlined]: 'bg-white text-[#009BE0] border border-[#A3A3A3]',
    [BUTTON_TYPE.text]: 'bg-transparent text-[#009BE0]',
    ['notImplemented']: `${type === BUTTON_TYPE.text ? 'bg-transparent' : 'bg-[#E5E5E5]'} text-[#A3A3A3] !cursor-default`,
  }

  const extraClasses = useMemo(() => {
    if (isCurrentNotImplemented) return ''
    switch (type) {
      case BUTTON_TYPE.filled:
        return disabled ? 'bg-[#E5E5E5] text-[#A3A3A3]' : 'hover:bg-[#38BDF8] active:bg-[#0284C7]'
      case BUTTON_TYPE.danger:
        return disabled ? 'bg-[#E5E5E5] text-[#A3A3A3]' : 'hover:bg-[#F87171] active:bg-[#DC2626]'
      case BUTTON_TYPE.outlined:
        return disabled ? 'bg-[#E5E5E5] text-[#A3A3A3] border-none' : 'hover:bg-[#F0F9FF] active:bg-[#E0F2FE]'
      case BUTTON_TYPE.text:
        return disabled ? 'text-[#A3A3A3]' : 'hover:text-[#38BDF8] active:text-[#0284C7]'
      default:
        return ''
    }
  }, [disabled, type])

  const stateClasses = useMemo(() => {
    switch (true) {
      case disabled:
        return 'cursor-not-allowed'
      case offFocus:
        return ''
      default:
        return classNameEl(
          'active:outline-none active:ring-offset-0 active:outline-0 active:shadow-none active:ring-0',
          'focus:outline-none focus:ring-offset-0 focus:outline-0 focus:shadow-none focus:ring-0',
          'focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-[#009BE0]'
        )
    }
  }, [disabled, offFocus, isCurrentNotImplemented, type])

  const renderButton = (element: React.ReactElement): React.ReactElement => {
    if (isCurrentNotImplemented) {
      return (
        <Tooltip placement="top" title={t('common.current_not_implemented')}>
          {element}
        </Tooltip>
      )
    }
    return element
  }

  return renderButton(
    <AntdButton
      type={type === BUTTON_TYPE.text ? 'link' : 'text'}
      className={`h-9 px-4 rounded-md ${baseClasses[isCurrentNotImplemented ? 'notImplemented' : type] ?? ''} ${extraClasses} ${stateClasses} ${className}`}
      disabled={disabled}
      {...rest}
    >
      <div className="flex justify-center items-center">
        {prefixIcon && <span className={text ? `mr-1` : ''}>{prefixIcon}</span>}
        {text && <span className="text-sm font-medium">{text}</span>}
        {suffixIcon && <span className={text ? `ml-1` : ''}>{suffixIcon}</span>}
      </div>
    </AntdButton>
  )
}

export default Button
