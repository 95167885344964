import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import TableOverview from '~/components/molecules/TableOverView'
import DoughnutChart from '~/components/organisms/doughnutChart'

import { overViewDataTable, overViewTitleColor } from '~/interfaces/airCondition'
import { formatDecimal, formatNumber } from '~/utils/helper'

const dataRate = [
  18.02574057157771, 9.048349210491974, 9.052427247814173, 9.04100874331202, 9.011646874592197, 9.035299491060943, 9.808495367349602, 9.014909304449954,
  8.983916220801252, 8.978206968550175,
]
const totalCostDemo = ['空調計', '700,000,000', '367,500,000', '100', '611']
const dataAirTotal = [0, 0, 100]

const dataAir = [
  {
    a0_cost: 127500,
    total_floor_cost: 0,
    rate: 57.2197913160552,
    keyForColor: 0,
    key: 0,
  },
  {
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    keyForColor: 1,
    key: 1,
  },
  {
    a0_cost: 9800,
    total_floor_cost: 0,
    rate: 4.398070234488949,
    keyForColor: 2,
    key: 2,
  },
  {
    a0_cost: 71425,
    total_floor_cost: 0,
    rate: 32.054302703915624,
    keyForColor: 3,
    key: 3,
  },
  {
    a0_cost: 14100,
    total_floor_cost: 0,
    rate: 6.327835745540222,
    keyForColor: 4,
    key: 4,
  },
  {
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    keyForColor: 5,
    key: 5,
  },
  {
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    keyForColor: 6,
    key: 6,
  },
  {
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    keyForColor: 7,
    key: 7,
  },
  {
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    keyForColor: 8,
    key: 8,
  },
  {
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    keyForColor: 9,
    key: 9,
  },
]

const dataCostBreakdown: overViewDataTable[] = [
  {
    main_code: 10221,
    item: '外注配管工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 0,
  },
  {
    main_code: 10222,
    item: '機器搬入・組立・据付費',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 1,
  },
  {
    main_code: 10231,
    item: '穴明補修費',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 2,
  },
  {
    main_code: 10311,
    item: 'ダクト工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 3,
  },
  {
    main_code: 10321,
    item: '保温工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 4,
  },
  {
    main_code: 10331,
    item: '塗装工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 5,
  },
  {
    main_code: 10341,
    item: '電気工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 6,
  },
  {
    main_code: 10342,
    item: '計装工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 7,
  },
  {
    main_code: 10352,
    item: '建築・CR関連工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 8,
  },
  {
    main_code: 10356,
    item: '耐震・免振工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 9,
  },
  {
    main_code: 10357,
    item: '架台関連工事',
    a0_cost: 0,
    total_floor_cost: 0,
    rate: 0,
    number_of_people: 0,
    key: 10,
  },
  {
    main_code_z: 'zzz',
    item_z: 'その他',
    a0_cost_z: 52800,
    total_floor_cost_z: 0,
    rate_z: 100,
    number_of_people_z: 0,
    key: 11,
  },
]
const overView = () => {
  const { t } = useTranslation()
  const [rowHoverChart, setRowHoverChart] = useState<number>()
  const [dataChange, setDataChange] = useState<any>(null)

  const renderTitleColorNet = (index: number): { title: string; bgColor: string; borderColor: string } => {
    switch (index) {
      case 0:
        return { title: '熱源機器設備', bgColor: 'bg-[#68ADDC]', borderColor: 'border-b-[#68ADDC]' }
      case 1:
        return { title: '空調機器設備', bgColor: 'bg-[#6CD7D0]', borderColor: 'border-b-[#6CD7D0]' }
      case 2:
        return { title: 'ダクト設備', bgColor: 'bg-[#ABDA82]', borderColor: 'border-b-[#ABDA82]' }
      case 3:
        return { title: '配管設備', bgColor: 'bg-[#7BC476]', borderColor: 'border-b-[#7BC476]' }
      case 4:
        return { title: '換気設備', bgColor: 'bg-[#EA9594]', borderColor: 'border-b-[#EA9594]' }
      case 5:
        return { title: '排煙設備', bgColor: 'bg-[#EF8B42]', borderColor: 'border-b-[#EF8B42]' }
      case 6:
        return { title: '自動制御設備', bgColor: 'bg-[#FDB085]', borderColor: 'border-b-[#FDB085]' }
      case 7:
        return { title: '中央監視設備', bgColor: 'bg-[#DAC1C1]', borderColor: 'border-b-[#DAC1C1]' }
      case 8:
        return { title: 'その他設備', bgColor: 'bg-[#D2CFC2]', borderColor: 'border-b-[#D2CFC2]' }
      case 9:
        return { title: '経費項目', bgColor: 'bg-[#F7EFAE]', borderColor: 'border-b-[#F7EFAE]' }
      case 10:
        return { title: '空調計', bgColor: 'bg-[#EADC64]', borderColor: 'border-b-[#EADC64]' }
      default:
        return { title: '', bgColor: '', borderColor: '' }
    }
  }

  const dataSet = useMemo(
    () =>
      dataRate.map((item, index) => ({
        value: item,
        color: renderTitleColorNet(index).bgColor,
        name: index,
      })),
    [dataRate]
  )

  const columnAir: ColumnsType<any> = [
    {
      title: t('overview.net_item'),
      dataIndex: 'netItem',
      key: 'netItem',
      render: (text, record, index) => (
        <span className={`${index === 10 && 'text-lg font-bold '}`} key={index}>
          {renderTitleColorNet(index).title}
        </span>
      ),
    },
    {
      title: t('overview.cost'),
      dataIndex: 'a0_cost',
      key: 'a0_cost',
      render: (text, record) => {
        return formatNumber(record.a0_cost)
      },
      align: 'right',
    },
    {
      title: t('overview.y_m2'),
      dataIndex: 'total_floor_cost',
      key: 'total_floor_cost',
      render: (text, record) => {
        return formatNumber(record.total_floor_cost)
      },
      align: 'right',
    },
    {
      title: t('overview.composition_ratio'),
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => {
        return `${formatDecimal(record.rate)}%`
      },
      align: 'center',
    },
  ]
  // Construction cost breakdown
  const costBreakdown: ColumnsType<any> = [
    {
      title: 'No.',
      dataIndex: 'main_code',
      key: 'main_code',
      width: 150,
      align: 'center',
      render(_, record) {
        return record.main_code ?? record.main_code_z
      },
    },
    {
      title: t('overview.construction_item'),
      dataIndex: 'item',
      key: 'item',
      render(_, record) {
        return record.item ?? record.item_z
      },
      width: 200,
    },
    {
      title: t('overview.cost'),
      dataIndex: 'a0_cost',
      key: 'a0_cost',
      align: 'right',
      render(_, record) {
        return formatNumber(record.a0_cost ?? record.a0_cost_z)
      },
      width: 180,
    },
    {
      title: t('overview.y_m2'),
      dataIndex: 'total_floor_cost',
      key: 'total_floor_cost',
      align: 'right',
      render(_, record) {
        return formatDecimal(record.a0_cost ?? record.total_floor_cost_z)
      },
      width: 200,
    },
    {
      title: t('overview.composition_ratio'),
      dataIndex: 'rate',
      key: 'rate',
      align: 'center',
      render(_, record) {
        return `${formatDecimal(record.rate ?? record.rate_z)}%`
      },
      width: 200,
    },
    {
      title: t('overview.number_people'),
      dataIndex: 'number_of_people',
      key: 'number_of_people',
      align: 'center',
      render(_, record) {
        return `${record.number_of_people ?? record.number_of_people_z}人`
      },
      width: 200,
    },
  ]

  const renderColorTable = (keyColor): overViewTitleColor => renderTitleColorNet(keyColor)

  return (
    <div className="w-10/12 mt-12 mx-auto">
      <div className="px-9">
        <h3 className="text-xl font-bold mb-10">{t('overview.net_breakdown')}</h3>
        <Row>
          <Col span={14} className="overview-air">
            <TableOverview
              tableName={'netDataAir'}
              data={dataAir}
              columns={columnAir}
              loading={false}
              dataSummary={dataAirTotal}
              renderColorNet={renderColorTable}
              setDataChange={setDataChange}
              rowHoverChart={rowHoverChart}
              textTotal={'衛生計'}
            />
          </Col>
          <Col span={10} className="bg-white">
            {dataRate.length > 0 && <DoughnutChart dataSource={dataSet} setHoverItem={setRowHoverChart} dataSelect={dataChange} />}
          </Col>
        </Row>
      </div>
      <div className="my-10 px-9">
        <h3 className="text-xl font-bold pb-10">{t('overview.construction_cost_breakdown')}</h3>
        <div className="w-full">
          <TableOverview tableName={'costTable'} data={dataCostBreakdown} columns={costBreakdown} loading={false} dataSummary={totalCostDemo} isRenderText />
        </div>
      </div>
    </div>
  )
}

export default overView
