import { Checkbox, Col, Row } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import React from 'react'

import Graph2Icon from '~/assets/icons/Graph2Icon'

const FooterMValue = () => {
  const checkboxSuperposition = [
    {
      value: 'all',
      label: '（すべて）',
    },
    {
      value: '鉄',
      label: '鉄',
    },
    {
      value: 'アルミ',
      label: 'アルミ',
    },
    {
      value: '人件費',
      label: '人件費',
    },
    {
      value: 'xxxx',
      label: 'xxxx',
    },
    {
      value: 'yyyy',
      label: 'yyyy',
    },
  ]
  const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
    console.log(`onChangeCheckbox ${checkedValues}`)
  }
  return (
    <div className="flex flex-col w-full rounded bg-white mt-1 items-center">
      <div className="flex flex-row w-10/12 mt-3">
        <div className="flex w-3/12 text-blue-600">
          <Graph2Icon />
          <span className="ml-1">重ね合わせ</span>
        </div>
        <div className="flex w-9/12 text-blue-600">
          <Checkbox.Group className=" mt-1 mb-3" onChange={onChangeCheckbox}>
            <Row>
              {checkboxSuperposition.map((superposition, index) => (
                <Col span={8} key={index}>
                  <Checkbox key={superposition.value} value={superposition.value}>
                    {superposition.label}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
      </div>
    </div>
  )
}

export default FooterMValue
