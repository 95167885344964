import React from 'react'

interface PencilIconProps {
  width?: string
  height?: string
  color?: string
}

const PencilIcon = (props: PencilIconProps) => {
  const width = props.width ?? '32'
  const height = props.height ?? '32'
  const color = props.color ?? '#009BE0'

  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none">
      <g>
        <path
          d="M11.8333 25.5833C11.66 25.5833 11.4867 25.5166 11.36 25.39C11.22 25.25 11.1467 25.0433 11.1667 24.8433L11.4267 22.51C11.46 22.2033 11.48 22.0166 11.5467 21.8166C11.6 21.6433 11.6867 21.4766 11.7867 21.3166C11.9 21.1433 12.0333 21.01 12.2533 20.79L19.28 13.7633C19.28 13.7633 19.2867 13.7566 19.2933 13.75C19.3 13.7433 19.3 13.7433 19.3067 13.7366L21.3667 11.6766C22.3867 10.6566 24.0467 10.6566 25.0667 11.6766C26.0867 12.6966 26.0867 14.3566 25.0667 15.3766L23 17.4433L15.9533 24.4966C15.7333 24.7166 15.6 24.85 15.4267 24.9633C15.2733 25.0633 15.1067 25.1433 14.9333 25.2033C14.7333 25.27 14.5467 25.29 14.24 25.3233L11.9067 25.5833C11.9067 25.5833 11.86 25.5833 11.8333 25.5833ZM19.7667 15.1633L13.2 21.7366C13.04 21.8966 12.9467 21.99 12.9067 22.05C12.8733 22.1033 12.84 22.1633 12.8267 22.2233C12.8067 22.29 12.7933 22.41 12.7667 22.6566L12.6 24.1633L14.1067 23.9966C14.3533 23.97 14.4667 23.9566 14.54 23.9366C14.6 23.9166 14.66 23.89 14.7133 23.8566C14.7733 23.8166 14.86 23.73 15.0333 23.5633L21.58 16.9833L19.7667 15.17V15.1633ZM20.7067 14.2233L22.52 16.0366L24.12 14.4366C24.62 13.9366 24.62 13.1233 24.12 12.6233C23.62 12.1233 22.8067 12.1233 22.3 12.6233L20.7 14.2233H20.7067Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_92802">
          <rect width="16" height="16" fill="white" transform="translate(10.5 10.25)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PencilIcon
