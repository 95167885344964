import React from 'react'

const ChartIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="35" height="35" rx="5.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2357 12.7649C18.1055 12.6348 17.8945 12.6348 17.7643 12.7649C17.6341 12.8951 17.6341 13.1062 17.7643 13.2364C17.8945 13.3665 18.1055 13.3665 18.2357 13.2364C18.3659 13.1062 18.3659 12.8951 18.2357 12.7649ZM16.8215 11.8221C17.4724 11.1713 18.5276 11.1713 19.1785 11.8221C19.4816 12.1252 19.6435 12.516 19.6644 12.9128L22.1371 13.8401C22.143 13.8341 22.1489 13.8281 22.1548 13.8221C22.8057 13.1713 23.861 13.1713 24.5118 13.8221C25.1627 14.473 25.1627 15.5283 24.5118 16.1792C23.861 16.83 22.8057 16.83 22.1548 16.1792C21.8517 15.8761 21.6898 15.4853 21.669 15.0885L19.1962 14.1612C19.1904 14.1672 19.1845 14.1732 19.1785 14.1792C18.6363 14.7213 17.8135 14.8119 17.1776 14.4508L14.2889 16.6173C14.4167 17.1606 14.2688 17.7555 13.8452 18.1792C13.1943 18.83 12.139 18.83 11.4882 18.1792C10.8373 17.5283 10.8373 16.473 11.4882 15.8221C12.0303 15.28 12.8531 15.1894 13.4891 15.5505L16.3778 13.384C16.25 12.8407 16.3979 12.2458 16.8215 11.8221ZM23.569 14.7649C23.4389 14.6348 23.2278 14.6348 23.0976 14.7649C22.9675 14.8951 22.9675 15.1062 23.0976 15.2364C23.2278 15.3665 23.4389 15.3665 23.569 15.2364C23.6992 15.1062 23.6992 14.8951 23.569 14.7649ZM12.9024 16.7649C12.7722 16.6348 12.5611 16.6348 12.431 16.7649C12.3008 16.8951 12.3008 17.1062 12.431 17.2364C12.5611 17.3665 12.7722 17.3665 12.9024 17.2364C13.0325 17.1062 13.0325 16.8951 12.9024 16.7649ZM18 16.0007C18.3682 16.0007 18.6667 16.2991 18.6667 16.6673V23.334C18.6667 23.7022 18.3682 24.0007 18 24.0007C17.6318 24.0007 17.3333 23.7022 17.3333 23.334V16.6673C17.3333 16.2991 17.6318 16.0007 18 16.0007ZM23.3333 18.0007C23.7015 18.0007 24 18.2991 24 18.6673V23.334C24 23.7022 23.7015 24.0007 23.3333 24.0007C22.9651 24.0007 22.6667 23.7022 22.6667 23.334V18.6673C22.6667 18.2991 22.9651 18.0007 23.3333 18.0007ZM12.6667 20.0007C13.0349 20.0007 13.3333 20.2991 13.3333 20.6673V23.334C13.3333 23.7022 13.0349 24.0007 12.6667 24.0007C12.2985 24.0007 12 23.7022 12 23.334L12 20.6673C12 20.2991 12.2985 20.0007 12.6667 20.0007Z"
        fill="black"
      />
      <rect x="0.5" y="0.5" width="35" height="35" rx="5.5" stroke="#A3A3A3" />
    </svg>
  )
}

export default ChartIcon
