import React from 'react'

const UpDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 5" width="10" height="20">
      <path
        id="_Color"
        data-name=" ↳Color"
        d="m 7 0 l -2.932 -3 l -3.077 3 l -0.923 -1 l 4 -4 l 4 4 z m 1 2 l -4 4 l -4 -4 l 1 -1 l 3 3 l 3 -3 z"
        fill="#1a44a9"
      />
    </svg>
  )
}

export default UpDown
