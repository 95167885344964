import React from 'react'

interface AnnotationIconProps {
  isDisable?: boolean
  isError?: boolean
}

const AnnotationIcon = ({ isDisable, isError }: AnnotationIconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="annotation">
        <path
          id="Vector"
          d="M10.8 14.6662H5.19999C3.97333 14.6662 3.35999 14.6662 2.78666 14.3728C2.27999 14.1128 1.87999 13.7128 1.61999 13.2062C1.32666 12.6328 1.32666 12.0195 1.32666 10.7928V5.19951C1.32666 3.97284 1.32666 3.35951 1.61999 2.78617C1.87999 2.27951 2.27999 1.87951 2.78666 1.61951C3.35999 1.32617 3.97333 1.32617 5.19999 1.32617H10.8C12.0267 1.32617 12.64 1.32617 13.2133 1.61951C13.72 1.87951 14.12 2.27951 14.38 2.78617C14.6733 3.35951 14.6733 3.97284 14.6733 5.19951V10.7995C14.6733 12.0262 14.6733 12.6395 14.38 13.2128C14.12 13.7195 13.72 14.1195 13.2133 14.3795C12.64 14.6728 12.0267 14.6728 10.8 14.6728V14.6662ZM5.19999 2.66617C4.21999 2.66617 3.67999 2.66617 3.39333 2.81284C3.13999 2.93951 2.93999 3.14617 2.81333 3.39284C2.66666 3.67951 2.66666 4.21951 2.66666 5.19951V10.7995C2.66666 11.7795 2.66666 12.3195 2.81333 12.6062C2.93999 12.8595 3.14666 13.0595 3.39333 13.1862C3.67999 13.3328 4.21999 13.3328 5.19999 13.3328H10.8C11.78 13.3328 12.32 13.3328 12.6067 13.1862C12.86 13.0595 13.06 12.8528 13.1867 12.6062C13.3333 12.3195 13.3333 11.7795 13.3333 10.7995V5.19951C13.3333 4.21951 13.3333 3.67951 13.1867 3.39284C13.06 3.13951 12.8533 2.93951 12.6067 2.81284C12.32 2.66617 11.78 2.66617 10.8 2.66617H5.19999ZM8.00666 11.3328C7.63999 11.3328 7.33999 11.0328 7.33999 10.6662C7.33999 10.2995 7.63333 9.99951 8.00666 9.99951C8.37333 9.99951 8.67333 10.2995 8.67333 10.6662C8.67333 11.0328 8.37333 11.3328 8.00666 11.3328ZM8.00666 8.66617C7.63999 8.66617 7.33999 8.36617 7.33999 7.99951V5.33284C7.33999 4.96617 7.63999 4.66617 8.00666 4.66617C8.37333 4.66617 8.67333 4.96617 8.67333 5.33284V7.99951C8.67333 8.36617 8.37333 8.66617 8.00666 8.66617Z"
          fill={isDisable ? '#A3A3A3' : isError ? '#DC2626' : '#009BE0'}
        />
      </g>
    </svg>
  )
}

export default AnnotationIcon
