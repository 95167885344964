import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { cloneDeep } from 'lodash'
import React, { useState, useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import SegmentedButton from '~/components/atoms/SegmentedButton'
import SortIcon from '~/components/atoms/SortIconTable'
import TableResize from '~/components/molecules/TableResize'

import { CATEGORY1_CODE, CATEGORY2_CODE, DEFAULT_GROUP_CODE, INDIRECT_COST_GROUP, POSITION_SEGMENTED_BUTTON } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import { DataTypeConstruction } from '~/interfaces/construction'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { message } from '~/redux/reducers/app.slice'
import { setDataConstruction, constructions, setConstructionSelects } from '~/redux/reducers/construction.slice'
import { getKeyInArr, deepMergeTree, getSelectedHandler, removeKey, markLastEnd, addKey } from '~/utils/helper'
import '~/assets/styles/feature/construction.scss'
import { className } from '~/utils/stringUtils'

interface DrawerConstructionProps {
  selectedGroupCode?: string
  dataTable?: DataTypeConstruction[]
}

const DrawerConstruction = ({ selectedGroupCode = DEFAULT_GROUP_CODE, dataTable = [] }: DrawerConstructionProps) => {
  const { dataSelectKeys, dataTableInDrawer } = useAppSelector(constructions)
  const { isLoading } = useAppSelector(message)
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(dataSelectKeys[selectedGroupCode])
  const [radioFilter, setRadioFilter] = useState<number | string>('')
  const [dataTableView, setDataTableView] = useState<DataTypeConstruction[]>([])
  const [isLoadingChange, setIsLoadingChange] = useState<boolean>(false)

  const dataTableBySelectedGroupCode = dataTable.filter((item: any) => item.group_code === selectedGroupCode)
  const dataTableByOthersGroupCode = dataTable.filter((item: any) => item.group_code !== selectedGroupCode)

  const onSelectChange = (record: any, selected: boolean) => {
    let getKeysConstruction = {}
    let dataReverted: DataTypeConstruction[] = []
    if (!selected) {
      const dataRemoved = removeKey(dataTableBySelectedGroupCode, record.key)
      getKeysConstruction = getKeyInArr(dataRemoved)
      dataReverted = markLastEnd(dataRemoved)
    } else {
      const newTree = getSelectedHandler(dataTableInDrawer, record.index)
      const dataTreeMerge = deepMergeTree(dataTableBySelectedGroupCode, addKey(newTree, 'group_code', 'group_code', selectedGroupCode))
      getKeysConstruction = getKeyInArr(dataTreeMerge)
      dataReverted = markLastEnd(dataTreeMerge)
    }

    dispatch(setDataConstruction([...dataTableByOthersGroupCode, ...dataReverted]))
    dispatch(setConstructionSelects({ ...dataSelectKeys, [selectedGroupCode]: getKeysConstruction }))
    setSelectedRowKeys(getKeysConstruction)
  }
  const options = [
    { label: '全て', value: '' },
    { label: '空調', value: 10 },
    { label: 'ユーティリティ', value: 11 },
    { label: '衛生', value: 20 },
    { label: '電気', value: 30 },
    { label: '建築', value: 40 },
  ]

  const columns: ColumnsType<any> = [
    {
      title: ({ sortColumns }) => <SortIcon title="コード" sortColumns={sortColumns} keyName="construction_code" />,
      dataIndex: 'construction_code',
      key: 'construction_code',
      width: 150,
      align: 'left',
      defaultSortOrder: 'ascend',
      sorter: true,
      ellipsis: true,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="名称" sortColumns={sortColumns} keyName="construction_name" />,
      dataIndex: 'construction_name',
      key: 'construction_name',
      width: 180,
      defaultSortOrder: 'ascend',
      ellipsis: true,
      sorter: true,
    },

    {
      title: ({ sortColumns }) => <SortIcon title="間接費" sortColumns={sortColumns} keyName="indirect_cost_code" />,
      dataIndex: 'indirect_cost_code',
      key: 'indirect_cost_code',
      width: 150,
      defaultSortOrder: 'ascend',
      ellipsis: true,
      sorter: true,
      render: (text: number) => <span>{INDIRECT_COST_GROUP[text]}</span>,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="工事区分1" sortColumns={sortColumns} keyName="category1_code" />,
      dataIndex: 'category1_code',
      key: 'category1_code',
      width: 120,
      defaultSortOrder: 'ascend',
      ellipsis: true,
      sorter: true,
      render: (text: number) => <span>{CATEGORY1_CODE[text]}</span>,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="工事区分2" sortColumns={sortColumns} keyName="category2_code" />,
      dataIndex: 'category2_code',
      key: 'category2_code',
      // width: 120,
      defaultSortOrder: 'ascend',
      ellipsis: true,
      sorter: true,
      render: (text: number) => <span>{CATEGORY2_CODE[text]}</span>,
    },
  ]

  const handleAction = () => {
    console.log('sort')
  }
  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectChange,
    hideSelectAll: true,
    checkStrictly: true,
  }

  const getDataFilter = () => {
    setIsLoadingChange(true)
    setTimeout(() => {
      const newDataFilter = cloneDeep(dataTableInDrawer).filter((item) => {
        return radioFilter !== '' ? item.category1_code === radioFilter : item
      })
      setDataTableView(newDataFilter)
      setIsLoadingChange(false)
    }, 1200)
  }
  useEffect(() => {
    getDataFilter()
  }, [radioFilter])

  const renderFilterButton = () => {
    return options.map((opt, index) => {
      const containerClass = {
        0: 'border rounded-l-md',
        [options.length - 1]: 'border border-l-0 rounded-r-md',
      }

      return (
        <SegmentedButton
          key={opt.label}
          position={
            index === 0 ? POSITION_SEGMENTED_BUTTON.left : index === options.length - 1 ? POSITION_SEGMENTED_BUTTON.right : POSITION_SEGMENTED_BUTTON.center
          }
          text={opt.label}
          isSelected={opt.value === radioFilter}
          containerStyle={className(
            'filterButton flex w-fit items-center justify-center',
            'h-[36px] px-[14px] border-[#A3A3A3] cursor-pointer',
            'hover:bg-[#009be0] text-[#009be0] hover:text-white',
            'focus-visible:outline-none focus-visible:ring-offset-0 focus-visible:outline-0 focus-visible:shadow-none focus-visible:ring-0',
            `${containerClass[index] || 'border border-l-0'}`,
            `${opt.value === radioFilter ? 'bg-[#009be0]' : ''}`
          )}
          outlineStyle={`h-[44px]`}
          textStyle={'text-sm font-medium'}
          handleClick={() => {
            setRadioFilter(opt.value)
          }}
        />
      )
    })
  }
  return (
    <div>
      <div className="flex mb-6 mt-1.5 ml-1.5">{renderFilterButton()}</div>
      <div className="tree-table-draw ml-1.5">
        {dataTableInDrawer.length > 0 && dataTableView.length > 0 ? (
          <TableResize
            key="loading-done"
            columnDefine={columns}
            data={dataTableView}
            loading={isLoading}
            handleAction={handleAction}
            defaultExpandAllRows
            rowSelection={rowSelection}
            customMaxHeight="100%"
            handleClickRow={(record) =>
              navigate(
                generatePath(ROUTER_PATH.PROJECT.DETAIL, {
                  idProject: params.idProject,
                  idEstimate: record.id ?? params.idEstimate,
                })
              )
            }
          />
        ) : (
          <Table key="loading-not-done" columns={columns} loading={isLoadingChange || isLoading} />
        )}
      </div>
    </div>
  )
}

export default DrawerConstruction
