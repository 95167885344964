import React from 'react'
import { Navigate } from 'react-router-dom'

import { ROUTER_PATH } from '~/consts/router'
import { IS_LOGGED_IN } from '~/consts/storage'
import { getLocalStorage } from '~/utils/storage'

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthLocal = getLocalStorage(IS_LOGGED_IN) as boolean
  return isAuthLocal ? children : <Navigate to={ROUTER_PATH.AUTH.LOGIN} />
}

export default PrivateRoute
