import React from 'react'

import { useAppSelector } from '~/redux/hooks'
import { projectIn } from '~/redux/reducers/project.slice'

export interface InformationProjectProps {
  isModal?: boolean
}

const InformationProject = ({ isModal }: InformationProjectProps) => {
  const { projectInfo } = useAppSelector(projectIn)

  return (
    <table className="mb-3">
      <thead>
        <tr className="text-sm text-[#171717] font-normal leading-[22px]">
          <th className="p-0">物件番号</th>
          <th className="py-0 pl-6">プロジェクトID</th>
          <th className={`${isModal ? 'w-80' : 'w-96'} py-0 pl-6`}>プロジェクト名</th>
          <th className="py-0 pl-6">施主名</th>
        </tr>
      </thead>
      <tbody>
        <tr className={`${isModal ? 'text-lg' : 'text-[22px] leading-8'} text-[#171717] font-bold`}>
          <td className="px-0">{projectInfo.property_number}</td>
          <td className="px-6">{projectInfo.project_id}</td>
          <td className="pl-6">{projectInfo.project_name}</td>
          <td className="pl-6">{projectInfo.ower_name}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default InformationProject
