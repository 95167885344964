import { DownloadOutlined } from '@ant-design/icons'
import { Row, Col } from 'antd'
import html2pdf from 'html2pdf.js'
import React from 'react'

import Button from '~/components/atoms/Button'

import ExcelIcon from '~/assets/icons/ExcelIcon'
import TakasagoLogo from '~/assets/icons/TakasagoLogo'

const PreviewDocument = () => {
  const handleDownLoad = async () => {
    const element = document.getElementById('pdf-content')
    html2pdf()
      .from(element)
      .set({ html2canvas: { scale: 2 }, margin: [10, 15] })
      .save('demoPDF.pdf')
  }
  return (
    <div className="h-[750px] overflow-y-auto">
      <div className="w-10/12 mx-auto my-6">
        <div className="bg-white py-14 px-24">
          <div id="pdf-content">
            <div className="flex mx-auto font-medium text-[32px] items-center justify-center tracking-[24px]">御見積書</div>
            <div className="mt-10">
              <Row>
                <Col span={14}>
                  <Row className="flex justify-between border-b-2 border-b-[#a3a3a3] py-2">
                    <div className="text-[28px] text-[#171717] font-normal">〇〇建築株式会社</div>
                    <div className="text-xl text-[#737373] font-bold">御中</div>
                  </Row>
                  <Row className="flex justify-between border-b-2 border-b-[#a3a3a3] mt-1 py-3">
                    <Col span={4} className="text-base">
                      名称
                    </Col>
                    <Col span={20} className="text-2xl font-medium">
                      〇×ビル建替工事
                    </Col>
                  </Row>
                  <Row className="flex justify-between border-b border-b-[#a3a3a3] mt-1 py-6">
                    下記のとおり、お見積もり申し上げます。
                    <br />
                    何卒ご用命賜りますようお願い申し上げます。
                  </Row>
                  <Row className="flex justify-between border-b border-b-[#a3a3a3] items-center  mt-1 py-5">
                    <div className="text-base">工事金額</div>
                    <div className="text-xl text-[#171717]">¥ 802,400,000</div>
                  </Row>
                  <Row className="flex justify-between border-b border-b-[#a3a3a3] items-center  mt-1 py-5">
                    <div className="text-base">{`同上取引に関わる消費税額  (10%)`}</div>
                    <div className="text-xl text-[#171717]">¥ 80,240,000</div>
                  </Row>
                  <Row className="flex justify-between border-b border-b-[#a3a3a3] items-center  mt-1 py-5">
                    <div className="text-base">合計御見積金額</div>
                    <div className="text-xl text-[#171717]">¥ 882,640,000</div>
                  </Row>
                </Col>
                <Col span={10} className="flex justify-end pt-5">
                  <div className="w-9/12">
                    <Row className="flex justify-between border-b border-b-[#a3a3a3] items-center py-1">
                      <div className="px-3 text-sm">見積 ID</div>
                      <div className="px-3 text-base text-[#171717]">51136-04</div>
                    </Row>
                    <div className="flex justify-end py-2 text-sm text-[#171717] leading-6 tracking-wider">2023年9月7日</div>
                    <Row className="pt-12 flex justify-end ">
                      <TakasagoLogo />
                    </Row>
                    <Row className="flex justify-end py-1">
                      <div className="text-base leading-6 font-bold">東京本店</div>
                    </Row>
                    <div className="py-1 px-3">
                      <Row className="text-xs leading-6 ">〒160-0022</Row>
                      <Row className="text-sm leading-6 ">
                        東京都新宿区新宿6-27-30
                        <br />
                        新宿イーストサイドスクエア 12階
                      </Row>
                      <div className="mt-3">
                        <Row>
                          <Col className="text-xs leading-6  text-[#737373]" span={6}>
                            営業部
                          </Col>
                          <Col span={18}>
                            <Row className="space-x-4 text-[#171717]">
                              <div className="text-xs leading-6">tel</div>
                              <div className="text-sm leading-6 ">03-6369-8222</div>
                            </Row>
                            <Row className="space-x-4 text-[#171717]">
                              <div className="text-xs leading-6 ">fax</div>
                              <div className="text-sm leading-6 ">03-6369-9116</div>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-xs leading-6 text-[#737373]" span={6}>
                            営業所
                          </Col>
                          <Col className="text-sm leading-6 text-[#171717]" span={18}>
                            千葉・多摩
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-xs leading-6 text-[#737373]" span={6}>
                            本社
                          </Col>
                          <Col className="text-sm leading-6 text-[#171717]" span={18}>
                            東京都新宿区新宿6-27-30
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <table className="w-full ">
                            <thead>
                              <tr>
                                <th className="border w-16">担当</th>
                                <th className="border">&nbsp;</th>
                              </tr>
                            </thead>
                          </table>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="w-10/12 border-b pt-8">
                  <div className="flex justify-between w-5/12 border-y p-4">
                    <div>納期</div>
                    <div>御指示通り</div>
                  </div>
                  <div className="flex justify-between w-5/12 border-b p-4">
                    <div>お支払条件</div>
                    <div>従来通り</div>
                  </div>
                  <div className="flex justify-between w-5/12 border-b p-4">
                    <div>納入場所</div>
                    <div>東京都</div>
                  </div>
                  <div className="w-5/12 p-4 mb-1">本見積有効期限は、60日とさせて戴きます。</div>
                </div>
              </Row>
              <div className="pt-6">
                <Row>
                  <Col span={14} className="space-y-5 text-base font-normal text-[#171717]">
                    <div>
                      <Row className="mb-3">1. 内訳</Row>
                      <div className="w-10/12 mx-auto">
                        <Row className="py-3 border-y">
                          <Col span={12}>空気調和設備工事</Col>
                          <Col span={4} className="text-sm leading-6">
                            1式
                          </Col>
                          <Col span={8} className="flex justify-end">
                            543,600,000
                          </Col>
                        </Row>
                        <Row className="py-3 border-b">
                          <Col span={12}>給排水衛生設備工事</Col>
                          <Col span={4} className="text-sm leading-6">
                            1式
                          </Col>
                          <Col span={8} className="flex justify-end">
                            543,600,000
                          </Col>
                        </Row>
                        <Row className="flex justify-end py-3">
                          <Col span={4} className="text-sm leading-6">
                            小計
                          </Col>
                          <Col span={8} className="flex justify-end">
                            802,400,000
                          </Col>
                        </Row>
                        <Row className="flex justify-end py-1">
                          <Col span={4} className="text-sm leading-6">
                            消費税
                          </Col>
                          <Col span={8} className="flex justify-end">
                            80,240,000
                          </Col>
                        </Row>
                        <Row className="flex justify-end py-1">
                          <Col span={4} className="text-sm leading-6">
                            合計
                          </Col>
                          <Col span={8} className="flex justify-end">
                            802,400,000
                          </Col>
                        </Row>
                        <Row className="py-3 text-sm leading-6">上記の合計額には以下の法定福利費相当額を含んでいます。 </Row>
                      </div>
                    </div>
                    <div>
                      <Row className="mb-3">2. 法定福利費相当額</Row>
                      <div className="w-10/12 mx-auto text-sm leading-6">
                        （Ａ） 22,969,776 円 Ａ＝Ｕ×Ｚ <br />
                        Ｕ：労務費 \ 151,615,686 <br />
                        Ｚ：法定福利費事業主負担率 <br />
                        （合計値：151.50/1000）
                      </div>
                    </div>
                    <div>
                      <Row className="mb-3">3. 御見積条件</Row>
                      <div className="w-10/12 mx-auto mb-3">
                        <ul className="list-disc text-sm leading-6 ">
                          <li>質疑回答、追加指示反映後の見積となります。</li>
                          <li>自動制御については前回提出した見積が質疑</li>
                          <li>追加指示反映後 の見積となるため、金額の変更はありません。</li>
                          <li>追加指示図の排煙ﾀﾞｸﾄ図にﾍﾞﾝﾁｭﾘｰﾀﾞｸﾄがﾌﾟﾛｯﾄされていなかったため 想定で見込んでおります。</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full absolute bottom-0 right-0 h-16 bg-white flex justify-between px-6 py-3 z-10">
        <Button prefixIcon={<ExcelIcon />} type="outlined" />
        <div className="space-x-3">
          <Button disabled text="引合依頼" />
          <Button type="outlined" size="small" onClick={handleDownLoad} text="PDFをダウンロード" prefixIcon={<DownloadOutlined />} />
        </div>
      </div>
    </div>
  )
}

export default PreviewDocument
