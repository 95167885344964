import { Col, Radio, RadioChangeEvent, Row } from 'antd'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'

import TableConstruction from '~/components/molecules/TableConstruction'

import { CATEGORY1_CODE, CATEGORY2_CODE, CATEGORY3_CODE, CONSTRUCTION_TABS_OPTIONS, DEFAULT_GROUP_CODE, FLUID_TYPE, INDIRECT_COST_GROUP } from '~/consts/common'
import { DataTypeConstruction } from '~/interfaces/construction'
import { useAppSelector } from '~/redux/hooks'
import { constructions } from '~/redux/reducers/construction.slice'
import { generateUniqueNumberKey, groupItemsByCode, removeParentItemByGroupCode, swapParentItemsByGroupCode } from '~/utils/helper'

import '~/assets/styles/feature/construction.scss'

interface ListConstructionProps {
  isLoading: boolean
  dataTable: DataTypeConstruction[]
  selectedGroupCode?: string
  form?: any
  onOpenDrawer?: any
  reloadDataTable?: any
  setIsDisabledSubmit?: any
}

const defaultRowData = {
  key: generateUniqueNumberKey(),
  group_code: DEFAULT_GROUP_CODE,
  construction_id: '',
  construction_name: '',
  category1_code: null,
  category2_code: null,
}

const ListConstruction = ({ isLoading, dataTable, selectedGroupCode, form, onOpenDrawer, reloadDataTable, setIsDisabledSubmit }: ListConstructionProps) => {
  const { dataSelectKeys } = useAppSelector(constructions)

  const [radioFilter, setRadioFilter] = useState<number | string>('')
  const [dataTableView, setDataTableView] = useState<any[]>([])
  const [isLoadingChange, setIsLoadingChange] = useState<boolean>(false)
  const [queryParams, setQueryParams] = useState<{
    limit: number
    page: number
    order_by: string | null
    sort: string | null
  }>({
    limit: 10,
    page: 1,
    order_by: null,
    sort: null,
  })

  const columns: any[] = [
    {
      key: 'code',
      title: '項',
      className: 'w-1/4 px-2',
    },
    {
      key: 'construction_name',
      title: '名称',
      className: 'w-1/3 px-2',
    },
    {
      key: 'indirect_cost_code',
      title: '間接費',
      className: 'w-1/5 px-2',
      render: (text: number) => <span>{INDIRECT_COST_GROUP[text]}</span>,
    },
    {
      key: 'fluid_type',
      title: '流体',
      className: 'w-1/5 px-2',
      render: (text: number) => <span>{FLUID_TYPE[text]}</span>,
    },
    {
      key: 'category1_code',
      title: '工事区分1',
      className: 'w-1/5 px-2',
      render: (text: number) => <span>{CATEGORY1_CODE[text]}</span>,
    },
    {
      key: 'category2_code',
      title: '工事区分2',
      className: 'w-1/5 px-2',
      render: (text: number) => <span>{CATEGORY2_CODE[text]}</span>,
    },
    {
      key: 'category3_code',
      title: '工事区分3',
      className: 'w-1/5 px-2',
      render: (text: number) => <span>{CATEGORY3_CODE[text]}</span>,
    },
  ]

  const tableColumns: any = [
    {
      title: 'code',
      dataIndex: 'code',
      key: 'code',
      className: 'min-w-[50px]',
      width: '15%',
    },
    {
      title: 'construction_name',
      dataIndex: 'construction_name',
      key: 'construction_name',
      editable: true,
      className: 'min-w-[50px]',
      width: '22%',
    },
    {
      title: 'rowData',
      dataIndex: 'construction_id',
      key: 'construction_id',
      render: (_: string, record: any) => {
        return (
          <Row className="w-full">
            <Col span={24} className="flex items-center">
              {columns
                .filter((col: any) => !['code', 'construction_name'].includes(col.key))
                .map((col: any) => (
                  <div key={col.key} className={col.className}>
                    {col.render(record[col.key], record)}
                  </div>
                ))}
            </Col>
          </Row>
        )
      },
    },
  ]

  const handleAction = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParams }
    if (sorter.column)
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'asc',
        }
      else {
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'desc',
        }
      }
    queryParamsClone = {
      ...queryParamsClone,
      page: queryParams.page === pagination.current ? 1 : pagination.current,
    }
    setQueryParams(queryParamsClone)
  }

  const handleFilter = (e: RadioChangeEvent) => {
    setRadioFilter(e.target.value)
  }

  const getDataFilter = () => {
    setIsLoadingChange(true)
    setTimeout(() => {
      const newDataFilter = cloneDeep(dataTable).filter((item) => {
        return radioFilter !== '' ? item.category1_code === radioFilter : item
      })
      setDataTableView(newDataFilter)
      setIsLoadingChange(false)
    }, 1200)
  }

  useEffect(() => {
    getDataFilter()
  }, [radioFilter, dataTable])

  const handleReloadDataTable = async (data: any[]) => {
    await reloadDataTable(data)
  }

  const handleOpenDrawer = (record: any) => {
    if (onOpenDrawer) onOpenDrawer(record?.group_code)
  }

  const handleAddRow = async () => {
    const clonedData = cloneDeep(dataTable)
    clonedData.push({ ...defaultRowData, group_code: (Object.keys(dataSelectKeys).length + 1).toString() })
    await handleReloadDataTable(clonedData)
  }

  const handleRemoveRow = async (record: any) => {
    const newData = removeParentItemByGroupCode(dataTable, record?.group_code)
    await handleReloadDataTable(newData)
  }

  const handleReorderRow = async (record: any, order: string) => {
    const clonedData = cloneDeep(dataTable)
    const sourceGroupCode = record?.group_code
    const targetGroupCode = (order === 'up' ? Number(record?.group_code) - 1 : Number(record?.group_code) + 1)?.toString()
    await handleReloadDataTable(swapParentItemsByGroupCode(clonedData, sourceGroupCode, targetGroupCode))
  }

  const handleSetFieldValueForm = () => {
    form.setFieldValue
  }

  useEffect(() => {
    setIsDisabledSubmit(!dataTableView.length || dataTableView.filter((item: any) => item.construction_id === '').length)
  }, [dataTableView])

  return (
    <div className="px-6 py-12 w-[85%] max-h-[70%] overflow-y-auto">
      <div className="text-[22px] font-bold mb-6">工事項目</div>
      <div className="flex justify-between my-6 hidden">
        <Radio.Group
          className="leading-9"
          buttonStyle="solid"
          options={CONSTRUCTION_TABS_OPTIONS}
          defaultValue=""
          optionType="button"
          size="middle"
          onChange={handleFilter}
        />
      </div>
      <div className="tree-table">
        <TableConstruction
          headerColumns={columns}
          tableColumns={tableColumns}
          data={dataTableView.length ? groupItemsByCode(dataTableView) : [[defaultRowData]]}
          loading={isLoading || isLoadingChange}
          selectedGroupCode={selectedGroupCode}
          defaultExpandAllRows
          handleAction={handleAction}
          onOpenDrawer={handleOpenDrawer}
          onAddRow={handleAddRow}
          onDeleteRow={handleRemoveRow}
          onReorderRow={handleReorderRow}
          setFieldValue={handleSetFieldValueForm}
        />
      </div>
    </div>
  )
}

export default ListConstruction
