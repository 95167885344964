import { IFixedTableValue } from '~/interfaces/scrollbar'

export const CONTENT_TABLE_WIDTH = 4188
export const FIXED_COLUMN_WIDTH = 568
export const DEFAULT_THUMB_WIDTH = 250

export const TABLE_MACHINE_VALUE: IFixedTableValue = {
  contentWidth: 4188,
  containerWidth: 1650,
  trackWidth: 1082,
  fixedColumWidth: 568,
}
