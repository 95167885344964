import {
  // Checkbox,
  DatePicker,
  Form,
  Radio,
  Space,
} from 'antd'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'

import InputNumberSuffix from '~/components/atoms/InputNumberSuffix'

import DatePickerIcon from '~/assets/icons/DatePickerIcon'
import { DATE, YEAR } from '~/consts/common'
import { DateFormat, formatDatetime, isAfter, isBefore } from '~/utils/datetimeUtils'

interface FiltersLeftProps {
  optionFilter?: any
  setQueryFilters: (filters) => void
  onChangeDateOption: (dateOption) => void
}

const FILTER_KEY = 'from_to'

const FilterInForMachine = ({ optionFilter, setQueryFilters, onChangeDateOption }: FiltersLeftProps) => {
  const [form] = Form.useForm()

  const [isOpenDate, setIsOpenDate] = useState<boolean>(true)
  const [dateStart, setDateStart] = useState<any>(null)
  const [dateEnd, setDateEnd] = useState<any>(null)

  useEffect(() => {
    form.setFieldsValue(optionFilter)
  }, [optionFilter])

  const disabledStartDate = (current: any) => current && isAfter(current, dateEnd)
  const disabledEndDate = (current: any) => current && isBefore(current, dateStart)

  const onChangePeriod = (e: any) => {
    let queryParamsClone = {}
    if (e.target.checked && e.target.value === 1) {
      queryParamsClone = {
        ...queryParamsClone,
        start_date: formatDatetime(dayjs().subtract(1, YEAR), DateFormat.DATE),
        end_date: formatDatetime(dayjs(), DateFormat.DATE),
      }
      setIsOpenDate(true)
      setDateEnd(null)
      setDateStart(null)
    } else if (e.target.checked && e.target.value === 2) {
      queryParamsClone = {
        ...queryParamsClone,
        start_date: formatDatetime(dayjs().subtract(2, YEAR), DateFormat.DATE),
        end_date: formatDatetime(dayjs(), DateFormat.DATE),
      }
      setIsOpenDate(true)
      setDateEnd(null)
      setDateStart(null)
    } else if (e.target.checked && e.target.value === 3) {
      queryParamsClone = {
        ...queryParamsClone,
        start_date: formatDatetime(dayjs().subtract(5, YEAR), DateFormat.DATE),
        end_date: formatDatetime(dayjs(), DateFormat.DATE),
      }
      setIsOpenDate(true)
      setDateEnd(null)
      setDateStart(null)
    } else if (e.target.checked && e.target.value === 4) {
      setIsOpenDate(false)
      setDateEnd(null)
      setDateStart(null)
    } else return null
    onChangeDateOption(queryParamsClone)
  }

  const onChangeData = () => {
    if (!dateStart || !dateEnd) return
    onChangeDateOption({
      start_date: formatDatetime(dateStart, DateFormat.DATE),
      end_date: formatDatetime(dateEnd, DateFormat.DATE),
    })
  }

  const handleStartDate = (newValue) => {
    setDateStart(newValue)
  }

  const handleEndDate = (newValue) => {
    setDateEnd(newValue)
  }

  useEffect(() => {
    onChangeData()
  }, [dateStart, dateEnd])

  const handleActionChange = () => {
    const filters = { [FILTER_KEY]: form.getFieldValue(FILTER_KEY) }
    setQueryFilters(filters)
  }

  return (
    <div className="pt-4">
      <div>
        <p className="mb-3 text-[#23221E] text-base font-bold">期間</p>
        <Radio.Group onChange={(e) => onChangePeriod(e)} defaultValue={3}>
          <Space direction="vertical">
            <Radio value={1}>１年以内</Radio>
            <Radio value={2}>２年以内</Radio>
            <Radio value={3}>5年以内</Radio>
            <Radio value={4}>任意の期間</Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className="w-full mt-2 space-y-2">
        <div className="flex justify-between items-center">
          <DatePicker
            className="w-10/12 rounded border-[#A3A3A3] "
            format={DATE}
            disabled={isOpenDate}
            disabledDate={disabledStartDate}
            value={dateStart}
            onChange={handleStartDate}
            suffixIcon={<DatePickerIcon />}
          />
          <span className="text-xl">～</span>
        </div>
        <DatePicker
          className="w-10/12 rounded border-[#A3A3A3]"
          format={DATE}
          disabled={isOpenDate}
          disabledDate={disabledEndDate}
          value={dateEnd}
          onChange={handleEndDate}
          suffixIcon={<DatePickerIcon />}
        />
      </div>
      <div className={`max-h-[15rem] overflow-y-auto ${optionFilter?.[FILTER_KEY]?.length ? '' : 'hidden'}`}>
        <Form form={form} autoComplete="off" initialValues={optionFilter} onValuesChange={handleActionChange}>
          <Form.List name={FILTER_KEY}>
            {(fields) => (
              <div>
                {fields.map((field) => {
                  const name = form.getFieldValue([FILTER_KEY, field.name, 'name'])
                  const unit = form.getFieldValue([FILTER_KEY, field.name, 'unit'])
                  const min = form.getFieldValue([FILTER_KEY, field.name, 'min'])
                  const max = form.getFieldValue([FILTER_KEY, field.name, 'max'])

                  return (
                    <div key={field.key} className="my-3">
                      <p className="text-base text-[#23221E] font-bold mb-4">{name}</p>
                      <div>
                        <div className="flex items-center justify-between mb-[6px]">
                          <Form.Item className="mb-0 w-10/12" name={[field.name, 'min']}>
                            <InputNumberSuffix suffixText={unit} max={max} />
                          </Form.Item>
                          <span className="text-xl">～</span>
                        </div>
                        <Form.Item className="mb-0  w-10/12" name={[field.name, 'max']}>
                          <InputNumberSuffix suffixText={unit} min={min} />
                        </Form.Item>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    </div>
  )
}

export default React.memo(FilterInForMachine)
