import { MinusOutlined, RightOutlined } from '@ant-design/icons'
import { Col, Form, Input, Modal, Radio, Row, Select, Spin } from 'antd'
import { debounce, isArray, isEqual, uniq } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'
import { useParams } from 'react-router-dom'

import ISelectDropDown from '~/components/atoms/addMachine/ISelectDropDown'
import Button from '~/components/atoms/Button'
import InputNumberSuffix from '~/components/atoms/InputNumberSuffix'

import { apiAddDevice, apiAddMachine, apiProjectElement, validateDrawingNumber } from '~/api/project'
import ArrowDownIcon from '~/assets/icons/ArrowDownIcon'
import CusPlusIcon from '~/assets/icons/CusPlusIcon'
import { useAppSelector } from '~/redux/hooks'
import { machines } from '~/redux/reducers/machine.slice'
import { verifyArray } from '~/utils/arrayUtils'
import { checkValidFormValues, filterObject, generateUniqueKey, handleError, pushNotification } from '~/utils/helper'

import '~/assets/styles/feature/addMachine.scss'

interface ModalProps {
  keyAdd: any
  isStep: any
  showStep: any
  reloadListMachine: () => void
  queryFilter?: any
}

const initialState = [
  {
    construction_name: '',
    contents: [],
    main_code_a: 0,
  },
]
const initialValueState = {
  code: '',
  mainCodeA: '',
  sectionNumber: '',
  users: [],
}

const deviceState = {
  construction_name: [],
  object_id: '',
  options: [],
  settingPlace: [],
  specs: [],
}

const integerFormat = 0
const floatFormat = 0.0

const addMachine = ({ keyAdd, isStep, showStep, reloadListMachine }: ModalProps) => {
  const { t } = useTranslation()
  const [element, setElement] = useState<any>(initialState)
  const [device, setDevice] = useState<any>(deviceState)
  const [spec, setSpec] = useState<any>([])

  const [mainCodeA, setMainCodeA] = useState<null | string>(keyAdd.mainCodeA || '10')
  const [sectionNumber, setSectionNumber] = useState<null | string>(keyAdd.sectionNumber)
  const [mainCodeB, setMainCodeB] = useState<null | string>(null)
  const [subCode, setSubCode] = useState<null | string>(null)
  const [isCode, setIsCode] = useState<boolean>(false)
  const [queryParams, setQueryParams] = useState<any>(null)
  const [isLoadingSpec, setIsLoadingSpec] = useState<boolean>(false)
  const [cloneItem, setCloneItem] = useState<any[]>([])
  const [valueOption, setValueOption] = useState<any[]>([])
  const [isDisableBTN, setIsDisableBTN] = useState<boolean>(false)
  const [oldValues, setOldValues] = useState<any[]>([])
  const [unitItem, setUnitItem] = useState<string>('')
  const [isValidating, setIsValidating] = useState<boolean>(false)

  const [form] = Form.useForm()
  const { selectedConstruction } = useAppSelector(machines)
  const params = useParams()
  const handleChangeMainCodeA = (e: any) => {
    setIsCode(false)
    setUnitItem('')
    setMainCodeA(e.target.value)
    if (sectionNumber !== '' || sectionNumber !== null) {
      form.setFieldsValue({
        mainCodeB: '',
        subCode: '',
        code: `${e.target.value}${sectionNumber}----`,
      })
    }
    form.setFieldsValue({
      mainCodeB: '',
      subCode: '',
      code: `${e.target.value}${sectionNumber}-----`,
    })
    setMainCodeB(null)
  }

  const handleChangeSectionNumber = (e: any) => {
    setIsCode(false)
    setUnitItem('')
    setSectionNumber(e.target.value)
    form.setFieldsValue({
      mainCodeB: null,
      subCode: null,
    })
    setMainCodeB(null)
    form.setFieldsValue({
      code: `${mainCodeA}${e.target.value}----`,
    })
  }

  const handleChangeMainCodeB = (value: any) => {
    setMainCodeB(value)
    setSubCode(null)
    setIsCode(false)
    form.setFieldsValue({
      mainCodeB: value,
      subCode: null,
      code: `${mainCodeA}${value}--`,
      equipment: null,
      settingPlace: null,
    })
  }

  const handleChangeSubCode = (value, { unit }: any) => {
    setSubCode(value)
    setUnitItem(unit || '')
    setIsCode(true)
    setQueryParams(null)
    form.setFieldsValue({
      subCode: value,
      code: `${mainCodeA}${mainCodeB}${value}`,
    })
  }

  const handleMatchingFormValues = () => {
    const getValueCode = form.getFieldValue('code')

    if (getValueCode.length === 7) {
      setIsCode(true)
      let mainCodeA = getValueCode.slice(0, 2)
      if (
        !checkValidFormValues(
          mainCodeA,
          mainCodeAOption.map((item: any) => item.value)
        )
      ) {
        mainCodeA = ''
      }

      let sectionNumber = getValueCode.slice(2, 3)
      if (
        !checkValidFormValues(
          sectionNumber,
          sectionNumberOption.map((item) => item.value)
        )
      ) {
        sectionNumber = ''
      }

      let mainCodeB = getValueCode.slice(2, 5)
      setTimeout(() => {
        if (
          !checkValidFormValues(
            mainCodeB,
            mainCodeBOption.map((item) => item.value)
          )
        ) {
          mainCodeB = null
        }
      }, 0)
      setMainCodeB(mainCodeB)

      let subCode = getValueCode.slice(5, 7)
      setTimeout(() => {
        if (
          !checkValidFormValues(
            subCode,
            subCodeOption.map((item) => item.value)
          )
        ) {
          subCode = null
        }
        setSubCode(subCode)
      }, 0)

      setMainCodeA(mainCodeA)
      setSectionNumber(sectionNumber)
      setMainCodeB(mainCodeB)
      setSubCode(subCode)

      form.setFieldsValue({
        mainCodeA,
        sectionNumber,
        mainCodeB,
        subCode,
      })

      form.validateFields(['mainCodeA', 'sectionNumber', 'mainCodeB', 'subCode'])
    }

    if (getValueCode.length === 0) {
      form.setFieldsValue({
        mainCodeB: null,
        subCode: null,
        code: '',
        mainCodeA: null,
        sectionNumber: '',
      })
      setMainCodeA(null)
      setSectionNumber('')
      setMainCodeB(null)
      setSubCode(null)
    }
    return
  }

  const dataValid = useMemo(
    () =>
      element
        .filter((item) => `${item.main_code_a}` === mainCodeA)
        .flatMap((item2: any) => item2.contents)
        .filter((item3) => `${item3.section_number}` === sectionNumber)
        .flatMap((item4) => item4.contents),
    [mainCodeA, sectionNumber, element]
  )

  const mainCodeBOption = useMemo(
    () =>
      dataValid.map((item) => ({
        value: `${item.main_code_b}`,
        label: `${item.main_code_b} ${item.element_section}`,
        section: `${item.element_section}`,
      })),
    [dataValid, mainCodeA, sectionNumber]
  )

  const subCodeOption = useMemo(
    () =>
      dataValid
        .filter((item) => `${item.main_code_b}` === mainCodeB)
        .flatMap((item) =>
          item.contents.map((item2) => ({
            value: `${item2.sub_code}`,
            label: `${item2.sub_code} ${item2.element_name}`,
            section: `${item2.element_name}`,
            unit: item2.unit,
          }))
        ),
    [dataValid, mainCodeA, sectionNumber, mainCodeB]
  )

  const mainCodeAOption = [
    {
      label: '空调',
      value: '10',
    },
    {
      label: 'ユーティリティ',
      value: '11',
    },
    {
      label: '衛生',
      value: '20',
    },
    {
      label: '経費',
      value: '99',
    },
  ]

  const sectionNumberOption = [
    {
      label: '機器・材料費',
      value: '1',
    },
    {
      label: '労務費',
      value: '2',
    },
    {
      label: '外注費',
      value: '3',
    },
  ]

  const locationOption = useMemo(() => {
    if (device) {
      return device.settingPlace
        ? device.settingPlace.map((item) => ({
            label: item,
            value: item,
          }))
        : []
    } else {
      return deviceState
    }
  }, [device])

  const constructorNameOption = useMemo(() => {
    const dataFilter =
      selectedConstruction && device?.construction_name
        ? device?.construction_name?.filter((item) => selectedConstruction?.some((str: string) => str.includes(item.uuid)))
        : []
    return dataFilter.length > 0
      ? dataFilter?.map((construction) => ({ label: construction.name, value: construction.uuid }))
      : device?.construction_name &&
          device?.construction_name?.map((construction) => ({
            label: construction.name,
            value: construction.uuid,
          }))
  }, [selectedConstruction, device?.construction_name])

  const optionDefault = useMemo(
    () =>
      device?.options
        ? device.options
            ?.map((item) => ({
              label: item?.option_name,
              value: item?.uuid,
              key: item?.uuid,
            }))
            .sort((a, b) => a?.label - b?.label)
        : [],
    [device, form.getFieldValue('users')]
  )

  const setValueItem = (value, option) => {
    setCloneItem(
      optionDefault?.filter(
        (item) =>
          !form
            .getFieldValue('users')
            .map((item) => item.value)
            .includes(item.value)
      )
    )
    setValueOption(option)
  }

  const queryParamsGet: { project: any; params: any } = useMemo(() => {
    const mapEstimateConstructionIds = uniq(
      selectedConstruction.map((item: any) => {
        // fn: addKeyValueSelectTree - conventional item format: `${item.construction_id}_${item.item_number}_${item.estimate_construction_id}`
        const [, , estimate_construction_id] = item.split('_')
        return estimate_construction_id || item
      })
    )

    return {
      params: {
        ...queryParams,
        project_id: params.idProject,
        estimate_construction_ids: mapEstimateConstructionIds,
      },
    }
  }, [selectedConstruction, queryParams])

  const getDeviceSpec = async () => {
    setIsLoadingSpec(true)
    try {
      const res = await apiAddDevice({
        mainCode: `${mainCodeA}${mainCodeB}`,
        subCode: `${subCode}`,
        ...queryParamsGet,
      })

      if (
        isEqual(res.data.data?.specs, device?.specs) ||
        device?.specs?.length === 0 ||
        (res.data.data?.object_id && device.object_id !== res.data.data?.object_id)
      ) {
        setDevice(res.data.data)
      }

      setSpec(res.data.data?.specs || [])
      setIsLoadingSpec(false)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoadingSpec(false)
    }
  }

  const getElement = async () => {
    try {
      const res = await apiProjectElement()
      setElement(res.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }
  const handleSpecs = (values: any) => {
    const specObject = Object.fromEntries(Object.entries(values).filter(([key]) => key.includes('spec-')))
    const specValue = Object.fromEntries(Object.entries(specObject).filter(([, value]) => value !== undefined))
    return Object.keys(specValue)?.map((key) => {
      const specId = key.split('-').pop()
      const array = key.split('-')
      const specModule = JSON.parse(array[array.length - 2])
      return { id: specId, value: specValue[key], isModule: specModule }
    })
  }

  const handleOk = async (values: any) => {
    const spec = handleSpecs(values)
    const submitData = {
      object_id: device.object_id,
      quantity: values.equipment,
      settingPlace: values.settingPlace,
      construction_id: values.constructionName.value,
      drawing_number: values.equipmentNumber,
      name: values.equipmentName,
      specs: spec,
      options: values.users.map((item) => item.value),
      list_price: values.listPrice,
      mul_rate: values.mulRate,
    }
    onCallApiAdd(submitData)
    form.resetFields()
    showStep(false)
    reloadListMachine()
  }
  const handleAddToInput = async () => {
    form.validateFields().then(() => {
      const getValueInform = form.getFieldsValue()
      const spec = handleSpecs(getValueInform)
      const submitData = {
        object_id: device.object_id,
        quantity: getValueInform.equipment,
        settingPlace: getValueInform.settingPlace,
        construction_id: getValueInform.constructionName.value,
        drawing_number: getValueInform.equipmentNumber,
        name: getValueInform.equipmentName,
        specs: spec,
        options: getValueInform.users.map((item) => item.value),
        list_price: getValueInform.listPrice,
        mul_rate: getValueInform.mulRate,
      }
      onCallApiAdd(submitData)
      setIsDisableBTN(true)
    })
  }

  const onCallApiAdd = async (dataSubmit: any) => {
    try {
      await apiAddMachine({
        estimateId: params.idEstimate,
        body: dataSubmit,
      })
      pushNotification('機器の追加が成功', 'success')
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }
  const cancelStep = () => {
    showStep(false)
    reloadListMachine()
  }

  const removeData = () => {
    showStep(false)
    setIsCode(false)
    form.resetFields()
    reloadListMachine()
  }

  useEffect(() => {
    if (queryParams !== null) {
      getDeviceSpec()
    }
  }, [queryParams])

  useEffect(() => {
    if (isStep) {
      getElement()
    }
  }, [isStep])

  useEffect(() => {
    if (isCode && subCode !== '--' && subCode !== null) {
      getDeviceSpec()
      form.resetFields(['users'])
      setValueOption([])
    }
  }, [subCode])

  useEffect(() => {
    const values = {
      ...initialValueState,
      code: `${keyAdd.mainCodeA || mainCodeA}${keyAdd.sectionNumber || sectionNumber}----`,
      mainCodeA: keyAdd.mainCodeA || mainCodeA,
      sectionNumber: keyAdd.sectionNumber || sectionNumber,
    }
    form.setFieldsValue(values)
  }, [keyAdd])

  const renderModelOrSpecification = (child: any, indexSpec: number, itemValueLength = 0) => {
    const { get_specs, id, is_module, label, unit, value } = child

    const renderX = () => (
      <div className={`${indexSpec % 2 !== 0 || indexSpec === 2 ? 'w-3/12' : 'w-0/12'} flex justify-center`}>
        {(indexSpec % 2 !== 0 || indexSpec === 2) && <span className="text-xs text-[#737373] mb-2">X</span>}
      </div>
    )

    const renderLabel = () => (
      <div className="w-full">
        {label && <span className="text-[#171717]">{label}&nbsp;</span>}
        {label && get_specs && value?.toString()?.replace(/'/g, '"').length > 1 && (
          <span className="text-[#737373] text-sm"> - 選択すると追加項目が表示されます</span>
        )}
      </div>
    )

    const renderInputString = () => (
      <div className="w-full">
        <Form.Item name={generateUniqueKey(`spec-text-${is_module}`, id)} className="rounded text-sm mb-0" initialValue={value ?? ''}>
          <Input size="large" className="rounded" placeholder="テキスト" />
        </Form.Item>
        <div className="mt-1 h-5" />
      </div>
    )

    const renderInputNumber = () => (
      <div className="w-full">
        <Form.Item name={generateUniqueKey(`spec-number-${is_module}`, id)} className="rounded text-sm mb-0">
          <InputNumberSuffix
            placeholder="数量"
            suffixText={unit}
            step={value !== integerFormat ? '0.1' : '1'}
            stringMode={value !== integerFormat}
            classes={is_module ? 'border-[#059669] border-2' : ''}
            min={0}
          />
        </Form.Item>
        <div className="mt-1 h-5 text-xs text-[#059669] font-normal leading-5">{is_module && '能力値'}</div>
      </div>
    )

    const renderSelectDropdown = () => (
      <div className="w-full">
        <Form.Item name={generateUniqueKey(`spec-select-spec_${get_specs}-${is_module}`, id)} className=" mb-0">
          <ISelectDropDown
            isLoadingSpec={isLoadingSpec}
            options={value.map((item: any) => ({
              label: item,
              value: item,
              id: id,
              suffix: unit,
            }))}
          />
        </Form.Item>
        <div className="mt-1 h-5" />
      </div>
    )

    return (
      <Col className=" flex items-end" key={generateUniqueKey('spec-child', indexSpec)} span={itemValueLength === 1 ? 24 : 8}>
        <div className="flex w-full items-center">
          {itemValueLength !== 1 && renderX()}
          <div className="w-full">
            {renderLabel()}
            {isArray(value) ? renderSelectDropdown() : [integerFormat, floatFormat].includes(value) ? renderInputNumber() : renderInputString()}
          </div>
        </div>
      </Col>
    )
  }
  const onFieldsChange = (e) => {
    if (e[0]?.name.length !== 0) {
      setIsDisableBTN(false)
    }
  }
  const getSpecs = (listSpec) => {
    setIsLoadingSpec(true)
    const mapContents = listSpec.map((item) => {
      const convertID = Object.keys(item)[0].split('-')
      const getID = convertID[convertID.length - 1]
      const content = Object.values(item)[0]
      return { id: getID, content }
    })
    let queryParamClone = { ...queryParams }
    queryParamClone = {
      ...queryParamClone,
      specs: true,
      contents: mapContents,
    }
    setQueryParams(queryParamClone)
  }

  const onValuesChange = () => {
    const valuesForm = form.getFieldsValue()
    const listValue: any = []
    for (const [key, value] of Object.entries(valuesForm)) {
      const item = JSON.parse(`{"${key}": "${value}"}`)
      listValue.push(item)
    }
    const fieldGetSpecs = listValue.filter((item) => Object.keys(item)[0].includes('spec-select-spec_true') && Object.values(item)[0] !== 'undefined')
    if (fieldGetSpecs.length > 0 && !isEqual(oldValues, fieldGetSpecs)) {
      getSpecs(fieldGetSpecs)
    }
    setOldValues(fieldGetSpecs)
  }

  const validateEquipmentNumber = useCallback(
    debounce(async (e) => {
      try {
        if (!e.target.value) {
          form.setFields([
            {
              name: 'equipmentNumber',
              errors: [],
            },
          ])
          return
        }
        setIsValidating(true)
        const res = await validateDrawingNumber({
          estimateId: params.idEstimate,
          params: { drawing_number: e.target.value },
        })

        const { data: { data: { isUnique = false, message = 'Error' } = {} } = {} } = res

        form.setFields([
          {
            name: 'equipmentNumber',
            errors: isUnique ? [] : [message],
          },
        ])

        // Trigger validation
        form.validateFields(['equipmentNumber'])
      } catch (error) {
        const message = handleError(error)
        pushNotification(message, 'error')
      } finally {
        setIsValidating(false)
      }
    }, 1000),
    []
  )

  return (
    <Modal
      open={isStep}
      okText={t('modal.ok_text')}
      cancelText={t('modal.cancel_text')}
      centered={true}
      mask={true}
      width="53.75rem"
      onCancel={cancelStep}
      focusTriggerAfterClose={false}
      footer={null}
      closable={false}
    >
      <div className="add-machine">
        <div className="mb-4">
          <span className="text-[#23221E] text-[22px] font-bold">項目追加</span>
        </div>
        <Row className="items-center mb-4">
          <Col span={4}>
            <span className="text-[#23221E] text-lg font-bold">形式/仕様</span>
          </Col>
          <Col span={20}>
            <span className="text-[#171717] text-sm">要素番号を直接入力、または下から選んでください。</span>
          </Col>
        </Row>
        <Form
          form={form}
          autoComplete="off"
          initialValues={{
            code: `${mainCodeA}${sectionNumber}----`,
            mainCodeA: mainCodeA,
            sectionNumber: sectionNumber,
            users: [],
          }}
          onFinish={handleOk}
          className="pb-5"
          name="deviceAdd"
          onFieldsChange={onFieldsChange}
          onValuesChange={onValuesChange}
        >
          <Row className="mb-4">
            <Col span={4} className="space-y-1">
              <div className="text-[#171717] text-sm font-normal">要素番号入力</div>
              <div className="text-xs text-red-600 font-medium">必須</div>
            </Col>
            <Col span={20}>
              <div>
                <Form.Item
                  name="code"
                  rules={[
                    { max: 7 },
                    {
                      validator: () => {
                        setIsCode(form.getFieldValue('code').length === 7)
                        return Promise.resolve()
                      },
                    },
                  ]}
                  className="container-code m-0"
                >
                  <PatternFormat
                    className="w-full code-valid"
                    valueIsNumericString
                    format="#######"
                    mask="-"
                    allowEmptyFormatting={true}
                    onChange={handleMatchingFormValues}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className="mb-4 bg-[#F5F5F5] p-8 rounded-lg">
            <div className="polygon"></div>
            <Row className="text-[#737373] mb-4">メイン要素・要素名称・サブ要素を選択すると要素番号に反映されます。</Row>
            <Row className="mb-4">
              <Col span={4}>
                <div className=" text-[#171717] text-sm font-normal">メイン要素</div>
                <div className="text-xs text-red-600 font-medium">必須</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  className="m-0"
                  name="mainCodeA"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!mainCodeAOption.map((item) => item.value).includes(value)) {
                          return Promise.reject('有効なコードを入力してください')
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Radio.Group onChange={handleChangeMainCodeA} value={mainCodeA} className="w-full mt-1">
                    <Row className="space-x-14">
                      {mainCodeAOption.map((item, index) => (
                        <Radio key={index} value={item.value} className="text-sm">
                          {item.label}
                        </Radio>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col span={4}>
                <div className="text-[#171717] text-sm font-normal">メイン要素</div>
                <div className="text-xs text-red-600 font-medium">必須</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  className="m-0"
                  name="sectionNumber"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!sectionNumberOption.map((item) => item.value).includes(value)) {
                          return Promise.reject('有効なコードを入力してください')
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Radio.Group className="block mt-1" onChange={handleChangeSectionNumber} value={sectionNumber}>
                    <Row className="space-x-14">
                      {sectionNumberOption.map((item, index) => (
                        <Radio key={index} value={item.value} disabled={!mainCodeA}>
                          {item.label}
                        </Radio>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col span={4}>
                <div className="text-[#171717] text-sm font-normal">要素名称</div>
                <div className="text-xs text-red-600 font-medium">必須</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  className="m-0"
                  name="mainCodeB"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!mainCodeBOption.map((item) => item.value).includes(value)) {
                          return Promise.reject('有効なコードを入力してください')
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Select
                    className="w-full"
                    size="large"
                    placeholder="-　　選択してください"
                    onChange={handleChangeMainCodeB}
                    value={mainCodeB}
                    disabled={!sectionNumber || !mainCodeA}
                    suffixIcon={<ArrowDownIcon />}
                  >
                    {verifyArray(mainCodeBOption).map((option) => (
                      <Select.Option key={option.value}>
                        <Row className="space-x-1">
                          <Col span={2}>{option.value}</Col>
                          <Col span={20}>{option.section}</Col>
                        </Row>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <div className="text-[#171717] text-sm font-normal">サブ要素</div>
                <div className="text-xs text-red-600 font-medium">必須</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  className="m-0"
                  name="subCode"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!subCodeOption.map((item) => item.value).includes(value)) {
                          return Promise.reject('有効なコードを入力してください')
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Select
                    className="w-full"
                    size="large"
                    onChange={handleChangeSubCode}
                    value={subCode}
                    disabled={
                      !mainCodeB ||
                      !sectionNumber ||
                      !mainCodeA ||
                      !checkValidFormValues(
                        mainCodeB,
                        mainCodeBOption.map((item) => item.value)
                      )
                    }
                    placeholder="-　　選択してください"
                    suffixIcon={<ArrowDownIcon />}
                  >
                    {verifyArray(subCodeOption).map((option) => (
                      <Select.Option key={option.value} value={option.value} unit={option.unit}>
                        <Row className="space-x-1">
                          <Col span={2}>{option.value}</Col>
                          <Col span={20}>{option.section}</Col>
                        </Row>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row className="mb-4">
            <Col span={4}>
              <div className="text-[#171717] text-sm font-normal">追加する数量</div>
              <div className="text-xs text-red-600 font-medium">必須</div>
            </Col>
            <Col span={20}>
              <div className="w-full flex">
                <Form.Item className="w-full m-0" name="equipment" rules={[{ required: true, message: '数量を入力してください。' }]}>
                  <InputNumberSuffix suffixText={unitItem} placeholder="---" min={0} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col span={4} className="self-center">
              <div className="text-[#171717] text-sm font-normal">定価単価</div>
            </Col>
            <Col span={20}>
              <div className="w-full flex">
                <Form.Item className="w-full m-0" name="listPrice">
                  <InputNumberSuffix suffixText="円" placeholder="---" min={0} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col span={4} className="self-center">
              <div className="text-[#171717] text-sm font-normal">掛率</div>
            </Col>
            <Col span={20}>
              <div className="w-full flex">
                <Form.Item className="w-full m-0" name="mulRate">
                  <InputNumberSuffix suffixText="" placeholder="---" min={0} step={0.1} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col span={4} className="self-center">
              <span className="text-[#171717] text-sm font-normal">設置場所</span>
            </Col>
            <Col span={20}>
              <Form.Item className="w-full m-0" name="settingPlace">
                <Select
                  className="w-full"
                  size="large"
                  disabled={!isCode}
                  options={verifyArray(locationOption)}
                  placeholder="- 選択してください"
                  suffixIcon={<ArrowDownIcon />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mb-8">
            <Col span={4} className="self-center">
              <div className="text-[#171717] text-sm font-normal">工事項目</div>
              <div className="text-xs text-red-600 font-medium">必須</div>
            </Col>
            <Col span={20}>
              <Form.Item className="w-full m-0" name="constructionName" rules={[{ required: true, message: '工事項目を入力してください。' }]}>
                <Select
                  labelInValue
                  size="large"
                  options={verifyArray(constructorNameOption)}
                  disabled={!isCode}
                  placeholder="- 選択してください"
                  suffixIcon={<ArrowDownIcon />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Spin spinning={isLoadingSpec}>
            <div className="mb-8">
              <div className="text-lg text-[#23221E] font-bold">形式/仕様</div>
              <>
                {isCode ? (
                  <div className="code mt-6">
                    <Row className="code mb-6">
                      <Col span={4}>
                        <div className="text-[#171717] text-sm font-normal">機器番号</div>
                        <div className="text-xs text-red-600 font-medium">必須</div>
                      </Col>
                      <Col span={20}>
                        <Form.Item className="w-full mb-0" name="equipmentNumber">
                          <Input className="w-full h-10 rounded" placeholder="テキスト" onChange={validateEquipmentNumber} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className="code mb-6">
                      <Col span={4} className="flex self-center">
                        <div className="text-[#171717] text-sm font-normal">図面名称</div>
                      </Col>
                      <Col span={20}>
                        <Form.Item className="w-full mb-0" name="equipmentName">
                          <Input className="w-full h-10 rounded" placeholder="テキスト" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="add-machine">
                      {spec.length !== 0 &&
                        spec?.map((item, index) => {
                          const checkStyleLabel = item.value.some((subItem) => subItem.label)
                          const renderStyle = `flex ${!checkStyleLabel && 'self-center'} `
                          return (
                            <Row key={generateUniqueKey('spec', index)} className="my-1">
                              <Col span={4} className={renderStyle}>
                                <div className="text-[#171717] text-sm font-normal mb-2.5">{item.label}</div>
                              </Col>
                              <Col span={20}>
                                <Row>{item.value.map((child, indexSpec) => renderModelOrSpecification(child, indexSpec, item?.value?.length))}</Row>
                              </Col>
                            </Row>
                          )
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="flex  justify-center pt-[84px] px-[38px]">
                    <div className="text-[#A3A3A3] font-normal">「サブ要素」まで選ぶと形式/仕様の入力欄が表示されます。</div>
                  </div>
                )}
              </>
            </div>
          </Spin>
          <Spin spinning={isLoadingSpec}>
            <div className="text-lg text-[#23221E] font-bold">オプション</div>
            <div>
              {isCode ? (
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Row key={field.key} className="mt-4">
                            <Col span={4} className="flex self-center">
                              <span className="text-[#171717] text-sm font-normal">オプション{index + 1}</span>
                            </Col>
                            <Col span={20} className="flex self-center ">
                              <div className="w-full flex">
                                <Form.Item {...field} className="w-11/12 mb-0" label="" required={false}>
                                  <Select
                                    labelInValue
                                    value={valueOption}
                                    size="large"
                                    onChange={setValueItem}
                                    options={verifyArray(index === 0 ? optionDefault : cloneItem)}
                                    suffixIcon={<ArrowDownIcon />}
                                  />
                                </Form.Item>
                                <Button
                                  type="text"
                                  offFocus
                                  className="ml-2"
                                  size="large"
                                  prefixIcon={<MinusOutlined className="text-black" />}
                                  onClick={() => {
                                    const demo = form.getFieldValue('users')[field.name]
                                    const optionClone = [filterObject(demo), ...cloneItem]
                                    optionClone.sort((a, b) => a.label - b.label)
                                    remove(field.name)
                                    setCloneItem(optionClone)
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        )
                      })}
                      <Form.Item className="mt-4 mb-0">
                        <Button
                          type="outlined"
                          disabled={fields.length === device?.options?.length}
                          onClick={() => add()}
                          size="middle"
                          block
                          className="flex justify-center items-center text-[#009BE0] border-[#009BE0]"
                          text="オプションを追加する"
                          prefixIcon={<CusPlusIcon />}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                <div className="flex justify-center pt-[84px] px-[38px]">
                  <span className="text-[#A3A3A3] font-normal">「サブ要素」まで選ぶとオプションの入力欄が表示されます。</span>
                </div>
              )}
            </div>
          </Spin>
          <Row className="flex justify-end mt-[72px]">
            <Button type="outlined" onClick={removeData} text={t('modal.cancel_text')} />
            <Form.Item className="flex mb-0 ml-3 items-center">
              <Button
                htmlType="submit"
                className="w-36"
                disabled={!((isCode && isValidating) || !(form.getFieldsError().filter(({ errors }) => errors.length).length > 0)) || isDisableBTN}
                text={t('modal.confirm')}
              />
              <Button
                className="ml-3"
                onClick={handleAddToInput}
                size="middle"
                disabled={!((isCode && isValidating) || !(form.getFieldsError().filter(({ errors }) => errors.length).length > 0)) || isDisableBTN}
                text={t('modal.ok_to_input')}
                suffixIcon={<RightOutlined />}
              />
            </Form.Item>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}

export default addMachine
