import client from '~/api/axiosClient'

// Get machine
export const apiGetMachine = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machine`, {
    params: request.params,
  })
export const apiDeleteMachine = (request: any) =>
  client.delete(`/projects/${request.idEstimate}/machine`, {
    params: request.filter && { f: { main_code: request.filter } },
    data: { data: request.data, screen: request.screen },
  })

export const apiUpdateMachine = (request: any) =>
  client.put(
    `/projects/${request.idEstimate}/machine`,
    {
      data: request.data,
      screen: request.screen,
    },
    { params: request.filter && { f: { main_code: request.filter } } }
  )

// Total machine
export const apiGetTotalMachine = (request: any) => client.get(`/projects/${request.idEstimate}/machineTotal`, { params: request.filter })

// Get machine element
export const apiGetMachineElement = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machineInElement`, {
    params: request.params,
  })

// Get machine element detail
export const apiGetMachineElementDetail = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machineInElement/${request.mainElement}/${request.subElement}`, {
    params: request.params,
  })

// Update group number
export const apiUpdateGroupNumber = (data: any) => client.put(`/projects/grouping`, data)

// Auto group number
export const apiAutoGroupNumber = (request: any) =>
  client.put(`/projects/${request.idEstimate}/automaticGrouping`, null, { params: request.filter && { f: { main_code: request.filter } } })

// Inquiry request status group
export const apiInquiriesGroup = (request: any) =>
  client.post(`/projects/inquiries/${request.idEstimate}/inGroup`, {
    data: request.data,
  })

// Inquiry request status group
export const apiInquiriesElement = (request: any) =>
  client.post(`/projects/inquiries/${request.idEstimate}/inElement`, {
    data: request.data,
  })

// Update status
export const apiInquiries = (request: any) =>
  client.post(`/projects/inquiries/${request.idEstimate}`, {
    object_registration_id: request.object_registration_id,
  })

// Re-inquiry
export const apiReInquiries = (request: any) =>
  client.post(`/projects/reInquiry/${request.idEstimate}`, {
    is_send_notify: request.isSendNotify,
  })

// Machine group
export const apiMachineGroup = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machineInGroup`, {
    params: request.params,
  })

// Machine group detail
export const apiMachineGroupDetail = (request: any) =>
  client.get(`/projects/${request.idEstimate}/machineInGroup/${request.mainCode}/${request.groupNumber}`, {
    params: request.params,
  })
