import SortIcon from '@components/atoms/CustomSort'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { useTranslation } from 'react-i18next'

// import { apiGetHistory } from '~/api/history'
import { formatDatetime, DateFormat } from '~/utils/datetimeUtils'

interface History {
  id: string
  updated_at: string
  user_name: string
  content: string
  from_datetime: string
  to_datetime: string
}

// const [history, setHistory] = useState<any>([])

// const getHistory = async () => {
//   try {
//     const res = await apiGetHistory({})
//     console.log(res)
//   } catch (error) {
//     console.log('error', error)
//   }
// }

const history = [
  {
    id: '1',
    updated_at: '2022/06/01 12:40',
    user_name: '田中 一郎',
    content: '機器編集画面 ID0123 の 数量 が 3から4に変更されました',
    from_datetime: '2022/06/01 12:30',
    to_datetime: '2022/06/01 12:40',
  },
]

// useEffect(() => {
//   getHistory()
// }, [])

const History = () => {
  const { t } = useTranslation()
  const columns: ColumnsType<History> = [
    {
      title: <SortIcon title={t('history.date_time')} />,
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 150,
    },
    {
      title: <SortIcon title={t('history.changer')} />,
      dataIndex: 'user_name',
      key: 'user_name',
      width: 180,
    },
    {
      title: <SortIcon title={t('history.changes')} />,
      dataIndex: 'content',
      key: 'content',
      width: 200,
    },
    {
      title: <SortIcon title="From" />,
      dataIndex: 'from_datetime',
      key: 'from_datetime',
      width: 150,
      render: (record) => formatDatetime(record, DateFormat.DATE_TIME_MINUTE_SLASH),
    },
    {
      title: <SortIcon title="To" />,
      dataIndex: 'to_datetime',
      key: 'to_datetime',
      width: 150,
      render: (record) => formatDatetime(record, DateFormat.DATE_TIME_MINUTE_SLASH),
    },
  ]
  return (
    <div className="mt-10 mx-20 mb-20">
      <Table
        scroll={{ x: 1000 }}
        tableLayout="fixed"
        columns={columns}
        dataSource={history}
        showSorterTooltip={false}
        pagination={false}
        bordered={true}
        rowKey={(record) => record.id}
      />
    </div>
  )
}

export default History
