import React from 'react'

const EditLightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <g id="Edit" transform="translate(-3.5 -3.5)">
        <path
          id="Icon"
          d="M.5,16.5,0,16.445A.5.5,0,0,0,.555,17Zm4.382-1.071-.354-.354h0Zm-.506.458.275.417h0Zm-1.075.3.055.5h0Zm.673-.109L3.821,15.6h0Zm-2.4-3.962-.354-.354h0Zm-.458.506.417.275ZM.811,13.7l.5.055h0Zm.109-.673.476.154h0ZM15.814,1.186l-.354.354Zm0,3.311.354.354h0ZM12.5,1.186l.354.354h0Zm.474,6.148a.5.5,0,0,0,.707-.707Zm-2.6-4.018a.5.5,0,0,0-.707.707ZM1.924,12.471,12.857,1.539,12.149.832,1.217,11.764ZM15.461,4.143,4.529,15.076l.707.707L16.168,4.851ZM3.246,15.692.445,16,.555,17l2.8-.311ZM1,16.555l.311-2.8-.994-.111L0,16.445Zm3.532-1.48a3.153,3.153,0,0,1-.427.394l.551.835a3.919,3.919,0,0,0,.584-.522Zm-1.172,1.61a3.929,3.929,0,0,0,.772-.13L3.821,15.6a3.158,3.158,0,0,1-.575.088ZM4.1,15.469a1.158,1.158,0,0,1-.28.135l.308.951a2.156,2.156,0,0,0,.523-.251ZM1.217,11.764a3.92,3.92,0,0,0-.522.584l.835.55a3.148,3.148,0,0,1,.394-.427Zm.091,1.989a3.15,3.15,0,0,1,.088-.575l-.951-.308a3.921,3.921,0,0,0-.13.772ZM.7,12.348a2.156,2.156,0,0,0-.251.523l.951.308a1.156,1.156,0,0,1,.135-.28ZM15.461,1.539a1.841,1.841,0,0,1,0,2.6l.707.707a2.841,2.841,0,0,0,0-4.018Zm.707-.707a2.841,2.841,0,0,0-4.018,0l.707.707a1.841,1.841,0,0,1,2.6,0ZM13.684,6.627,10.373,3.316l-.707.707,3.311,3.311Z"
          transform="translate(3.5 3.5)"
          fill="#262626"
        />
      </g>
    </svg>
  )
}

export default EditLightIcon
