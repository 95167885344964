import { useRoutes } from 'react-router-dom'

// routes
import PrivateBasicRouter from '~/routes/privateBasic'
import PrivateMainRouter from '~/routes/privateMain'
import PublicRouter from '~/routes/public'

// ==============================|| ROUTING RENDER ||============================== //
export default function Routes() {
  return useRoutes([PrivateBasicRouter, PrivateMainRouter, PublicRouter])
}
