import React from 'react'

const DropdownIcon = () => {
  return (
    <svg id="icon-navigation-arrow_drop_down_24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Boundary" width="24" height="24" fill="none" />
      <path id="_Color" data-name=" ↳Color" d="M0,0,5,5l5-5Z" transform="translate(7 10.316)" />
    </svg>
  )
}

export default DropdownIcon
