import { Select, Checkbox, Row, Col } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import React, { useState } from 'react'

import ReloadIcon from '~/assets/icons/ReloadIcon'

const FilterRight = () => {
  const [queryParam, setQueryParam] = useState<any>({})
  const optionLocation = [
    {
      value: '東京',
      label: '東京',
    },
    {
      value: '大坂',
      label: '大坂',
    },
    {
      value: '千葉',
      label: '千葉',
    },
  ]
  const checkBoxTypeWork = [
    {
      value: 'all',
      label: '（すべて）',
    },
    {
      value: 'ダクト',
      label: 'ダクト',
    },
    {
      value: '配管',
      label: '配管',
    },
    {
      value: '保温（国交省）',
      label: '配保温（国交省）',
    },
    {
      value: '保温（民間）',
      label: '保温（民間）',
    },
    {
      value: '冷媒',
      label: '冷媒',
    },
  ]
  const checkBoxMaterial = [
    {
      value: 'all',
      label: '（すべて）',
    },
    {
      value: 'SGP',
      label: 'SGP',
    },
    {
      value: 'STPG',
      label: 'STPG',
    },
    {
      value: 'SUS',
      label: 'SUS',
    },
    {
      value: 'VLP',
      label: 'VLP',
    },
    {
      value: 'VP',
      label: 'VP',
    },
  ]
  const checkBoxBuildingUse = [
    {
      value: 'all',
      label: '（すべて）',
    },
    {
      value: '工場（CR）',
      label: '工場（CR）',
    },
    {
      value: '校舎',
      label: '校舎',
    },
    {
      value: '事務所',
      label: '事務所',
    },
    {
      value: '宿泊施設',
      label: '情報通信施設',
    },
    {
      value: '大規模小売店舗',
      label: '都市施設',
    },
  ]
  const checkBoxMode = [
    {
      value: 'all',
      label: '（すべて）',
    },
    {
      value: '新築',
      label: '新築',
    },
    {
      value: '改修',
      label: '改修',
    },
  ]

  const handleChange = () => {
    console.log('handleChange')
  }
  const onChangeTypeWork = (checkedValues: CheckboxValueType[]) => {
    console.log('onChangeTypeWork')
    setQueryParam({ ...queryParam, checkedValues })
  }
  const onChangeMaterial = (checkedValues: CheckboxValueType[]) => {
    console.log('onChangeMaterial')
    setQueryParam({ ...queryParam, checkedValues })
  }
  const onChangeBuildingApplication = (checkedValues: CheckboxValueType[]) => {
    console.log('onChangeBuildingApplication')
    setQueryParam({ ...queryParam, checkedValues })
  }
  const onChangeMode = (checkedValues: CheckboxValueType[]) => {
    console.log('onChangeMode')
    setQueryParam({ ...queryParam, checkedValues })
  }

  return (
    <div className="flex flex-col w-14/16">
      <div className="flex w-full">
        <div className="flex w-5/12 showPointer h-10 border rounded items-center justify-center mt-3">
          <ReloadIcon />
          <span className="ml-2">M値更新</span>
        </div>
      </div>
      <div className="flex w-full text-blue-500 mt-6">本支店名</div>
      <div className="flex w-full mt-1 text-justify">
        <Select defaultValue="東京" className="w-48" onChange={handleChange} options={optionLocation} />
      </div>
      <div className="flex w-full text-blue-500 mt-6">工種</div>
      <Checkbox.Group className=" mt-1" onChange={onChangeTypeWork}>
        <Row>
          {checkBoxTypeWork.map((typeWork, index) => (
            <Col span={12} key={index}>
              <Checkbox key={typeWork.value} value={typeWork.value}>
                {typeWork.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <div className="flex w-full text-blue-500 mt-6">材質</div>
      <Checkbox.Group className=" mt-1" onChange={onChangeMaterial}>
        <Row>
          {checkBoxMaterial.map((material, index) => (
            <Col span={12} key={index}>
              <Checkbox key={material.value} value={material.value}>
                {material.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <div className="flex w-full text-blue-500 mt-6">建物用途</div>
      <Checkbox.Group className=" mt-1" onChange={onChangeBuildingApplication}>
        <Row>
          {checkBoxBuildingUse.map((buildingUse, index) => (
            <Col span={12} key={index}>
              <Checkbox key={buildingUse.value} value={buildingUse.value}>
                {buildingUse.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <div className="flex w-full text-blue-500 mt-6">態様</div>
      <Checkbox.Group className=" mt-1" onChange={onChangeMode}>
        <Row>
          {checkBoxMode.map((mode, index) => (
            <Col span={12} key={index}>
              <Checkbox key={mode.value} value={mode.value}>
                {mode.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  )
}

export default FilterRight
