import React from 'react'

const FilterIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 6H6.76C7.17 7.16 8.27 8 9.58 8C10.89 8 11.98 7.16 12.4 6H21.51C22.06 6 22.51 5.55 22.51 5C22.51 4.45 22.06 4 21.51 4H12.39C11.98 2.84 10.88 2 9.57 2C8.26 2 7.17 2.84 6.75 4H3.5C2.95 4 2.5 4.45 2.5 5C2.5 5.55 2.95 6 3.5 6ZM9.57 4C10.06 4 10.44 4.36 10.53 4.82C10.526 4.844 10.5188 4.868 10.5116 4.892C10.5008 4.928 10.49 4.964 10.49 5C10.49 5.036 10.5008 5.072 10.5116 5.108C10.5188 5.132 10.526 5.156 10.53 5.18C10.44 5.64 10.05 6 9.57 6C9.02 6 8.57 5.55 8.57 5C8.57 4.45 9.02 4 9.57 4ZM21.5 11H20.39C19.98 9.84 18.88 9 17.57 9C16.26 9 15.17 9.84 14.75 11H3.5C2.95 11 2.5 11.45 2.5 12C2.5 12.55 2.95 13 3.5 13H14.76C15.17 14.16 16.27 15 17.58 15C18.89 15 19.98 14.16 20.4 13H21.51C22.06 13 22.51 12.55 22.51 12C22.51 11.45 22.06 11 21.51 11H21.5ZM17.57 13C17.02 13 16.57 12.55 16.57 12C16.57 11.45 17.02 11 17.57 11C18.06 11 18.44 11.36 18.53 11.82C18.526 11.844 18.5188 11.868 18.5116 11.892C18.5008 11.928 18.49 11.964 18.49 12C18.49 12.036 18.5008 12.072 18.5116 12.108C18.5188 12.132 18.526 12.156 18.53 12.18C18.44 12.64 18.05 13 17.57 13ZM10.39 18H21.5H21.51C22.06 18 22.51 18.45 22.51 19C22.51 19.55 22.06 20 21.51 20H10.4C9.98 21.16 8.89 22 7.58 22C6.27 22 5.17 21.16 4.76 20H3.5C2.95 20 2.5 19.55 2.5 19C2.5 18.45 2.95 18 3.5 18H4.75C5.17 16.84 6.26 16 7.57 16C8.88 16 9.98 16.84 10.39 18ZM6.57 19C6.57 19.55 7.02 20 7.57 20C8.05 20 8.44 19.64 8.53 19.18C8.526 19.156 8.5188 19.132 8.5116 19.108C8.5008 19.072 8.49 19.036 8.49 19C8.49 18.964 8.5008 18.928 8.5116 18.892C8.5188 18.868 8.526 18.844 8.53 18.82C8.44 18.36 8.06 18 7.57 18C7.02 18 6.57 18.45 6.57 19Z"
        fill="#009BE0"
      />
    </svg>
  )
}

export default FilterIcon
