import React from 'react'

interface SlideOutIconProps {
  width?: string
  height?: string
}

const SlideOutIcon = (props: SlideOutIconProps) => {
  const width = props.width ?? '24'
  const height = props.height ?? '24'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5195 2C22.0718 2 22.5195 2.44772 22.5195 3V21C22.5195 21.5523 22.0718 22 21.5195 22C20.9672 22 20.5195 21.5523 20.5195 21V3C20.5195 2.44772 20.9672 2 21.5195 2ZM9.81242 4.29289C10.2029 3.90237 10.8361 3.90237 11.2266 4.29289L18.2259 11.2922C18.2283 11.2946 18.2307 11.297 18.2331 11.2995C18.3258 11.3938 18.396 11.502 18.4437 11.6172C18.4922 11.7343 18.5191 11.8625 18.5195 11.997C18.5195 11.998 18.5195 11.999 18.5195 12C18.5195 12.001 18.5195 12.002 18.5195 12.003C18.5191 12.1375 18.4922 12.2657 18.4437 12.3828C18.3942 12.5021 18.3222 12.6098 18.2331 12.7005C18.2307 12.703 18.2283 12.7054 18.2259 12.7078L11.2266 19.7071C10.8361 20.0976 10.2029 20.0976 9.81242 19.7071C9.4219 19.3166 9.4219 18.6834 9.81242 18.2929L15.1053 13H3.51953C2.96725 13 2.51953 12.5523 2.51953 12C2.51953 11.4477 2.96725 11 3.51953 11H15.1053L9.81242 5.70711C9.4219 5.31658 9.4219 4.68342 9.81242 4.29289Z"
        fill="black"
      />
    </svg>
  )
}

export default SlideOutIcon
