import React from 'react'

interface PenIconProps {
  isDisable?: boolean
}
const PenIcon = ({ isDisable }: PenIconProps) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 17" fill="none">
      <path
        d="M1.83337 15.7674C1.66003 15.7674 1.4867 15.7007 1.36003 15.574C1.22003 15.434 1.1467 15.2274 1.1667 15.0274L1.4267 12.694C1.46003 12.3874 1.48003 12.2007 1.5467 12.0007C1.60003 11.8274 1.6867 11.6607 1.7867 11.5007C1.90003 11.3274 2.03337 11.194 2.25337 10.974L9.28003 3.94737C9.28003 3.94737 9.2867 3.9407 9.29337 3.93404C9.30003 3.92737 9.30003 3.92737 9.3067 3.9207L11.3667 1.8607C12.3867 0.840703 14.0467 0.840703 15.0667 1.8607C16.0867 2.8807 16.0867 4.5407 15.0667 5.5607L13 7.62737L5.95337 14.6807C5.73337 14.9007 5.60003 15.034 5.4267 15.1474C5.27337 15.2474 5.1067 15.3274 4.93337 15.3874C4.73337 15.454 4.5467 15.474 4.24003 15.5074L1.9067 15.7674C1.9067 15.7674 1.86003 15.7674 1.83337 15.7674ZM9.7667 5.34737L3.20003 11.9207C3.04003 12.0807 2.9467 12.174 2.9067 12.234C2.87337 12.2874 2.84003 12.3474 2.8267 12.4074C2.8067 12.474 2.79337 12.594 2.7667 12.8407L2.60003 14.3474L4.1067 14.1807C4.35337 14.154 4.4667 14.1407 4.54003 14.1207C4.60003 14.1007 4.66003 14.074 4.71337 14.0407C4.77337 14.0007 4.86003 13.914 5.03337 13.7474L11.58 7.16737L9.7667 5.35404V5.34737ZM10.7067 4.40737L12.52 6.2207L14.12 4.6207C14.62 4.1207 14.62 3.30737 14.12 2.80737C13.62 2.30737 12.8067 2.30737 12.3 2.80737L10.7 4.40737H10.7067Z"
        fill={isDisable ? '#A3A3A3' : '#009BE0'}
      />
    </svg>
  )
}

export default PenIcon
