import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import SortIcon from '~/components/atoms/SortIconTable'
import TableResize from '~/components/molecules/TableResize'
import ModalEstimate from '~/components/organisms/ModalEstimate'
import InformationProject from '~/components/organisms/ModalEstimate/informationProject'

import { apiGetEstimateList, postProjectEstimate, deleteProjectEstimate } from '~/api/project'
import ArrowLeft from '~/assets/icons/ArrowLeftIcon'
import CopyIcon from '~/assets/icons/CopyIcon'
import PlusIcon from '~/assets/icons/PlusNoBorderIcon'
import ReloadRowIcon from '~/assets/icons/ReloadRowIcon'
import TrashIcon from '~/assets/icons/TrashIcon'
import { BUTTON_TYPE } from '~/consts/common'
import { ROUTER_PATH } from '~/consts/router'
import { CreateProjectEstimateData } from '~/interfaces/project'
import { useAppDispatch } from '~/redux/hooks'
import { setProjectInfo } from '~/redux/reducers/project.slice'
import { DateFormat, formatDatetime } from '~/utils/datetimeUtils'
import { formatDecimal, handleError, pushNotification } from '~/utils/helper'
import { setLocalStorage } from '~/utils/storage'

const listEstimate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()
  const [estimate, setEstimate] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAddEstimate, setIsAddEstimate] = useState<boolean>(false)
  const [projectName, setProjectName] = useState<string>()
  const [propertyNumber, setPropertyNumber] = useState<string>()

  const [queryParams, setQueryParams] = useState<{
    limit: number
    page: number
    order_by: string | null
    sort: string | null
  }>({
    limit: 10,
    page: 1,
    order_by: null,
    sort: null,
  })

  const handleNavigate = () => {
    navigate(ROUTER_PATH.PROJECT.LIST)
  }

  const getListEstimate = async () => {
    try {
      const { data } = await apiGetEstimateList({
        id: params.id,
        params: queryParams,
      })

      const {
        project_name = '',
        property_number = '',
        office_name = '',
        ower_name = '',
        company_office_name = '',
        core_system_project_id: project_id = '',
        construction_period_end_date = '',
        construction_period_start_date = '',
      } = data.meta || {}

      const infoProject = {
        property_number,
        project_name,
        office_name,
        ower_name,
        project_id,
        construction_period_end_date,
        construction_period_start_date,
      }

      dispatch(setProjectInfo(infoProject))

      if (data.data?.data) {
        data.data.data.forEach((item) => {
          item.company_office_name = company_office_name
        })
      }

      setEstimate(data.data)
      setProjectName(project_name)
      setPropertyNumber(property_number)
      setIsLoading(false)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCopy = async (record: any) => {
    const data: CreateProjectEstimateData = {
      submission_deadline: record.submission_deadline,
      estimate_name: record.estimate_name,
      project_id: params?.id as string,
      customer_code: record.customer_code,
      staff_number: record.staff_number,
    }
    setIsLoading(true)
    try {
      await postProjectEstimate(data)
      pushNotification(`見積${record.estimate_name}をコピーしました`, 'success')
      setIsLoading(false)
      await getListEstimate()
    } catch (e) {
      pushNotification((e as any).message, 'error')
      setIsLoading(false)
    }
  }

  const handleDelete = async (record: any) => {
    const idEstimate = record.id
    if (!idEstimate) return

    setIsLoading(true)
    try {
      await deleteProjectEstimate({ projectId: params?.id, estimateIds: [idEstimate] })
      pushNotification(`見積${record.estimate_name}を削除しました`, 'success')
      setIsLoading(false)
      await getListEstimate()
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheckConstructionsTotal = (constructionsTotal = 0) => {
    setLocalStorage('menuItem', constructionsTotal ? '3' : '2')
  }

  const columns: any = [
    {
      title: ({ sortColumns }) => <SortIcon title="見積ID" sortColumns={sortColumns} keyName="id" />,
      dataIndex: 'estimate_number',
      key: 'estimate_number',
      width: 100,
      className: 'min-w-[100px]',
      sorted: true,
      defaultSortOrder: 'ascend ',
      ellipsis: false,
      render: (text: any) => <>{!text ? <Button text="ID取得" isCurrentNotImplemented /> : text}</>,
    },
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.status')} sortColumns={sortColumns} keyName="status" />,
      dataIndex: 'status',
      key: 'status',
      width: 120,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 見積名
    {
      title: ({ sortColumns }) => <SortIcon title="見積名" sortColumns={sortColumns} keyName="estimate_name" />,
      dataIndex: 'estimate_name',
      key: 'estimate_name',
      width: 150,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 提出先
    {
      title: ({ sortColumns }) => <SortIcon title="提出先" sortColumns={sortColumns} keyName="customer_name" />,
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 150,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 見積担当課
    {
      title: ({ sortColumns }) => <SortIcon title="見積担当課" sortColumns={sortColumns} keyName="section_name" />,
      dataIndex: 'section_name',
      key: 'section_name',
      width: 150,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 見積担当者
    {
      title: ({ sortColumns }) => <SortIcon title="見積担当者" sortColumns={sortColumns} keyName="staff_name" />,
      dataIndex: 'staff_name',
      key: 'staff_name',
      width: 150,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 見積レベル
    {
      title: ({ sortColumns }) => <SortIcon title="見積レベル" sortColumns={sortColumns} keyName="estimate_level" />,
      dataIndex: 'estimate_level',
      key: 'estimate_level',
      width: 150,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 契約金額
    {
      title: ({ sortColumns }) => <SortIcon title="契約金額" sortColumns={sortColumns} keyName="contract_amount" />,
      dataIndex: 'contract_amount',
      key: 'contract_amount',
      width: 150,
      sorted: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record) => <div className="flex justify-between  items-center">{record}</div>,
      ellipsis: true,
    },
    //TODO: 見積提出期日
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.submit_deadline')} sortColumns={sortColumns} keyName="submission_deadline" />,
      dataIndex: 'submission_deadline',
      key: 'submission_deadline',
      width: 150,
      sorter: true,
      className: 'min-w-[100px]',
      defaultSortOrder: 'ascend ',
      render: (record: any) => formatDatetime(record, DateFormat.DATE_SLASH),
      ellipsis: true,
    },
    //TODO: 見積提出日
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.submit_date')} sortColumns={sortColumns} keyName="submit_date" />,
      dataIndex: 'submission_date',
      key: 'submission_date',
      width: 150,
      sorter: true,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      render: (record: any) => {
        return formatDatetime(record, DateFormat.DATE_SLASH)
      },
      ellipsis: true,
    },
    //TODO: 回答金額
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.answer_amount')} sortColumns={sortColumns} keyName="offer_amount" />,
      dataIndex: 'offer_amount',
      key: 'offer_amount',
      width: 120,
      className: 'min-w-[120px]',
      defaultSortOrder: 'ascend ',
      sorter: true,
      ellipsis: true,
    },
    //TODO: A0原価
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.cost')} sortColumns={sortColumns} keyName="a0_cost_amount" />,
      dataIndex: 'a0_cost_amount',
      key: 'a0_cost_amount',
      width: 150,
      className: 'min-w-[150px]',
      defaultSortOrder: 'ascend ',
      sorter: true,
      ellipsis: true,
    },
    //TODO: 粗利率(A0原価)
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.gross_margin')} sortColumns={sortColumns} keyName="a0_gross_profit_rate" />,
      dataIndex: 'a0_gross_profit_rate',
      key: 'a0_gross_profit_rate',
      width: 160,
      className: 'min-w-[180px]',
      render: (record: any) => {
        return formatDecimal(record)
      },
      defaultSortOrder: 'ascend ',
      sorter: true,
      ellipsis: true,
    },
    //TODO: E原価
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.e_cost')} sortColumns={sortColumns} keyName="e_cost_amount" />,
      dataIndex: 'e_cost_amount',
      key: 'e_cost_amount',
      width: 120,
      className: 'min-w-[160px]',
      defaultSortOrder: 'ascend ',
      sorter: true,
      ellipsis: true,
    },
    //TODO: 粗利率(E原価)
    {
      title: ({ sortColumns }) => <SortIcon title={t('project_list.gross_margin_ecost')} sortColumns={sortColumns} keyName="e_gross_profit_rate" />,
      dataIndex: 'e_gross_profit_rate',
      key: 'e_gross_profit_rate',
      width: 160,
      className: 'min-w-[160px]',
      render: (record: any) => {
        return formatDecimal(record)
      },
      defaultSortOrder: 'ascend ',
      sorter: true,
      ellipsis: true,
    },

    {
      // title: ({ sortColumns }) => <SortIcon title="情報連携" sortColumns={sortColumns} keyName="information_collaboration" />,
      title: '',
      dataIndex: 'information_collaboration',
      key: 'information_collaboration',
      width: 250,
      className: 'min-w-[250px]',
      align: 'center',
      ellipsis: true,
      render: (_, record: any) => {
        const renderButton = ({ component, text, onClick }) => (
          <Button
            type={BUTTON_TYPE.text}
            text={
              <div className="flex flex-col gap-2 justify-center items-center">
                {component}
                <span>{text}</span>
              </div>
            }
            className="mt-0"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              onClick && onClick()
            }}
            isCurrentNotImplemented={text !== '削除'}
          />
        )

        return (
          <div className="flex items-center gap-2">
            {renderButton({ component: <ReloadRowIcon isDisable={true} />, text: '情報連携', onClick: () => null })}
            {renderButton({
              component: <CopyIcon isDisable={true} />,
              text: 'コピー',
              onClick: () => {
                // TODO: It will be implemented later
                // handleCopy(record)
              },
            })}
            {renderButton({ component: <TrashIcon />, text: '削除', onClick: () => handleDelete(record) })}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    getListEstimate()
  }, [params.id, queryParams])

  const handleAction = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParams }
    if (sorter.column)
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'asc',
          page: pagination.current,
        }
      else {
        queryParamsClone = {
          ...queryParamsClone,
          order_by: `${sorter.column.key}`,
          sort: 'desc',
          page: pagination.current,
        }
      }
    queryParamsClone = {
      ...queryParamsClone,
      page: queryParams.page === pagination.current ? 1 : pagination.current,
    }
    setQueryParams(queryParamsClone)
  }

  return (
    <div>
      <div className="flex items-center pt-2 px-8 mb-12 ml-[-2rem] mr-[-1.75rem] bg-white border-b-2">
        <div className="flex items-center">
          <Button type={BUTTON_TYPE.text} onClick={handleNavigate} className="button-navigate pl-0" prefixIcon={<ArrowLeft />} />
          <div>
            <InformationProject />
          </div>
        </div>
      </div>
      <div className="table-padding mx-6">
        <div className="flex justify-between items-center mb-6">
          <div className="text-lg font-bold">見積一覧</div>
          <div className="flex">
            <Button type={BUTTON_TYPE.outlined} prefixIcon={<PlusIcon />} text="見積追加" onClick={() => setIsAddEstimate(true)} />
            <Button
              isCurrentNotImplemented
              className="ml-3 font-bold"
              text="見積を比較する"
              // It will be implemented in the future
              // onClick={() => navigate(ROUTER_PATH.PROJECT.LIST_ESTIMATE_2)}
            />
          </div>
        </div>
        <TableResize
          columnDefine={columns}
          data={estimate?.data}
          loading={isLoading}
          from={estimate?.from}
          to={estimate?.to}
          current_page={estimate?.current_page}
          total={estimate?.total}
          per_page={estimate?.per_page}
          handleAction={handleAction}
          handleClickRow={(record) => {
            handleCheckConstructionsTotal(record?.constructions_total)
            navigate(
              generatePath(ROUTER_PATH.PROJECT.DETAIL, {
                idProject: `${params.id}`,
                idEstimate: `${record.id}`,
              })
            )
          }}
        />
      </div>

      {isAddEstimate && (
        <ModalEstimate
          projectName={projectName}
          propertyNumber={propertyNumber}
          onCancel={() => setIsAddEstimate(false)}
          onSuccess={() => {
            pushNotification('見積作成が成功しました。', 'success')
            setIsAddEstimate(false)
            getListEstimate()
          }}
        />
      )}
    </div>
  )
}

export default listEstimate
