import React, { useRef, useEffect } from 'react'
import Map, { Source, Layer } from 'react-map-gl'

import { DUMMY_FEATURE_MAP, MAPBOXGL_TOKEN, MAP_STYLE, MAP_TEXT_PLUGIN } from '~/consts/common'

interface MapBoxGLProps {
  geoFeatures: any
}

const MapBoxGL = ({ geoFeatures }: MapBoxGLProps) => {
  const mapContainer = useRef(null)
  const geojson: any = {
    type: 'FeatureCollection',
    features: [geoFeatures],
  }
  const layerStyle: any = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': 'blue',
    },
    locale: 'ja-JP',
  }

  useEffect(() => {
    if (mapContainer.current) {
      mapContainer.current.setLanguage('ja')
    }
  }, [mapContainer.current])

  useEffect(() => {
    if(geoFeatures && geoFeatures.geometry) {
      const geometry = geoFeatures.geometry
      mapContainer.current?.flyTo({ center: [geometry.coordinates[0], geometry.coordinates[1]], duration: 2000 })
    }
  }, [geoFeatures])

  return (
    <>
      <Map
        ref={mapContainer}
        initialViewState={{ longitude: DUMMY_FEATURE_MAP.geometry.coordinates[0], latitude: DUMMY_FEATURE_MAP.geometry.coordinates[1], zoom: 11 }}
        style={{ width: '100%', height: '400px' }}
        mapStyle={MAP_STYLE}
        mapboxAccessToken={MAPBOXGL_TOKEN}
        RTLTextPlugin={MAP_TEXT_PLUGIN}
      >
        <Source id="my-data" type="geojson" data={geojson}>
          <Layer {...layerStyle} />
        </Source>
      </Map>
    </>
  )
}

export default MapBoxGL
