import React, { useState } from 'react'

import { ADD_MACHINE_TYPE } from '~/consts/common'
import AddMachine from '~/features/addMachine/step1'
import Filter from '~/features/airCondition-7/filter'
import LaborCost from '~/features/airCondition-7/laborCost'

const Index = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [laborCostId, setLaborCostId] = useState('')
  const [isShowAdd, setIsShowAdd] = useState<boolean>(false)
  const [checkReload, setCheckReload] = useState<boolean>(false)

  const handleEdit = (laborCostIdArg) => {
    setIsEdit(true)
    setLaborCostId(laborCostIdArg)
  }
  const showStep = () => setIsShowAdd(!isShowAdd)
  const reloadList = () => {
    setCheckReload(true)
  }

  return (
    <div className="pt-6 px-24 w-full">
      {isEdit ? (
        <Filter setIsEdit={setIsEdit} laborCostId={laborCostId} setShowAddModal={showStep} />
      ) : (
        <LaborCost
          handleEdit={(laborCostIdArg) => handleEdit(laborCostIdArg)}
          setShowAddModal={showStep}
          reloadData={checkReload}
          setCheckReload={setCheckReload}
        />
      )}
      <AddMachine keyAdd={ADD_MACHINE_TYPE.AIR_CONDITION_TYPE.mainCodeA} isStep={isShowAdd} showStep={showStep} reloadListMachine={reloadList} />
    </div>
  )
}

export default Index
