import { Col, Divider, Row, Table } from 'antd'
import React, { useMemo } from 'react'

import GraphIcon from '~/assets/icons/GraphIcon'

import { MValue } from './index'

import type { ColumnsType } from 'antd/es/table'

interface ListMValueProps {
  rowData: MValue[]
  handleOnClickGraph: (detail: boolean) => any
}

const ListMValue = ({ rowData, handleOnClickGraph }: ListMValueProps) => {
  const renderCellArray = (keyID, valueCell) => {
    return {
      props: {
        style: {
          padding: 0,
        },
      },
      children: (
        <Col key={keyID}>
          {valueCell.map((item, index) => (
            <div key={index}>
              <Row className="p-4">{item.toUpperCase()}</Row>
              {index !== valueCell.length - 1 && <Divider className="border-gray-400" />}
            </div>
          ))}
        </Col>
      ),
    }
  }
  const columnMValue: ColumnsType<any> = useMemo(
    () => [
      {
        title: '工種',
        dataIndex: 'typeWork',
        key: 'typeWork',
        width: 80,
      },
      {
        title: '材質',
        dataIndex: 'material',
        key: 'material',
        width: 80,
      },
      {
        title: '期',
        dataIndex: 'period',
        key: 'period',
        width: 80,
      },
      {
        title: '本支店名',
        dataIndex: 'branchName',
        key: 'branchName',
        width: 90,
      },
      {
        title: '物件名称',
        dataIndex: 'propertyName',
        key: 'propertyName',
        width: 150,

        render: (_, { id, propertyName }) => renderCellArray(id, propertyName),
      },
      {
        title: '態様',
        dataIndex: 'mode',
        key: 'mode',
        width: 90,
        render: (_, { id, mode }) => renderCellArray(id, mode),
      },
      {
        title: '建物用途',
        dataIndex: 'buildingApp',
        key: 'buildingApp',
        width: 130,
        render: (_, { id, buildingApp }) => renderCellArray(id, buildingApp),
      },
      {
        title: '発注先',
        dataIndex: 'supplier',
        key: 'supplier',
        width: 100,
        render: (_, { id, supplier }) => renderCellArray(id, supplier),
      },
      {
        title: '数量（ton/m/m2）',
        dataIndex: 'amount',
        key: 'amount',
        width: 180,
        render: (_, { id, amount }) => renderCellArray(id, amount),
      },
      {
        title: '複合発注金額',
        dataIndex: 'combinedAmount',
        key: 'combinedAmount',
        render: (_, { id, combinedAmount }) => renderCellArray(id, combinedAmount),
      },
      {
        title: '単体発注金額',
        dataIndex: 'singleAmount',
        key: 'singleAmount',
        render: (_, { id, singleAmount }) => renderCellArray(id, singleAmount),
      },
      {
        title: '複合M値',
        dataIndex: 'combinedMvalue',
        key: 'combinedMvalue',
        render: (_, { id, combinedMvalue }) => renderCellArray(id, combinedMvalue),
      },
      {
        title: 'グラフ',
        dataIndex: 'action',
        key: 'action',
        width: 90,

        render: (_, record) => {
          return (
            <div
              key={record.id}
              className="showPointer"
              onClick={() => {
                handleOnClickGraph(true)
              }}
            >
              <GraphIcon />
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <>
      <div className="flex w-19/20 font-bold text-2xl mt-8">M値一覧</div>
      <div className="flex w-19/20 items-center mt-2">
        <Table tableLayout="fixed" bordered columns={columnMValue} dataSource={rowData} />
      </div>
      <div className="w-full">&nbsp;&nbsp;&nbsp;&nbsp;</div>
    </>
  )
}

export default ListMValue
