import { Select, Space, Input } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { cloneDeep } from 'lodash'
import React, { useState, useRef } from 'react'

import Button from '~/components/atoms/Button'

import ArrowDownIcon from '~/assets/icons/ArrowDownIcon'

interface SelectCustomProps extends SelectProps {
  isLoadingSpec: boolean
  options: any
  getSpec?: ((e: React.ChangeEvent<HTMLInputElement>, option: any) => void) | null
}

const ISelectDropDown = ({ getSpec, options, isLoadingSpec, onChange }: SelectCustomProps) => {
  const [selectValue, setSelectValue] = useState<any>(null)
  const [customOption, setCustomOption] = useState<any>(cloneDeep(options))
  const [openDropDown, setOpenDropDown] = useState<boolean>(false)
  const [isAdd, setIsAdd] = useState<boolean>(false)
  const inputRef = useRef<any>(null)
  const handleChangeButton = () => {
    setIsAdd(!isAdd)
  }
  const handleAddItem = () => {
    setCustomOption([...customOption, { label: inputRef.current.input.value, value: inputRef.current.input.value }])
    setSelectValue(inputRef.current.input.value)

    onChange?.(
      inputRef.current.input.value || '',
      customOption.map(({ label, value }) => ({ label, value }))
    )
    setIsAdd(!isAdd)
    setOpenDropDown(false)
  }

  const handleVisibleDrop = (visible) => {
    setOpenDropDown(visible)
    if (inputRef && inputRef.current) {
      inputRef.current.value = null
    }
    setIsAdd(false)
  }
  const handleSelect = (value) => {
    setSelectValue(value)
  }
  const handleChange = (e: any, option: any) => {
    if (getSpec) {
      getSpec(e, option)
    }
    onChange?.(
      e,
      customOption.map(({ label, value }) => ({ label, value }))
    )
  }

  const renderedOptions = customOption.map((option) => {
    const label = option?.suffix ? (
      <div className="flex justify-between items-center">
        <span>{option.label}</span>
        <span>{option.suffix}</span>
      </div>
    ) : (
      option.label
    )
    return {
      ...option,
      label,
    }
  })

  return (
    <Select
      placeholder="選択してください"
      onChange={(e, option) => handleChange(e, option)}
      onSelect={handleSelect}
      size="large"
      loading={isLoadingSpec}
      value={selectValue}
      open={openDropDown}
      onDropdownVisibleChange={(visible) => handleVisibleDrop(visible)}
      options={renderedOptions}
      suffixIcon={<ArrowDownIcon />}
      dropdownRender={(menu) => (
        <>
          {menu}
          {isAdd ? (
            <>
              <div className="flex justify-between px-2.5 py-2 text-lg h-full">
                <Input className="w-7/12" placeholder="手入力" ref={inputRef} />
                <Space size={5} className="w-1/12" />
                <div className="w-4/12">
                  <Button className="text-[0.68rem] h-full w-full text-center" size="small" text="追加" onClick={handleAddItem} />
                </div>
              </div>
            </>
          ) : (
            <div className="px-2.5">
              <Button type="text" text="--手入力--" onClick={handleChangeButton} />
            </div>
          )}
        </>
      )}
    />
  )
}

export default ISelectDropDown
