import React from 'react'

import Button from '~/components/atoms/Button'

import ImportIcon from '~/assets/icons/ImportIcon'
import { BUTTON_TYPE } from '~/consts/common'
import { className } from '~/utils/stringUtils'

interface CreateConstructionProps {
  setDataNew?: any
}

const CreateConstruction = ({ setDataNew }: CreateConstructionProps) => {
  const classButton = className('!w-60 !h-[120px] bg-white text-[#009BE0] font-medium border-[#a3a3a3]')
  return (
    <div className="px-6 py-12">
      <div className="text-[22px] font-bold mb-6">工事項目作成</div>
      <div className="flex space-x-4 ">
        <Button type={BUTTON_TYPE.outlined} className={classButton} onClick={setDataNew} text="新規作成" />
        <Button type={BUTTON_TYPE.outlined} className={classButton} prefixIcon={<ImportIcon />} text="空調基本から作成" />
      </div>
    </div>
  )
}

export default CreateConstruction
