import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Col, Row, Table } from 'antd'
import get from 'lodash/get'
import React, { useMemo } from 'react'

import Button from '~/components/atoms/Button'

import ArrowUpOrDownIcon from '~/assets/icons/ArrowUpOrDownIcon'
import DeleteIcon from '~/assets/icons/DeleteIcon2'
import EditIcon from '~/assets/icons/EditIcon2'
import PlusIcon from '~/assets/icons/PlusNoBorderIcon'
import { generateUniqueNumberKey } from '~/utils/helper'

import EditableCell from './EditableCell'

type AddRowFunction = () => Promise<void>
type DeleteRowFunction = (record: any) => Promise<void>
type ReorderRowFunction = (record: any, order: string) => Promise<void>

interface TableConstructionProps {
  headerColumns: any
  tableColumns: any
  data: any[]
  loading: boolean
  selectedGroupCode?: string
  defaultExpandAllRows?: boolean
  handleAction?: any
  onOpenDrawer?: (record: any) => void
  onAddRow?: AddRowFunction
  onDeleteRow?: DeleteRowFunction
  onReorderRow?: ReorderRowFunction
  setFieldValue?: any
}

const TableConstruction = ({
  headerColumns,
  tableColumns,
  data,
  loading,
  selectedGroupCode,
  defaultExpandAllRows,
  handleAction,
  onOpenDrawer,
  onAddRow,
  onDeleteRow,
  onReorderRow,
  setFieldValue,
}: TableConstructionProps) => {
  const getColumnRenderer = (col: any) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => {
        const recordData = record['code']
        return {
          record,
          dataIndex: col.dataIndex,
          formItemName: `${col.dataIndex}&${recordData}`,
          editing: col.editable,
          setFieldValue,
        }
      },
    }
  }

  const getHeaderCell = (column: any) => {
    const getMinWidth = column.className?.match(/(\d+)/)
    const valueMin = getMinWidth ? getMinWidth[0] : null
    return {
      width: column.width || +valueMin,
      minWidth: +valueMin,
    }
  }

  const mergedColumns = useMemo(
    () =>
      tableColumns.map((col: any) => {
        const columnRender = getColumnRenderer(col)
        const headerCellProps = getHeaderCell(columnRender)
        return { ...columnRender, onHeaderCell: () => headerCellProps }
      }),
    [tableColumns, setFieldValue]
  )

  const renderHeader = () => {
    return (
      <Row className="rounded-md border border-solid border-[#D4D4D4] bg-[#F5F5F5] py-2">
        <Col span={2} />
        <Col span={20} className="flex items-center">
          {headerColumns.map((col: any) => (
            <div key={col.key} className={col.className}>
              {col.title}
            </div>
          ))}
        </Col>
        <Col span={2} />
      </Row>
    )
  }

  const renderContent = () => {
    return data?.map((group, index) => {
      const isFirstRow = index === 0
      const isLastRow = index === data.length - 1 || !get(data, `${index + 1}.0.construction_id`)
      const rowData = group[0]
      const isEditing = rowData?.group_code === selectedGroupCode
      const isDummyRowData = !rowData?.construction_id

      return (
        <Row key={generateUniqueNumberKey()} className={`rounded-md border border-solid border-[#D4D4D4] ${isEditing ? 'bg-[#F0F9FF]' : ''} mt-2 py-3`}>
          <Col span={2} className="h-full flex justify-center items-center">
            <div className="flex flex-col items-center gap-y-3">
              {['up', 'down'].map((order) => {
                const isDisabled = isDummyRowData || (order === 'up' ? isFirstRow : isLastRow)
                return (
                  <Button
                    key={order}
                    type="text"
                    offFocus
                    className="h-3 px-0"
                    prefixIcon={<ArrowUpOrDownIcon type={order} disabled={isDisabled} />}
                    onClick={async () => {
                      if (!isDisabled && onReorderRow) {
                        await onReorderRow(rowData, order)
                      }
                    }}
                  />
                )
              })}
            </div>
          </Col>
          <Col span={20} className="min-h-[54px]">
            {isDummyRowData ? (
              <div className="h-full flex justify-center items-center">
                <span className="text-sm text-[#737373]">No data</span>
              </div>
            ) : (
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                tableLayout="fixed"
                columns={mergedColumns}
                dataSource={group}
                showHeader={false}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
                onChange={handleAction}
                rowKey={(record) => record.key || Math.random()}
                // className="border border-solid border-[#D4D4D4]"
                expandable={{
                  defaultExpandAllRows,
                  expandIcon: ({ expanded, onExpand, record }) => {
                    if (record.children) {
                      const checkRenderSpan =
                        record.parentEnd &&
                        record.parentEnd.split('-').map((item: any, index: number) => {
                          if (index > 1 && !item.includes('end')) {
                            return <span key={index} className={`child-${index}`} />
                          }
                        })
                      return (
                        <div className="flex">
                          <span className={`level-${record.index && record.index.split('-').length}`}>
                            {checkRenderSpan}
                            {record.index && record.index.length > 1 && <span className={`${record.end ? 'LMark' : 'xMark'}`} />}
                          </span>
                          <span className="mr-2 flex items-center">
                            {record.children && record.children.length > 0 ? (
                              expanded ? (
                                <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                              ) : (
                                <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                              )
                            ) : (
                              <span className="block w-2">&nbsp;</span>
                            )}
                          </span>
                        </div>
                      )
                    }
                  },
                }}
              />
            )}
          </Col>
          <Col span={2} className="h-full flex justify-center items-center">
            <Button type="text" offFocus className="px-0" prefixIcon={<EditIcon />} onClick={() => onOpenDrawer && onOpenDrawer(rowData)} />
            <Button type="text" offFocus className="px-0" prefixIcon={<DeleteIcon />} onClick={async () => onDeleteRow && (await onDeleteRow(rowData))} />
          </Col>
        </Row>
      )
    })
  }

  return (
    <>
      {renderHeader()}
      {renderContent()}
      {onAddRow && <Button type="outlined" offFocus className="mt-2 flex items-center h-10" prefixIcon={<PlusIcon />} onClick={async () => await onAddRow()} />}
    </>
  )
}

export default TableConstruction
