import get from 'lodash/get'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import Upload from '~/components/atoms/Upload'

import { uploadImportApi } from '~/api/project'
import AnnotationIcon from '~/assets/icons/AnnotationIcon'
import AutoGroupIcon from '~/assets/icons/AutoGroupIcon'
import CsvUploadIcon from '~/assets/icons/CsvUploadIcon'
import CusPlusIcon from '~/assets/icons/CusPlusIcon'
import PenIcon from '~/assets/icons/PenIcon'
import TrashIcon from '~/assets/icons/TrashIcon'
import { useAppSelector } from '~/redux/hooks'
import { machines } from '~/redux/reducers/machine.slice'
import { handleError, pushNotification } from '~/utils/helper'

interface ButtonProps {
  isSelectRow: boolean
  selectedRowKeys: any[]
  isLoadingStatus: boolean
  isLoadingReInquiry: boolean
  isLoadingGroup: boolean
  isEditRows?: boolean
  isDeleteRow?: boolean
  setShowAddModal: () => void
  handleUpdateStatus: () => void
  handleAutomaticGroup: () => void
  handleClickGroup: () => void
  handleDeleteRow: () => void
  handleEditRows: () => void
  onSuccessUploadCSV: any
  onReInquiry: any
}

const ButtonForAirCondition = ({
  isSelectRow,
  selectedRowKeys,
  isEditRows,
  isDeleteRow,
  isLoadingStatus,
  isLoadingReInquiry,
  handleUpdateStatus,
  setShowAddModal,
  handleDeleteRow,
  handleEditRows,
  onSuccessUploadCSV,
  onReInquiry,
}: ButtonProps) => {
  const { t } = useTranslation()
  const params = useParams()

  const {
    metaDataTable: { re_inquiry_items = [] },
  } = useAppSelector(machines)

  const isDisabledReInquiryButton = !re_inquiry_items?.length
  const isDisabledInquiryButton = !isSelectRow && selectedRowKeys.length === 0
  const isDisabledAddButton = selectedRowKeys.length !== 0
  const isDisabledEditButton = selectedRowKeys.length === 0 || isDeleteRow
  const isDisabledDeleteButton = selectedRowKeys.length === 0 || isEditRows

  const handleDisplayUploadNotifications = (data: any) => {
    for (const item of data) {
      const { file_name, is_success, total, total_import, errors } = item

      const isSuccess = is_success && total === total_import && errors.length === 0
      const isPartialSuccess = is_success && total > total_import && errors.length > 0
      const isError = !is_success && total === 0 && errors.length > 0

      const renderMessage = () => {
        if (isSuccess) {
          return (
            <>
              <span>{file_name}</span>
              <br />
              <span>CSV取り込みが完了しました</span>
            </>
          )
        } else if (isPartialSuccess) {
          return (
            <>
              <span>{file_name}</span>
              <br />
              {errors.map((error: any, index: number) => (
                <React.Fragment key={index}>
                  <span>{`- ${get(error, 'error_message') || ''}`}</span>
                  {index < errors.length - 1 && <br />}
                </React.Fragment>
              ))}
            </>
          )
        } else if (isError) {
          return (
            <>
              <span>{file_name}</span>
              <br />
              <span>{get(errors, '0.error_message') || ''}</span>
            </>
          )
        }
      }

      pushNotification(renderMessage(), isSuccess ? 'success' : 'error', isSuccess ? 5 : 10)
    }
  }

  return (
    <div className="flex items-center gap-2 h-9">
      <div className="flex items-center h-full">
        <Button
          type="outlined"
          className="flex items-center h-full rounded-l-md rounded-r-none"
          text={t('air_condition.inquiry_rsync')}
          prefixIcon={<AnnotationIcon isDisable={isDisabledReInquiryButton} />}
          disabled={isDisabledReInquiryButton}
          loading={isLoadingReInquiry}
          onClick={onReInquiry}
        />
        <Button
          type="outlined"
          className="flex items-center h-full rounded-r-md rounded-l-none border-l-0"
          text={t('air_condition.inquiry_request')}
          prefixIcon={<AutoGroupIcon isDisable={isDisabledInquiryButton} />}
          disabled={isDisabledInquiryButton}
          loading={isLoadingStatus}
          onClick={handleUpdateStatus}
        />
      </div>

      <div className="flex items-center h-full">
        <Button
          type="outlined"
          className="ml-2 h-full rounded-l-md rounded-r-none"
          text={t('air_condition.add_device')}
          prefixIcon={<CusPlusIcon isDisable={isDisabledAddButton} />}
          disabled={isDisabledAddButton}
          onClick={setShowAddModal}
        />
        <Button
          type="outlined"
          className="machine-edit h-full rounded-none"
          text="項目修正"
          prefixIcon={<PenIcon isDisable={isDisabledEditButton} />}
          disabled={isDisabledEditButton}
          onClick={handleEditRows}
        />
        <Button
          type="outlined"
          className="machine-delete h-full rounded-r-md rounded-l-none border-l-0"
          text="削除"
          prefixIcon={<TrashIcon isDisable={isDisabledDeleteButton} />}
          disabled={isDisabledDeleteButton}
          onClick={handleDeleteRow}
        />
      </div>

      <Upload
        className="h-full ml-2"
        request={{
          url: uploadImportApi,
          params: { estimateId: params.idEstimate },
          onSuccess: (responseData) => {
            if (responseData?.data) handleDisplayUploadNotifications(responseData.data)
            if (onSuccessUploadCSV) onSuccessUploadCSV()
          },
          onError: (error) => pushNotification(handleError(error), 'error', 10),
        }}
        formDataKey="files[]"
        acceptType=".csv"
        multiple
        showUploadList={false}
      >
        <Button type="outlined" className="rounded-md" text={t('air_condition.csv_upload')} prefixIcon={<CsvUploadIcon />} />
      </Upload>
    </div>
  )
}

export default ButtonForAirCondition
