import React from 'react'
import { RouteObject } from 'react-router-dom'

import Detail from '~/components/molecules/Tab'
import PrivateRoute from '~/components/organisms/privateRoute'

import BasicMain from '~/layout/MainLayout'

const PublicRouter: RouteObject = {
  path: '/project/:idProject/:idEstimate',
  element: (
    <PrivateRoute>
      <BasicMain />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      element: <Detail />,
    },
  ],
}

export default PublicRouter
