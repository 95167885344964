import { Modal } from 'antd'
import React from 'react'

interface ModalProps {
  title?: string | null
  okText: string
  cancelText: string
  width?: string
  handleOk: any
  handleClose: any
  isOpen: boolean
  children?: React.ReactElement | undefined
  closeIcon?: React.ReactNode
  closable?: boolean
  confirmLoading?: boolean
  okButtonProps?: any
}

const ModalContainer = ({
  title,
  okText,
  cancelText,
  width,
  handleOk,
  handleClose,
  isOpen,
  children,
  closeIcon,
  closable,
  confirmLoading,
  okButtonProps,
}: ModalProps) => {
  return (
    <>
      <Modal
        open={isOpen}
        okText={okText}
        title={title}
        cancelText={cancelText}
        style={{ height: 'calc(100vh - 200px)' }}
        bodyStyle={{ overflowY: 'hidden' }}
        width={width}
        centered={true}
        onOk={handleOk}
        onCancel={handleClose}
        closeIcon={closeIcon}
        closable={closable}
        confirmLoading={confirmLoading}
        okButtonProps={okButtonProps}
        destroyOnClose={true}
      >
        {children}
      </Modal>
    </>
  )
}

export default ModalContainer
