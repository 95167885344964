import { Form, Spin } from 'antd'
import get from 'lodash/get'
import React, { useMemo, useState } from 'react'

import TypeInputValueFormSecondary from '~/features/airCondition-7/typeInputValueFormSecondary'

const typeInputValueForm = ({
  fieldsLength,
  removeParent,
  remove,
  nameParent,
  indexParent,
  index,
  dataForm,
  form,
  nameGrandpa,
  objectRegistrationId,
  isActualValue = false,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isShowButtonAdd = useMemo(() => {
    const categoryId = get(dataForm, `${indexParent}.groupFormFirst.${index}.groupFormSecondary.0.category`)
    return !!categoryId
  }, [dataForm])

  const handleAddRule = (add) => {
    const res = get(dataForm, `${indexParent}.groupFormFirst.${index}.groupFormSecondary.0`)
    add({
      name: undefined,
      value: '',
      comparative: null,
      eCost: res?.eCost,
      category: res?.category,
      unit: '',
      deviceRuleId: null,
    })
  }

  return (
    <Spin spinning={isLoading}>
      <Form.List name={[nameParent, 'groupFormSecondary']}>
        {(fields, { add, remove: removeChildren }) => (
          <>
            {fields.map(({ key, name, ...restField }, indexChildren) => (
              <TypeInputValueFormSecondary
                indexParent={indexParent}
                key={key}
                nameSuper={nameGrandpa}
                nameParent={name}
                nameGrandpa={nameParent}
                {...restField}
                index={index}
                indexChildren={indexChildren}
                dataForm={dataForm}
                removeParent={removeParent}
                removeChildren={removeChildren}
                remove={remove}
                fieldsLength={fields.length}
                fieldsLengthParent={fieldsLength}
                setIsLoading={setIsLoading}
                form={form}
                objectRegistrationId={objectRegistrationId}
                isActualValue={isActualValue}
                isShowButtonAdd={isShowButtonAdd}
                handleAddRule={() => handleAddRule(add)}
              />
            ))}
          </>
        )}
      </Form.List>
    </Spin>
  )
}

export default typeInputValueForm
