import React from 'react'

const TabIcon = () => {
  return (
    <svg id="Filter" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        id="Path_51191"
        data-name="Path 51191"
        d="M2,5.667h9.333m0,0a2.333,2.333,0,1,0,2.333-2.333A2.333,2.333,0,0,0,11.333,5.667ZM6.667,11.889H16m-9.333,0A2.333,2.333,0,1,1,4.333,9.556,2.333,2.333,0,0,1,6.667,11.889Z"
        transform="translate(3.048 3.259)"
        fill="none"
        stroke="#171717"
      />
      <rect id="Rectangle_8409" data-name="Rectangle 8409" width="24" height="24" fill="none" />
    </svg>
  )
}

export default TabIcon
