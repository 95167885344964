import React from 'react'

const HomeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.455" height="14" viewBox="0 0 12.455 14">
      <path id="home_FILL1_wght600_GRAD0_opsz48" d="M7.3,19.1V9.773L13.555,5.1l6.2,4.673V19.1H14.84V13.794H12.178V19.1Z" transform="translate(-7.3 -5.1)" />
    </svg>
  )
}

export default HomeIcon
