import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'
import { Form, Input } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '~/components/atoms/Button'

import { apiCreateUser } from '~/api/user'
import { awsConfiguration } from '~/config/awsConfiguration'
import { ROUTER_PATH } from '~/consts/router'
import { pushNotification } from '~/utils/helper'

const Register = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [userId, setUserId] = useState<string>('')
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  const changedEmailHandler = (event: any) => setEmail(event.target.value)
  const changedPasswordHandler = (event: any) => setPassword(event.target.value)
  const changedConfirmPasswordHandler = (event: any) => setConfirmPassword(event.target.value)

  const onRegister = () => {
    setIsSubmit(true)
    const userPool = new CognitoUserPool({
      UserPoolId: awsConfiguration.UserPoolId,
      ClientId: awsConfiguration.ClientId,
    })

    if (password !== confirmPassword) {
      pushNotification('パスワードとパスワードの確認が同じではありません', 'error')
    }

    const attributeEmail = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ]

    userPool.signUp(email, password, attributeEmail, [], (err, result: any) => {
      if (err) {
        console.error(err)
        console.log(result)
        return
      }
      setUserId(result?.userSub)
    })
  }

  const addUser = async () => {
    await apiCreateUser({
      user_id: userId,
      email: email,
    })
  }

  useEffect(() => {
    if (isSubmit && userId !== '') {
      addUser()
      navigate(ROUTER_PATH.AUTH.LOGIN)
      navigate(0)
    }
  }, [isSubmit, userId])

  return (
    <div className="justify-center items-center align-center flex h-full">
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{ width: '600px' }}
          className="bg-white flex m-auto flex h-80"
        >
          <div className="m-auto">
            <Form.Item label={t('login.email_address')}>
              <Input onChange={changedEmailHandler} />
            </Form.Item>
            <Form.Item label={t('login.password')}>
              <Input onChange={changedPasswordHandler} />
            </Form.Item>
            <Form.Item label={t('login.confirm_password')}>
              <Input onChange={changedConfirmPasswordHandler} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button htmlType="submit" onClick={onRegister} text={t('login.login_button')} />
            </Form.Item>
          </div>
        </Form>
        <Button type="text" text={t('login.reset_password')} block />
      </div>
    </div>
  )
}

export default Register
