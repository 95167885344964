import Table from 'antd/es/table'
import React, { useState, useMemo } from 'react'

import Button from '~/components/atoms/Button'
import SortIcon from '~/components/atoms/CustomSort'

import EditIconLight from '~/assets/icons/EditLightIcon'
import SettingIcon from '~/assets/icons/SettingIcon'

interface AirConditionProps {
  eCostList: any[]
}

const AirCondition = ({ eCostList }: AirConditionProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const columnsInitial: any = [
    {
      id: 1,
      title: <SortIcon title="ID" />,
      dataIndex: 'id',
      key: 'id',
      width: 90,
    },
    {
      id: 2,
      title: ({ sortColumns }) => <SortIcon title="種別" sortColumns={sortColumns} keyName="category" />,
      dataIndex: 'category',
      key: 'category',
      width: 100,
    },
    {
      id: 3,
      title: ({ sortColumns }) => <SortIcon title="タイプ" sortColumns={sortColumns} keyName="type" />,
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      id: 4,
      title: ({ sortColumns }) => <SortIcon title="数量" sortColumns={sortColumns} keyName="quantity" />,
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
    },
    {
      id: 5,
      title: ({ sortColumns }) => <SortIcon title="協力会社" sortColumns={sortColumns} keyName="company" />,
      dataIndex: 'company',
      key: 'company',
      width: 100,
    },
  ]
  const columnsDemo = useMemo(() => {
    const filteredColumns = eCostList.map((item) => {
      if (item.key === 'eCostType') {
        return {
          id: item.id,
          title: ({ sortColumns }) => <SortIcon title={item.label} sortColumns={sortColumns} keyName={item.label} />,
          key: item.label,
          dataIndex: 'id',
          width: 90,
          isHide: !item.isCheck,
          render(_, record, index) {
            if (index < 4)
              return (
                <div className="flex justify-between justify-center items-center">
                  <span className={record.eCostType === '未選択' ? 'text-slate-300' : 'text-black'}>{record.eCostType}</span>
                  <SettingIcon />
                </div>
              )
          },
        }
      }

      return {
        id: item.id,
        title: ({ sortColumns }) => <SortIcon title={item.label} sortColumns={sortColumns} keyName={item.label} />,
        key: item.key,
        dataIndex: item.key,
        width: 90,
        isHide: !item.isCheck,
      }
    })
    return [
      ...columnsInitial,
      ...filteredColumns,
      {
        id: 9999,
        dataIndex: 'edit',
        key: 'edit',
        render: () => <Button type="text" offFocus prefixIcon={<EditIconLight />} />,
        width: 90,
      },
    ]
      .filter((item) => !item.isHide)
      .sort((a, b) => a.id - b.id)
  }, [eCostList])

  const data = [
    {
      key: 1,
      id: '10221',
      category: 'SGP(白)加工管',
      type: '100t',
      quantity: '100t',
      company: '○○建設',
      unitPrice: '95,100,200',
      aRate: '9.9',
      aUnitPrice: '95,100,200',
      aCost: '95,100,200',
      grossProfitMargin: '9.9',
      submissionAmount: '95,100,200',
      eUnitPrice: '95,100,200',
      eCost: '95,100,200',
      eUnitPriceActual: '95,100,200',
      eCostActual: '95,100,200',
      grossProfitMargin2: '9.9',
      eUnitPriceSetValue: '95,100,200',
      eCostSetValue: '95,100,200',
      eUnitPriceConfirm: '95,100,200',
      eCostFixed: '95,100,200',
      eCostType: '未選択',
      grossProfitMargin4: '9.9',
      grossProfitMargin5: '9.9',
    },
    {
      key: 2,
      id: '10221',
      category: 'SGP(白)加工管',
      type: '100t',
      quantity: '100t',
      company: '○○建設',
      unitPrice: '95,100,200',
      aRate: '9.9',
      aUnitPrice: '95,100,200',
      aCost: '95,100,200',
      grossProfitMargin: '9.9',
      submissionAmount: '95,100,200',
      eUnitPrice: '95,100,200',
      eCost: '95,100,200',
      eUnitPriceActual: '95,100,200',
      eCostActual: '95,100,200',
      grossProfitMargin2: '9.9',
      eUnitPriceSetValue: '95,100,200',
      eCostSetValue: '95,100,200',
      eUnitPriceConfirm: '95,100,200',
      eCostFixed: '95,100,200',
      eCostType: '未選択',
      grossProfitMargin4: '9.9',
      grossProfitMargin5: '9.9',
    },
    {
      key: 3,
      id: '10221',
      category: 'SGP(白)加工管',
      type: '100t',
      quantity: '100t',
      company: '○○建設',
      unitPrice: '95,100,200',
      aRate: '9.9',
      aUnitPrice: '95,100,200',
      aCost: '95,100,200',
      grossProfitMargin: '9.9',
      submissionAmount: '95,100,200',
      eUnitPrice: '95,100,200',
      eCost: '95,100,200',
      eUnitPriceActual: '95,100,200',
      eCostActual: '95,100,200',
      grossProfitMargin2: '9.9',
      eUnitPriceSetValue: '95,100,200',
      eCostSetValue: '95,100,200',
      eUnitPriceConfirm: '95,100,200',
      eCostFixed: '95,100,200',
      eCostType: '未選択',
      grossProfitMargin4: '9.9',
      grossProfitMargin5: '9.9',
    },
    {
      key: 4,
      id: '10221',
      category: 'SGP(白)加工管',
      type: '100t',
      quantity: '100t',
      company: '○○建設',
      unitPrice: '95,100,200',
      aRate: '9.9',
      aUnitPrice: '95,100,200',
      aCost: '95,100,200',
      grossProfitMargin: '9.9',
      submissionAmount: '95,100,200',
      eUnitPrice: '95,100,200',
      eCost: '95,100,200',
      eUnitPriceActual: '95,100,200',
      eCostActual: '95,100,200',
      grossProfitMargin2: '9.9',
      eUnitPriceSetValue: '95,100,200',
      eCostSetValue: '95,100,200',
      eUnitPriceConfirm: '95,100,200',
      eCostFixed: '95,100,200',
      eCostType: '未選択',
      grossProfitMargin4: '9.9',
      grossProfitMargin5: '9.9',
    },
    {
      key: 5,
      id: null,
      category: null,
      type: null,
      quantity: null,
      company: null,
      unitPrice: '合計費',
      aRate: null,
      aUnitPrice: null,
      aCost: '999,999,999',
      grossProfitMargin: null,
      submissionAmount: null,
      eUnitPrice: null,
      eCost: null,
      eUnitPriceActual: '合計費',
      eCostActual: '999,999,999',
      grossProfitMargin2: null,
      eUnitPriceSetValue: null,
      eCostSetValue: null,
      eUnitPriceConfirm: '999,999,999',
      eCostFixed: '999,999,999',
      eCostType: null,
      grossProfitMargin4: null,
      rossProfitMargin5: null,
    },
  ]
  return (
    <div className="w-full">
      <Table
        scroll={{ x: 1000 }}
        tableLayout="fixed"
        rowSelection={rowSelection}
        columns={columnsDemo}
        dataSource={data}
        showSorterTooltip={false}
        pagination={false}
        bordered={true}
        className="render-total-condition-2"
      />
    </div>
  )
}

export default AirCondition
