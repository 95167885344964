// import { CheckboxChangeEvent } from 'antd/es/checkbox'
import React, { useCallback, useState } from 'react'

import FilterRight from '~/components/organisms/MValue/filterRightMValue'

import MValueDetail from '~/features/value/detail'
import ListMValue from '~/features/value/list'

export interface MValue {
  key: number
  typeWork: string
  material: string
  period: string
  branchName: string
  propertyName: string[]
  mode: string[]
  buildingApp: string[]
  supplier: string[]
  amount: string[]
  combinedAmount: string[]
  singleAmount: string[]
  combinedMvalue: string[]
}

const rowData: MValue[] = [
  {
    key: 1,
    typeWork: 'ダクト',
    material: '亜鉛...',
    period: '139',
    branchName: '名古屋',
    propertyName: ['△△実験棟新築', '電算センター新築'],
    mode: ['新築', '新築'],
    buildingApp: ['工場', '情報通信施設'],
    supplier: ['XXXX', 'YYYY', 'XXXX', 'YYYY'],
    amount: ['1610.9', '1085.2', '1610.9', '1085.2'],
    combinedAmount: ['12,120', '8,445', '12,120', '8,445'],
    singleAmount: ['10,194', '6.367', '10,194', '6.367'],
    combinedMvalue: ['7.524', '8,445', '7.524', '8,445'],
  },
  {
    key: 2,
    typeWork: 'ダクト',
    material: '亜鉛...',
    period: '139',
    branchName: '名古屋',
    propertyName: ['△△実験棟新築', '電算センター新築'],
    mode: ['新築', '新築'],
    buildingApp: ['工場', '情報通信施設'],
    supplier: ['XXXX', 'YYYY', 'XXXX', 'YYYY'],
    amount: ['1610.9', '1085.2', '1610.9', '1085.2'],
    combinedAmount: ['12,120', '8,445', '12,120', '8,445'],
    singleAmount: ['10,194', '6.367', '10,194', '6.367'],
    combinedMvalue: ['7.524', '8,445', '7.524', '8,445'],
  },
  {
    key: 3,
    typeWork: 'ダクト',
    material: '亜鉛...',
    period: '139',
    branchName: '名古屋',
    propertyName: ['△△実験棟新築', '電算センター新築'],
    mode: ['新築', '新築'],
    buildingApp: ['工場', '情報通信施設'],
    supplier: ['XXXX', 'YYYY', 'XXXX', 'YYYY'],
    amount: ['1610.9', '1085.2', '1610.9', '1085.2'],
    combinedAmount: ['12,120', '8,445', '12,120', '8,445'],
    singleAmount: ['10,194', '6.367', '10,194', '6.367'],
    combinedMvalue: ['7.524', '8,445', '7.524', '8,445'],
  },
  {
    key: 4,
    typeWork: 'ダクト',
    material: '亜鉛...',
    period: '139',
    branchName: '名古屋',
    propertyName: ['△△実験棟新築', '電算センター新築'],
    mode: ['新築', '新築'],
    buildingApp: ['工場', '情報通信施設'],
    supplier: ['XXXX', 'YYYY', 'XXXX', 'YYYY'],
    amount: ['1610.9', '1085.2', '1610.9', '1085.2'],
    combinedAmount: ['12,120', '8,445', '12,120', '8,445'],
    singleAmount: ['10,194', '6.367', '10,194', '6.367'],
    combinedMvalue: ['7.524', '8,445', '7.524', '8,445'],
  },
]

const MValue = () => {
  const [isDetailMValue, setIsDetailMValue] = useState(false)
  const handleOnClickGraph = useCallback((detail) => {
    setIsDetailMValue(detail)
  }, [])

  return (
    <div className="flex flex-row w-full h-full">
      <div className="flex flex-col w-13/16 items-center h-full">
        {isDetailMValue ? <MValueDetail handleOnClickGraph={handleOnClickGraph} /> : <ListMValue rowData={rowData} handleOnClickGraph={handleOnClickGraph} />}
      </div>
      <div className="filter flex w-3/16 bg-white h1000 justify-center">
        <FilterRight />
      </div>
    </div>
  )
}

export default MValue
