import React from 'react'

const GraphIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_8859" data-name="Group 8859" transform="translate(-1527 -200)">
        <g id="_Color" data-name="↳ 🎨 Color" transform="translate(1527 200)" fill="#fff" stroke="rgba(0,0,0,0.12)" strokeMiterlimit="10" strokeWidth="1">
          <rect width="32" height="32" rx="4" stroke="none" />
          <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="none" />
        </g>
        <g id="Group_8858" data-name="Group 8858" transform="translate(1530.646 204)">
          <path id="Path_50487" data-name="Path 50487" d="M3,3V20a1,1,0,0,0,1,1H21V19H5V3Z" />
          <path
            id="Path_50488"
            data-name="Path 50488"
            d="M15.293,14.707a1,1,0,0,0,1.414,0l5-5L20.293,8.293,16,12.586l-2.293-2.293a1,1,0,0,0-1.414,0l-5,5,1.414,1.414L13,12.414Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default GraphIcon
