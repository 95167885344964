import React from 'react'

interface ArrowUpAndDownProps {
  width?: string
  height?: string
  color?: string
}

const ArrowUpAndDown = (props: ArrowUpAndDownProps) => {
  const width = props.width ?? '16'
  const height = props.height ?? '32'
  const color = props.color ?? '#000'

  return (
    <svg className="sort-icon" width={width} height={height} viewBox="0 0 16 17" fill="none">
      <path
        d="M4.19196 3.11261L1.52862 5.77928L1.47262 5.84261C1.37321 5.97096 1.32404 6.13117 1.33435 6.29319C1.34466 6.45521 1.41374 6.6079 1.52862 6.72261L1.59196 6.77794C1.72031 6.87736 1.88052 6.92652 2.04254 6.91621C2.20456 6.9059 2.35725 6.83682 2.47196 6.72194L3.99996 5.19061V12.9173L4.00462 12.9953C4.02358 13.1576 4.10147 13.3072 4.22351 13.4159C4.34555 13.5245 4.50323 13.5846 4.66662 13.5846L4.74462 13.5799C4.90691 13.5608 5.05651 13.4828 5.16504 13.3606C5.27356 13.2384 5.33343 13.0807 5.33329 12.9173V5.19528L6.86196 6.72194L6.92529 6.77794C7.0593 6.88282 7.22835 6.93248 7.39779 6.91675C7.56723 6.90102 7.72425 6.82109 7.83666 6.69334C7.94907 6.56558 8.00838 6.39967 8.00242 6.22961C7.99647 6.05954 7.9257 5.89819 7.80462 5.77861L5.13396 3.11194L5.07129 3.05661C4.94294 2.95719 4.78273 2.90803 4.62071 2.91834C4.45869 2.92865 4.30667 2.99773 4.19196 3.11261Z"
        fill={color}
      />
      <path
        d="M11.8107 13.3889L14.4741 10.7222L14.5301 10.6589C14.6295 10.5305 14.6786 10.3703 14.6683 10.2083C14.658 10.0463 14.5889 9.89357 14.4741 9.77886L14.4107 9.72352C14.2824 9.62411 14.1222 9.57494 13.9601 9.58525C13.7981 9.59556 13.6454 9.66464 13.5307 9.77952L12.0027 11.3109V3.58419L11.9981 3.50619C11.9791 3.3439 11.9012 3.19423 11.7792 3.08558C11.6571 2.97694 11.4995 2.9169 11.3361 2.91686L11.2581 2.92152C11.0958 2.94064 10.9462 3.01869 10.8377 3.14086C10.7291 3.26302 10.6693 3.42079 10.6694 3.58419V11.3062L9.14073 9.77952L9.0774 9.72352C8.94338 9.61864 8.77434 9.56898 8.6049 9.58471C8.43545 9.60044 8.27844 9.68037 8.16603 9.80813C8.05361 9.93588 7.99431 10.1018 8.00026 10.2719C8.00622 10.4419 8.07699 10.6033 8.19806 10.7229L10.8687 13.3895L10.9314 13.4449C11.0597 13.5443 11.22 13.5934 11.382 13.5831C11.544 13.5728 11.696 13.5037 11.8107 13.3889Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowUpAndDown
