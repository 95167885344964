import React from 'react'

const ArrowUpIcon = () => {
  return (
    <svg id="icon-navigation-arrow_drop_up_24px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Boundary" width="24" height="24" fill="none" />
      <path d="M0,5,5,0l5,5Z" transform="translate(7 9)" fill="#737373" />
    </svg>
  )
}

export default ArrowUpIcon
