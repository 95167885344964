import { Row, Col } from 'antd'
import React, { useState } from 'react'

import EstimateDocumentEdit from '~/components/organisms/EstimateDocument/EstimateDocumentEdit'
import FilterLeft from '~/components/organisms/EstimateDocument/FilterLeftEstimate'
import PreviewDocument from '~/components/organisms/EstimateDocument/PreviewDocument'

import '~/assets/styles/feature/estimateDocument.scss'

const EstimateDocument = () => {
  const [mode, setMode] = useState<string>('')
  return (
    <Row className="h-full filter-left ">
      <Col className="w-80 border-r px-6 py-4" span={5}>
        <FilterLeft setMode={setMode} mode={mode} />
      </Col>
      <Col span={19} className="estimate-content">
        {mode === 'edit' ? <EstimateDocumentEdit /> : <PreviewDocument />}
      </Col>
    </Row>
  )
}

export default EstimateDocument
