import { Button } from 'antd'
import React from 'react'

import TreeDataComponent from '~/components/atoms/EstimateDocument/TreeDataComponent'

import ArrowRight from '~/assets/icons/ArrowRightIcon'

interface FilterLeftProps {
  setMode: any
  mode: string
}
const FilterLeft = ({ setMode, mode }: FilterLeftProps) => {
  const handleEdit = () => {
    setMode('edit')
  }
  const handleSelectedPreview = () => {
    setMode('preview')
  }
  return (
    <>
      <Button className="bg-white w-full h-16 flex justify-between px-4 rounded-md items-center text-sm text-[#171717] cursor-auto border" onClick={handleEdit}>
        <span>見積書表紙編集</span>
        <span>
          <ArrowRight />
        </span>
      </Button>
      <div className="mt-6 ">
        <div className="py-3 border-b">プレビュー</div>
        <div className="h-[640px] overflow-y-auto mt-3 p-2 text-sm ">
          <TreeDataComponent onSelectDocument={handleSelectedPreview} mode={mode} />
        </div>
      </div>
    </>
  )
}

export default FilterLeft
