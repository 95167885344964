import React from 'react'
import { Trans } from 'react-i18next'

import ArrowUpAndDown from '~/assets/icons/ArrowUpAndDown'

interface IconProps {
  title?: string
  sortColumns?: any
  keyName?: string
  justifyClassName?: string
}

const SortIconTable = ({ title, sortColumns, keyName, justifyClassName = 'justify-center' }: IconProps) => {
  const renderColor = () => {
    if (sortColumns) {
      return sortColumns?.find(({ column }) => column.key !== keyName) ||
        (sortColumns?.find(({ column }) => column.key === keyName) && !sortColumns?.find(({ column }) => column.key === keyName)?.order)
        ? '#D4D4D4'
        : '#000000'
    }

    return '#D4D4D4'
  }

  return (
    <div className={`space-x-2 flex items-center ${justifyClassName}`}>
      <Trans parent="p" i18nKey={title} className="truncate" />
      <ArrowUpAndDown color={renderColor()} />
    </div>
  )
}

export default SortIconTable
