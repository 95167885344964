import React from 'react'

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 21 19" className="ml-2">
      <path
        d="M5.66833 14.6665C5.495 14.6665 5.32833 14.5999 5.195 14.4732C4.935 14.2132 4.935 13.7932 5.195 13.5332L10.7283 7.99988L5.195 2.47321C4.935 2.21321 4.935 1.78654 5.195 1.52654C5.455 1.26654 5.88167 1.26654 6.14167 1.52654L12.1417 7.52654C12.4017 7.78654 12.4017 8.20654 12.1417 8.46654L6.14167 14.4732C6.00833 14.6065 5.84167 14.6665 5.66833 14.6665Z"
        fill="#171717"
      />
    </svg>
  )
}

export default ArrowRight
