export const ROUTER_PATH = {
  AUTH: {
    LOGIN: '/login',
    RESET: '/reset',
    CHANGE_PASSWORD: '/change-password',
  },
  PROJECT: {
    LIST: '/',
    DETAIL: '/project/:idProject/:idEstimate',
    LIST_ESTIMATE: '/list-estimate/:id',
    LIST_ESTIMATE_2: '/list-estimate/item',
    CREATE_PROJECT: '/create-project',
  },
}
