import React from 'react'

const CheckIcon = () => {
  return (
    <svg id="icon-action-check_circle_24px" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect id="Boundary" width="16" height="16" fill="none" />
      <path id="_Color" data-name=" ↳Color" d="M3.818,7.383.955,4.458,0,5.433l3.818,3.9L12,.975,11.045,0Z" transform="translate(2 3.333)" fill="#3ca4ff" />
    </svg>
  )
}

export default CheckIcon
