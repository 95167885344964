import React from 'react'
import { RouteObject } from 'react-router-dom'

import PrivateRoute from '~/components/organisms/privateRoute'

import CreateProject from '~/features/project/create'
import ProjectList from '~/features/project/list'
import ListEstimate from '~/features/project/listEstimate'
import ListEstimateDetail from '~/features/project/listEstimateDetail'
import MValue from '~/features/value'
import BasicLayout from '~/layout/BasicLayout/index'

const PublicRouter: RouteObject = {
  path: '',
  element: (
    <PrivateRoute>
      <BasicLayout />
    </PrivateRoute>
  ),
  children: [
    {
      index: true,
      path: '',
      element: <ProjectList />,
    },
    {
      index: true,
      path: '/create-project',
      element: <CreateProject />,
    },
    {
      index: true,
      path: '/list-estimate/:id',
      element: <ListEstimate />,
    },
    {
      index: true,
      path: '/list-estimate/item',
      element: <ListEstimateDetail />,
    },

    {
      index: true,
      path: '/m-value',
      element: <MValue />,
    },
  ],
}

export default PublicRouter
