import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Table, Typography } from 'antd'
import { cloneDeep, isNil, omit } from 'lodash'
import React, { useCallback, useMemo, useState, useRef, useLayoutEffect, useEffect } from 'react'
import { VList } from 'virtuallist-antd'

import Scrollbar from '~/components/atoms/Scrollbar'

import { CONTENT_TABLE_WIDTH, FIXED_COLUMN_WIDTH, DEFAULT_THUMB_WIDTH, TABLE_MACHINE_VALUE } from '~/consts/scrollbar'
import { showTotalPaginationTable } from '~/utils/helper'

import EditableCell from './EditableCell'
import ResizableTitle from './ResizableTitle'

const virtualizedTableHeight = 500

const VComponents = VList({ height: virtualizedTableHeight, resetTopWhenDataChange: false })

interface TableMachineProps {
  id?: string
  columnsDefine: any[]
  data: any[]
  total: number
  from: number
  to: number
  per_page: number
  current_page: number
  loading: boolean
  editingKey?: any
  rowSelection?: any
  usingVirtualized?: boolean
  handleBlurInput?: any
  handleAction?: any
  renderCell?: any
  setFieldValue?: any
  activeRecordId?: string
}

const TableMachine = ({
  id,
  current_page,
  columnsDefine,
  data,
  total,
  to,
  from,
  per_page,
  loading,
  editingKey,
  rowSelection,
  usingVirtualized = false,
  handleBlurInput,
  handleAction,
  renderCell,
  setFieldValue,
  activeRecordId,
  ...rest
}: TableMachineProps) => {
  const { Text } = Typography

  const [columns, setColumns] = useState<any[]>(columnsDefine || [])
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const tableBodyRef = useRef(null)

  useEffect(() => {
    tableBodyRef.current = document.querySelector(`.table-scroll_${id} .ant-table-body`) as any
  })

  const handleResize = useCallback(
    (index: number, parentIndex: number) => {
      return (size: { width: number; height: number }) => {
        const getNewColumns = (prevColumns: any[]) => {
          const newColumns = cloneDeep(prevColumns)

          const currentColumn = newColumns[parentIndex]?.children[index]
          const sourceCurrentColumn = columnsDefine[parentIndex]?.children[index]

          const getMinWidth = currentColumn?.className?.match(/(\d+)/)
          const valueMin = getMinWidth ? getMinWidth[0] : null

          if (currentColumn) {
            newColumns[parentIndex].children[index] = {
              ...currentColumn,
              width: size.width,
              title: size.width <= 12 ? '' : sourceCurrentColumn?.title,
              render: size.width < +valueMin && !['status', 'rule_type'].includes(currentColumn.dataIndex) ? null : sourceCurrentColumn.render,
            }
          }

          return newColumns
        }

        setColumns(getNewColumns)
      }
    },
    [columns]
  )

  const getColumnRenderer = (col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => {
        const recordRemove = record ? omit(record, ['end', 'parentEnd', 'e_NO_rate', 'a0_NO_rate']) : record
        if (
          record?.id?.toString()?.includes('-parent') &&
          !['used_a0_mul_rate', 'a0_gross_profit_rate', 'a0_no_rate', 'e_gross_profit_rate'].includes(col.dataIndex)
        ) {
          return recordRemove
        }

        let formItemName = `${col.dataIndex}&${record['id']}&${record['index']}`
        if (col.dataIndex === 'quantity' && ['quantity-1', 'quantity-2', 'quantity-3'].includes(col.key)) {
          formItemName = `${col.key}&${record['id']}&${record['index']}`
        }

        const handleBlur = () => {
          const titleDataIndex = () => {
            switch (col.dataIndex) {
              case 'used_a0_mul_rate':
                return 'a0_mul_rate'
              case 'a0_no_rate':
                return 'a0_NO_rate'
              case 'e_no_rate':
                return 'e_NO_rate'
              default:
                return col.dataIndex
            }
          }
          if (handleBlurInput) handleBlurInput(record, titleDataIndex())
        }

        return {
          record,
          inputType:
            col.dataIndex === 'quantity'
              ? 'numberSuffix'
              : [
                  'list_price',
                  'used_a0_mul_rate',
                  'a0_gross_profit_rate',
                  'a0_no_rate',
                  'offer_unit_cost',
                  'offer_amount',
                  'e_cost',
                  'e_gross_profit_rate',
                ].includes(col.dataIndex)
              ? 'number'
              : 'text',
          dataIndex: col.dataIndex,
          formItemName,
          suffix: col.dataIndex === 'quantity' ? record.unit : '',
          title: col.title,
          editing: editingKey.includes(record.id),
          setFieldValue,
          onBlur: handleBlur,
          VirtualizedCell: usingVirtualized ? VComponents.body.cell : null,
        }
      },
    }
  }

  const getHeaderCell = (column: any, index: number, parentIndex: number | null) => {
    const getMinWidth = column.className?.match(/(\d+)/)
    const valueMin = getMinWidth ? getMinWidth[0] : null
    return {
      width: column.width || +valueMin,
      onResize: !isNil(parentIndex) ? (handleResize(index, parentIndex) as React.ReactEventHandler<any>) : null,
      onDrag: setIsDragging,
    }
  }

  const mergedColumns = useMemo(() => {
    const getColumns = (cols: any[], parentIndex: number | null = null) => {
      return cols.map((col: any, index: number) => {
        if (col.children) {
          col.children = getColumns(col.children, index)
        }
        const columnRender = getColumnRenderer(col)
        const headerCellProps = getHeaderCell(columnRender, index, parentIndex)
        return { ...columnRender, onHeaderCell: () => headerCellProps }
      })
    }

    return getColumns(columns)
  }, [columns, editingKey, handleBlurInput, handleResize, setFieldValue])

  const summaryTable = useCallback(() => {
    return renderCell ? (
      <>
        {data.length !== 0 && (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={4} className="pr-10 font-normal text-[#171717] text-end bg-neutral-100 summary-title">
              <Text>合計</Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4} colSpan={7} className="summary-col-span" />
            {renderCell}
            <Table.Summary.Cell index={24} colSpan={5} className="summary-col-span" />
          </Table.Summary.Row>
        )}
      </>
    ) : null
  }, [data.length, renderCell])

  const tableComponents = usingVirtualized
    ? {
        ...VComponents,
        header: {
          cell: ResizableTitle,
        },
        body: {
          ...VComponents.body,
          cell: EditableCell,
        },
      }
    : {
        header: {
          cell: ResizableTitle,
        },
        body: {
          cell: EditableCell,
        },
      }

  const getRowClassName = React.useCallback(
    (item: any, _index) => {
      return item?.id === activeRecordId ? 'editable-row-active' : 'editable-row'
    },
    [activeRecordId]
  )

  return (
    <>
      <Table
        id={id}
        components={tableComponents}
        className={`table-scroll_${id} ${isDragging ? 'dragging-table' : ''}`}
        scroll={usingVirtualized ? { x: '100%', y: virtualizedTableHeight } : { x: 600, y: '100%' }}
        tableLayout="fixed"
        rowSelection={rowSelection}
        columns={mergedColumns}
        dataSource={data}
        showSorterTooltip={false}
        rowKey={(record: any) => record.id}
        loading={loading}
        onChange={handleAction}
        rowClassName={getRowClassName}
        pagination={{
          position: ['topRight'],
          total: total ? total + Math.ceil(total / per_page) : 0,
          current: current_page || 1,
          showTotal: () => <span className="flex flex-wrap items-center">{showTotalPaginationTable(from || 1, to || 1, total || 0)}</span>,
          pageSize: per_page && per_page + 1,
        }}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            const checkRenderSpan = record.parentEnd?.split('-').map((item, index) => {
              if (index > 1 && !item.includes('end')) {
                return <span key={index} className={`child-${index}`} />
              } else {
                return null
              }
            })
            return (
              <>
                {checkRenderSpan ? (
                  <div className="flex">
                    <span className={`level-${record.index?.split('-')?.length}`}>
                      {checkRenderSpan}
                      {record.index?.length > 1 && <span className={`${record.end ? 'LMark' : 'xMark'}`} />}
                    </span>
                    <span className="flex items-center mr-2 mb-2">
                      {record.children?.length > 0 ? (
                        <span onClick={(e) => onExpand(record, e)}>{expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}</span>
                      ) : (
                        <span className="block w-3">&nbsp;</span>
                      )}
                    </span>
                  </div>
                ) : null}
              </>
            )
          },
        }}
        summary={summaryTable}
        {...rest}
      />
      <Scrollbar
        contentRef={tableBodyRef}
        defaultContentWidth={CONTENT_TABLE_WIDTH}
        defaultFixedWidth={FIXED_COLUMN_WIDTH}
        defaultThumbWidth={DEFAULT_THUMB_WIDTH}
        fixedTableValue={TABLE_MACHINE_VALUE}
      />
    </>
  )
}

export default React.memo(TableMachine)
