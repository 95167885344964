import { Tooltip } from 'antd'
import React, { cloneElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { className } from '~/utils/stringUtils'

interface SidebarMenuProps {
  items: any[]
  selectedItemId: string
  onChange: (value: string) => void
}

const SidebarMenu = ({ items, selectedItemId, onChange }: SidebarMenuProps) => {
  const { t } = useTranslation()
  const [hoveredItemId, setHoveredItemId] = useState(null)

  const hoveredBoxStyle = 'hover:bg-[#009BE0] hover:text-[#FFFFFF] hover:cursor-pointer'

  const handleClickItem = (id: string) => {
    onChange(id)
  }

  return (
    <div className="flex flex-col gap-3 px-3 py-6">
      {items.map((item) => {
        const isNotImplemented = ['4', '5'].includes(item.id)

        const boxStyle = item.id === selectedItemId ? 'bg-[#009BE0] text-[#FFFFFF]' : 'text-[#171717]'
        const isActiveIcon = item.id === hoveredItemId || item.id === selectedItemId
        const currentNotImplemented = isNotImplemented ? '!bg-transparent !text-[#A3A3A3] !cursor-default' : ''

        return (
          <Tooltip key={item.id} placement="right" title={isNotImplemented ? t('common.current_not_implemented') : null}>
            <div
              className={className(
                'flex flex-col justify-center gap-1 ',
                'items-center text-center p-2 rounded-lg',
                `${boxStyle} ${isNotImplemented ? currentNotImplemented : hoveredBoxStyle}`
              )}
              onMouseEnter={() => setHoveredItemId(item.id)}
              onMouseLeave={() => setHoveredItemId(null)}
              onClick={isNotImplemented ? () => null : () => handleClickItem(item.id)}
            >
              {cloneElement(item.icon, { active: isNotImplemented ? false : isActiveIcon, isNotImplemented: isNotImplemented })}
              <span className={`text-xs w-[35px] ${isNotImplemented ? 'text-[#A3A3A3]' : ''}`}>{item.text}</span>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default SidebarMenu
