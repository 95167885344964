import React from 'react'

interface CsvUploadIconProps {
  isDisable?: boolean
}

const CsvUploadIcon = ({ isDisable }: CsvUploadIconProps) => {
  const color = isDisable ? '#A3A3A3' : '#009BE0'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <g clipPath="url(#clip0_10617_73722)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.48433 0.711914C2.64433 0.711914 1.151 2.20525 1.151 4.04525V12.0452C1.151 13.8852 2.64433 15.3786 4.48433 15.3786H8.94433C9.311 15.3786 9.611 15.0786 9.611 14.7119C9.611 14.3452 9.311 14.0452 8.94433 14.0452H4.48433C3.37767 14.0452 2.48433 13.1519 2.48433 12.0452V4.04525C2.48433 2.93858 3.37767 2.04525 4.48433 2.04525H12.4843C13.591 2.04525 14.4843 2.93858 14.4843 4.04525V8.27191C14.4843 8.63858 14.7843 8.93858 15.151 8.93858C15.5177 8.93858 15.8177 8.63858 15.8177 8.27191V4.04525C15.8177 2.20525 14.3243 0.711914 12.4843 0.711914H4.48433Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.191 15.378C12.8243 15.378 12.5243 15.078 12.5243 14.7114V12.398L11.7043 13.218C11.4443 13.478 11.0243 13.478 10.7643 13.218C10.5043 12.958 10.5043 12.538 10.7643 12.278L12.7243 10.318C12.9843 10.058 13.4043 10.058 13.6643 10.318L15.6243 12.278C15.8843 12.538 15.8843 12.958 15.6243 13.218C15.3643 13.478 14.9443 13.478 14.6843 13.218L13.8643 12.398V14.7114C13.8643 15.078 13.5643 15.378 13.1977 15.378H13.191Z"
          fill={color}
        />
        <path
          d="M5.96438 8.63151C5.74438 8.87151 5.51771 8.98484 5.24438 8.98484C4.97104 8.98484 4.73771 8.87818 4.57771 8.66484C4.40438 8.43818 4.31104 8.09818 4.31104 7.68484C4.31104 7.27151 4.40438 6.95151 4.59104 6.71818C4.75771 6.50484 4.99104 6.39818 5.25771 6.39818C5.49104 6.39818 5.69104 6.49151 5.88438 6.69151L6.03771 6.85151L6.63104 6.15818L6.51104 6.02484C6.18438 5.67151 5.72438 5.46484 5.25104 5.46484C4.69771 5.46484 4.20438 5.67818 3.84438 6.07151C3.47771 6.47818 3.27771 7.04484 3.27771 7.71151C3.27771 8.37818 3.47104 8.93818 3.83104 9.33818C4.17771 9.72484 4.67104 9.93151 5.22438 9.93151C5.77771 9.93151 6.23104 9.71818 6.59771 9.29818L6.71104 9.17151L6.12438 8.47818L5.97104 8.64484L5.96438 8.63151Z"
          fill={color}
        />
        <path
          d="M12.7177 5.52539L12.0977 7.70539C12.0444 7.89206 11.9977 8.05872 11.9511 8.23206V8.25872C11.9511 8.25872 11.9244 8.32539 11.9177 8.35206C11.8644 8.14539 11.8044 7.93872 11.7444 7.71872L11.1177 5.52539H10.0377L11.3977 9.85872H12.4244L13.7777 5.52539H12.7244H12.7177Z"
          fill={color}
        />
        <path
          d="M9.23104 7.33801L8.69104 7.10467C8.54437 7.04467 8.39771 6.98467 8.29771 6.91134C8.21104 6.84467 8.17771 6.78467 8.17771 6.69134C8.17771 6.59801 8.21104 6.53801 8.2777 6.48467C8.3577 6.42467 8.48437 6.39134 8.6377 6.39134C8.91104 6.39134 9.13771 6.48467 9.37104 6.69134L9.52437 6.83134L10.0977 6.13801L9.97104 6.01134C9.62437 5.65801 9.1377 5.45801 8.6377 5.45801C7.79104 5.45801 7.14437 6.01134 7.14437 6.73801C7.14437 7.27801 7.46437 7.69801 8.06437 7.95134L8.61104 8.18467C8.78437 8.25801 8.91771 8.32467 9.01104 8.39134C9.0777 8.44467 9.1177 8.50467 9.1177 8.62467C9.1177 8.73134 9.0777 8.81134 9.00437 8.86467C8.9377 8.91801 8.81104 8.97801 8.57771 8.97801C8.26437 8.97801 7.92437 8.82467 7.67104 8.57134L7.51771 8.41801L6.89771 9.15134L7.02437 9.28467C7.42437 9.69134 7.98437 9.91801 8.56437 9.91801C9.47771 9.91801 10.1444 9.35134 10.1444 8.57801C10.1444 8.25801 10.051 7.99134 9.87104 7.77801C9.71771 7.59801 9.5177 7.45801 9.22437 7.33134L9.23104 7.33801Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10617_73722">
          <rect width="16" height="16" fill="white" transform="translate(0.484375 0.0449219)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CsvUploadIcon
