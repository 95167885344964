import { EChartsOption, SetOptionOpts, ECharts , init, getInstanceByDom } from 'echarts'
import * as echarts from 'echarts'
import * as ecStat from 'echarts-stat'
import React, { useRef, useEffect } from 'react'

export interface ReactEChartsProps {
  option?: EChartsOption
  settings?: SetOptionOpts
  loading?: boolean
  theme?: 'light' | 'dark'
  dataChart: any[]
  dimensionsChart: []
}

echarts.registerTransform(ecStat['transform'].regression)

const ChartLineCommon = ({ option, theme, dataChart, dimensionsChart }: ReactEChartsProps) => {
  const chartRef = useRef(null)

  option = {
    dataset: [
      {
        source: dataChart,
        dimensions: dimensionsChart,
      },
      {
        transform: {
          type: 'ecStat:regression',
          config: { method: 'polynomial', order: 3 },
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      showContent: false,
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
      },
    },
    grid: {
      left: '5%',
      right: '4%',
      bottom: '8%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      max: 90,
      min: 0,
      interval: 10,
      name: '数量（ton/m/m2）',
      nameLocation: 'middle',
      nameGap: 28,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} 円',
        align: 'right',
      },
      max: 1400000,
      min: 0,
      name: '単体M値（円/ton）（円/m）（円/m2）',
      nameLocation: 'middle',
      nameTextStyle: {
        verticalAlign: 'middle',
      },
      nameGap: 90,
      interval: 100000,
    },
    series: [
      {
        name: 'line',
        type: 'line',
        smooth: true,
        datasetIndex: 1,
        symbolSize: 0.1,
        symbol: 'circle',
        label: { show: false, fontSize: 16 },
        labelLayout: { dx: -20 },
        encode: {
          x: 'Category',
          y: 'Value2',
        },
        lineStyle: {
          color: '#9e9e9e',
        },
      },
      {
        name: 'scatter',
        type: 'scatter',
        symbol: 'circle',
        symbolSize: () => {
          return 10
        },
        itemStyle: {
          color: 'transparent',
          borderWidth: 1,
          borderColor: '#EACE37',
        },
        // emphasis: {
        //   focus: 'series',
        //   blurScope: 'coordinateSystem',
        // },
      },
    ],
  }
  useEffect(() => {
    let chart: ECharts | undefined
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme)
    }
    return () => {
      chart?.dispose()
    }
  }, [theme])
  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart?.setOption({ ...option }, true, true)
      chart?.resize()
    }
  }, [])

  return (
    <div className="App">
      <div className="relative w-[600px]  h-[600px] px-5" ref={chartRef} />
    </div>
  )
}

export default React.memo(ChartLineCommon)
