import { Tree } from 'antd'
import React, { useState, useEffect } from 'react'

import type { DataNode } from 'antd/es/tree'

interface TreeDataComponentProps {
  onSelectDocument: (keySelect) => void
  mode: string
}
const treeData: DataNode[] = [
  {
    title: '表紙',
    key: 'cover',
  },
  {
    title: '見積集計表',
    key: 'spreadsheet',
  },
  {
    title: '1   空気調和設備工事',
    key: '0-0',
    children: [
      {
        title: '1-1   空調機器設備工事',
        key: '0-0-0',
      },
      {
        title: '1-2   空調ダクト設備工事',
        key: '0-0-1',
      },
      {
        title: '1-3   配管設備工事',
        key: '0-0-2',
        children: [
          { title: '1-3-1   給水配管設備工事', key: '0-0-2-0' },
          { title: '1-3-2   排水配管設備工事', key: '0-0-2-1' },
          { title: '1-3-3   冷媒配管設備工事', key: '0-0-2-2' },
        ],
      },
      {
        title: '1-4   換気設備工事',
        key: '0-0-3',
        children: [
          { title: '1-4-1   換気機器設備工事', key: '0-0-3-0' },
          { title: '1-4-2   換気ダクト設備工事', key: '0-0-3-1' },
        ],
      },
      {
        title: '1-5   排煙設備工事',
        key: '0-0-4',
        children: [
          { title: '1-5-1   排煙機器設備工事', key: '0-0-4-0' },
          { title: '1-5-2   排煙ダクト設備工事', key: '0-0-4-1' },
        ],
      },
      {
        title: '1-6   自動制御設備工事',
        key: '0-0-5',
      },
    ],
  },
  {
    title: '2  給排水衛生設備工事',
    key: '0-1',
    children: [
      {
        title: '2-1   給水設備工事',
        key: '0-1-0',
        children: [
          {
            title: '2-1-1   屋内給水設備工事',
            key: '0-1-0-0',
          },
          {
            title: '2-1-2   屋外給水設備工事',
            key: '0-1-0-1',
          },
        ],
      },
      { title: '2-2   給湯設備工事', key: '0-1-1' },
      {
        title: '2-3   排水通気設備工事',
        key: '0-1-2',
        children: [
          {
            title: '2-3-1   屋内排水通気設備工事',
            key: '0-1-2-0',
          },
          {
            title: '2-3-2   屋外排水設備工事',
            key: '0-1-2-1',
          },
        ],
      },
      {
        title: '2-4   消火設備工事',
        key: '0-1-3',
        children: [
          {
            title: '2-4-1   屋内消火設備工事',
            key: '0-1-3-0',
          },
        ],
      },
      { title: '2-5   衛生器具設備工事', key: '0-1-4' },
    ],
  },
]
const { DirectoryTree } = Tree

const TreeDataComponent = ({ mode, onSelectDocument }: TreeDataComponentProps) => {
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue)
    onSelectDocument(info?.node?.key)
  }
  useEffect(() => {
    if (mode === 'edit') {
      setSelectedKeys([])
    }
  }, [mode])
  return (
    <DirectoryTree
      className="tree-estimate-document bg-transparent"
      expandAction={false}
      defaultExpandAll={true}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      treeData={treeData}
    />
  )
}

export default TreeDataComponent
