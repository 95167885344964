import { Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { get, isArray } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button from '~/components/atoms/Button'

import { apiGetValueOfCategory } from '~/api/airCondition-7'
import DropDownBoldIcon from '~/assets/icons/ArrowDownBoldIcon'
import DeleteIcon from '~/assets/icons/DeleteIcon'
import PlusIcon from '~/assets/icons/PlusNoBorderIcon'
import { OPTION_COMPARATIVE, OPTION_RULE_TYPE, RULE_CATEGORY } from '~/consts/common'
import { handleError, pushNotification } from '~/utils/helper'
import { className } from '~/utils/stringUtils'

const typeInputValueFormSecondary = ({
  fieldsLength,
  fieldsLengthParent,
  index,
  indexParent,
  indexChildren,
  remove,
  removeParent,
  removeChildren,
  nameParent,
  nameGrandpa,
  nameSuper,
  dataForm,
  setIsLoading,
  form,
  objectRegistrationId,
  isActualValue = false,
  isShowButtonAdd,
  handleAddRule,
  ...restField
}) => {
  const [valueUnit, setValueUnit] = useState<string>('')
  const [valueListOption, setValueListOption] = useState<any[]>([])
  const [isFirst, setIsFirst] = useState(false)

  const { Option } = Select

  const handleRemove = () => {
    if (!(fieldsLength - 1) && !(fieldsLengthParent - 1)) {
      removeParent(indexParent)
      return
    }
    if (!(fieldsLength - 1)) {
      remove(index)
      return
    }
    removeChildren(indexChildren)
  }

  const valueCategory = useMemo(() => {
    const categoryId = get(dataForm, `${indexParent}.groupFormFirst.${index}.groupFormSecondary.0.category`)
    return categoryId ?? null
  }, [dataForm])

  const optionCostTypeFilter = useMemo(() => {
    const res = get(dataForm, `${indexParent}.groupFormFirst`)
    if (res) {
      const data = Array.from(new Set(res.map((item) => get(item, 'groupFormSecondary.0.category'))))
      return OPTION_RULE_TYPE.filter((item) => item.value === valueCategory || !data.includes(item.value))
    }
    return OPTION_RULE_TYPE
  }, [dataForm])
  const renderOptionValue = useCallback(
    (keyValue: string, noBorderRight = false) => (
      <Select placeholder="---" suffixIcon={<DropDownBoldIcon />} className={`form-select-style ${noBorderRight ? 'form-select-style--border-r-0' : ''}`}>
        {valueListOption.map((item: any, index: number) => (
          <Option key={index} value={item[keyValue]}>
            {item.name}
          </Option>
        ))}
      </Select>
    ),
    [valueListOption]
  )

  const renderUnitValue = useMemo(() => {
    if (valueUnit) return valueUnit

    const valueSelected = valueListOption.find((item) =>
      [item.detail_item_registration_id, item.name].includes(
        form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])
      )
    )

    return valueSelected?.unit ?? ''
  }, [valueListOption, form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])])

  const renderInputValue = useMemo(() => {
    const valueSelected = valueListOption.find((item) =>
      [item.detail_item_registration_id, item.name].includes(
        form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])
      )
    )
    if (valueSelected?.value === 0) {
      return (
        <Form.Item name={[nameParent, 'value']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
          <InputNumber
            type="number"
            className="w-full h-10 rounded-l rounded-r-none border-[#A3A3A3] border-r-0 flex items-center input-number-custom flex-row-reverse"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            step="0.1"
            placeholder="数量"
            stringMode
            min={0}
            prefix={renderUnitValue}
          />
        </Form.Item>
      )
    }
    if (isArray(valueSelected?.value)) {
      const comparativeList = [...valueSelected.value]
      return (
        <Form.Item name={[nameParent, 'value']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
          <Select placeholder="---" suffixIcon={<DropDownBoldIcon />} className={`form-select-style form-select-style--border-r-0`}>
            {comparativeList.map((item: any, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )
    }

    return (
      <Form.Item name={[nameParent, 'value']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
        <Input className="text-right w-full h-10 rounded-l rounded-r-none border-[#A3A3A3] border-r-0" placeholder="---" suffix={renderUnitValue} />
      </Form.Item>
    )
  }, [valueListOption, form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])])

  const renderSelectComparative = (comparatives: string[]) => {
    return (
      <Select placeholder="---" suffixIcon={<DropDownBoldIcon />} bordered={false} className="form-select-style form-select-style--rounded-l-0">
        {OPTION_COMPARATIVE.filter((item: any) => comparatives.includes(item.value)).map((item: any) => (
          <Option key={item.id} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    )
  }

  const renderInputComparative = useMemo(() => {
    const comparativeSelected = valueListOption.find((item) =>
      [item.detail_item_registration_id, item.name].includes(
        form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])
      )
    )
    if (isArray(comparativeSelected?.value)) {
      return renderSelectComparative(['eq', 'ne'])
    }
    if (comparativeSelected?.value === 0) {
      return renderSelectComparative(['lt', 'gte'])
    }
    return renderSelectComparative(['eq', 'ne', 'lt', 'gte'])
  }, [valueListOption, form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])])

  const renderOptionComparative = () => {
    switch (valueCategory) {
      case RULE_CATEGORY.CATEGORY_10.value:
        return renderSelectComparative(['lt', 'gte'])
      case RULE_CATEGORY.CATEGORY_20.value:
        return renderSelectComparative(['eq', 'ne'])
      case RULE_CATEGORY.CATEGORY_30.value:
        return renderSelectComparative(['eq', 'ne'])
      case RULE_CATEGORY.CATEGORY_40.value:
        return renderInputComparative
      case RULE_CATEGORY.CATEGORY_50.value:
        return renderSelectComparative(['eq', 'ne'])
      default:
        return null
    }
  }

  const resetValueAndComparative = () => {
    form.setFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'value'], null)
    form.setFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'comparative'], null)
  }

  const getValueOfCategory = async () => {
    try {
      const res = await apiGetValueOfCategory({
        rule_category: valueCategory,
        object_registration_id: objectRegistrationId,
      })
      if (valueCategory === RULE_CATEGORY.CATEGORY_10.value) {
        setValueUnit(res.data.data.unit)
        setValueListOption([])
        return
      }
      setValueUnit('')
      setValueListOption(res.data.data)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (valueCategory) {
      getValueOfCategory()
      if (!isFirst) {
        setIsFirst(true)
        return
      }
      return
    }
    setIsLoading(false)
  }, [valueCategory])

  useEffect(() => {
    if (dataForm) {
      const listInherit = ['category', 'eCost', 'eCostId']
      listInherit.forEach((item) => {
        const originalValue = form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', 0, item])
        form.setFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, item], originalValue)
      })
    }
  }, [dataForm])

  useEffect(() => {
    if (!isFirst) {
      setIsFirst(true)
      return
    }
    resetValueAndComparative()
  }, [
    form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name']),
    form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'category']),
  ])

  useEffect(() => {
    if (!isFirst) {
      setIsFirst(true)
      return
    }
    resetValueAndComparative()
  }, [form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', nameParent, 'name'])])

  const renderInputCategory = () => {
    switch (valueCategory) {
      case RULE_CATEGORY.CATEGORY_10.value:
        return (
          <Form.Item name={[nameParent, 'value']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
            <Input className="text-right h-10 rounded-l rounded-r-none border-[#A3A3A3] border-r-0" placeholder="---" suffix={renderUnitValue} />
          </Form.Item>
        )
      case RULE_CATEGORY.CATEGORY_40.value:
        return renderInputValue
      case RULE_CATEGORY.CATEGORY_20.value:
      case RULE_CATEGORY.CATEGORY_30.value:
      case RULE_CATEGORY.CATEGORY_50.value:
        return (
          <Form.Item name={[nameParent, 'value']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
            {renderOptionValue(valueCategory === RULE_CATEGORY.CATEGORY_30.value ? 'name' : 'id', true)}
          </Form.Item>
        )
      default:
        return null
    }
  }

  const renderForm = () => {
    if (valueCategory)
      return (
        <>
          {valueCategory === RULE_CATEGORY.CATEGORY_40.value ? (
            <>
              <Col span={14} className="pl-2">
                <Form.Item name={[nameParent, 'name']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
                  {renderOptionValue('detail_item_registration_id')}
                </Form.Item>
              </Col>
              <Col span={10} />
            </>
          ) : null}
          <Col span={8} className="pl-2">
            {renderInputCategory()}
          </Col>
          <Col span={6}>
            <Form.Item name={[nameParent, 'comparative']} {...restField} className="mb-2 w-full" rules={[{ required: true, message: '' }]}>
              {renderOptionComparative()}
            </Form.Item>
          </Col>
        </>
      )

    return (
      <>
        <Col span={8} className="pl-2 mb-2">
          <Form.Item name={''} {...restField} className="mb-0 w-full" rules={[{ required: true, message: '' }]}>
            <Input disabled className="text-right h-10 rounded-l rounded-r-none border-[#A3A3A3] border-r-0" placeholder="---" suffix={renderUnitValue} />
          </Form.Item>
        </Col>
        <Col span={6} className="mb-2">
          <Form.Item name={''} {...restField} className="mb-0 w-full" rules={[{ required: true, message: '' }]}>
            <Select disabled placeholder="---" suffixIcon={<DropDownBoldIcon />} className="form-select-style form-select-style--rounded-l-0">
              <Option key={0} value={'---'}>
                ---
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </>
    )
  }

  const renderCol1 = () => {
    const isShowAddRule = fieldsLength === 1 || fieldsLength - 1 === indexChildren

    let containerStyle = ''
    switch (true) {
      case indexChildren === 0 && fieldsLength === 1:
        containerStyle = ''
        break
      case indexChildren === 0 && fieldsLength > 1:
        containerStyle = 'border-b-0 rounded-b-none'
        break
      case fieldsLength - 1 === indexChildren && fieldsLength > 1:
        containerStyle = 'border-t-0 rounded-t-none'
        break
      default:
        containerStyle = 'border-b-0 border-t-0 rounded-t-none rounded-b-none'
    }
    return (
      <Col
        span={16}
        className={className(
          `h-full px-4 ${indexChildren === 0 ? 'pt-3' : ''}`,
          `${valueCategory && isShowAddRule ? 'pb-3' : ''}`,
          `border border-[#D4D4D4] bg-white rounded-lg`,
          `${containerStyle}`
        )}
      >
        <Row>
          <Col span={10} className="h-full">
            {indexChildren === 0 && (
              <Form.Item
                name={[nameParent, 'category']}
                {...restField}
                className={`w-full ${valueCategory ? 'mb-2' : 'mb-0'}`}
                rules={[{ required: true, message: '' }]}
              >
                <Select placeholder="" suffixIcon={<DropDownBoldIcon />} className="form-select-style" options={optionCostTypeFilter} />
              </Form.Item>
            )}
          </Col>
          {renderForm()}
        </Row>
        {valueCategory && isShowAddRule ? (
          <Row className=" h-10 flex justify-end items-center">
            <Col span={10} />
            <Col span={14} className="pl-2">
              {isShowButtonAdd && (
                <Button type="outlined" onClick={handleAddRule} block className="text-center w-full h-10" prefixIcon={<PlusIcon />} text="項目の追加" />
              )}
            </Col>
          </Row>
        ) : null}
      </Col>
    )
  }

  const renderCol2 = () => {
    return (
      <Col span={5} className="">
        <div className={`${indexChildren === 0 ? 'h-[52px]' : 'h-[48px]'} pt-3 px-2`}>
          {index === 0 && indexChildren === 0 ? (
            isActualValue ? (
              <div className="w-full flex items-center justify-end h-full">
                <span className="text-right truncate">
                  {form.getFieldValue(['groupForm', nameSuper, 'groupFormFirst', nameGrandpa, 'groupFormSecondary', 0, 'eCost'])}
                </span>
              </div>
            ) : (
              <Form.Item {...restField} name={[nameParent, 'eCost']} rules={[{ required: true, message: '' }]}>
                <Input className="text-right h-10 rounded border-[#A3A3A3]" placeholder="---" />
              </Form.Item>
            )
          ) : null}
        </div>
      </Col>
    )
  }

  const renderCol3 = () => {
    return (
      <Col span={3} className={`flex justify-center items-center pr-1 ${indexChildren === 0 ? 'h-[52px] pt-3' : 'h-[48px]'}`}>
        <Button className="h-full" onClick={handleRemove} type="text" offFocus prefixIcon={<DeleteIcon />} />
      </Col>
    )
  }

  return (
    <Row className={`${indexChildren === 0 ? 'mt-1' : ''}`} gutter={2}>
      {renderCol1()}
      {renderCol2()}
      {renderCol3()}
    </Row>
  )
}

export default typeInputValueFormSecondary
