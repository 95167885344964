import React from 'react'

const ReloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.127" height="20.003" viewBox="0 0 17.127 20.003">
      <path
        id="Path_50491"
        data-name="Path 50491"
        d="M19.89,10.1A8.7,8.7,0,0,0,19.1,8.649L17.443,9.768a6.56,6.56,0,0,1,.987,4.993,6.5,6.5,0,0,1-.384,1.231,6.4,6.4,0,0,1-.6,1.112,6.58,6.58,0,0,1-4.119,2.762,6.734,6.734,0,0,1-2.646,0A6.562,6.562,0,0,1,5.571,14.76a6.627,6.627,0,0,1,.382-3.877A6.551,6.551,0,0,1,7.36,8.8,6.628,6.628,0,0,1,9.446,7.39a6.539,6.539,0,0,1,1.23-.382c.107-.022.216-.032.324-.049V10l5-4L11,2V4.938q-.365.04-.725.111a8.563,8.563,0,0,0-4.33,14.445A8.564,8.564,0,0,0,19.889,16.77a8.453,8.453,0,0,0,.5-1.608,8.568,8.568,0,0,0-.5-5.057Z"
        transform="translate(-3.436 -2)"
      />
    </svg>
  )
}

export default ReloadIcon
