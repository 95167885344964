import { Typography } from 'antd'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table'
import { cloneDeep } from 'lodash'
import React, { useState, useEffect } from 'react'

import { overViewTitleColor } from '~/interfaces/airCondition'
import { formatNumber } from '~/utils/helper'

import ResizableTitle from './ResizableTitle'

import type { ResizeCallbackData } from 'react-resizable'

interface TableProps {
  tableName: string
  data: any[]
  columns: ColumnsType<any>
  loading?: boolean
  dataSummary: any[]
  isRenderText?: boolean
  renderColorNet?: (key) => overViewTitleColor
  setDataChange?: any
  rowHoverChart?: number
  textTotal?: string
}
const { Text } = Typography
const TableOverview = ({
  data,
  columns,
  loading,
  dataSummary,
  tableName,
  renderColorNet,
  setDataChange,
  rowHoverChart,
  textTotal,
}: TableProps) => {
  const [columnsTable, setColumns] = useState<ColumnsType<any>>(columns || [])
  const [revertDataTotal, setRevertDataTotal] = useState<any>([])
  const [renderCellSummary, setRenderCellSummary] = useState([])
  const [rowSelect, setRowSelect] = useState<any>(null)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const handleColor = (key) => renderColorNet?.(key).bgColor
  const handleRowClassName = (record) => {
    if (record.key === rowHoverChart || record.key === rowSelect?.key) {
      const border = renderColorNet?.(rowHoverChart ?? rowSelect?.key).borderColor
      return `hover-chart border-b-solid ${border}`
    }
    return ''
  }
  const handleMoveRecord = (record = null) => {
    setRowSelect(record)
    setDataChange(record)
  }

  const renderText = (dataTotal, indexItem): { suffix: string; className: string } => {
    switch (tableName) {
      case 'netDataAir':
        return {
          suffix: indexItem === dataTotal.length - 1 ? '%' : '',
          className: indexItem === dataTotal.length - 1 ? 'text-center' : 'text-right',
        }
      case 'costTable':
        return {
          suffix: indexItem === dataTotal.length - 2 ? '%' : indexItem === dataTotal.length - 1 ? '人' : '',
          className: indexItem !== dataTotal.length - 1 && indexItem !== dataTotal.length - 2 ? 'text-right' : 'text-center',
        }
      default:
        return { suffix: '', className: indexItem !== dataTotal.length - 1 && indexItem !== dataTotal.length - 2 ? 'text-right' : 'text-center' }
    }
  }

  const handleResize: any = (index: number) => (_: React.SyntheticEvent<Element>, size: ResizeCallbackData) => {
    if (size) {
      const newColumns = [...columnsTable]
      const getMinWidth = columns[index].className?.match(/(\d+)/)
      const valueMin: string | null = getMinWidth ? getMinWidth[0] : null
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
        title: size.width <= 5 ? '' : columns[index].title,
        render: valueMin && size.width < +valueMin ? null : columns[index].render,
      }
      setColumns(newColumns)
    }
  }
  const mergeColumns: any = columnsTable.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnsType<any>[number]) => {
      const getMinWidth = column.className?.match(/(\d+)/)
      const valueMin: any = getMinWidth ? getMinWidth[0] : null
      return {
        width: column.width || +valueMin,
        minWidth: +valueMin,
        onResize: handleResize(index),
        onDrag: setIsDragging,
      }
    },
  }))

  useEffect(() => {
    if (revertDataTotal.length > 0) {
      if (!isNaN(revertDataTotal[0])) {
        setRevertDataTotal([textTotal, ...revertDataTotal])
      }
      const convertData = revertDataTotal.map((item, index) => {
        if (index > 0) {
          const suffixItem = renderText(revertDataTotal, index).suffix
          const renderItem = index === 1 && !isNaN(item) ? formatNumber(item) : item
          return (
            <Table.Summary.Cell key={index} index={1} className={renderText(revertDataTotal, index).className}>
              <Text className="w-full truncate">{`${renderItem}${suffixItem}`}</Text>
            </Table.Summary.Cell>
          )
        }
      })
      setRenderCellSummary(convertData)
    }
  }, [revertDataTotal])

  useEffect(() => {
    setRevertDataTotal(cloneDeep(dataSummary))
  }, [dataSummary])

  return (
    <Table
      scroll={{ x: '110%' }}
      tableLayout="fixed"
      columns={mergeColumns}
      dataSource={data}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      className={`${isDragging ? 'dragging-table' : ''}`}
      showSorterTooltip={false}
      pagination={false}
      loading={loading}
      rowClassName={(record) => handleRowClassName(record)}
      summary={() => {
        return (
          <>
            {data.length !== 0 && (
              <Table.Summary.Row className="h-12">
                <>
                  <Table.Summary.Cell index={0} className={'bg-neutral-100 border-b-0 text-right font-semibold mr-3'} />
                  <Table.Summary.Cell index={1} className={'bg-neutral-100 border-b-0 text-right font-semibold mr-3'}>
                    {revertDataTotal[0]}
                  </Table.Summary.Cell>
                  {renderCellSummary}
                </>
              </Table.Summary.Row>
            )}
          </>
        )
      }}
      expandable={
        tableName === 'netDataAir'
          ? {
              columnWidth: 4,
              expandedRowRender: () => <p style={{ margin: 0 }}></p>,
              expandIcon: ({ record }) => {
                return (
                  <div className={`${handleColor(record.keyForColor)} w-full h-[48px]`}>
                    <br />
                  </div>
                )
              },
            }
          : {}
      }
      onRow={(record) => {
        return {
          onMouseMove: () => (tableName === 'netDataAir' ? handleMoveRecord(record) : {}),
          onMouseOut: () => (tableName === 'netDataAir' ? handleMoveRecord() : {}),
        }
      }}
    />
  )
}

export default TableOverview
