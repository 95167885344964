import React from 'react'

const ExcelIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_91_162447)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.41574 0.986355C10.1948 0.904197 10.9825 0.988225 11.7269 1.23306C11.7593 1.24373 11.7909 1.25687 11.8213 1.27237C12.3155 1.52424 12.7174 1.92608 12.9692 2.42032C12.9847 2.45067 12.9978 2.48215 13.0085 2.5145C13.2536 3.25888 13.3379 4.04668 13.2559 4.82579V8.51502C13.2559 8.88321 12.9574 9.18169 12.5893 9.18169C12.2211 9.18169 11.9226 8.88321 11.9226 8.51502V4.78969C11.9226 4.76485 11.924 4.74002 11.9267 4.71534C11.9922 4.13248 11.9349 3.54259 11.7588 2.98355C11.6417 2.77337 11.4683 2.59997 11.2582 2.48287C10.6991 2.30703 10.1092 2.24994 9.52646 2.31551C9.50171 2.31829 9.47683 2.31969 9.45192 2.31969H5.26925C5.24441 2.31969 5.21959 2.3183 5.1949 2.31553C4.61211 2.25012 4.02228 2.3074 3.4633 2.48343C3.25328 2.60059 3.08005 2.77399 2.96309 2.98412C2.78707 3.543 2.72977 4.13271 2.7951 4.71541C2.79787 4.74007 2.79925 4.76487 2.79925 4.78969V11.5864C2.79925 11.6112 2.79787 11.636 2.7951 11.6607C2.7297 12.2436 2.787 12.8334 2.96306 13.3925C3.08015 13.6027 3.25358 13.7761 3.46379 13.8932C4.02282 14.0693 4.61271 14.1266 5.19555 14.0612C5.22024 14.0584 5.24507 14.057 5.26992 14.057H7.68792C8.05611 14.057 8.35459 14.3555 8.35459 14.7237C8.35459 15.0919 8.05611 15.3904 7.68792 15.3904H5.30602C4.5269 15.4723 3.7391 15.3881 2.99471 15.1429C2.96237 15.1322 2.93089 15.1191 2.90055 15.1037C2.40631 14.8518 2.00447 14.45 1.7526 13.9557C1.73714 13.9254 1.72402 13.8939 1.71337 13.8615C1.46826 13.1171 1.38398 12.3294 1.46592 11.5503V4.82575C1.38406 4.04688 1.46834 3.25934 1.71336 2.51519C1.724 2.48289 1.73709 2.45145 1.75252 2.42114C2.00416 1.92704 2.40567 1.52522 2.89958 1.27319C2.93002 1.25766 2.96161 1.24449 2.99407 1.2338C3.73845 0.988677 4.52624 0.904396 5.30535 0.986355H9.41574Z"
          fill="#009BE0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9359 10.7898C12.3041 10.7898 12.6026 11.0883 12.6026 11.4565V13.7683L13.4252 12.9457C13.6855 12.6854 14.1076 12.6854 14.368 12.9457C14.6283 13.2061 14.6283 13.6282 14.368 13.8885L12.4073 15.8492C12.147 16.1095 11.7249 16.1095 11.4645 15.8492L9.50386 13.8885C9.24351 13.6282 9.24351 13.2061 9.50386 12.9457C9.76421 12.6854 10.1863 12.6854 10.4467 12.9457L11.2693 13.7683V11.4565C11.2693 11.0883 11.5677 10.7898 11.9359 10.7898Z"
          fill="#009BE0"
        />
        <path
          d="M8.24155 10.8031L7.36155 9.16914L6.48155 10.8031H5.07422L6.65755 7.86181L5.07422 4.92114H6.48155L7.36155 6.55514L8.24155 4.92114H9.64889L8.06489 7.86181L9.64889 10.8031H8.24155Z"
          fill="#009BE0"
        />
      </g>
      <defs>
        <clipPath id="clip0_91_162447">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ExcelIcon
