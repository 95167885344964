import { Col, Row } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import TableOverview from '~/components/molecules/TableOverView'
import DoughnutChart from '~/components/organisms/doughnutChart'

import { apiGetAirCondition } from '~/api/overView'
import { overViewDataTable, overViewTitleColor } from '~/interfaces/airCondition'
import { formatNumber, formatDecimal, pushNotification, handleError } from '~/utils/helper'

import '~/assets/styles/feature/overViewAir.scss'

const overView = () => {
  const { t } = useTranslation()
  const [netData, setNetData] = useState<overViewDataTable[]>([])
  const [netDataTotal, setNetDataTotal] = useState<any>([])
  const [costData, setCostList] = useState<overViewDataTable[]>([])
  const [costDataTotal, setCostDataTotal] = useState<any>([])
  const [equipmentData, setEquipmentList] = useState<overViewDataTable[]>([])
  const [equipmentTotal, setEquipmentTotal] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rowHoverChart, setRowHoverChart] = useState<number>()
  const [dataChange, setDataChange] = useState<any>(null)

  const params = useParams()

  const convertTotal = (dataTotal) =>
    dataTotal.map((cost: any, index) =>
      index > 0 ? (index === dataTotal.length - 2 || index === dataTotal.length - 3 ? formatDecimal(cost) : formatNumber(cost)) : cost
    )

  const getOverview = async () => {
    try {
      const res = await apiGetAirCondition({ idEstimate: params.idEstimate })
      //netData
      const resData: any = res.data.data[0]
      const { total, ...netDataRemoveTotal } = resData
      const customNetData = Object.values(netDataRemoveTotal).map((item: any, index) => ({
        ...item,
        keyForColor: index,
        key: index,
      }))
      setNetData(customNetData)
      setNetDataTotal(Object.values(total))

      //costDataTotal
      const { total: costTotal, ...costDataRemoveTotal } = res.data.data[1]
      const customCostData = Object.values(costDataRemoveTotal).map((item: any, index) => ({ ...item, key: index }))
      const convertCostDataTotal = convertTotal(Object.values(costTotal))
      setCostDataTotal(convertCostDataTotal)
      setCostList(customCostData)

      //equipmentData
      const { total: costEquipment, ...equipmentDataRemoveTotal } = res.data.data[2]
      const convertEquipmentDataTotal = convertTotal(Object.values(costEquipment))
      const customEquipmentList = Object.values(equipmentDataRemoveTotal).map((item: any, index) => ({
        ...item,
        key: index,
      }))
      setEquipmentTotal(convertEquipmentDataTotal)
      setEquipmentList(customEquipmentList)
      setIsLoading(false)
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const renderTitleColorNet = (index: number): overViewTitleColor => {
    switch (index) {
      case 0:
        return { title: '熱源機器設備', bgColor: 'bg-[#68ADDC]', borderColor: 'border-b-[#68ADDC]' }
      case 1:
        return { title: '空調機器設備', bgColor: 'bg-[#6CD7D0]', borderColor: 'border-b-[#6CD7D0]' }
      case 2:
        return { title: 'ダクト設備', bgColor: 'bg-[#ABDA82]', borderColor: 'border-b-[#ABDA82]' }
      case 3:
        return { title: '配管設備', bgColor: 'bg-[#7BC476]', borderColor: 'border-b-[#7BC476]' }
      case 4:
        return { title: '換気設備', bgColor: 'bg-[#EA9594]', borderColor: 'border-b-[#EA9594]' }
      case 5:
        return { title: '排煙設備', bgColor: 'bg-[#EF8B42]', borderColor: 'border-b-[#EF8B42]' }
      case 6:
        return { title: '自動制御設備', bgColor: 'bg-[#FDB085]', borderColor: 'border-b-[#FDB085]' }
      case 7:
        return { title: '中央監視設備', bgColor: 'bg-[#DAC1C1]', borderColor: 'border-b-[#DAC1C1]' }
      case 8:
        return { title: 'その他設備', bgColor: 'bg-[#D2CFC2]', borderColor: 'border-b-[#D2CFC2]' }
      case 9:
        return { title: '経費項目', bgColor: 'bg-[#F7EFAE]', borderColor: 'border-b-[#F7EFAE]' }
      case 10:
        return { title: '空調計', bgColor: 'bg-transparent', borderColor: 'border-b-transparent' }
      default:
        return { title: '', bgColor: '', borderColor: '' }
    }
  }
  const dataRate = useMemo(
    () =>
      netData.map((item, index) => ({
        value: item.rate,
        color: renderTitleColorNet(index).bgColor,
        name: index,
      })),
    [netData]
  )

  // Net item
  const columnAir: ColumnsType<any> = [
    {
      title: <p className="w-full truncate">{t('overview.net_item')}</p>,
      dataIndex: 'netItem',
      key: 'netItem',
      render: (text, record, index) => renderTitleColorNet(index).title,
      width: 90,
      ellipsis: true,
      className: 'min-w-[15px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.cost')}</p>,
      dataIndex: 'a0_cost',
      key: 'a0_cost',
      render: (text, record) => {
        return formatNumber(record.a0_cost)
      },
      align: 'right',
      width: 75,
      className: 'min-w-[15px]',
      ellipsis: true,
    },
    {
      title: <p className="w-full truncate">{t('overview.y_m2')}</p>,
      dataIndex: 'total_floor_cost',
      key: 'total_floor_cost',
      render: (text, record) => {
        return formatNumber(record.total_floor_cost)
      },
      align: 'right',
      width: 75,
      className: 'min-w-[15px]',
      ellipsis: true,
    },
    {
      title: <p className="w-full truncate">{t('overview.composition_ratio')}</p>,
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => {
        return `${formatDecimal(record.rate)}%`
      },
      align: 'center',
      width: 75,
      className: 'min-w-[15px]',
      ellipsis: true,
    },
  ]

  // Construction cost breakdown
  const costBreakdown: ColumnsType<any> = [
    {
      title: <p className="w-full truncate">No.</p>,
      dataIndex: 'main_code',
      key: 'main_code',
      width: 150,
      align: 'center',
      render(_, record) {
        return record.main_code ?? record.main_code_z
      },
      className: 'min-w-[50px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.construction_item')}</p>,
      dataIndex: 'item',
      key: 'item',
      render(_, record) {
        return record.item ?? record.item_z
      },
      className: 'min-w-[50px]',
      width: 200,
    },
    {
      title: <p className="w-full truncate">{t('overview.cost')}</p>,
      dataIndex: 'a0_cost',
      key: 'a0_cost',
      align: 'right',
      render(_, record) {
        return formatNumber(record.a0_cost ?? record.a0_cost_z)
      },
      width: 120,
      className: 'min-w-[50px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.y_m2')}</p>,
      dataIndex: 'total_floor_cost',
      key: 'total_floor_cost',
      align: 'right',
      render(_, record) {
        return formatDecimal(record.a0_cost ?? record.total_floor_cost_z)
      },
      width: 200,
      className: 'min-w-[50px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.composition_ratio')}</p>,
      dataIndex: 'rate',
      key: 'rate',
      align: 'center',
      render(_, record) {
        return `${formatDecimal(record.rate ?? record.rate_z)}%`
      },
      width: 200,
      className: 'min-w-[50px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.number_people')}</p>,
      dataIndex: 'number_of_people',
      key: 'number_of_people',
      align: 'center',
      render(_, record) {
        return `${record.number_of_people ?? record.number_of_people_z}人`
      },
      width: 200,
      className: 'min-w-[50px]',
    },
  ]

  // Equipment list
  const equipmentList: ColumnsType<any> = [
    {
      title: <p className="w-full truncate">No.</p>,
      dataIndex: 'main_code',
      key: 'main_code',
      width: 150,
      className: 'min-w-[90px]',
      align: 'center',
      render(_, record) {
        return record.main_code ?? record.main_code_z
      },
    },
    {
      title: <p className="w-full truncate">{t('overview.device_name')}</p>,
      dataIndex: 'name',
      key: 'name',
      render(_, record) {
        return record.name ?? record.name_z
      },
      width: 200,
      className: 'min-w-[200px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.platform')}</p>,
      dataIndex: 'platform',
      key: 'platform',
      align: 'right',
      render(_, record) {
        return record.platform ?? record.platform_z
      },
      width: 60,
      className: 'min-w-[60px]',
    },
    {
      title: <p className="w-full truncate">{t('overview.amount_of_money')}</p>,
      dataIndex: 'amount_of_money',
      key: 'amount_of_money',
      align: 'right',
      render(_, record) {
        return formatNumber(record.amount_of_money ?? record.amount_of_money_z)
      },
      width: 150,
      className: 'min-w-[150px]',
    },
    {
      title: <p className="w-full truncate"> {t('overview.chiller')}</p>,
      dataIndex: 'refrigerator_kw',
      key: 'refrigerator_kw',
      align: 'right',
      render(_, record) {
        return formatDecimal(record.refrigerator_kw ?? record.refrigerator_kw_z)
      },
      width: 150,
      className: 'min-w-[150px]',
    },
    {
      title: <p className="w-full truncate"> {t('overview.heat_source')}</p>,
      dataIndex: 'heat_source_kw',
      key: 'heat_source_kw',
      align: 'center',
      render(_, record) {
        return formatDecimal(record.heat_source_kw ?? record.heat_source_kw_z)
      },
      width: 150,
      className: 'min-w-[150px]',
    },
    {
      title: <p className="w-full truncate"> {t('overview.flow')}</p>,
      dataIndex: 'flow_rate',
      key: 'flow_rate',
      align: 'center',
      render(_, record) {
        return formatNumber(record.flow_rate ?? record.flow_rate_z)
      },
      width: 90,
      className: 'min-w-[90px]',
    },
  ]

  useEffect(() => {
    getOverview()
  }, [])

  const renderColorTable = (keyColor): { bgColor: string; borderColor: string } => {
    return renderTitleColorNet(keyColor)
  }

  return (
    <div className="w-10/12 mt-12 mx-auto">
      <div className="px-9">
        <h3 className="text-xl font-bold mb-10">{t('overview.net_breakdown')}</h3>
        <Row>
          <Col span={14} className="overview-air">
            <TableOverview
              key={'netDataAir'}
              tableName={'netDataAir'}
              data={netData}
              columns={columnAir}
              loading={isLoading}
              dataSummary={netDataTotal}
              renderColorNet={renderColorTable}
              setDataChange={setDataChange}
              rowHoverChart={rowHoverChart}
              textTotal={'空調計'}
            />
          </Col>
          <Col span={10} className="bg-white">
            {dataRate.length > 0 && <DoughnutChart dataSource={dataRate} setHoverItem={setRowHoverChart} dataSelect={dataChange} />}
          </Col>
        </Row>
      </div>
      <div className="my-10 px-9 ">
        <h3 className="text-xl font-bold pb-10">{t('overview.construction_cost_breakdown')}</h3>
        <div className="w-full">
          <TableOverview
            key={'costTable'}
            tableName={'costTable'}
            data={costData}
            columns={costBreakdown}
            loading={isLoading}
            dataSummary={costDataTotal}
            isRenderText
          />
        </div>
      </div>
      <div className="px-9 mb-12">
        <h3 className="text-xl font-bold pb-10">{t('overview.equipment_list')}</h3>
        <div className="w-full">
          <TableOverview
            key={'equipmentTable'}
            tableName={'equipmentTable'}
            data={equipmentData}
            columns={equipmentList}
            loading={isLoading}
            dataSummary={equipmentTotal}
          />
        </div>
      </div>
    </div>
  )
}

export default overView
