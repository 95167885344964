import { Drawer, Form, Spin } from 'antd'
import { cloneDeep } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Button from '~/components/atoms/Button'
import DrawerConstruction from '~/components/organisms/DrawerConstruction'

import { getConstruction, getConstructionView, putConstructionData } from '~/api/construction'
import SlideOutIcon from '~/assets/icons/SlideOutIcon'
import { DEFAULT_GROUP_CODE } from '~/consts/common'
import { DataTypeConstruction } from '~/interfaces/construction'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { SET_LOADING } from '~/redux/reducers/app.slice'
import { constructions, setConstructionSelects, setDataConstruction, setDataConstructionDrawer } from '~/redux/reducers/construction.slice'
import {
  addChildIndex,
  addEstimateId,
  addIndex,
  addKey,
  checkChildrenEnd,
  flattenTreeData,
  groupedObj,
  groupItemPropertyByCode,
  handleError,
  markLastEnd,
  pushNotification,
  sortArrayByField,
} from '~/utils/helper'
import { getLocalStorage } from '~/utils/storage'

import CreateConstruction from './createConstruction'
import ListConstruction from './listConstruction'

import '~/assets/styles/feature/construction.scss'

const Construction = () => {
  const dispatch = useAppDispatch()
  const { dataTable: dataForTable } = useAppSelector(constructions)

  const [form] = Form.useForm()

  const [isAddConstruction, setIsAddConstruction] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataTypeConstruction[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingPut, setIsLoadingPut] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [isDummyCreate, setIsDummyCreate] = useState<boolean>(false)
  const [selectedGroupCode, setSelectedGroupCode] = useState<string | null>(null)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(true)

  const params = useParams()

  const handleCloseDrawer = () => {
    setOpenEdit(false)
    setSelectedGroupCode(null)
  }
  const handleOpenDrawer = useCallback(
    (groupCode = DEFAULT_GROUP_CODE) => {
      setOpenEdit(true)
      setSelectedGroupCode(groupCode)
    },
    [openEdit]
  )

  const handleCreate = () => {
    setIsAddConstruction(false)
    setIsDummyCreate(true)
    // setOpenEdit(true)
    dispatch(setDataConstruction([]))
    dispatch(setConstructionSelects({}))
    setDataTable([])
  }

  const mergeArrayByMatchingKey = (
    sourceArray: any[],
    lookupArray: any[],
    sourceValue: string,
    sourcePrevValue: string,
    lookupValue: string,
    updateKey: string
  ): any => {
    const flattenedSourceArray = flattenTreeData(sourceArray)

    return flattenedSourceArray.map((item) => {
      const lookupItem = lookupArray.find((lItem: any) => lItem[lookupValue] === item[sourcePrevValue])
      if (lookupItem) {
        return { ...item, [lookupValue]: item[sourceValue], [updateKey]: lookupItem[updateKey] }
      }
      return { ...item, [lookupValue]: item[sourceValue] }
    })
  }

  const reloadFormData = async (data: any[]) => {
    const values = await extractFormData()
    if (!values.length) return

    const newValues = mergeArrayByMatchingKey(data, values, 'code', 'prevCode', 'id', 'construction_name')

    const res = newValues.reduce((newObj: any, item: any) => {
      // format cell on TableConstruction
      newObj[`construction_name&${item.id}`] = item.construction_name
      return newObj
    }, {})
    form.setFieldsValue(res)
  }

  const handleSetDataTableAndSelectedKeys = (data: any[]) => {
    setDataTable(data)
    // reset dataSelectKeys state when no data rows on table
    dispatch(setConstructionSelects(data.length ? groupItemPropertyByCode(data) : {}))
  }

  const callDataDefault = async () => {
    try {
      setIsLoading(true)
      const { data } = await getConstructionView(params.idEstimate)
      if (data.data.length > 0 && !isDummyCreate) {
        const revertData = checkChildrenEnd(addKey(addChildIndex(markLastEnd(addIndex(cloneDeep(data.data))))))
        const sortedData = sortArrayByField([...revertData], 'group_code')
        dispatch(setDataConstruction(sortedData))
        handleSetDataTableAndSelectedKeys(sortedData)
        setIsDummyCreate(false)
      } else if (!isDummyCreate) {
        setIsAddConstruction(true)
      }
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    } finally {
      setIsLoading(false)
    }
  }
  const getDataDefault = async (data: any[]) => {
    const sortedData = sortArrayByField([...data], 'group_code')
    const render = addChildIndex(checkChildrenEnd(cloneDeep(sortedData)))
    handleSetDataTableAndSelectedKeys(render)
    await reloadFormData(render)
  }

  const getDataConstruction = async () => {
    try {
      dispatch(SET_LOADING(true))
      const res = await getConstruction()
      const dataRes = cloneDeep(res.data.data)
      const convertData = checkChildrenEnd(addKey(markLastEnd(addIndex(dataRes))))
      dispatch(setDataConstructionDrawer(convertData))
    } catch (error) {
      const message = handleError(error) || 'ERROR'
      pushNotification(message, 'error')
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const handleUpdateRow = (data: any[], itemKey: string, valueKey: string, valueData: any) => {
    for (const item of data) {
      if (item?.['code'] === itemKey) {
        item[valueKey] = valueData
        break
      }
      if (item.children && item.children.length) {
        handleUpdateRow(item.children, itemKey, valueKey, valueData)
      }
    }

    return data
  }

  const extractFormData = async () => {
    const values = await form.validateFields()
    // const values = form.getFieldsValue()
    return Object.values(groupedObj(values))
  }

  const prepareData = async (data: any[] = dataTable) => {
    let clonedData = cloneDeep(data)
    const values: any = await extractFormData()

    for (const value of values) {
      const { id: key, ...others } = value
      const valueObj = Object.keys(others)
      const valueKey = valueObj[0]
      const valueData = others[valueKey]
      clonedData = handleUpdateRow(clonedData, key, valueKey, valueData)
    }

    return clonedData
  }

  const handlePutDataConstruction = async () => {
    try {
      const updatedDataTable = await prepareData()
      setIsLoadingPut(true)
      const dataBody = addEstimateId(cloneDeep(updatedDataTable), params?.idEstimate as string)
      await putConstructionData(params.idEstimate, dataBody)
      pushNotification('工事項目の更新が成功', 'success')
      setOpenEdit(false)
      setIsAddConstruction(!dataBody.length)
    } catch (error: any) {
      let message = ''
      if (error.response?.status === 400 && error.response?.data.data[0].code === '400109') {
        message = '建設IDが見つかりません'
      } else {
        message = handleError(error)
      }
      pushNotification(message, 'error')
    } finally {
      setIsLoadingPut(false)
    }
  }

  const handleCancel = async () => {
    setOpenEdit(false)
    await callDataDefault()
  }

  useEffect(() => {
    const fetchData = async () => {
      await callDataDefault()
      await getDataConstruction()
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await getDataDefault(dataForTable)
    }
    fetchData()
  }, [dataForTable, params])

  useEffect(() => {
    setIsDisabledSubmit(!dataTable.length)
  }, [dataTable])

  useEffect(() => {
    handleCloseDrawer()
  }, [getLocalStorage('activeTab')])

  return (
    <div className="h-fit">
      <div className="constructionIndex mb-16 max-h-[80vh] overflow-y-auto">
        {isAddConstruction ? (
          <CreateConstruction setDataNew={handleCreate} />
        ) : (
          <Form form={form}>
            <ListConstruction
              isLoading={isLoading}
              dataTable={dataTable}
              selectedGroupCode={selectedGroupCode}
              form={form}
              onOpenDrawer={handleOpenDrawer}
              reloadDataTable={getDataDefault}
              setIsDisabledSubmit={setIsDisabledSubmit}
            />
          </Form>
        )}
        <Drawer
          title={
            <div className="flex justify-between items-center">
              <span className="text-lg text-[#171717] font-bold">工事項目編集</span>
              <Button type="text" offFocus className="relative" prefixIcon={<SlideOutIcon />} onClick={() => setOpenEdit(false)} />
            </div>
          }
          placement="right"
          onClose={handleCloseDrawer}
          open={openEdit}
          maskClassName="bg-[transparent]"
          destroyOnClose={true}
          closable={false}
          className="construction-drawer right-0 bottom-0 top-[146px]"
          width={'53rem'}
        >
          <Spin spinning={isLoadingPut}>
            {/* need to use no dummy data of dataTable inside Drawer */}
            <DrawerConstruction selectedGroupCode={selectedGroupCode} dataTable={dataTable.filter((item: any) => item?.construction_id)} />
          </Spin>
        </Drawer>
      </div>
      {!isAddConstruction && (
        <div className="w-full absolute bottom-0 right-0 h-16 bg-white flex justify-end px-6 py-3 z-[1001]">
          <Button type="text" className="w-[120px]" disabled={false} onClick={handleCancel} text="キャンセル" />
          <Button className="w-[120px]" disabled={isDisabledSubmit} onClick={handlePutDataConstruction} text="確定" />
        </div>
      )}
    </div>
  )
}

export default Construction
