import { Form, Col, Row, Input, Select, DatePicker } from 'antd'
import React from 'react'

import Button from '~/components/atoms/Button'

import DatePickerIcon from '~/assets/icons/DatePickerIcon'

const options = [
  {
    value: 'jack',
    label: 'Jack',
  },
  {
    value: 'lucy',
    label: 'Lucy',
  },
  {
    value: 'tom',
    label: 'Tom',
  },
]
const optionsTitle = [
  {
    value: 'doing',
    label: '御中',
  },
]
const { TextArea } = Input
const EstimateDocumentEdit = () => {
  const [form] = Form.useForm()

  return (
    <div className="h-[750px] overflow-y-auto">
      <Form form={form}>
        <div className="w-7/12 mx-auto my-6">
          <div className="leading-8 text-[22px] font-bold mb-4 text-[#171717]">見積書表紙編集</div>
          <div className="bg-white p-[60px]">
            <div className="py-3 border-b-2">
              <table>
                <thead>
                  <tr className="text-sm text-[#171717] font-normal leading-[22px] ">
                    <th className="p-0">見積 ID</th>
                    <th className="py-3 pl-16">見積名</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-[22px] leading-8 text-[#171717] font-bold">
                    <td className="px-0 py-3">123-4</td>
                    <td className="pl-16">ビル1-５階空調施設</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-8">
              <Row className="space-x-6">
                <Col span={19}>
                  <div className="text-base font-bold mb-4">提出先</div>
                  <Input.Group className="flex justify-between space-x-4">
                    <Form.Item name="submission_destination" className="w-11/12 ">
                      <Select className="rounded " size="large" placeholder="Select a person" options={options} />
                    </Form.Item>
                    <Button className="h-10 rounded w-1/7" type="outlined" text="情報取得" />
                  </Input.Group>
                </Col>
                <Col span={4}>
                  <div className="text-base font-bold mb-4">敬称</div>
                  <Form.Item name="title" className="w-11/12 ">
                    <Select className="rounded " size="large" placeholder="Select " options={optionsTitle} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Row className="w-2/3">
                  <Col span={8}>
                    <div className="text-sm mb-4">見積提出期限</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="submission_date">
                      <DatePicker className="rounded" size="large" placeholder="yyyy/mm/dd" suffixIcon={<DatePickerIcon />} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="w-2/3">
                  <Col span={8}>
                    <div className="text-sm mb-4">敬文</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="submission_date" className="w-full">
                      <TextArea rows={3} className="round" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="w-2/3">
                  <Col span={8}>
                    <div className="text-sm mb-4">納入場所</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="submission_date" className="w-full">
                      <Select className="rounded " size="large" placeholder="場所" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="w-2/3">
                  <Col span={8}>
                    <div className="text-sm mb-4">納期</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="submission_date" className="w-full">
                      <Select className="rounded " size="large" placeholder="納期" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="w-2/3">
                  <Col span={8}>
                    <div className="text-sm mb-4">お支払い条件</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="submission_date" className="w-full">
                      <Select className="rounded " size="large" placeholder="納期" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="w-2/3">
                  <Col span={8}>
                    <div className="text-sm mb-4">有効期限</div>
                  </Col>
                  <Col span={16}>
                    <Form.Item name="submission_date" className="w-full">
                      <Select className="rounded " size="large" placeholder="有効期限" />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
              <Row className="text-base font-bold mb-4">担当</Row>
              <Row className="w-full ">
                <Col span={12}>
                  <div className="text-sm mb-4">担当支部</div>
                  <Input.Group className="flex justify-between space-x-2">
                    <Form.Item name="branch_in_charge" className="w-11/12">
                      <Input className="rounded" size="large" />
                    </Form.Item>
                    <Button className="h-10 rounded w-1/7" type="outlined" text="情報取得" />
                  </Input.Group>
                </Col>
              </Row>
              <Row className="w-full space-x-6 ">
                <Col span={12}>
                  <div className="text-sm mb-4">見積担当課</div>
                  <Input.Group className="flex justify-between space-x-2">
                    <Form.Item name="estimate_staff_section" className="w-11/12 ">
                      <Input className="rounded" size="large" />
                    </Form.Item>
                    <Button className="h-10 rounded w-1/7" type="outlined" text="情報取得" />
                  </Input.Group>
                </Col>
                <Col span={11}>
                  <div className="text-sm mb-4">見積担当者</div>
                  <Input.Group className="flex justify-between space-x-2">
                    <Form.Item name="estimate_staff_name" className="w-11/12">
                      <Input className="rounded" size="large" />
                    </Form.Item>
                    <Button className="h-10 rounded w-1/7" type="outlined" text="情報取得" />
                  </Input.Group>
                </Col>
              </Row>
              <Row>
                <div className="text-base font-bold mb-4 w-full">2. 法定福利費相当額</div>
                <Form.Item name="submission_date" className="w-9/12 mx-auto">
                  <TextArea rows={5} className="round" />
                </Form.Item>
              </Row>
              <Row>
                <div className="text-base font-bold mb-4 w-full">3. 御見積条件</div>
                <Form.Item name="submission_date" className="w-9/12 mx-auto">
                  <TextArea rows={5} className="round" />
                </Form.Item>
              </Row>
              <Row>
                <div className="text-base font-bold mb-4 w-full">4. 下記項目は別途とし、本見積に含めておりません。</div>
                <div className="w-11/12 mx-auto space-y-4 mb-4">
                  <Row className="space-x-2">
                    <Col span={2}>（ロ）</Col>
                    <Col span={21}>需要家（者）負担金等（給水、排水、ガス、共）</Col>
                  </Row>
                  <Row className="space-x-2 ">
                    <Col span={2}>（ハ）</Col>
                    <Col span={21}>建築工事関係</Col>
                  </Row>
                </div>
                <Form.Item name="submission_date" className="w-9/12 mx-auto">
                  <TextArea rows={5} className="round" />
                </Form.Item>
              </Row>
              <Row>
                <div className="w-11/12 mx-auto space-y-4 mb-4">
                  <Row className="space-x-2 ">
                    <Col span={2}>（ニ）</Col>
                    <Col span={21}>電気工事関係</Col>
                  </Row>
                </div>
                <Form.Item name="submission_date" className="w-9/12 mx-auto">
                  <TextArea rows={5} className="round" />
                </Form.Item>
              </Row>
              <Row>
                <div className="w-11/12 mx-auto space-y-4 mb-4">
                  <Row className="space-x-2 ">
                    <Col span={2}>（ホ）</Col>
                    <Col span={21}>その他</Col>
                  </Row>
                </div>
                <Form.Item name="submission_date" className="w-9/12 mx-auto">
                  <TextArea rows={5} className="round" />
                </Form.Item>
              </Row>
            </div>
          </div>
        </div>
        <div className="w-full absolute bottom-0 right-0 h-16 bg-white flex justify-end px-6 py-3 z-10">
          <Button type="text" className="h-9" text="キャンセル" />
          <Button className="h-9 w-[120px]" text="確定" />
        </div>
      </Form>
    </div>
  )
}

export default EstimateDocumentEdit
