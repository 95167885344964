import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import Login from '~/features/auth/login'
import Register from '~/features/auth/register'
import AuthLayout from '~/layout/AuthLayout'
const ResetPassword = lazy(() => import('~/features/auth/resetPassword'))

const PublicRouter: RouteObject = {
  element: <AuthLayout />,
  children: [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: 'reset',
      element: <ResetPassword />,
    },
  ],
}

export default PublicRouter
