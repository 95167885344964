import React from 'react'

export interface ReloadRowIconProps {
  hover?: boolean
  isDisable?: boolean
}

const ReloadRowIcon = ({ hover, isDisable }: ReloadRowIconProps) => {
  const iconColor = hover ? '#38BDF8' : isDisable ? '#A3A3A3' : '#009BE0'
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.66666 15.3332H2.66666C2.29999 15.3332 1.99999 15.0332 1.99999 14.6665C1.99999 14.2998 2.29999 13.9998 2.66666 13.9998H4.63333C4.17333 13.5932 3.70666 13.1332 3.27999 12.7132C2.01999 11.4532 1.32666 9.77984 1.32666 7.99984C1.32666 6.21984 2.02666 4.5465 3.28666 3.2865C4.17999 2.39317 5.29333 1.77984 6.51999 1.49984C6.87999 1.41984 7.23333 1.63984 7.31999 1.99984C7.39999 2.35984 7.17333 2.71317 6.81999 2.79984C5.83999 3.01984 4.94666 3.51317 4.23333 4.2265C3.22666 5.23317 2.67333 6.57317 2.67333 7.99984C2.67333 9.4265 3.22666 10.7665 4.23333 11.7732C5.02666 12.5665 5.59999 13.0798 6.00666 13.4198V10.6665C6.00666 10.2998 6.30666 9.99984 6.67333 9.99984C7.03999 9.99984 7.33999 10.2998 7.33999 10.6665V14.6532C7.33999 14.6532 7.33999 14.6932 7.33999 14.7132C7.33999 14.7932 7.31333 14.8665 7.28666 14.9398C7.24666 15.0265 7.19333 15.1065 7.11999 15.1665C7.04666 15.2332 6.95333 15.2865 6.84666 15.3132C6.79333 15.3265 6.73333 15.3332 6.67333 15.3332H6.66666ZM9.33333 14.5198C9.02666 14.5198 8.75333 14.3132 8.68666 13.9998C8.60666 13.6398 8.82666 13.2865 9.18666 13.1998C10.1667 12.9798 11.06 12.4798 11.7733 11.7732C12.78 10.7665 13.3333 9.4265 13.3333 7.99984C13.3333 6.57317 12.78 5.23317 11.7733 4.2265C10.98 3.43317 10.4067 2.91984 9.99999 2.57984V5.33317C9.99999 5.69984 9.69999 5.99984 9.33333 5.99984C8.96666 5.99984 8.66666 5.69984 8.66666 5.33317V1.3465C8.66666 1.3465 8.66666 1.3065 8.66666 1.2865C8.66666 1.2065 8.69333 1.13317 8.71999 1.0665C8.75999 0.973171 8.81999 0.893171 8.89333 0.833171C8.96666 0.766504 9.05333 0.719837 9.15333 0.693171C9.21333 0.679837 9.27999 0.666504 9.33999 0.666504H13.3333C13.7 0.666504 14 0.966504 14 1.33317C14 1.69984 13.7 1.99984 13.3333 1.99984H11.3667C11.8267 2.4065 12.2933 2.8665 12.72 3.2865C13.98 4.5465 14.6733 6.21984 14.6733 7.99984C14.6733 9.77984 13.98 11.4532 12.72 12.7132C11.8267 13.6065 10.7133 14.2198 9.48666 14.4998C9.43999 14.5132 9.38666 14.5132 9.33999 14.5132L9.33333 14.5198Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default ReloadRowIcon
