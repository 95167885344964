import React from 'react'

interface AutoGroupProps {
  isDisable?: boolean
}

const AutoGroupIcon = ({ isDisable }: AutoGroupProps) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M9.63803 1.02925C9.89838 1.2896 9.89838 1.71171 9.63803 1.97206L9.44013 2.16996C12.4921 2.62413 14.8333 5.25565 14.8333 8.43398C14.8333 10.5398 13.8049 12.4054 12.2259 13.5559C11.9283 13.7727 11.5113 13.7072 11.2945 13.4096C11.0777 13.112 11.1431 12.695 11.4407 12.4782C12.6901 11.568 13.5 10.0956 13.5 8.43398C13.5 6.00451 11.7672 3.97974 9.47017 3.52805L9.63803 3.69591C9.89838 3.95626 9.89838 4.37837 9.63803 4.63872C9.37768 4.89907 8.95557 4.89907 8.69522 4.63872L7.36189 3.30539C7.10154 3.04504 7.10154 2.62293 7.36189 2.36258L8.69522 1.02925C8.95557 0.768897 9.37768 0.768897 9.63803 1.02925ZM5.70545 3.45835C5.92226 3.75593 5.85678 4.17293 5.5592 4.38974C4.30979 5.30002 3.49996 6.77239 3.49996 8.43398C3.49996 10.8635 5.23268 12.8882 7.52975 13.3399L7.36189 13.1721C7.10154 12.9117 7.10154 12.4896 7.36189 12.2292C7.62224 11.9689 8.04435 11.9689 8.3047 12.2292L9.63803 13.5626C9.89838 13.8229 9.89838 14.245 9.63803 14.5054L8.3047 15.8387C8.04435 16.0991 7.62224 16.0991 7.36189 15.8387C7.10154 15.5784 7.10154 15.1563 7.36189 14.8959L7.55979 14.698C4.50785 14.2438 2.16663 11.6123 2.16663 8.43398C2.16663 6.32819 3.19501 4.46253 4.77406 3.31209C5.07164 3.09528 5.48864 3.16076 5.70545 3.45835Z"
          fill={isDisable ? '#A3A3A3' : '#009BE0'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_96578">
          <rect width="16" height="16" fill="white" transform="translate(0.5 0.433594)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AutoGroupIcon
