import { Table, Typography } from 'antd'
import React, { useMemo, useState } from 'react'

import Button from '~/components/atoms/Button'
import SortIcon from '~/components/atoms/CustomSort'

import ArrowLeft from '~/assets/icons/ArrowLeftIcon'
import DropdownBoldIcon from '~/assets/icons/DropdownBoldIcon'

import '~/assets/styles/feature/listEstimate.scss'

const listEstimate = () => {
  const { Title } = Typography

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const handleNavigate = () => {
    history.back()
  }

  const columnFirst: any = [
    {
      title: ({ sortColumns }) => <SortIcon title="見積ID" sortColumns={sortColumns} keyName="quotation_id" />,
      dataIndex: 'quotation_id',
      key: 'quotation_id',
      width: 150,
      render: (record) => (
        <div className="flex justify-between items-center">
          {record}
          <DropdownBoldIcon />
        </div>
      ),
    },
    {
      title: ({ sortColumns }) => <SortIcon title="ステータス" sortColumns={sortColumns} keyName="status" />,
      dataIndex: 'status',
      key: 'status',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="見積提出期日" sortColumns={sortColumns} keyName="submission_deadline" />,
      dataIndex: 'submission_deadline',
      key: 'submission_deadline',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="見積提出日" sortColumns={sortColumns} keyName="submission_date" />,
      dataIndex: 'submission_date',
      key: 'submission_date',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="回答金額" sortColumns={sortColumns} keyName="answer_amount" />,
      dataIndex: 'answer_amount',
      key: 'answer_amount',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="A0原価" sortColumns={sortColumns} keyName="ao_cost" />,
      dataIndex: 'ao_cost',
      key: 'ao_cost',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="粗利率 (A0原価" sortColumns={sortColumns} keyName="gross_margin_a" />,
      dataIndex: 'gross_margin_a',
      key: 'gross_margin_a',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="E原価" sortColumns={sortColumns} keyName="e_cost" />,
      dataIndex: 'e_cost',
      key: 'e_cost',
      width: 150,
    },
    {
      title: ({ sortColumns }) => <SortIcon title="粗利率 (E原価)" sortColumns={sortColumns} keyName="gross_margin_e" />,
      dataIndex: 'gross_margin_e',
      key: 'gross_margin_e',
      width: 150,
    },
  ]

  const dataFirst = [
    {
      key: 1,
      quotation_id: 4,
      status: '見積中',
      submission_deadline: '2022/06/22',
      submission_date: '2022/06/08',
      answer_amount: '9,999,999,999,999',
      ao_cost: '9,999,999,999,999',
      gross_margin_a: '9,999,999,999,999',
      e_cost: '9,999,999,999,999',
      gross_margin_e: '9,999,999,999,999',
    },
    {
      key: 2,
      quotation_id: 3,
      status: '見積提出済',
      submission_deadline: '2022/06/22',
      submission_date: '2022/06/08',
      answer_amount: '9,999,999,999,999',
      ao_cost: '9,999,999,999,999',
      gross_margin_a: '9,999,999,999,999',
      e_cost: '9,999,999,999,999',
      gross_margin_e: '9,999,999,999,999',
    },
  ]

  const columnSecond: any = useMemo(
    () => [
      {
        title: '比較',
        dataIndex: 'comparison',
        key: 'comparison',
        width: 90,
        render: (_, record: any) => {
          if (isEdit) {
            return (
              <div>
                <span className="text-[#000000]">{record.isDelete ? '削除' : record.isAdd ? '追加' : ''}</span>
              </div>
            )
          }
        },
      },
      {
        id: 1,
        title: '詳細',
        dataIndex: 'comparison',
        key: 'comparison',
        width: 90,
        render: () => <Button type="outlined" text="詳細" />,
      },
      {
        title: ({ sortColumns }) => <SortIcon title="機械ID" sortColumns={sortColumns} keyName="machineId" />,
        dataIndex: 'machineId',
        key: 'machineId',
        width: 100,
        render: (text: any, record: any) => {
          if (isEdit && (record.isDelete || record.isAdd)) {
            return (
              <div>
                <span>{record.machineId}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.machineId}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="サブID" sortColumns={sortColumns} keyName="subId" />,
        dataIndex: 'subId',
        key: 'subId',
        width: 100,
      },
      {
        title: ({ sortColumns }) => <SortIcon title="名称" sortColumns={sortColumns} keyName="name" />,
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.name}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.name}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.name}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="メイン要素" sortColumns={sortColumns} keyName="main_element" />,
        dataIndex: 'main_element',
        key: 'main_element',
        width: 90,
      },
      {
        title: ({ sortColumns }) => <SortIcon title="サブ要素" sortColumns={sortColumns} keyName="sub_element" />,
        dataIndex: 'sub_element',
        key: 'sub_element',
        width: 100,
      },
      {
        title: ({ sortColumns }) => <SortIcon title="グループ番号" sortColumns={sortColumns} keyName="group_number" />,
        dataIndex: 'group_number',
        key: 'group_number',
        width: 100,
      },
      {
        title: ({ sortColumns }) => <SortIcon title="要素名称" sortColumns={sortColumns} keyName="element_name" />,
        dataIndex: 'element_name',
        key: 'element_name',
        width: 150,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.element_name}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.element_name}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.element_name}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="オプションの有無" sortColumns={sortColumns} keyName="option" />,
        dataIndex: 'option',
        key: 'option',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.option}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.option}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.option}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="合計能力" sortColumns={sortColumns} keyName="ability" />,
        dataIndex: 'ability',
        key: 'ability',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.ability}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.ability}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.ability}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="数量" sortColumns={sortColumns} keyName="quantity" />,
        dataIndex: 'quantity',
        key: 'quantity',
        width: 90,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.quantity}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.quantity}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.quantity}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="メーカー" sortColumns={sortColumns} keyName="manufacturer" />,
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.manufacturer}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.manufacturer}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.manufacturer}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="取引先コード" sortColumns={sortColumns} keyName="suppliers_code" />,
        dataIndex: 'suppliers_code',
        key: 'suppliers_code',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.suppliers_code}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.suppliers_code}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.suppliers_code}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title=" 代理店名" sortColumns={sortColumns} keyName="agency_name" />,
        dataIndex: 'agency_name',
        key: 'agency_name',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.agency_name}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.agency_name}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.agency_name}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="取引先コード" sortColumns={sortColumns} keyName="suppliers_code_2" />,
        dataIndex: 'suppliers_code_2',
        key: 'suppliers_code_2',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.suppliers_code_2}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.suppliers_code_2}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.suppliers_code_2}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="型番" sortColumns={sortColumns} keyName="model_number" />,
        dataIndex: 'model_number',
        key: 'model_number',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.model_number}</span>
              </div>
            )
          }
          if (index === 2 && isEdit) {
            return (
              <div>
                <span>Ref 122</span>
                <br />
                <span className="text-[#737373]">{record.model_number}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.model_number}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.model_number}</span>
        },
        onCell: (record, index) => ({
          style: { background: index === 2 && isEdit ? '#FFF5E5' : null },
        }),
      },
      {
        title: ({ sortColumns }) => <SortIcon title="定価" sortColumns={sortColumns} keyName="list_price" />,
        dataIndex: 'list_price',
        key: 'list_price',
        width: 150,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.list_price}</span>
              </div>
            )
          }
          if (index === 2 && isEdit) {
            return (
              <div>
                <span>367,500,000</span>
                <br />
                <span className="text-[#737373]">{record.list_price}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.list_price}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.model_number}</span>
        },
        onCell: (record, index) => ({
          style: { background: index === 2 && isEdit ? '#FFF5E5' : null },
        }),
      },
      {
        title: ({ sortColumns }) => <SortIcon title="掛率" sortColumns={sortColumns} keyName="rate" />,
        dataIndex: 'rate',
        key: 'rate',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.rate}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.rate}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.rate}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="AO原価" sortColumns={sortColumns} keyName="ao_cost" />,
        dataIndex: 'ao_cost',
        key: 'ao_cost',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.ao_cost}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.ao_cost}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.ao_cost}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="粗利率" sortColumns={sortColumns} keyName="gross_profit_margin" />,
        dataIndex: 'gross_profit_margin',
        key: 'gross_profit_margin',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.gross_profit_margin}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.gross_profit_margin}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.gross_profit_margin}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="提出金額" sortColumns={sortColumns} keyName="submit_amount" />,
        dataIndex: 'submit_amount',
        key: 'submit_amount',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.submit_amount}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.submit_amount}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.submit_amount}</span>
        },
      },
      {
        title: ({ sortColumns }) => <SortIcon title="E原価" sortColumns={sortColumns} keyName="e_cost" />,
        dataIndex: 'e_cost',
        key: 'e_cost',
        width: 100,
        render: (text: any, record: any, index: any) => {
          if (index === 0 && isEdit) {
            return (
              <div>
                <span>---</span>
                <br />
                <span className="text-[#737373]">{record.e_cost}</span>
              </div>
            )
          }
          if (index === 3 && isEdit) {
            return (
              <div>
                <span>{record.e_cost}</span>
                <br />
                <span className="text-[#737373]">---</span>
              </div>
            )
          }
          return <span>{record.e_cost}</span>
        },
      },
    ],
    [isEdit]
  )

  const dataSecond = [
    {
      key: 1,
      machineId: '01234',
      subId: 1,
      name: '片吸込渦巻ポンプ',
      main_element: 10141,
      sub_element: '01',
      group_number: 1,
      element_name: '渦巻ポンプ',
      option: 'なし',
      ability: '55kW',
      quantity: 3,
      manufacturer: 3,
      suppliers_code: 12345,
      agency_name: '△△商事',
      suppliers_code_2: 12345,
      model_number: 'Re121',
      list_price: '850,100,000',
      rate: 8.5,
      ao_cost: '528,400,000',
      gross_profit_margin: '9.5',
      submit_amount: '850,100,000',
      e_cost: '850,100,000',
      isDelete: true,
    },
    {
      key: 2,
      machineId: '01234',
      subId: 1,
      name: '片吸込渦巻ポンプ',
      main_element: 10141,
      sub_element: '01',
      group_number: 1,
      element_name: '渦巻ポンプ',
      option: 'なし',
      ability: '55kW',
      quantity: 3,
      manufacturer: 3,
      suppliers_code: 12345,
      agency_name: '△△商事',
      suppliers_code_2: 12345,
      model_number: 'Re121',
      list_price: '850,100,000',
      rate: 8.5,
      ao_cost: '528,400,000',
      gross_profit_margin: '9.5',
      submit_amount: '850,100,000',
      e_cost: '850,100,000',
    },
    {
      key: 3,
      machineId: '01234',
      subId: 1,
      name: '片吸込渦巻ポンプ',
      main_element: 10141,
      sub_element: '01',
      group_number: 1,
      element_name: '渦巻ポンプ',
      option: 'なし',
      ability: '55kW',
      quantity: 3,
      manufacturer: 3,
      suppliers_code: 12345,
      agency_name: '△△商事',
      suppliers_code_2: 12345,
      model_number: 'Re121',
      list_price: '850,100,000',
      rate: 8.5,
      ao_cost: '528,400,000',
      gross_profit_margin: '9.5',
      submit_amount: '850,100,000',
      e_cost: '850,100,000',
    },
    {
      key: 4,
      machineId: '01234',
      subId: 1,
      name: '片吸込渦巻ポンプ',
      main_element: 10141,
      sub_element: '01',
      group_number: 1,
      element_name: '渦巻ポンプ',
      option: 'なし',
      ability: '55kW',
      quantity: 3,
      manufacturer: 3,
      suppliers_code: 12345,
      agency_name: '△△商事',
      suppliers_code_2: 12345,
      model_number: 'Re121',
      list_price: '850,100,000',
      rate: 8.5,
      ao_cost: '528,400,000',
      gross_profit_margin: '9.5',
      submit_amount: '850,100,000',
      e_cost: '850,100,000',
      isAdd: true,
    },
    {
      key: 5,
      machineId: '01234',
      subId: 1,
      name: '片吸込渦巻ポンプ',
      main_element: 10141,
      sub_element: '01',
      group_number: 1,
      element_name: '渦巻ポンプ',
      option: 'なし',
      ability: '55kW',
      quantity: 3,
      manufacturer: 3,
      suppliers_code: 12345,
      agency_name: '△△商事',
      suppliers_code_2: 12345,
      model_number: 'Re121',
      list_price: '850,100,000',
      rate: 8.5,
      ao_cost: '528,400,000',
      gross_profit_margin: '9.5',
      submit_amount: '850,100,000',
      e_cost: '850,100,000',
    },
    {
      key: 6,
      machineId: '01234',
      subId: 1,
      name: '片吸込渦巻ポンプ',
      main_element: 10141,
      sub_element: '01',
      group_number: 1,
      element_name: '渦巻ポンプ',
      option: 'なし',
      ability: '55kW',
      quantity: 3,
      manufacturer: 3,
      suppliers_code: 12345,
      agency_name: '△△商事',
      suppliers_code_2: 12345,
      model_number: 'Re121',
      list_price: '850,100,000',
      rate: 8.5,
      ao_cost: '528,400,000',
      gross_profit_margin: '9.5',
      submit_amount: '850,100,000',
      e_cost: '850,100,000',
    },
  ]

  const changeStatusTable = () => {
    setIsEdit(!isEdit)
  }

  return (
    <div className="table-estimate-detail">
      <div className="flex mt-7 items-center relative">
        <Button type="text" offFocus prefixIcon={<ArrowLeft />} onClick={handleNavigate} className="button-navigate" />
        <Title level={3} className="title-tab">
          見積比較
        </Title>
      </div>
      <div className="pl-11 flex ">
        <Button
          className={!isEdit ? 'mr-3 w-[150px]' : 'mr-3 w-[150px] bg-[white] text-[#0EA5E9] border-[#0EA5E9]'}
          type={!isEdit ? 'filled' : 'outlined'}
          onClick={changeStatusTable}
          text={!isEdit ? '比較開始' : '比較終了'}
        />

        <Table
          className="table-list-estimate"
          columns={columnFirst}
          dataSource={dataFirst}
          showSorterTooltip={false}
          pagination={false}
          bordered={true}
          rowKey={() => Math.random()}
        />
      </div>
      <div>
        <div className="flex justify-end">
          <Button className="p-0 bg-[#FFE5EF] px-1 h-[30px]" text="削除項目" />
          <Button className="p-0 bg-[#E5FFE6] mx-2 px-1 h-[30px]" text="項目追加" />
          <Button className="p-0 bg-[#FFF5E5] px-1 h-[30px]" text="変更箇所" />
        </div>
        <Table
          className="mt-4"
          columns={columnSecond}
          dataSource={dataSecond}
          showSorterTooltip={false}
          pagination={false}
          bordered={true}
          // rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          tableLayout="fixed"
          rowClassName={(record) => (isEdit && record.isDelete ? 'bg-[#FFE5EF]' : isEdit && record.isAdd ? 'bg-[#E5FFE6]' : '')}
        />
      </div>
    </div>
  )
}

export default listEstimate
