import { logoutApi } from '~/api/authenApi'
import { ROUTER_PATH } from '~/consts/router'
import { User } from '~/interfaces/user'
import { getLocalStorage, setLocalStorage } from '~/utils/storage'

export const storageUser = (user: User) => {
  setLocalStorage('userInfo', JSON.stringify(user))
}

export const getLocalStorageUser = () => {
  const user = getLocalStorage('userInfo')

  return user ? JSON.parse(user) : console.log('user', user)
}

export const deleteLocalStorageUser = () => {
  return localStorage.removeItem('userInfo')
}

export const logout = () => {
  logoutApi()
  localStorage.clear()
  window.location.href = import.meta.env.MODE === 'staging' ? ROUTER_PATH.AUTH.LOGIN : import.meta.env.VITE_PLANET_URL
}
