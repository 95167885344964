import { Checkbox, TreeSelect } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { cloneDeep, isEqual } from 'lodash'
import React, { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { getConstructionView } from '~/api/construction'
import FilterIcon from '~/assets/icons/FilterIcon'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { machines, resetLoaded as resetMachineStateLoaded, setFilterConstruction, setSelectedConstruction } from '~/redux/reducers/machine.slice'
import { addKeyValueSelectTree, getKeyInArr, handleError, pushNotification } from '~/utils/helper'

interface TreeSelectProps {
  keyScreen: any
}

const TreeSelectCustom = ({ keyScreen }: TreeSelectProps) => {
  const params = useParams()

  const [value, setValue] = useState<any[]>([])
  const [valueAll, setValueAll] = useState<any[]>([])
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const dispatch = useAppDispatch()
  const { filterConstruction, selectedConstruction } = useAppSelector(machines)

  const getEstimateConstructions = async () => {
    try {
      dispatch(resetMachineStateLoaded())
      const { data } = await getConstructionView(params.idEstimate)
      const addKeyData = data.data.length ? addKeyValueSelectTree(cloneDeep(data.data)) : []
      dispatch(setFilterConstruction(addKeyData))
      dispatch(setSelectedConstruction(getKeyInArr(addKeyData)))
    } catch (error) {
      const message = handleError(error)
      pushNotification(message, 'error')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getEstimateConstructions()
    }

    if (filterConstruction.length === 0) {
      fetchData()
    }
  }, [])

  const onChange = (newValue: string[]) => {
    const isAllSelected = isEqual(newValue.sort(), valueAll.sort())
    setCheckAll(newValue.length === 0 ? false : isAllSelected)
    setIndeterminate(!isAllSelected && newValue.length > 0)
    setValue(newValue)
    dispatch(setSelectedConstruction(newValue))
  }
  const handleSelectAll = ({ target }: CheckboxChangeEvent) => {
    const valueSelect = getKeyInArr(filterConstruction)
    const afterValueCheckAll = target.checked ? valueSelect : []
    setValue(afterValueCheckAll)
    dispatch(setSelectedConstruction(afterValueCheckAll))
    setIndeterminate(false)
    setCheckAll(target.checked)
  }

  const customTreeData = useMemo(() => {
    const allItem = {
      title: (
        <Checkbox className={'ml-[-5px]'} indeterminate={indeterminate} checked={checkAll} onChange={handleSelectAll}>
          全てを選択する
        </Checkbox>
      ),
      value: 'all',
      key: 'all',
      disableCheckbox: true,
      disabled: true,
      checkable: false,
    }
    return filterConstruction.length !== 0 ? [allItem, ...filterConstruction] : filterConstruction
  }, [filterConstruction, value])
  const tProps = {
    treeData: customTreeData,
    value,
    dropdownStyle: { maxHeight: 700, overflow: 'auto' },
    showCheckedStrategy: TreeSelect.SHOW_ALL,
    onChange,
    showSearch: false,
    showArrow: true,
    treeCheckable: true,
    placeholder: '工事内容を選択',
    suffixIcon: <FilterIcon />,
    style: {
      width: '100%',
      borderRadius: '4px',
    },
  }
  useEffect(() => {
    setValueAll(getKeyInArr(filterConstruction))
  }, [filterConstruction])

  useEffect(() => {
    setValue(selectedConstruction)
    if (selectedConstruction.length > 0) {
      const selectedKeyClone = cloneDeep(selectedConstruction)
      const dataAll = cloneDeep(getKeyInArr(filterConstruction))
      const isCheckAll = isEqual(selectedKeyClone.sort(), dataAll.sort())
      setCheckAll(selectedKeyClone.length === 0 ? false : isCheckAll)
      setIndeterminate(!isCheckAll && selectedKeyClone.length > 0)
    } else {
      setCheckAll(false)
    }
  }, [keyScreen, selectedConstruction])

  return <TreeSelect maxTagCount={'responsive'} {...tProps} />
}

export default React.memo(TreeSelectCustom)
