import React from 'react'

const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5">
      <g id="Trash" transform="translate(-2.25 -2.25)">
        <g id="グループ_829" data-name="グループ 829" transform="translate(3 3)">
          <path
            id="パス_993"
            data-name="パス 993"
            d="M.75.75V0A.75.75,0,0,0,0,.833Zm14.4,0L15.9.833A.75.75,0,0,0,15.15,0Zm-1.422,12.8.745.083h0Zm-1.789,1.6V14.4h0Zm-7.978,0V14.4h0Zm-1.789-1.6-.745.083h0ZM.75,1.5h14.4V0H.75ZM14.4.667l-1.422,12.8,1.491.166L15.9.833Zm-1.422,12.8a1.05,1.05,0,0,1-.344.666l1,1.118a2.55,2.55,0,0,0,.834-1.617Zm-.344.666a1.05,1.05,0,0,1-.7.267v1.5a2.55,2.55,0,0,0,1.7-.649Zm-.7.267H3.961v1.5h7.978Zm-7.978,0a1.05,1.05,0,0,1-.7-.267l-1,1.118a2.55,2.55,0,0,0,1.7.649Zm-.7-.267a1.05,1.05,0,0,1-.344-.666l-1.491.167a2.55,2.55,0,0,0,.834,1.617Zm-.343-.665L1.5.667,0,.833l1.422,12.8Z"
            transform="translate(1.05 2.85)"
            fill="#262626"
          />
          <path
            id="パス_994"
            data-name="パス 994"
            d="M1.96,1.782l-.678-.32h0ZM3.589.75V1.5h0Zm5.123,0V1.5h0ZM10.34,1.782l.679-.32h0ZM11.55,4.35V5.1a.75.75,0,0,0,.679-1.07Zm-10.8,0L.072,4.03A.75.75,0,0,0,.75,5.1ZM2.638,2.1a1.05,1.05,0,0,1,.388-.439L2.223.4a2.55,2.55,0,0,0-.942,1.066Zm.388-.439A1.05,1.05,0,0,1,3.589,1.5V0A2.55,2.55,0,0,0,2.223.4ZM3.589,1.5H8.712V0H3.589Zm5.123,0a1.05,1.05,0,0,1,.562.163L10.077.4A2.55,2.55,0,0,0,8.712,0Zm.562.163a1.05,1.05,0,0,1,.387.439l1.357-.64A2.55,2.55,0,0,0,10.077.4Zm.387.439,1.21,2.568,1.357-.639-1.21-2.568ZM11.55,3.6H.75V5.1h10.8ZM1.428,4.67,2.638,2.1,1.282,1.462.072,4.03Z"
            transform="translate(2.85 -0.75)"
            fill="#262626"
          />
          <path
            id="パス_995"
            data-name="パス 995"
            d="M.75,0a.75.75,0,0,0,0,1.5Zm18,1.5a.75.75,0,0,0,0-1.5Zm-18,0h18V0H.75Z"
            transform="translate(-0.75 2.85)"
            fill="#262626"
          />
          <path
            id="パス_996"
            data-name="パス 996"
            d="M1.5.75A.75.75,0,0,0,0,.75ZM0,5.25a.75.75,0,0,0,1.5,0ZM0,.75v4.5H1.5V.75Z"
            transform="translate(6.45 7.35)"
            fill="#262626"
          />
          <path
            id="パス_997"
            data-name="パス 997"
            d="M1.5.75A.75.75,0,0,0,0,.75ZM0,5.25a.75.75,0,0,0,1.5,0ZM0,.75v4.5H1.5V.75Z"
            transform="translate(10.05 7.35)"
            fill="#262626"
          />
        </g>
      </g>
    </svg>
  )
}

export default DeleteIcon
