import React from 'react'

import { useAppSelector } from '~/redux/hooks'
import { machines } from '~/redux/reducers/machine.slice'

const TitleModal = () => {
  const { infoMachine } = useAppSelector(machines)

  return (
    <div className="border-b py-3">
      <table>
        <thead>
          <tr className="text-sm text-[#171717] font-normal leading-[22px]">
            <th className="p-0">機器ID</th>
            <th className="py-0 pl-6">名称</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-[22px] text-[#171717] font-bold leading-8">
            <td className="px-0">{infoMachine.machineID || ''}</td>
            <td className="pl-6">{infoMachine.machineName || ''}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default TitleModal
